import { CountryCode } from './contants';
export type IntegrationClass = 'erp' | 'weather' | 'machinery' | 'traps' | 'ag_insights';

export type IntegrationProvider =
  | 'Trap View'
  | 'Farmobile'
  | 'Raven Industries'
  | 'Veris Technologies'
  | 'Claas'
  | 'Perfect Flight'
  | 'GroGuru'
  | 'John Deere'
  | 'Pessl Instruments'
  | 'CNHi'
  | 'AGCO'
  | 'Ag Leader'
  | 'Sencrop'
  | 'Stara'
  | 'Trimble Agriculture'
  | 'SAP'
  | 'Nectar'
  | 'Siagri'
  | 'Cropwise'
  | 'Ministry of Agriculture, Fisheries and Food'
  | 'Arable';
export type IntegrationSource = 'product-level' | 'module' | 'native' | 'third-party';
export type IntegrationTag = 'free' | 'paid' | 'beta';
export type IntegrationAvailableLanguages = 'en' | 'pt-BR' | 'es' | 'fr' | 'it' | 'de';
export const languages = [
  {
    value: 'en',
    label: 'English (USA)'
  },
  {
    value: 'pt-BR',
    label: 'Portugês'
  },
  {
    value: 'es',
    label: 'Spanish'
  },
  {
    value: 'fr',
    label: 'Français'
  },
  {
    value: 'it',
    label: 'Italiano'
  },
  {
    value: 'de',
    label: 'Deutsch'
  }
] as const;

export interface Integration {
  name: string;
  provider: IntegrationProvider;
  source: IntegrationSource;
  setup_url: string;
  entitlement_key: string;
  partner_enum?: string;
  description: {
    [key in IntegrationAvailableLanguages]?: string;
  };
  description_extended: {
    [key in IntegrationAvailableLanguages]?: string;
  };
  countries: CountryCode[];
  classes: IntegrationClass[];
  supported_applications: string[];
  required_applications: string[];
  icon: string;
  image: string;
  tags: IntegrationTag[];
  id: string;
}
