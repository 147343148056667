import { ApiOutlined, FileSearchOutlined, RightOutlined } from '@ant-design/icons';
import { Card, Layout } from 'antd';
import leafImage from 'assets/images/cropwise-leaf.svg';
import { INavigationRouteProps } from 'core/navigation/models';
import { UUID } from 'core/utils/BasicModels';
import React from 'react';
import { Img } from 'react-image';
import { Button, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import { history, RoutePaths } from '../../core/history';
import './style.less';
const {
  Content
} = Layout;
const {
  Text,
  Paragraph
} = Typography;
interface IAppCreated {
  appCreated: {
    id?: UUID;
    org_id: UUID;
    name: string;
    short_description: string;
    icon_uri?: string;
    site_uri: string;
    detailed_description?: string;
    fallback_details_language: string;
  };
  apiClientCreated: {
    client_id?: UUID;
    app_id: UUID;
    created_at: Date;
    last_access: Date;
    revoked: boolean;
    name: string;
    redirect_uris: string[];
    client_secret?: string;
  };
}
type ICreateAppProps = INavigationRouteProps<IAppCreated>;
export const AppCreated: React.FC<ICreateAppProps> = props => {
  return <>
            <Content className="cw-content-container" data-sentry-element="Content" data-sentry-source-file="index.tsx">
                    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '50px'
      }}>
                        <Text style={{
          fontSize: '28px'
        }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Your Cropwise App has been created</Text>
                    </div>
                    <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '40px'
      }}>
                        {props.location.state.appCreated.icon_uri ? <Img className="cameraPictureBlock" src={[props.location.state.appCreated.icon_uri, leafImage]} /> : <img className="cameraPictureBlock" src={leafImage} />}
                        <div className={'cw-app-created-right-outlined'}>
                            <RightOutlined data-sentry-element="RightOutlined" data-sentry-source-file="index.tsx" />
                        </div>
                        <div style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '20px',
          marginTop: '-10px'
        }}>
                            <Text strong={true} style={{
            fontSize: '14px'
          }} data-sentry-element="Text" data-sentry-source-file="index.tsx">App Name</Text>
                            <Text style={{
            fontSize: '14px'
          }} data-sentry-element="Text" data-sentry-source-file="index.tsx">{props.location.state.appCreated.name}</Text>
                        </div>
                    </div>
                    {props.location.state.apiClientCreated ? <>
                                <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '20px'
        }}>
                                    <Text style={{
            fontSize: '16px'
          }}>An API Client was generated for your app</Text>
                                    <Text style={{
            fontSize: '16px'
          }}>Copy and save the data below, you won't be able to see the secret again without resetting your client.</Text>
                                </div>

                                <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '20px'
        }}>

                                    <div className={'cw-app-created-client-container'}>
                                        <Text style={{
              fontSize: '14px'
            }}>Client ID</Text>
                                        <div className={'cw-app-created-copy-container'}>
                                            <Paragraph copyable={true}>{props.location.state.apiClientCreated.client_id}</Paragraph>
                                        </div>
                                    </div>

                                    {props.location.state.apiClientCreated.client_secret ? <div className={'cw-app-created-client-container'}>
                                                <Text style={{
              fontSize: '14px'
            }}>Client Secret</Text>
                                                <div className={'cw-app-created-copy-container'}>
                                                    <Paragraph copyable={true}>{props.location.state.apiClientCreated.client_secret}</Paragraph>
                                                </div>
                                            </div> : <></>}

                                </div>
                            </> : <></>}
                    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '30px',
        marginBottom: '20px'
      }}>
                        <Text strong={true} style={{
          fontSize: '28px'
        }} data-sentry-element="Text" data-sentry-source-file="index.tsx">So what are the next steps?</Text>
                    </div>
                    <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignItems: 'center',
        textAlign: 'center'
      }}>
                    <Card hoverable={true} className={'cw-app-card-container'} data-sentry-element="Card" data-sentry-source-file="index.tsx">
                        <FileSearchOutlined className={'cw-app-created-icon-outlined'} data-sentry-element="FileSearchOutlined" data-sentry-source-file="index.tsx" />
                        <div className="cw-app-card-text">
                            <Text strong={true} style={{
              fontSize: '14px'
            }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Explore the docs</Text>
                            <Text style={{
              fontSize: '14px'
            }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Get started and discover what</Text>
                            <Text style={{
              fontSize: '14px'
            }} data-sentry-element="Text" data-sentry-source-file="index.tsx">you can do with the platform</Text>
                        </div>
                    </Card>
                    <Card hoverable={true} className={'cw-app-card-container'} data-sentry-element="Card" data-sentry-source-file="index.tsx">
                        <ApiOutlined className={'cw-app-created-icon-outlined'} data-sentry-element="ApiOutlined" data-sentry-source-file="index.tsx" />
                        <div className="cw-app-card-text">
                            <Text strong={true} style={{
              fontSize: '14px'
            }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Integrate your app</Text>
                            <Text style={{
              fontSize: '14px'
            }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Submit your integration</Text>
                            <Text style={{
              fontSize: '14px'
            }} data-sentry-element="Text" data-sentry-source-file="index.tsx">manifest</Text>
                        </div>
                    </Card>
                    </div>
                    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '40px'
      }}>
                    <Button style={{
          minWidth: 142,
          minHeight: 42,
          borderRadius: '4px'
        }} onClick={() => {
          history.push(RoutePaths.APPS());
        }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                        See my apps
                    </Button>
                    </div>
            </Content>
        </>;
};