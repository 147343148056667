import { SearchOutlined } from '@ant-design/icons';
import { Drawer, Form, Input, Switch, Tooltip } from 'antd';
import { INavigationRouteProps } from 'core/navigation/models';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Button, ButtonType, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import { App } from '../../app-redux/reducers/apps.reducer';
import { CreateEntitlementDTO, EntitlementBasicDataForDTO, EntitlementDTO, UpdateEntitlementDTO } from '../../core/entitlements/models';
import { UUID } from '../../core/utils/BasicModels';
import { EditIcon } from '../../pages/new-license-plan/images/EditIcon';
import { EntitlementsTable } from '../entitlements-table';
import { ManageEntitlementDrawer } from '../manage-entitlement-drawer';
import './style.less';
const {
  Text
} = Typography;
interface IEntitlementDrawerProps extends INavigationRouteProps<{}> {
  currentApp: App | null;
  appEntitlements: {
    id: string;
    name: string;
    key: string;
  }[];
  drawerVisible: boolean;
  preSelectedKeys: UUID[];
  onCancelEntitlementDrawer: () => void;
  onSaveEntitlementDrawer: () => void;
  onSubmit: (entitlement: any) => void;
  onDelete: (entitlementId: UUID, currentIndex: number) => void;
  onCreateEntitlement: (createEntitlementDTO: CreateEntitlementDTO) => void;
  onEditEntitlement: (entitlementId: UUID, updateEntitlementDTO: UpdateEntitlementDTO, currentIndex: number) => void;
  onSetEntitlementsOnPlan: (entitlementIds: UUID[], entitlements: {
    id: string;
    name: string;
    key: string;
  }[]) => void;
}
export const getEntitlementsAndItsIdsFromCurrentPlan = (currentLicensePlan: {
  entitlements?: {
    id: string;
    name: string;
    key: string;
  }[];
} | undefined): Array<{
  id: string;
  name: string;
  key: string;
}[] | UUID[]> => {
  const planEntitlementsIds: UUID[] = [];
  const plansEntitlements: {
    id: string;
    name: string;
    key: string;
  }[] = [];
  currentLicensePlan?.entitlements?.forEach((entitlement: {
    id: string;
    name: string;
    key: string;
  }) => {
    planEntitlementsIds.push(entitlement.id);
    plansEntitlements.push(entitlement);
  });
  return [plansEntitlements, planEntitlementsIds];
};
export const EntitlementDrawerComponent: React.FC<IEntitlementDrawerProps> = props => {
  const [rerenderTable, setRerenderTable] = useState<boolean>(false);
  const [preSelectedKeys, setPreSelectedKeys] = useState<UUID[]>([]);
  const [allEntitlementsSelected, setAllEntitlementsSelected] = useState<boolean>(false);
  const [selectedEntitlementsIds, setSelectedEntitlementsIds] = useState<UUID[]>([]);
  const [currentEditableEntitlementIndex, setCurrentEditableEntitlementIndex] = useState<number | undefined>(undefined);
  const [currentEditableEntitlement, setCurrentEditableEntitlement] = useState<EntitlementDTO | undefined>(undefined);
  const [entitlementsData, setEntitlementsData] = useState<{
    id: string;
    name: string;
    key: string;
  }[]>([]);
  const [isCreateDrawerVisible, setIsCreatedDrawerVisible] = useState<boolean>(false);
  const [isLoadingPlanData, setIsLoadingPlanData] = useState<boolean>(true);
  const entitlementColumns = [{
    title: '',
    dataIndex: 'edit',
    key: 'edit',
    align: ('right' as const),
    render: (text: any, record: EntitlementDTO, index: any) => <div>
            <a onClick={() => {
        setCurrentEditableEntitlement(record);
        setCurrentEditableEntitlementIndex(index);
        setIsCreatedDrawerVisible(true);
      }}>
              <EditIcon />
            </a>
          </div>
  }];
  useEffect(() => {
    setEntitlementsData(props.appEntitlements);
    setIsLoadingPlanData(false);
  }, [props.appEntitlements]);
  useEffect(() => setAllEntitlementsSelected(props.preSelectedKeys.length === props.appEntitlements.length), [props.preSelectedKeys, props.appEntitlements, props.drawerVisible]);
  useEffect(() => {
    setPreSelectedKeys(props.preSelectedKeys);
    setSelectedEntitlementsIds(props.preSelectedKeys);
  }, [props.preSelectedKeys]);
  const handleSubmit = (entitlementBasicDataForDTO: EntitlementBasicDataForDTO) => {
    if (currentEditableEntitlement) {
      handleEntitlementEdition(entitlementBasicDataForDTO);
    } else {
      handleEntitlementCreation(entitlementBasicDataForDTO);
    }
    handleEditCreateDrawerClose();
  };
  const handleEntitlementDelete = () => {
    props.onDelete(currentEditableEntitlement?.id ?? '', currentEditableEntitlementIndex ?? -1);
    handleEditCreateDrawerClose();
  };
  const handleEntitlementEdition = (entitlementBasicDataForDTO: EntitlementBasicDataForDTO) => {
    const updateEntitlementDTO: UpdateEntitlementDTO = {
      ...entitlementBasicDataForDTO
    };
    props.onEditEntitlement(currentEditableEntitlement?.id ?? '', updateEntitlementDTO, currentEditableEntitlementIndex ?? -1);
  };
  const handleEntitlementCreation = (entitlementBasicDataForDTO: EntitlementBasicDataForDTO) => {
    const createEntitlementDTO: CreateEntitlementDTO = {
      ...entitlementBasicDataForDTO,
      app_id: props.match.params.appId || ''
    };
    props.onCreateEntitlement(createEntitlementDTO);
  };
  const handleDrawerResetClose = async () => {
    form.resetFields();
    await handleFilter();
    props.onCancelEntitlementDrawer();
  };
  const handleDrawerSaveClose = async () => {
    form.resetFields();
    await handleFilter();
    props.onSaveEntitlementDrawer();
  };
  const handleDrawerClose = async () => {
    await handleDrawerResetClose();
    const shouldRerender = !isEqual(preSelectedKeys, selectedEntitlementsIds);
    setRerenderTable(shouldRerender);
    setRerenderTable(false);
  };
  const handleEditCreateDrawerClose = () => {
    setIsCreatedDrawerVisible(false);
    setCurrentEditableEntitlement(undefined);
    setCurrentEditableEntitlementIndex(undefined);
  };
  const handleFilter = async () => {
    const searchValue = (await form.validateFields(['search'])).search;
    if (searchValue) {
      setEntitlementsData(props.appEntitlements.filter(entitlement => {
        return entitlement.key.toLowerCase().includes(searchValue.toLowerCase()) || entitlement.name.toLowerCase().includes(searchValue.toLowerCase());
      }));
    } else {
      setEntitlementsData(props.appEntitlements);
    }
  };
  const handleEntitlementsSelector = () => {
    const newStatus = !allEntitlementsSelected;
    setAllEntitlementsSelected(newStatus);
    if (newStatus) {
      const entitlementsIds: UUID[] = props.appEntitlements.map(({
        id
      }) => id);
      setSelectedEntitlementsIds(entitlementsIds);
      setPreSelectedKeys(entitlementsIds);
    } else {
      setSelectedEntitlementsIds([]);
      setPreSelectedKeys([]);
    }
  };
  const DrawerButtons: React.FC = () => {
    return <div className="entitlements-drawer-buttons" data-sentry-component="DrawerButtons" data-sentry-source-file="index.tsx">
          <div className="entitlements-drawer-buttons__wrapper">
            <Button className="entitlement-drawer-create-button" onClick={() => {
          setIsCreatedDrawerVisible(true);
        }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
              Create a new Entitlement
            </Button>
            <div className="entitlements-drawer-buttons__wrapper__right">
              <Button onClick={handleDrawerClose} className="entitlement-drawer-cancel-button" data-sentry-element="Button" data-sentry-source-file="index.tsx">Cancel</Button>
              <Button style={{
            width: '84px'
          }} type={ButtonType.primary} onClick={async () => {
            props.onSetEntitlementsOnPlan(selectedEntitlementsIds, props.appEntitlements.filter(({
              id
            }) => selectedEntitlementsIds.includes(id)));
            await handleDrawerSaveClose();
          }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                Save
              </Button>
            </div>
          </div>
        </div>;
  };
  const [form] = Form.useForm();
  return <div data-testid="entitlements-drawer" data-sentry-component="EntitlementDrawerComponent" data-sentry-source-file="index.tsx">
      <Drawer className="entitlements-drawer" title={'Manage Plans entitlements'} placement="right" closable={true} onClose={handleDrawerClose} open={props.drawerVisible} width={550} data-sentry-element="Drawer" data-sentry-source-file="index.tsx">
        <div className="entitlements-drawer__wrapper">
          <div className="entitlements-drawer__wrapper_first_column">
            <Text data-sentry-element="Text" data-sentry-source-file="index.tsx">Entitlements available on {props.currentApp?.name || 'the current App'}</Text>
            <div className="entitlements-drawer-header">
              <Form form={form} data-sentry-element="Form" data-sentry-source-file="index.tsx">
                <Form.Item name="search" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                  <Input className="entitlements-drawer-header__input" onChange={handleFilter} placeholder={'Search by name or key'} prefix={<SearchOutlined />} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
                </Form.Item>
              </Form>
              <div className="entitlements-drawer-header__switch">
                <Text style={{
                marginRight: '20px'
              }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Select All Entitlements</Text>
                <Tooltip title="Click to select or deselect all entitlements" data-sentry-element="Tooltip" data-sentry-source-file="index.tsx">
                  <Switch checked={allEntitlementsSelected} onChange={handleEntitlementsSelector} data-sentry-element="Switch" data-sentry-source-file="index.tsx" />
                </Tooltip>
              </div>
            </div>
          </div>
          <ManageEntitlementDrawer drawerVisible={isCreateDrawerVisible} onCloseDrawer={handleEditCreateDrawerClose} onSubmit={handleSubmit} onDelete={handleEntitlementDelete} initialEntitlement={currentEditableEntitlement} data-sentry-element="ManageEntitlementDrawer" data-sentry-source-file="index.tsx" />
          <EntitlementsTable rerender={rerenderTable} entitlementsData={entitlementsData} isLoadingPlanData={isLoadingPlanData} customColumns={entitlementColumns} preSelectedKeys={preSelectedKeys ?? []} enableRowSelection={true} onEntitlementSelection={(entitlementsIds: UUID[]) => {
          setSelectedEntitlementsIds(entitlementsIds);
          setAllEntitlementsSelected(entitlementsIds.length === props.appEntitlements.length);
        }} data-sentry-element="EntitlementsTable" data-sentry-source-file="index.tsx" />
          <DrawerButtons data-sentry-element="DrawerButtons" data-sentry-source-file="index.tsx" />
        </div>
      </Drawer>
    </div>;
};
export const EntitlementDrawer = withRouter(EntitlementDrawerComponent);