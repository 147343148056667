import { ACTION_DISPATCH_DEV_CONSOLE_ACCESS, ACTION_VERIFY_RBAC_PERMISSIONS } from 'app-redux/actions/rbac.actions';
import { AllowedAndDeniedOperationsDTO } from 'core/rbac/models';
import { PayloadAction } from 'core/utils/actionUtils';

export interface RbacState {
  allowedAndDeniedOperationsDTO?: AllowedAndDeniedOperationsDTO;
  devConsoleAccess?: AllowedAndDeniedOperationsDTO;
  loading?: boolean;
  error?: any;
}

const initialState: RbacState = {
};

export const RbacReducer = (state: RbacState = initialState, action: PayloadAction<any>): RbacState => {
  switch (action.type) {
    case ACTION_VERIFY_RBAC_PERMISSIONS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_VERIFY_RBAC_PERMISSIONS.SUCCESS:
      return {
        ...state,
        allowedAndDeniedOperationsDTO: action.payload,
        loading: false,
      };
    case ACTION_DISPATCH_DEV_CONSOLE_ACCESS:
      return {
        ...state,
        devConsoleAccess: action.payload,
        allowedAndDeniedOperationsDTO: action.payload,
        loading: false,
      };
    case ACTION_VERIFY_RBAC_PERMISSIONS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
