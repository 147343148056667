import { AxiosResponse } from 'axios';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import qs from 'querystring';
import { Dispatch } from 'redux';
import { OAuth2ClientDTO } from '../../core/api-clients/models';
import { axiosRequest } from '../../core/axios';
import { getHeaders } from '../../core/common-methods';
import { PageDTO, UUID } from '../../core/utils/BasicModels';

export const ACTION_CREATE_API_CLIENT: RequestAction = createRequestAction('CREATE_API_CLIENT_REQUEST');
export const ACTION_GET_API_CLIENTS_LIST: RequestAction = createRequestAction('GET_API_CLIENTS_LIST_REQUEST');
export const ACTION_GET_API_CLIENT: RequestAction = createRequestAction('GET_API_CLIENT_REQUEST');
export const ACTION_DELETE_API_CLIENT: RequestAction = createRequestAction('DELETE_API_CLIENT_REQUEST');
export const ACTION_SET_CURRENT_API_CLIENT = 'SET_CURRENT_API_CLIENT_REQUEST';
export const ACTION_RESET_GET_API_CLIENT: RequestAction = createRequestAction('RESET_GET_API_CLIENT_REQUEST');
export const ACTION_EDIT_API_CLIENT: RequestAction = createRequestAction('EDIT_API_CLIENT_REQUEST');

export const fetchApiClients = (appId: UUID, pageNumber: number, pageSize: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_GET_API_CLIENTS_LIST.REQUEST });

    const pageInfo = {
      page: pageNumber,
      size: pageSize,
    };

    const allClients = await axiosRequest
            .get<any, AxiosResponse<PageDTO<OAuth2ClientDTO>>>(`/v2/apps/${appId}/oauth2-clients?${qs.stringify(pageInfo)}`,
      {
        headers: {
          ...getHeaders().common,
          // tslint:disable-next-line:no-duplicate-string  TODO(marcela): use user preference
          'Accept-Language': 'pt-BR',
        },
      });

    const data = allClients.data;

    dispatch({ type: ACTION_GET_API_CLIENTS_LIST.SUCCESS, payload: data });

    return data;
  } catch (e) {
    dispatch({ type: ACTION_GET_API_CLIENTS_LIST.ERROR, payload: e });
    throw e;
  }
};

export const getApiClientById = (clientId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_GET_API_CLIENT.REQUEST });

    const apiClient = await axiosRequest
            .get<any, AxiosResponse<OAuth2ClientDTO>>(`/v2/oauth2-clients/${clientId}`,
      {
        headers: {
          ...getHeaders().common,
          'Accept-Language': 'pt-BR',
        },
      });

    const data = apiClient.data;

    dispatch({ type: ACTION_GET_API_CLIENT.SUCCESS, payload: data });

    return data;
  } catch (e) {
    dispatch({ type: ACTION_GET_API_CLIENT.ERROR, payload: e });
    throw e;
  }
};

export const createApiClient = (valuesRequest: OAuth2ClientDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_API_CLIENT.REQUEST });

    const apiClient = await axiosRequest
            .post<any, AxiosResponse<OAuth2ClientDTO>>(`/v2/oauth2-clients`, valuesRequest,
      {
        headers: {
          ...getHeaders().common,
          'Accept-Language': 'pt-BR',
        },
      });

    const data = apiClient.data;

    dispatch({ type: ACTION_CREATE_API_CLIENT.SUCCESS, payload: data });
    return data;

  } catch (e) {
    dispatch({ type: ACTION_CREATE_API_CLIENT.ERROR, payload: e });
    throw e;
  }
};

export const deleteApiClient = (apiClientId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_API_CLIENT.REQUEST });

    const deleteApiClientResponse = await axiosRequest
            .delete(`/v2/oauth2-clients/${apiClientId}`,
      {
        headers: {
          ...getHeaders().common,
          'Accept-Language': 'pt-BR',
        },
      });

    dispatch({ type: ACTION_DELETE_API_CLIENT.SUCCESS, payload: deleteApiClientResponse });

    return deleteApiClientResponse;
  } catch (e) {
    dispatch({ type: ACTION_DELETE_API_CLIENT.ERROR, payload: e });
    throw e;
  }
};

export const editApiClient = (clientId: string, valuesRequest: OAuth2ClientDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_EDIT_API_CLIENT.REQUEST });

    const apiClientValues = {
      ...valuesRequest,
      fallback_details_language: 'pt-BR',
    };

    const apiClientEdited = await axiosRequest
            .put<any, AxiosResponse<OAuth2ClientDTO>>(`/v2/oauth2-clients/${clientId}`, apiClientValues,
      {
        headers: {
          ...getHeaders().common,
          'Accept-Language': 'pt-BR',
          'Content-Language': 'pt-BR',
        },
      });

    dispatch({ type: ACTION_EDIT_API_CLIENT.SUCCESS, payload: apiClientEdited.data });

    return apiClientEdited.data;

  } catch (e) {
    dispatch({ type: ACTION_EDIT_API_CLIENT.ERROR, payload: e });
  }
};

export const resetGetApiClient = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_RESET_GET_API_CLIENT.REQUEST });

    const data = '';

    dispatch({ type: ACTION_RESET_GET_API_CLIENT.SUCCESS, payload: data });

    return data;
  } catch (e) {
    dispatch({ type: ACTION_RESET_GET_API_CLIENT.ERROR, payload: e });
    throw e;
  }
};

export const setCurrentApiClient = (currentClientId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ACTION_SET_CURRENT_API_CLIENT, payload: currentClientId });
};

export const requestDevAuth = async () => {

  try {
    await axiosRequest.post('/v2/notifications/slack/dev-auth', undefined,
      {
        headers: {
          ...getHeaders().common,
        },
      });
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.log(e);
  }
};
