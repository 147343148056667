import axios from 'axios';
import { getHeaders } from 'core/common-methods';
import { createRequestAction } from 'core/utils/actionUtils';
import { Integration } from 'pages/integrations/utils/types';
import { Dispatch } from 'redux';

const connectAxiosInstance = axios.create({
  baseURL: process.env.CONNECT_API_URL
});

export const ACTION_DELETE_INTEGRATION = createRequestAction('ACTION_DELETE_INTEGRATION_REQUEST');
export const ACTION_SAVE_INTEGRATION = createRequestAction('ACTION_SAVE_INTEGRATION_REQUEST');
export const ACTION_GET_INTEGRATIONS_LIST = createRequestAction('GET_INTEGRATIONS_LIST_REQUEST');

export const fetchIntegrations = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_GET_INTEGRATIONS_LIST.REQUEST });

    const response = await connectAxiosInstance.get('/v2/connect_integrations', {
      headers: getHeaders().common
    });

    const integrations = response.data.map((integration: any) => {
      delete integration.is_active;
      delete integration.is_configured;
      return {
        ...integration,
        supported_applications: integration?.supported_applications?.map(
          (app: any) => app.id
        ) as string[],
        required_applications: integration?.required_applications?.map(
          (app: any) => app.id
        ) as string[]
      };
    });

    dispatch({ type: ACTION_GET_INTEGRATIONS_LIST.SUCCESS, payload: integrations });
  } catch (e) {
    dispatch({ type: ACTION_GET_INTEGRATIONS_LIST.ERROR, payload: e });
  }
};

export const saveIntegration =
  (integration: Partial<Integration>) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ACTION_SAVE_INTEGRATION.REQUEST });

      const response = await connectAxiosInstance.request<Integration>({
        method: integration.id ? 'PATCH' : 'POST',
        headers: getHeaders().common,
        url: '/v2/connect_integrations',
        data: integration
      });

      dispatch({ type: ACTION_SAVE_INTEGRATION.SUCCESS, payload: response.data });
      return response.data;
    } catch (e) {
      dispatch({ type: ACTION_SAVE_INTEGRATION.ERROR, payload: e });
    }
  };

export const deleteIntegration = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_INTEGRATION.REQUEST });

    const response = await connectAxiosInstance.delete<Integration>(
      `/v2/connect_integrations?id=${id}`,
      {
        headers: getHeaders().common
      }
    );

    dispatch({ type: ACTION_DELETE_INTEGRATION.SUCCESS, payload: response.data });
    return response.data;
  } catch (e) {
    dispatch({ type: ACTION_DELETE_INTEGRATION.ERROR, payload: e });
  }
};
