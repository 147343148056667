import { Tooltip } from 'antd';
import { InfoIcon } from 'pages/integrations/images/InfoIcon';
import { FlexContainer } from 'pages/integrations/styled';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
export const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  margin-bottom: 2px;

  .optional {
    color: #c2c7d0;
  }
`;
interface Props {
  inputs: {
    label: string;
    tooltip?: string;
    optional?: boolean;
    containerStyle?: React.CSSProperties;
    render: () => ReactNode;
  }[];
}
export const FormGroup: React.FC<Props> = ({
  inputs
}) => {
  return <FlexContainer gap={12} style={{
    width: '100%',
    flexWrap: 'wrap'
  }} align="flex-start" data-sentry-element="FlexContainer" data-sentry-component="FormGroup" data-sentry-source-file="form-group.tsx">
      {inputs.map(input => <div style={{
      width: 'calc(50% - 6px)',
      ...(input.containerStyle ?? {})
    }} key={input.label}>
          <Label>
            <span>{input.label}</span>
            {input.optional && <span className="optional"> (optional)</span>}
            {input.tooltip && <Tooltip title={input.tooltip} color="#fff" overlayInnerStyle={{
          color: '#14151C',
          borderRadius: 4,
          textAlign: 'center'
        }}>
                <span style={{
            verticalAlign: 'middle',
            margin: '-2px 0 0 4px',
            display: 'inline-block',
            height: 18
          }}>
                  <InfoIcon />
                </span>
              </Tooltip>}
          </Label>
          {input.render()}
        </div>)}
    </FlexContainer>;
};