import {
  ACTION_DELETE_INTEGRATION,
  ACTION_GET_INTEGRATIONS_LIST,
  ACTION_SAVE_INTEGRATION
} from 'app-redux/actions/integrations.actions';
import { PayloadAction } from 'core/utils/actionUtils';
import { Integration } from 'pages/integrations/utils/types';

export interface IntegrationsState {
  integrations: Integration[];
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  error: string | null;
}

const initialState: IntegrationsState = {
  integrations: [],
  loading: false,
  saving: false,
  deleting: false,
  error: null
};

export const integrationsReducer = (
  state: IntegrationsState = initialState,
  action: PayloadAction<Integration[]> | PayloadAction<string>
): IntegrationsState => {
  switch (action.type) {
    case ACTION_GET_INTEGRATIONS_LIST.REQUEST:
      return {
        ...state,
        loading: true
      };
    case ACTION_DELETE_INTEGRATION.REQUEST:
      return {
        ...state,
        deleting: true
      };
    case ACTION_SAVE_INTEGRATION.REQUEST:
      return {
        ...state,
        saving: true
      };
    case ACTION_GET_INTEGRATIONS_LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        integrations: (action as PayloadAction<Integration[]>).payload
      };
    case ACTION_SAVE_INTEGRATION.SUCCESS:
    case ACTION_DELETE_INTEGRATION.SUCCESS:
      return {
        ...state,
        saving: false,
        deleting: false
      };
    case ACTION_SAVE_INTEGRATION.ERROR:
    case ACTION_DELETE_INTEGRATION.ERROR:
    case ACTION_GET_INTEGRATIONS_LIST.ERROR:
      return {
        ...state,
        loading: false,
        saving: false,
        deleting: false,
        error: (action as PayloadAction<string>).payload
      };
    default:
      return state;
  }
};
