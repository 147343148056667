import { CloseCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
interface IPlanFormKeyModalsProps {
  handleClose: any;
  isVisible: boolean;
}
export const PlanFormKeyModals: React.FC<IPlanFormKeyModalsProps> = props => {
  return <Modal open={props.isVisible} onOk={props.handleClose} onCancel={props.handleClose} width="384px" footer={[<Button key={1} type={ButtonType.primary} size="large" onClick={props.handleClose}>
                    Ok
                </Button>]} data-sentry-element="Modal" data-sentry-component="PlanFormKeyModals" data-sentry-source-file="index.tsx">
        <div className="modal-placeholder">
            <CloseCircleFilled className="modal-error" data-sentry-element="CloseCircleFilled" data-sentry-source-file="index.tsx" />
            <p style={{
        paddingTop: '20px'
      }}>
                Sorry, the Quota Key should be unique.
            </p>
        </div>
    </Modal>;
};