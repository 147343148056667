interface BaseDTO {
  updated_at: string;
  updated_by: string;
  created_at: string;
  created_by: string;
  deleted: boolean;
}

export enum AcceptedEntityTypes {
  ORGANIZATION = 'ORGANIZATION',
  REGION = 'REGION',
  PROPERTY = 'PROPERTY',
  FIELD = 'FIELD',
  TASK = 'TASK',
  EQUIPMENT = 'EQUIPMENT',
  WORKER = 'WORKER',
  CROP_ZONE = 'CROP_ZONE',
  CROP_CYCLE = 'CROP_CYCLE',
  ACCOUNT = 'ACCOUNT',
}

export enum AcceptedUnits {
  HECTARE = 'HECTARE',
  UNIT = 'UNIT',
  CUSTOM = 'CUSTOM',
}

export interface EntitlementDTO {
  id: string;
  key: string;
  name: string;
}

export interface QuotaDTO {
  id: string;
  key: string;
  name: string;
  system: boolean;
  entity_type: AcceptedEntityTypes;
  unit: AcceptedUnits;
  max_limit: number;
  threshold: number;
}

export interface PlanDTO extends BaseDTO {
  id: string;
  name: string;
  app: string;
  valid_countries: string[];
  commercial_offers_ids: string[];
  quotas: QuotaDTO[]
  entitlements: EntitlementDTO[]
}

export interface UpdateOperation {
  operation: "ADD" | "REMOVE",
  entitlement_id: string
}

export interface Update {
  updates: UpdateOperation[]
}


export interface CreatePlanDTO {
  name: string;
  app: string;
  valid_countries: string[];
  commercial_offers_ids: string[];
  entitlements: Update
}
export type UpdatePlanDTO = CreatePlanDTO