import { combineReducers } from 'redux';
import { accountsReducer, AccountsState } from './accounts.reducer';
import { apiClientReducer, ApiClientState } from './api-clients.reducer';
import { appsReducer, AppsState } from './apps.reducer';
import { errorHandlerReducer, ErrorHandlerState } from './error-handler.reducer';
import { OrgsState, orgsStateReducer } from './orgs.reducer';
import { plansReducer, PlansState } from './plans.reducer';
import { RbacReducer, RbacState } from './rbac.reducer';
import { rolloutReducer, RolloutState } from './rollout.reducer';
import { TermsReducer, TermsState } from './terms.reducer';
import { integrationsReducer, IntegrationsState } from './integrations.reducer';

export interface AppState {
  accounts: AccountsState;
  apps: AppsState;
  apiClients: ApiClientState;
  orgsState: OrgsState;
  errorHandlerState: ErrorHandlerState;
  plans: PlansState;
  terms: TermsState;
  rbac: RbacState;
  rollout: RolloutState;
  integrations: IntegrationsState;
}

export const rootReducer = combineReducers<AppState>({
  accounts: accountsReducer,
  apps: appsReducer,
  apiClients: apiClientReducer,
  orgsState: orgsStateReducer,
  errorHandlerState: errorHandlerReducer,
  plans: plansReducer,
  terms: TermsReducer,
  rbac: RbacReducer,
  rollout: rolloutReducer,
  integrations: integrationsReducer
});
