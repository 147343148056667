import { ExclamationCircleFilled } from '@ant-design/icons';
import { Layout, Modal } from 'antd';
import { createApiClient } from 'app-redux/actions/api-client.actions';
import { createApps } from 'app-redux/actions/apps.actions';
import { AppState } from 'app-redux/reducers';
import { getCurrentAppLoadingStatus } from 'app-redux/selectors/apps.selector';
import { getErrorMessage } from 'app-redux/selectors/error-handler.selector';
import { getCurrentOrgId } from 'app-redux/selectors/orgs.selector';
import { AppPageHeader } from 'components/headers/app-details-page-header';
import { TechnologyCard } from 'components/technology-card';
import { OAuth2ClientDTO } from 'core/api-clients/models';
import { AppConstants } from 'core/app.constants';
import { DeveloperAppDTO } from 'core/apps/models';
import { ErrorMessageDTO } from 'core/AxiosResponseInterceptor';
import { INavigationRouteProps } from 'core/navigation/models';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Button, ButtonType, Input, InputType, Switch, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import { AvailableLanguagesDropdown } from '../../components/available-languages-dropdown';
import { UploadAppIcon, UploadTypes } from '../../components/upload-app-icon';
import { history, RoutePaths } from '../../core/history';
import './style.less';
const {
  Content
} = Layout;
const {
  Text
} = Typography;
const Label = styled(Text)`
  display: block;
  color: #2F3031;
  font-size: 16px;
  font-weight: 400;
`;
const SmallTitle = styled(Text)`
  margin-top: 20px;
  display: block;
  color: #2F3031;
  font-size: 20px;
  font-weight: 400;
`;
interface ICreateAppProps extends INavigationRouteProps<{}> {
  isLoadingApp?: boolean;
  errorData?: ErrorMessageDTO;
  currentOrgId: string;
  dispatchCreateApps: (valuesRequest: any) => any;
  dispatchCreateClient: (valuesClientRequest: any) => any;
}

// tslint:disable-next-line:no-big-function
export const CreateApp: React.FC<ICreateAppProps> = props => {
  const [selectedTechnology, setSelectedTechnology] = useState<string>();
  const [apiClient, setApiClient] = useState(false);
  const [apiSecret, setApiSecret] = useState(false);
  const [showing, setShowing] = useState(false);
  const [visibleErrorModal, setVisibleErrorModal] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [iconData, setIconData] = useState<string>('');
  const [published, setPublished] = useState(false);
  useEffect(() => {
    if (props.errorData) {
      setIsButtonLoading(false);
    }
  }, [props.errorData]);
  const handleCreateApp = async (values: any) => {
    setIsButtonLoading(true);
    const valuesReq: DeveloperAppDTO = {
      apple_store_uri: null,
      details_review_status: null,
      play_store_uri: null,
      supported_apps: null,
      type: null,
      id: undefined,
      org_id: props.currentOrgId,
      name: values.appName,
      published: published ? 1 : 0,
      short_description: values.appDescription,
      detailed_description: undefined,
      site_uri: values.appDomain,
      icon_uri: iconData ? iconData : undefined,
      language: values.appLanguage
    };
    const appAuthUris: string[] = [values.appAuthURI];
    if (valuesReq.name && valuesReq.short_description && valuesReq.site_uri) {
      let apiClientCreated;
      const appCreated = await props.dispatchCreateApps(valuesReq);
      if (apiClient) {
        const valuesReqClient: OAuth2ClientDTO = {
          app_id: appCreated.id,
          name: values.appClientName,
          redirect_uris: appAuthUris,
          create_secret: apiSecret
        };
        apiClientCreated = await props.dispatchCreateClient(valuesReqClient);
      }
      history.push({
        pathname: RoutePaths.APP_CREATED(),
        state: {
          appCreated,
          apiClientCreated
        }
      });
      setIsButtonLoading(false);
    } else {
      setVisibleErrorModal(true);
      setIsButtonLoading(false);
    }
  };
  const appTechnologies = [{
    name: 'React'
  }, {
    name: 'Angular'
  }, {
    name: 'Vue'
  }, {
    name: 'Android'
  }, {
    name: 'iOS'
  }, {
    name: 'React Native'
  }];
  const onSwitchChange = (checked: boolean) => {
    setApiClient(checked);
    setShowing(checked);
  };
  const onSwitchSecretChange = (checked: boolean) => {
    setApiSecret(checked);
  };
  const handleClose = () => {
    setVisibleErrorModal(false);
  };
  return <>
        <Content className="cw-content-container" data-sentry-element="Content" data-sentry-source-file="index.tsx">
            <Modal open={visibleErrorModal} onOk={() => handleClose()} onCancel={() => handleClose()} width="384px" footer={[<Button key={1} type={ButtonType.primary} size="large" onClick={() => handleClose()}>
                      Ok
                  </Button>]} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
              <div className="modal-placeholder">
                <ExclamationCircleFilled className="name-error" data-sentry-element="ExclamationCircleFilled" data-sentry-source-file="index.tsx" />
                <p style={{
            paddingTop: '20px'
          }}>To create an app, you must add a name, a short description and its domain.</p>
              </div>
            </Modal>
            <Formik initialValues={{
        appName: '',
        appDescription: '',
        appDomain: '',
        appClientName: '',
        appAuthURI: '',
        appLanguage: 'en'
      }} onSubmit={(values, {
        setSubmitting
      }) => {
        alert(JSON.stringify(values, null, 2));
        setSubmitting(false);
      }} data-sentry-element="Formik" data-sentry-source-file="index.tsx">
                {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting
        }) => <form onSubmit={handleSubmit}>
                <AppPageHeader isPublished={published} onPublishClick={() => setPublished(!published)} canChangeThisApp={true} handleSendChanges={handleCreateApp} formValues={values} isSubmitting={isSubmitting} submitButtonTitle={'Create App'} />
                <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
                  <div className="cw-content-row">
                  <Text strong={true} style={{
                fontSize: '16px'
              }}>App Details</Text>
                    <div style={{
                marginLeft: '100px'
              }}>
                      <span style={{
                  marginRight: '30px',
                  color: 'black'
                }}>Fallback language:</span>
                      <AvailableLanguagesDropdown onClick={languageKey => setFieldValue('appLanguage', languageKey)} preSelectedLanguageKey={values.appLanguage} />
                    </div>
                  </div>
                    <div style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}>
                        <div className="cw-create-app-column1">
                            <div className="cw-app-details-input">
                                <Label data-testid="label">Name your App</Label>
                                <Input data-testid="inputId" name="appName" type={InputType.success} value={values.appName} onChange={handleChange} placeholder={'App\'s Name'} autoFocus={true} maxLength={AppConstants.EMAIL_MAX_LENGTH} />
                            </div>
                            <div className="cw-app-details-input">
                                <Label data-testid="label">Short Description</Label>
                                <Input data-testid="inputId" name="appDescription" type={InputType.success} value={values.appDescription} onChange={handleChange} placeholder={'App\'s short Description'} autoFocus={true} maxLength={AppConstants.EMAIL_MAX_LENGTH} />
                            </div>
                            <div className="cw-app-details-input">
                              <UploadAppIcon defaultImgUrl={''} mode={UploadTypes.BASE64} uploadAppIcon={icon => setIconData((icon as string))} />
                            </div>
                            <div className="cw-app-details-input">
                                <Label data-testid="label">Site URI</Label>
                                <Input data-testid="inputId" name="appDomain" type={InputType.success} value={values.appDomain} onChange={handleChange} placeholder={'App\'s domain'} autoFocus={true} maxLength={AppConstants.EMAIL_MAX_LENGTH} />
                            </div>
                            <SmallTitle>You may also want to:</SmallTitle>
                            <div style={{
                  paddingTop: '20px',
                  paddingBottom: '30px'
                }}>
                              <div style={{
                    float: 'right',
                    marginLeft: '300px'
                  }}><Switch onChange={onSwitchChange} /> </div>
                              <Label data-testid="label">Create an API Client</Label>
                            </div>
                        </div>
                          <div style={{
                display: showing ? 'flex' : 'none'
              }} className="cw-create-app-column2">
                          <Text strong={true} style={{
                  fontSize: '16px'
                }}>Application Client</Text>
                              <div>
                                <div className="cw-app-details-input">
                                    <Label data-testid="label">Client Name</Label>
                                    <Input data-testid="inputId" name="appClientName" type={InputType.success} value={values.appClientName} onChange={handleChange} placeholder={'Protector Frontend'} autoFocus={true} maxLength={AppConstants.EMAIL_MAX_LENGTH} />
                                </div>
                                <div className="cw-app-details-input">
                                    <Label data-testid="label">OAuth2 Redirect URI</Label>
                                    <Input data-testid="inputId" name="appAuthURI" type={InputType.success} value={values.appAuthURI} onChange={handleChange} placeholder={'https://protector.cropwise.com/auth'} autoFocus={true} maxLength={AppConstants.EMAIL_MAX_LENGTH} />
                                </div>
                              </div>
                              <SmallTitle>Choose a technology</SmallTitle>
                              <div className="technology-placeholder">
                                  {appTechnologies.map(technology => <TechnologyCard selected={selectedTechnology === technology.name} key={technology.name} name={technology.name} onClick={() => {
                    setSelectedTechnology(technology.name);
                  }} />)}
                              </div>
                              <div style={{
                  paddingTop: '70px'
                }}>
                                <div style={{
                    float: 'right',
                    marginLeft: '250px'
                  }}><Switch onChange={onSwitchSecretChange} /> </div>
                                <Label data-testid="label">Create private API Secret</Label>
                              </div>
                          </div>
                    </div>
                </div>
                </form>}
            </Formik>
        </Content>

    </>;
};
const mapStateToProps = (state: AppState) => ({
  errorData: getErrorMessage(state),
  isLoadingApp: getCurrentAppLoadingStatus(state),
  currentOrgId: getCurrentOrgId(state)
});
const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatchCreateApps: (valuesRequest: any) => dispatch(createApps(valuesRequest)),
    dispatchCreateClient: (valuesClientRequest: any) => dispatch(createApiClient(valuesClientRequest))
  };
};
export const CreateAppComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateApp));