export const countries = [
  { code: 'AD', flag: '🇦🇩', name: 'Andorra' },
  { code: 'AE', flag: '🇦🇪', name: 'United Arab Emirates' },
  { code: 'AF', flag: '🇦🇫', name: 'Afghanistan' },
  { code: 'AG', flag: '🇦🇬', name: 'Antigua and Barbuda' },
  { code: 'AI', flag: '🇦🇮', name: 'Anguilla' },
  { code: 'AL', flag: '🇦🇱', name: 'Albania' },
  { code: 'AM', flag: '🇦🇲', name: 'Armenia' },
  { code: 'AO', flag: '🇦🇴', name: 'Angola' },
  { code: 'AQ', flag: '🇦🇶', name: 'Antarctica' },
  { code: 'AR', flag: '🇦🇷', name: 'Argentina' },
  { code: 'AS', flag: '🇦🇸', name: 'American Samoa' },
  { code: 'AT', flag: '🇦🇹', name: 'Austria' },
  { code: 'AU', flag: '🇦🇺', name: 'Australia' },
  { code: 'AW', flag: '🇦🇼', name: 'Aruba' },
  { code: 'AX', flag: '🇦🇽', name: 'Åland Islands' },
  { code: 'AZ', flag: '🇦🇿', name: 'Azerbaijan' },
  { code: 'BA', flag: '🇧🇦', name: 'Bosnia and Herzegovina' },
  { code: 'BB', flag: '🇧🇧', name: 'Barbados' },
  { code: 'BD', flag: '🇧🇩', name: 'Bangladesh' },
  { code: 'BE', flag: '🇧🇪', name: 'Belgium' },
  { code: 'BF', flag: '🇧🇫', name: 'Burkina Faso' },
  { code: 'BG', flag: '🇧🇬', name: 'Bulgaria' },
  { code: 'BH', flag: '🇧🇭', name: 'Bahrain' },
  { code: 'BI', flag: '🇧🇮', name: 'Burundi' },
  { code: 'BJ', flag: '🇧🇯', name: 'Benin' },
  { code: 'BL', flag: '🇧🇱', name: 'Saint Barthélemy' },
  { code: 'BM', flag: '🇧🇲', name: 'Bermuda' },
  { code: 'BN', flag: '🇧🇳', name: 'Brunei Darussalam' },
  { code: 'BO', flag: '🇧🇴', name: 'Bolivia' },
  { code: 'BQ', flag: '🇧🇶', name: 'Bonaire, Sint Eustatius and Saba' },
  { code: 'BR', flag: '🇧🇷', name: 'Brazil' },
  { code: 'BS', flag: '🇧🇸', name: 'Bahamas' },
  { code: 'BT', flag: '🇧🇹', name: 'Bhutan' },
  { code: 'BV', flag: '🇧🇻', name: 'Bouvet Island' },
  { code: 'BW', flag: '🇧🇼', name: 'Botswana' },
  { code: 'BY', flag: '🇧🇾', name: 'Belarus' },
  { code: 'BZ', flag: '🇧🇿', name: 'Belize' },
  { code: 'CA', flag: '🇨🇦', name: 'Canada' },
  { code: 'CC', flag: '🇨🇨', name: 'Cocos (Keeling) Islands' },
  { code: 'CD', flag: '🇨🇩', name: 'Congo' },
  { code: 'CF', flag: '🇨🇫', name: 'Central African Republic' },
  { code: 'CG', flag: '🇨🇬', name: 'Congo' },
  { code: 'CH', flag: '🇨🇭', name: 'Switzerland' },
  { code: 'CI', flag: '🇨🇮', name: "Côte D'Ivoire" },
  { code: 'CK', flag: '🇨🇰', name: 'Cook Islands' },
  { code: 'CL', flag: '🇨🇱', name: 'Chile' },
  { code: 'CM', flag: '🇨🇲', name: 'Cameroon' },
  { code: 'CN', flag: '🇨🇳', name: 'China' },
  { code: 'CO', flag: '🇨🇴', name: 'Colombia' },
  { code: 'CR', flag: '🇨🇷', name: 'Costa Rica' },
  { code: 'CU', flag: '🇨🇺', name: 'Cuba' },
  { code: 'CV', flag: '🇨🇻', name: 'Cape Verde' },
  { code: 'CW', flag: '🇨🇼', name: 'Curaçao' },
  { code: 'CX', flag: '🇨🇽', name: 'Christmas Island' },
  { code: 'CY', flag: '🇨🇾', name: 'Cyprus' },
  { code: 'CZ', flag: '🇨🇿', name: 'Czech Republic' },
  { code: 'DE', flag: '🇩🇪', name: 'Germany' },
  { code: 'DJ', flag: '🇩🇯', name: 'Djibouti' },
  { code: 'DK', flag: '🇩🇰', name: 'Denmark' },
  { code: 'DM', flag: '🇩🇲', name: 'Dominica' },
  { code: 'DO', flag: '🇩🇴', name: 'Dominican Republic' },
  { code: 'DZ', flag: '🇩🇿', name: 'Algeria' },
  { code: 'EC', flag: '🇪🇨', name: 'Ecuador' },
  { code: 'EE', flag: '🇪🇪', name: 'Estonia' },
  { code: 'EG', flag: '🇪🇬', name: 'Egypt' },
  { code: 'EH', flag: '🇪🇭', name: 'Western Sahara' },
  { code: 'ER', flag: '🇪🇷', name: 'Eritrea' },
  { code: 'ES', flag: '🇪🇸', name: 'Spain' },
  { code: 'ET', flag: '🇪🇹', name: 'Ethiopia' },
  { code: 'FI', flag: '🇫🇮', name: 'Finland' },
  { code: 'FJ', flag: '🇫🇯', name: 'Fiji' },
  { code: 'FK', flag: '🇫🇰', name: 'Falkland Islands (Malvinas)' },
  { code: 'FM', flag: '🇫🇲', name: 'Micronesia' },
  { code: 'FO', flag: '🇫🇴', name: 'Faroe Islands' },
  { code: 'FR', flag: '🇫🇷', name: 'France' },
  { code: 'GA', flag: '🇬🇦', name: 'Gabon' },
  { code: 'GB', flag: '🇬🇧', name: 'United Kingdom' },
  { code: 'GD', flag: '🇬🇩', name: 'Grenada' },
  { code: 'GE', flag: '🇬🇪', name: 'Georgia' },
  { code: 'GF', flag: '🇬🇫', name: 'French Guiana' },
  { code: 'GG', flag: '🇬🇬', name: 'Guernsey' },
  { code: 'GH', flag: '🇬🇭', name: 'Ghana' },
  { code: 'GI', flag: '🇬🇮', name: 'Gibraltar' },
  { code: 'GL', flag: '🇬🇱', name: 'Greenland' },
  { code: 'GM', flag: '🇬🇲', name: 'Gambia' },
  { code: 'GN', flag: '🇬🇳', name: 'Guinea' },
  { code: 'GP', flag: '🇬🇵', name: 'Guadeloupe' },
  { code: 'GQ', flag: '🇬🇶', name: 'Equatorial Guinea' },
  { code: 'GR', flag: '🇬🇷', name: 'Greece' },
  { code: 'GS', flag: '🇬🇸', name: 'South Georgia' },
  { code: 'GT', flag: '🇬🇹', name: 'Guatemala' },
  { code: 'GU', flag: '🇬🇺', name: 'Guam' },
  { code: 'GW', flag: '🇬🇼', name: 'Guinea-Bissau' },
  { code: 'GY', flag: '🇬🇾', name: 'Guyana' },
  { code: 'HK', flag: '🇭🇰', name: 'Hong Kong' },
  { code: 'HM', flag: '🇭🇲', name: 'Heard Island and Mcdonald Islands' },
  { code: 'HN', flag: '🇭🇳', name: 'Honduras' },
  { code: 'HR', flag: '🇭🇷', name: 'Croatia' },
  { code: 'HT', flag: '🇭🇹', name: 'Haiti' },
  { code: 'HU', flag: '🇭🇺', name: 'Hungary' },
  { code: 'ID', flag: '🇮🇩', name: 'Indonesia' },
  { code: 'IE', flag: '🇮🇪', name: 'Ireland' },
  { code: 'IL', flag: '🇮🇱', name: 'Israel' },
  { code: 'IM', flag: '🇮🇲', name: 'Isle of Man' },
  { code: 'IN', flag: '🇮🇳', name: 'India' },
  { code: 'IO', flag: '🇮🇴', name: 'British Indian Ocean Territory' },
  { code: 'IQ', flag: '🇮🇶', name: 'Iraq' },
  { code: 'IR', flag: '🇮🇷', name: 'Iran' },
  { code: 'IS', flag: '🇮🇸', name: 'Iceland' },
  { code: 'IT', flag: '🇮🇹', name: 'Italy' },
  { code: 'JE', flag: '🇯🇪', name: 'Jersey' },
  { code: 'JM', flag: '🇯🇲', name: 'Jamaica' },
  { code: 'JO', flag: '🇯🇴', name: 'Jordan' },
  { code: 'JP', flag: '🇯🇵', name: 'Japan' },
  { code: 'KE', flag: '🇰🇪', name: 'Kenya' },
  { code: 'KG', flag: '🇰🇬', name: 'Kyrgyzstan' },
  { code: 'KH', flag: '🇰🇭', name: 'Cambodia' },
  { code: 'KI', flag: '🇰🇮', name: 'Kiribati' },
  { code: 'KM', flag: '🇰🇲', name: 'Comoros' },
  { code: 'KN', flag: '🇰🇳', name: 'Saint Kitts and Nevis' },
  { code: 'KP', flag: '🇰🇵', name: 'North Korea' },
  { code: 'KR', flag: '🇰🇷', name: 'South Korea' },
  { code: 'KW', flag: '🇰🇼', name: 'Kuwait' },
  { code: 'KY', flag: '🇰🇾', name: 'Cayman Islands' },
  { code: 'KZ', flag: '🇰🇿', name: 'Kazakhstan' },
  { code: 'LA', flag: '🇱🇦', name: "Lao People's Democratic Republic" },
  { code: 'LB', flag: '🇱🇧', name: 'Lebanon' },
  { code: 'LC', flag: '🇱🇨', name: 'Saint Lucia' },
  { code: 'LI', flag: '🇱🇮', name: 'Liechtenstein' },
  { code: 'LK', flag: '🇱🇰', name: 'Sri Lanka' },
  { code: 'LR', flag: '🇱🇷', name: 'Liberia' },
  { code: 'LS', flag: '🇱🇸', name: 'Lesotho' },
  { code: 'LT', flag: '🇱🇹', name: 'Lithuania' },
  { code: 'LU', flag: '🇱🇺', name: 'Luxembourg' },
  { code: 'LV', flag: '🇱🇻', name: 'Latvia' },
  { code: 'LY', flag: '🇱🇾', name: 'Libya' },
  { code: 'MA', flag: '🇲🇦', name: 'Morocco' },
  { code: 'MC', flag: '🇲🇨', name: 'Monaco' },
  { code: 'MD', flag: '🇲🇩', name: 'Moldova' },
  { code: 'ME', flag: '🇲🇪', name: 'Montenegro' },
  { code: 'MF', flag: '🇲🇫', name: 'Saint Martin (French Part)' },
  { code: 'MG', flag: '🇲🇬', name: 'Madagascar' },
  { code: 'MH', flag: '🇲🇭', name: 'Marshall Islands' },
  { code: 'MK', flag: '🇲🇰', name: 'Macedonia' },
  { code: 'ML', flag: '🇲🇱', name: 'Mali' },
  { code: 'MM', flag: '🇲🇲', name: 'Myanmar' },
  { code: 'MN', flag: '🇲🇳', name: 'Mongolia' },
  { code: 'MO', flag: '🇲🇴', name: 'Macao' },
  { code: 'MP', flag: '🇲🇵', name: 'Northern Mariana Islands' },
  { code: 'MQ', flag: '🇲🇶', name: 'Martinique' },
  { code: 'MR', flag: '🇲🇷', name: 'Mauritania' },
  { code: 'MS', flag: '🇲🇸', name: 'Montserrat' },
  { code: 'MT', flag: '🇲🇹', name: 'Malta' },
  { code: 'MU', flag: '🇲🇺', name: 'Mauritius' },
  { code: 'MV', flag: '🇲🇻', name: 'Maldives' },
  { code: 'MW', flag: '🇲🇼', name: 'Malawi' },
  { code: 'MX', flag: '🇲🇽', name: 'Mexico' },
  { code: 'MY', flag: '🇲🇾', name: 'Malaysia' },
  { code: 'MZ', flag: '🇲🇿', name: 'Mozambique' },
  { code: 'NA', flag: '🇳🇦', name: 'Namibia' },
  { code: 'NC', flag: '🇳🇨', name: 'New Caledonia' },
  { code: 'NE', flag: '🇳🇪', name: 'Niger' },
  { code: 'NF', flag: '🇳🇫', name: 'Norfolk Island' },
  { code: 'NG', flag: '🇳🇬', name: 'Nigeria' },
  { code: 'NI', flag: '🇳🇮', name: 'Nicaragua' },
  { code: 'NL', flag: '🇳🇱', name: 'Netherlands' },
  { code: 'NO', flag: '🇳🇴', name: 'Norway' },
  { code: 'NP', flag: '🇳🇵', name: 'Nepal' },
  { code: 'NR', flag: '🇳🇷', name: 'Nauru' },
  { code: 'NU', flag: '🇳🇺', name: 'Niue' },
  { code: 'NZ', flag: '🇳🇿', name: 'New Zealand' },
  { code: 'OM', flag: '🇴🇲', name: 'Oman' },
  { code: 'PA', flag: '🇵🇦', name: 'Panama' },
  { code: 'PE', flag: '🇵🇪', name: 'Peru' },
  { code: 'PF', flag: '🇵🇫', name: 'French Polynesia' },
  { code: 'PG', flag: '🇵🇬', name: 'Papua New Guinea' },
  { code: 'PH', flag: '🇵🇭', name: 'Philippines' },
  { code: 'PK', flag: '🇵🇰', name: 'Pakistan' },
  { code: 'PL', flag: '🇵🇱', name: 'Poland' },
  { code: 'PM', flag: '🇵🇲', name: 'Saint Pierre and Miquelon' },
  { code: 'PN', flag: '🇵🇳', name: 'Pitcairn' },
  { code: 'PR', flag: '🇵🇷', name: 'Puerto Rico' },
  { code: 'PS', flag: '🇵🇸', name: 'Palestinian Territory' },
  { code: 'PT', flag: '🇵🇹', name: 'Portugal' },
  { code: 'PW', flag: '🇵🇼', name: 'Palau' },
  { code: 'PY', flag: '🇵🇾', name: 'Paraguay' },
  { code: 'QA', flag: '🇶🇦', name: 'Qatar' },
  { code: 'RE', flag: '🇷🇪', name: 'Réunion' },
  { code: 'RO', flag: '🇷🇴', name: 'Romania' },
  { code: 'RS', flag: '🇷🇸', name: 'Serbia' },
  { code: 'RU', flag: '🇷🇺', name: 'Russia' },
  { code: 'RW', flag: '🇷🇼', name: 'Rwanda' },
  { code: 'SA', flag: '🇸🇦', name: 'Saudi Arabia' },
  { code: 'SB', flag: '🇸🇧', name: 'Solomon Islands' },
  { code: 'SC', flag: '🇸🇨', name: 'Seychelles' },
  { code: 'SD', flag: '🇸🇩', name: 'Sudan' },
  { code: 'SE', flag: '🇸🇪', name: 'Sweden' },
  { code: 'SG', flag: '🇸🇬', name: 'Singapore' },
  { code: 'SH', flag: '🇸🇭', name: 'Saint Helena, Ascension and Tristan Da Cunha' },
  { code: 'SI', flag: '🇸🇮', name: 'Slovenia' },
  { code: 'SJ', flag: '🇸🇯', name: 'Svalbard and Jan Mayen' },
  { code: 'SK', flag: '🇸🇰', name: 'Slovakia' },
  { code: 'SL', flag: '🇸🇱', name: 'Sierra Leone' },
  { code: 'SM', flag: '🇸🇲', name: 'San Marino' },
  { code: 'SN', flag: '🇸🇳', name: 'Senegal' },
  { code: 'SO', flag: '🇸🇴', name: 'Somalia' },
  { code: 'SR', flag: '🇸🇷', name: 'Suriname' },
  { code: 'SS', flag: '🇸🇸', name: 'South Sudan' },
  { code: 'ST', flag: '🇸🇹', name: 'Sao Tome and Principe' },
  { code: 'SV', flag: '🇸🇻', name: 'El Salvador' },
  { code: 'SX', flag: '🇸🇽', name: 'Sint Maarten (Dutch Part)' },
  { code: 'SY', flag: '🇸🇾', name: 'Syrian Arab Republic' },
  { code: 'SZ', flag: '🇸🇿', name: 'Swaziland' },
  { code: 'TC', flag: '🇹🇨', name: 'Turks and Caicos Islands' },
  { code: 'TD', flag: '🇹🇩', name: 'Chad' },
  { code: 'TF', flag: '🇹🇫', name: 'French Southern Territories' },
  { code: 'TG', flag: '🇹🇬', name: 'Togo' },
  { code: 'TH', flag: '🇹🇭', name: 'Thailand' },
  { code: 'TJ', flag: '🇹🇯', name: 'Tajikistan' },
  { code: 'TK', flag: '🇹🇰', name: 'Tokelau' },
  { code: 'TL', flag: '🇹🇱', name: 'Timor-Leste' },
  { code: 'TM', flag: '🇹🇲', name: 'Turkmenistan' },
  { code: 'TN', flag: '🇹🇳', name: 'Tunisia' },
  { code: 'TO', flag: '🇹🇴', name: 'Tonga' },
  { code: 'TR', flag: '🇹🇷', name: 'Turkey' },
  { code: 'TT', flag: '🇹🇹', name: 'Trinidad and Tobago' },
  { code: 'TV', flag: '🇹🇻', name: 'Tuvalu' },
  { code: 'TW', flag: '🇹🇼', name: 'Taiwan' },
  { code: 'TZ', flag: '🇹🇿', name: 'Tanzania' },
  { code: 'UA', flag: '🇺🇦', name: 'Ukraine' },
  { code: 'UG', flag: '🇺🇬', name: 'Uganda' },
  { code: 'UM', flag: '🇺🇲', name: 'United States Minor Outlying Islands' },
  { code: 'US', flag: '🇺🇸', name: 'United States' },
  { code: 'UY', flag: '🇺🇾', name: 'Uruguay' },
  { code: 'UZ', flag: '🇺🇿', name: 'Uzbekistan' },
  { code: 'VA', flag: '🇻🇦', name: 'Vatican City' },
  { code: 'VC', flag: '🇻🇨', name: 'Saint Vincent and The Grenadines' },
  { code: 'VE', flag: '🇻🇪', name: 'Venezuela' },
  { code: 'VG', flag: '🇻🇬', name: 'Virgin Islands, British' },
  { code: 'VI', flag: '🇻🇮', name: 'Virgin Islands, U.S.' },
  { code: 'VN', flag: '🇻🇳', name: 'Viet Nam' },
  { code: 'VU', flag: '🇻🇺', name: 'Vanuatu' },
  { code: 'WF', flag: '🇼🇫', name: 'Wallis and Futuna' },
  { code: 'WS', flag: '🇼🇸', name: 'Samoa' },
  { code: 'YE', flag: '🇾🇪', name: 'Yemen' },
  { code: 'YT', flag: '🇾🇹', name: 'Mayotte' },
  { code: 'ZA', flag: '🇿🇦', name: 'South Africa' },
  { code: 'ZM', flag: '🇿🇲', name: 'Zambia' },
  { code: 'ZW', flag: '🇿🇼', name: 'Zimbabwe' }
] as const;
export type CountryCode = (typeof countries)[number]['code'];
export type CountryName = (typeof countries)[number]['name'];
export const CountryMap = countries.reduce(
  (acc, { code, name }) => ({ [code]: name, ...acc }),
  {} as { [key in CountryCode]: string }
);
