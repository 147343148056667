import { AppState } from 'app-redux/reducers';
import { PlansState } from 'app-redux/reducers/plans.reducer';
import { createSelector } from 'reselect';

export const getPlansState = (state: AppState) => state.plans || {} as PlansState;

export const getPlanList = createSelector(getPlansState,
  (plansState) => Array.from(plansState.pagedPlan?.content || []));

export const getPagedPlans = createSelector(getPlansState,
  (plansState) => plansState.pagedPlan);

export const getPlanLoadingStatus = createSelector(getPlansState,
  (plansState) => !!plansState.loading);
