import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { OrgsState } from '../reducers/orgs.reducer';

export const getOrgsState = (state: AppState) => state.orgsState || {} as OrgsState;

const orgsMapToList = (orgsState: OrgsState) => Object.values(orgsState.orgs);

export const getOrgsList = createSelector(getOrgsState, orgsMapToList);
export const getOrgsMap = createSelector(getOrgsState, (orgsState: OrgsState) => orgsState.orgs);

export const hasLoadedOrgs = createSelector(getOrgsState,
    (orgsState: OrgsState) => {
      return !!orgsMapToList(orgsState).length;
    },
);

export const selectIsLoadingOrgs = createSelector(getOrgsState,
    (orgsState) => orgsState.isLoadingOrgs);

const orgsStateToCurrentOrgId = (orgsState: OrgsState) => orgsState.currentOrgId ;

const getCurrentOrgIdFromStateOrPath = (orgsState: OrgsState) => {
  const stateOrgId = orgsStateToCurrentOrgId(orgsState);
  if (!stateOrgId) {
    return '43414955-4249-5359-4e47-454e54413432';
  }
  return stateOrgId;
};

export const getCurrentOrgId = createSelector(getOrgsState, getCurrentOrgIdFromStateOrPath);

export const getCurrentOrg = createSelector(getOrgsState,
  // tslint:disable-next-line:variable-name
  (_orgsState: OrgsState) => {
    return {
      name: 'Syngenta Digital',
      id: '43414955-4249-5359-4e47-454e54413432',
    };
  },
);

export const getOrgTeam = createSelector(getOrgsState,
  (orgsState: OrgsState) => orgsState.pagedOrgTeam?.content || []);
