import React, { useContext } from 'react';
import { PreviewSectionTitle } from './preview-section-title';
import styled from 'styled-components';
import { Skeleton } from './skeleton';
import { FlexContainer } from 'pages/integrations/styled';
import { IntegrationsFormContext } from './integrations-form';
import { iff } from 'core/iff';
import { CheckCircleIcon } from 'pages/integrations/images/CheckCircleIcon';
import { PersonCheckIcon } from 'pages/integrations/images/PersonCheckIcon';
const Card = styled.div`
  border-radius: 8px;
  width: 168px;
  height: 112px;
  padding: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;
const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin: 22px auto 18px;

  p.desc {
    font-size: 12px;
    line-height: 20px;
    margin: 2px 0 0;
  }
`;
const Name = styled.p`
  margin: -4px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  color: #fff;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;
const Tag = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  padding: 0 4px;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: #f3f4f6;
  color: #696f88;
`;
const Badge = styled(FlexContainer)`
  background-color: #4d5165;
  width: 20px;
  height: 20px;
  border-radius: 10px;
`;
const Description = styled.p`
  margin: auto 0 0;
  font-size: 12px;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;
const Badges = ({
  skeleton
}: {
  skeleton: boolean;
}) => <FlexContainer gap={2} style={{
  position: 'absolute',
  right: 0
}} data-sentry-element="FlexContainer" data-sentry-component="Badges" data-sentry-source-file="integration-deck-card.tsx">
    {skeleton ? <>
        <Skeleton rounded="full" width={20} height={20} dashed />
        <Skeleton rounded="full" width={20} height={20} dashed />
      </> : <>
        <Badge>
          <CheckCircleIcon />
        </Badge>
        <Badge>
          <PersonCheckIcon />
        </Badge>
      </>}
  </FlexContainer>;
export const IntegrationDeckCard = () => {
  const {
    values: {
      name,
      description,
      tags,
      icon
    },
    language: {
      value: selectedLanguage
    }
  } = useContext(IntegrationsFormContext);
  return <>
      <PreviewSectionTitle title="Deck of apps Thumb Card" data-sentry-element="PreviewSectionTitle" data-sentry-source-file="integration-deck-card.tsx" />
      <CardsContainer data-sentry-element="CardsContainer" data-sentry-source-file="integration-deck-card.tsx">
        <div>
          <Card style={{
          backgroundColor: '#363948'
        }} data-sentry-element="Card" data-sentry-source-file="integration-deck-card.tsx">
            <FlexContainer align="flex-start" style={{
            position: 'relative',
            margin: '4px 0 8px'
          }} data-sentry-element="FlexContainer" data-sentry-source-file="integration-deck-card.tsx">
              {iff(Boolean(icon), <FlexContainer style={{
              background: '#FFFFFF',
              borderRadius: 10,
              width: 72,
              padding: '3px 0'
            }}>
                  <img src={icon} style={{
                height: 42,
                width: 'auto',
                maxWidth: 62,
                margin: '0 auto'
              }} />
                </FlexContainer>, <Skeleton dashed image width={72} height={48} rounded />)}
              {iff(Boolean(icon) && Boolean(name), <Badges skeleton={false} />, <Badges skeleton={true} />)}
            </FlexContainer>
            <FlexContainer direction="column" gap={4} data-sentry-element="FlexContainer" data-sentry-source-file="integration-deck-card.tsx">
              {iff(Boolean(name), <Name>{name}</Name>, <>
                  <Skeleton background="#A3A9B9" rounded width={95} height={16} />
                  <Skeleton background="#A3A9B9" rounded width={147} height={16} />
                </>)}
            </FlexContainer>
          </Card>
          <p className="desc">Default State</p>
        </div>
        <div>
          <Card style={{
          backgroundColor: '#4D5165',
          display: 'flex',
          flexDirection: 'column'
        }} data-sentry-element="Card" data-sentry-source-file="integration-deck-card.tsx">
            <FlexContainer justify="space-between" style={{
            marginBottom: description[selectedLanguage] ? 12 : 16,
            position: 'relative'
          }} data-sentry-element="FlexContainer" data-sentry-source-file="integration-deck-card.tsx">
              <FlexContainer gap={4} data-sentry-element="FlexContainer" data-sentry-source-file="integration-deck-card.tsx">
                {iff(tags.length > 0, tags.map(tag => <Tag key={tag}>{tag}</Tag>), <>
                    <Skeleton rounded width={36} height={16} dashed />
                    <Skeleton rounded width={36} height={16} dashed />
                  </>)}
              </FlexContainer>
              <FlexContainer data-sentry-element="FlexContainer" data-sentry-source-file="integration-deck-card.tsx">
                {iff(Boolean(icon) && Boolean(name), <Badges skeleton={false} />, <Badges skeleton={true} />)}
              </FlexContainer>
            </FlexContainer>
            {iff(Boolean(description[selectedLanguage]), <Description>{description[selectedLanguage]}</Description>, <FlexContainer direction="column" gap={4}>
                <Skeleton background="#A3A9B9" rounded width={152} height={16} />
                <Skeleton background="#A3A9B9" rounded width={152} height={16} />
                <Skeleton background="#A3A9B9" rounded width={152} height={16} />
              </FlexContainer>)}
          </Card>
          <p className="desc">Hover State</p>
        </div>
      </CardsContainer>
    </>;
};