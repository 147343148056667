import { CardPageHeader } from 'components/headers/card-page-header';
import { Label } from 'components/label';
import { history } from 'core/history';
import { iff } from 'core/iff';
import React from 'react';
import { Button, ButtonType, Switch } from 'syngenta-digital-cropwise-react-ui-kit';
import './style.less';
interface IAppPageHeader {
  publishedDisabled?: boolean;
  isPublished: boolean;
  canDeleteThisApp?: boolean;
  canChangeThisApp: boolean;
  formValues: any;
  isSubmitting: boolean;
  submitButtonTitle: string;
  onPublishClick: () => void;
  handleSendChanges: (value: any) => void;
  setDeleteModal?: (value: boolean) => any;
  onBack?: () => void;
}
export const AppPageHeader: React.FC<IAppPageHeader> = (props: IAppPageHeader) => {
  return <CardPageHeader title="Application Details" onBack={props.onBack ? props.onBack : history.goBack} data-sentry-element="CardPageHeader" data-sentry-component="AppPageHeader" data-sentry-source-file="index.tsx">
      <div style={{
      display: 'flex',
      gap: '.5rem'
    }}>
        <Label data-sentry-element="Label" data-sentry-source-file="index.tsx">Published</Label>
        <Switch data-testid="card-page-header-switch" disabled={!!props.publishedDisabled} checked={props.isPublished} onClick={props.onPublishClick} data-sentry-element="Switch" data-sentry-source-file="index.tsx" />
      </div>
      <div className="card-page-header-buttons">
          {iff(!!props.canDeleteThisApp, <a className="cw-app-delete" onClick={e => {
        e.stopPropagation();
        const appDeleted = async () => {
          if (props.setDeleteModal) {
            props.setDeleteModal(true);
          }
        };
        appDeleted().catch();
      }}>
                  Delete app
                </a>)}
          {iff(props.canChangeThisApp, <Button type={ButtonType.primary} disabled={props.isSubmitting} onClick={() => props.handleSendChanges(props.formValues)}>
                {props.submitButtonTitle}
              </Button>)}
        </div>
    </CardPageHeader>;
};