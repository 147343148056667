import searchEmptyImage from 'assets/images/search-empty-image.svg';
import React from 'react';
const PlansEmptyState: React.FC<{}> = () => {
  return <div className="plansEmptyStateContainer" data-sentry-component="PlansEmptyState" data-sentry-source-file="PlansEmptyState.tsx">
      <img src={searchEmptyImage} alt="Search Empty" />
      <div className="plansEmptyStateTitle">No Plans found</div>
      <div className="plansEmptyStateText">
        <div>No plans match your search.</div>
        <div>Check your spelling or try a different term.</div>
      </div>
    </div>;
};
export default PlansEmptyState;