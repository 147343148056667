import { Table } from 'antd';
import { uniq } from 'lodash';
import React, { Key, useEffect, useState } from 'react';
import styled from 'styled-components';
import { EntitlementDTO } from '../../core/entitlements/models';
import './styles.less';
const StyledTable = styled(Table)<any>`
  .syngenta-ant-table-tbody>tr.syngenta-ant-table-row-selected>td {
    background  : #e6ffff;
    border-color: rgba(0, 0, 0, 0.03);
  }
  .syngenta-ant-table-tbody > tr.syngenta-ant-table-row-selected:hover > td {
    background: #e6ffff;
  }
  .syngenta-ant-checkbox-checked .syngenta-ant-checkbox-inner {
    background-color: #14803C;
    border-color: #14803C;
  }
`;
interface IEntitlementsTableProps {
  rerender?: boolean;
  entitlementsData: {
    id: string;
    name: string;
    key: string;
  }[] | null;
  isLoadingPlanData: boolean;
  onEntitlementSelection?: any;
  customColumns?: any;
  enableRowSelection?: boolean;
  preSelectedKeys?: Key[];
}
export const EntitlementsTable: React.FC<IEntitlementsTableProps> = props => {
  const [selectedKeyData, setSelectedKeyData] = useState<Key[]>([]);
  const [currentData, setCurrentData] = useState<{
    id: string;
    name: string;
    key: string;
  }[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let selectedIds: Key[] = [];
  useEffect(() => {
    setSelectedKeyData(props.preSelectedKeys ?? []);
  }, [props.preSelectedKeys]);
  useEffect(() => {
    if (props.isLoadingPlanData) {
      setIsLoading(true);
    } else {
      setCurrentData(props.entitlementsData);
      setIsLoading(false);
    }
  }, [props.entitlementsData, props.isLoadingPlanData]);
  useEffect(() => {
    if (props.rerender) {
      setSelectedKeyData(props.preSelectedKeys ?? []);
    }
  }, [props.rerender]);
  const defaultEntitlementColumns = [{
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
    sorter: (a: EntitlementDTO, b: EntitlementDTO) => a.key.localeCompare(b.key)
  }, {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: EntitlementDTO, b: EntitlementDTO) => a.name.localeCompare(b.name)
  }];
  if (props.enableRowSelection) {
    const rowSelection = {
      hideSelectAll: true,
      selectedRowKeys: selectedKeyData,
      preserveSelectedRowKeys: false,
      onChange: () => {
        setSelectedKeyData(selectedIds);
        props.onEntitlementSelection(selectedIds);
      },
      onSelect: (record: EntitlementDTO, selected: boolean, selectedRows: EntitlementDTO[], nativeEvent: any) => {
        if (selected) {
          selectedIds = uniq([...selectedKeyData, record.id]);
          setSelectedKeyData(selectedIds);
        } else {
          selectedIds = uniq([...selectedKeyData.filter(entitlementId => entitlementId !== record.id)]);
          setSelectedKeyData(selectedIds);
        }
      }
    };
    return <div className="entitlement-table-wrapper">
        <StyledTable pagination={false} loading={isLoading} rowKey={'id'} dataSource={currentData ?? []} columns={[...defaultEntitlementColumns, ...(props.customColumns ?? [])]} rowSelection={{
        type: 'checkbox',
        ...rowSelection
      }} />
      </div>;
  }
  return <div className="entitlement-table-wrapper" data-sentry-component="EntitlementsTable" data-sentry-source-file="index.tsx">
      <StyledTable loading={isLoading} pagination={false} rowKey={'id'} dataSource={currentData ?? []} columns={[...defaultEntitlementColumns, ...(props.customColumns ?? [])]} data-sentry-element="StyledTable" data-sentry-source-file="index.tsx" />
    </div>;
};