import { ImageIcon } from 'pages/integrations/images/ImageIcon';
import { FlexContainer } from 'pages/integrations/styled';
import React, { HTMLAttributes } from 'react';
interface Props extends HTMLAttributes<HTMLDivElement> {
  dashed?: boolean;
  rounded?: boolean | 'full';
  image?: boolean;
  background?: string;
  width: number | string;
  height: number | string;
}
export const Skeleton: React.FC<Props> = ({
  width,
  height,
  ...props
}) => {
  const getBorderRadius = () => {
    if (props.rounded) {
      if (props.rounded === 'full') {
        return '50%';
      }
      return 4;
    }
    return 0;
  };
  return <FlexContainer {...props} style={{
    width,
    height,
    backgroundColor: props.background ?? 'transparent',
    border: props.dashed ? '1px dashed #c2c7d0' : 'none',
    borderRadius: getBorderRadius(),
    ...props.style
  }} data-sentry-element="FlexContainer" data-sentry-component="Skeleton" data-sentry-source-file="skeleton.tsx">
      {props.image && <ImageIcon />}
    </FlexContainer>;
};