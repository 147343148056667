import { Button, Input, InputRef, Modal, Table } from 'antd';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IntegrationsFormContext } from './integrations-form';
import { EditIcon } from 'pages/integrations/images/EditIcon';
import { PlusOutlined } from '@ant-design/icons';
import { CloseIcon } from 'pages/integrations/images/CloseIcon';
import { FlexContainer } from 'pages/integrations/styled';
import { ButtonType, Button as UiKitButton } from 'syngenta-digital-cropwise-react-ui-kit';
import { IntegrationAvailableLanguages, languages } from 'pages/integrations/utils/types';
const AddOrEditButton = styled(Button)`
  display: flex;
  align-items: center;
  transition: 300ms;
  border-radius: 4px;

  svg {
    margin-right: 4px;
  }

  &,
  &:hover,
  &:focus {
    color: #fff;
    border-color: #fff;
  }

  &:hover {
    opacity: 0.7;
  }

  &:focus:not(&[class*='-btn-primary']) {
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.25) !important;
  }

  &[class*='-btn-primary'] {
    background: #0092e4;
    border-color: #0092e4;

    &:hover {
      opacity: 0.9;
    }
  }
`;
const StyledModal = styled(Modal)`
  &,
  .syngenta-ant-modal-title {
    color: #14151c !important;
  }

  .syngenta-ant-modal-header {
    border-radius: 8px;
    border: none;
  }

  .syngenta-ant-modal-close {
    top: 32px;
    right: 24px;
    transition: 300ms;

    &:hover {
      opacity: 0.8;
    }
  }

  .syngenta-ant-modal-close-x {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  .syngenta-ant-modal-header {
    padding: 32px 24px 0 24px;
    margin-bottom: 20px;
  }

  .syngenta-ant-modal-footer {
    padding: 0 24px 16px;
    margin-top: 28px;
  }

  .syngenta-ant-modal-body {
    padding: 0 24px;
    overflow-y: auto;
    overflow-y: overlay;
    max-height: calc(100vh - 200px);

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: padding-box;
      border-radius: 5px;
      background-color: #c2c7d0;
    }
  }

  .syngenta-ant-form-item-control {
    line-height: 32px;
  }
`;
const CancelBtn = styled(UiKitButton)`
  color: #14151c;
  background-color: transparent;
  box-shadow: none;
  border: none;
  margin-right: 8px;

  &:hover,
  &:focus,
  &:active {
    color: #14151c !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    opacity: 0.8;
  }
`;
const Tag = styled.div`
  width: max-content;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  padding: 0 4px;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: #f3f4f6;
  color: #696f88;
`;
export const IntegrationDescription = () => {
  const {
    values: {
      description,
      description_extended = {}
    },
    setValues
  } = useContext(IntegrationsFormContext);
  const hasDescription = description && Object.values(description).length > 0 || !description;
  const [dataSource, setDS] = useState(languages.map(language => ({
    languageKey: language.value,
    language: language.label,
    translation: description[language.value] ?? '',
    translation_extended: description_extended[language.value] ?? ''
  })));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const onChangeValue = useCallback((key: string, value: string) => {
    setDS(ds => ds.map(l => l.languageKey === key ? {
      ...l,
      translation: value
    } : l));
  }, []);
  const onChangeExtendedValue = useCallback((key: string, value: string) => {
    setDS(ds => ds.map(l => l.languageKey === key ? {
      ...l,
      translation_extended: value
    } : l));
  }, []);
  const onSave = useCallback(() => {
    setValues('description', dataSource.reduce((acc, {
      languageKey,
      translation
    }) => ({
      ...acc,
      [languageKey]: translation
    }), {}));
    setValues('description_extended', dataSource.reduce((acc, {
      languageKey,
      translation_extended
    }) => ({
      ...acc,
      [languageKey]: translation_extended
    }), {}));
    setIsModalOpen(false);
  }, [dataSource, setValues]);
  return <div data-sentry-component="IntegrationDescription" data-sentry-source-file="integration-description.tsx">
      <p style={{
      marginBottom: 8
    }}>Description</p>
      <p style={{
      fontSize: 12,
      marginBottom: 8
    }}>
        Click the button below to add or edit the description using the short description popup.
      </p>
      <AddOrEditButton size="middle" icon={hasDescription ? <EditIcon fill={'#fff'} /> : <PlusOutlined />} type={hasDescription ? 'primary' : 'ghost'} style={{
      marginBottom: 14
    }} onClick={() => {
      setIsModalOpen(true);
    }} data-sentry-element="AddOrEditButton" data-sentry-source-file="integration-description.tsx">
        {hasDescription ? 'Edit' : 'Add'} Description
      </AddOrEditButton>
      <StyledModal title="Add Integration Description" open={isModalOpen} onOk={() => {
      console.log('ok');
    }} onCancel={onCancel} centered={true} width="80vw" closeIcon={<CloseIcon size={24} />} maskClosable={false} footer={<FlexContainer style={{
      marginLeft: 'auto'
    }} justify="flex-end">
            <CancelBtn size="middle" onClick={onCancel} style={{
        color: '#14151C'
      }}>
              Cancel
            </CancelBtn>
            <UiKitButton size="middle" type={ButtonType.primary} onClick={onSave} data-testid="integration-description-save">
              Save
            </UiKitButton>
          </FlexContainer>} data-sentry-element="StyledModal" data-sentry-source-file="integration-description.tsx">
        <p style={{
        marginBottom: 38
      }}>
          Insert the short description and long description in the table cells in the corresponding
          language:
        </p>
        <Table components={{
        body: {
          cell: EditableCell
        }
      }} bordered pagination={false} dataSource={dataSource} rowKey={row => row.languageKey} columns={[{
        title: <span style={{
          fontWeight: 600,
          color: '#14151c'
        }}>Language</span>,
        dataIndex: 'language',
        render: value => <Tag>{value}</Tag>,
        width: 164
      }, {
        title: <FlexContainer direction="column" align="flex-start">
                  <span style={{
            fontWeight: 600,
            color: '#14151c'
          }}>
                    Translated short description
                  </span>
                  <span>Suggested maximum 110 characters maximum.</span>
                </FlexContainer>,
        dataIndex: 'translation',
        onCell: (record: (typeof dataSource)[number]) => ({
          record,
          name: 'translation',
          editable: true,
          onChangeValue
        } as React.TdHTMLAttributes<HTMLTableCellElement>)
      }, {
        title: <FlexContainer direction="column" align="flex-start">
                  <span style={{
            fontWeight: 600,
            color: '#14151c'
          }}>
                    Translated long description
                  </span>
                  <span>Suggested maximum 400 characters maximum.</span>
                </FlexContainer>,
        dataIndex: 'translation_extended',
        onCell: (record: (typeof dataSource)[number]) => ({
          record,
          name: 'translation_extended',
          editable: true,
          onChangeValue: onChangeExtendedValue
        } as React.TdHTMLAttributes<HTMLTableCellElement>)
      }]} data-sentry-element="Table" data-sentry-source-file="integration-description.tsx" />
      </StyledModal>
    </div>;
};
const EditableCell: React.FC<{
  editable: boolean;
  children: React.ReactNode;
  record: {
    languageKey: IntegrationAvailableLanguages;
    translation: string;
    translation_extended: string;
  };
  name: 'translation' | 'translation_extended';
  onChangeValue: (key: string, value: string) => void;
}> = ({
  editable,
  children,
  record,
  onChangeValue,
  name,
  ...restProps
}) => {
  const [value, setValue] = useState<string>((record?.[name] as string) ?? '');
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus?.();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
  };
  const save = () => {
    onChangeValue(record.languageKey, value);
    toggleEdit();
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? <InputWrap>
        <StyledInput data-testid="integration-description-input" ref={inputRef} onBlur={save} onPressEnter={save} maxLength={name === 'translation' ? 110 : 400} value={value} onInput={ev => setValue((ev.target as HTMLInputElement).value)} />
        <span className="count">
          {value.length} / {name === 'translation' ? 110 : 400}
        </span>
      </InputWrap> : <EditableCellWrap className="editable-cell-value-wrap" onClick={toggleEdit} data-testid="integration-edit-cell-wrap">
        {children}
      </EditableCellWrap>;
  }
  return <td {...restProps} data-sentry-component="EditableCell" data-sentry-source-file="integration-description.tsx">{childNode}</td>;
};
const InputWrap = styled.div`
  position: relative;

  .count {
    position: absolute;
    bottom: -12px;
    right: 4px;
    font-size: 8px;
    line-height: 8px;
  }
`;
const {
  TextArea
} = Input;
const StyledInput = styled(TextArea)`
  border: 1px solid #e8eaed !important;
  border-color: #e8eaed !important;
  height: 40px;
  transition: 300ms;
  border-radius: 4px;

  &:focus {
    border-color: #0092e4 !important;
    box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25);
  }
`;
const EditableCellWrap = styled.div`
  line-height: 30px;
  min-height: 54px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: border-color 300ms ease-in-out;
  border-radius: 4px;
  padding-left: 11px;
  word-break: break-all;

  &:hover {
    border: 1px solid #e8eaed !important;
    border-color: #e8eaed !important;
  }
`;