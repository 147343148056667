import { CloseCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import './style.less';
export const InfoErrorModal = (props: any) => {
  return <Modal open={props.visible} onOk={props.handleClose} onCancel={props.handleClose} width="384px" footer={[<Button key={1} type={ButtonType.primary} size="large" onClick={props.handleClose}>
            Ok
          </Button>]} data-sentry-element="Modal" data-sentry-component="InfoErrorModal" data-sentry-source-file="InfoErrorModal.tsx">
      <div className="modal-placeholder">
        <>
          <CloseCircleFilled className="modal-error" data-sentry-element="CloseCircleFilled" data-sentry-source-file="InfoErrorModal.tsx" />
          <p style={{
          paddingTop: '20px'
        }}>{props.message}</p>
        </>
      </div>
    </Modal>;
};