import { createBrowserHistory } from 'history';

export const history = createBrowserHistory<unknown>();

export const RoutePaths = {
  AUTH: () => '/authenticate',
  HOME: () => '/',
  APPS: () => `/`,
  CREATE_APP: () => `/apps/create`,
  APP_DETAILS: (appId = ':appId') => `/apps/${appId}/details`,
  API_CLIENT_DETAILS: (appId = ':appId', clientId = ':clientId') => `/apps/${appId}/credentials/${clientId}/details`,
  CREDENTIALS: (appId = ':appId') => `/apps/${appId}/credentials`,
  CREATE_API_CLIENT: (appId = ':appId') => `/apps/${appId}/credentials/create`,
  API_CLIENT_CREATED: (appId = ':appId') => `/apps/${appId}/credentials/api-client-created`,
  APP_CREATED: () => `/apps/app-created`,
  DOCS: () => '/docs',
  UI_KIT: () => `/ui-kit`,
  SDK_PAGE: () => `/sdks`,
  WIDGETS_PAGE: (appId = ':appId') => `/apps/${appId}/widgets`,
  TEAM_PAGE: () => `/team`,
  SDK_OVERVIEW: () => `/sdk/overview`,
  LICENSING_PAGE: (appId = ':appId') => `/apps/${appId}/licensing`,
  NEW_LICENSE_PLAN: (appId = ':appId') => `/apps/${appId}/licensing/create-plan`,
  LICENSE_PLAN_DETAILS: (appId = ':appId', planId = ':planId') => `/apps/${appId}/licensing/${planId}/details`,
  INTEGRATIONS: () => '/integrations',
};

export const PublicRoutes = [
  RoutePaths.AUTH(),
];
