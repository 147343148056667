import { Checkbox, Modal, Select, Table } from 'antd';
import { PagedApp } from 'app-redux/reducers/apps.reducer';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, ButtonType, Input, Switch, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import { IPaginationApp } from '.';
import { AccountAuthoritiesUpdateRequestDTO, AuthorityDTO, InviteeDTO, IUserAccount, PermissionDTO, PermissionScope } from '../../core/accounts/models';
import { DeveloperAppDTO } from '../../core/apps/models';
import { AuthenticationOptions } from '../../core/authentication/models';
import { iff } from '../../core/iff';
import { OrganizationDTO } from '../../core/orgs/models';
import { UUID } from '../../core/utils/BasicModels';
const {
  Text
} = Typography;
interface IInvitationModalProps {
  modalType: 'invite' | 'edit';
  handleClose: () => void;
  handleSubmitInvite: (inviteeDTO: InviteeDTO) => void;
  handleSubmitEdit: (accountId: UUID, accountAuthoritiesUpdateRequestDTO: AccountAuthoritiesUpdateRequestDTO) => void;
  handleChangeAppPagination: (pagination: IPaginationApp) => void;
  fetchApps?: (orgId: UUID, pageNumber: number, pageSize: number) => Promise<PagedApp>;
  appData: DeveloperAppDTO[];
  currentOrg: OrganizationDTO;
  selectedTeamMember?: IUserAccount | null;
  currentUserAuthorities: AuthorityDTO[];
  selectedUserAuthorities?: AuthorityDTO[];
  visible: boolean;
  paginationApp: IPaginationApp;
}
type AppPermissionNames = 'info' | 'plan' | 'credentials' | 'campaigns' | 'contracts';
interface IPermissionScope {
  [id: string]: PermissionScope;
  info: PermissionScope;
  plan: PermissionScope;
  credentials: PermissionScope;
  campaigns: PermissionScope;
  contracts: PermissionScope;
}
interface AppPermissions {
  [id: string]: IPermissionScope | undefined;
}
interface OrgPermissions {
  [id: string]: PermissionScope;
}
const {
  Option
} = Select;
export const InvitationModal: React.FC<IInvitationModalProps> = props => {
  const {
    modalType,
    handleClose,
    currentOrg,
    selectedTeamMember,
    handleSubmitInvite,
    handleSubmitEdit
  } = props;
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [canCreateApps, setCanCreateApps] = useState<boolean>(false);
  const [canEditOrg, setCanEditOrg] = useState<boolean>(false);
  const [canInviteOthers, setCanInviteOthers] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canAccessApp, setCanAccessApp] = useState<{
    [id: string]: boolean;
  }>({});
  const [appPermissionScopes, setAppPermissionScopes] = useState<AppPermissions>({});
  const [orgPermissionScopes, setOrgPermissionScopes] = useState<OrgPermissions>({});
  const [emptyRequestModal, setEmptyRequestModal] = useState<boolean>(false);
  const [loadingApps, setLoadingApps] = useState<boolean>(false);
  const onChange = async (pagination: any, filters?: any, sorter?: any, extra?: any) => {
    setLoadingApps(true);
    const page = pagination.current - 1;
    const pageSize = pagination.pageSize;
    if (props.fetchApps) {
      const apps = await props.fetchApps(props.currentOrg.id, page, pageSize);
      props.handleChangeAppPagination({
        pageNumber: apps.number + 1,
        pageSize: apps.size,
        total: apps.total_elements,
        appList: Array.from(apps.content)
      });
    }
    setLoadingApps(false);
  };
  const currentUserAppPermissions: AppPermissions = props.currentUserAuthorities.filter(authority => authority.context === 'APP').reduce((authAcc: AppPermissions, authority: AuthorityDTO) => {
    return {
      ...authAcc,
      [authority.id]: authority.permissions.reduce((permAcc: IPermissionScope, permission) => {
        return {
          ...permAcc,
          [permission.name.toLowerCase()]: permission.scope
        };
      }, {
        credentials: 'NONE',
        info: 'NONE',
        plan: 'NONE',
        campaigns: 'NONE',
        contracts: 'NONE'
      })
    };
  }, {});
  const currentOrgAuthorities = props.currentUserAuthorities.filter(authority => authority.context === 'ORGANIZATION' && authority.id === props.currentOrg.id)[0];
  const currentUserCanEditOrg: boolean = currentOrgAuthorities.permissions.filter(permission => (permission.name === 'ORG' || permission.name === 'OWNER') && permission.scope === 'WRITE').length > 0;
  const currentUserCanCreateApps: boolean = currentOrgAuthorities.permissions.filter(permission => (permission.name === 'APPS' || permission.name === 'OWNER') && permission.scope === 'WRITE').length > 0;
  const currentUserCanInviteOthers: boolean = currentOrgAuthorities.permissions.filter(permission => (permission.name === 'USERS' || permission.name === 'OWNER') && permission.scope === 'WRITE').length > 0;
  const isCurrentUserOwner = currentOrgAuthorities.permissions.filter(permission => permission.name === 'OWNER').length > 0;
  const appsInviteeUserHasAccessTo = isCurrentUserOwner ? props.paginationApp.appList : props.paginationApp.appList.filter(app => Object.keys(currentUserAppPermissions).includes(app.id || ''));
  const inviteeUserPermissionToApp = (appId: UUID, permissionName: string) => {
    const inviteeAppPermissions = props.currentUserAuthorities.find(authority => authority.context === 'APP' && authority.id === appId)?.permissions.filter(permission => permission.name === permissionName.toUpperCase()) || [];
    const inviteeAppScope = inviteeAppPermissions.length > 0 ? inviteeAppPermissions?.find(permission => permission.scope === 'WRITE') ? 'WRITE' : 'READ' : 'NONE';
    return isCurrentUserOwner ? 'WRITE' : inviteeAppScope;
  };
  const selectedMemberOrgPermissions = (selectedTeamMember?.authorities.filter(authority => authority.context === 'ORGANIZATION' && authority.id === props.currentOrg.id)[0].permissions.reduce((permissionAcc, permission) => {
    return {
      ...permissionAcc,
      [permission.name]: permission.scope
    };
  }, {}) as OrgPermissions);
  const selectedMemberAppPermissions: AppPermissions = (selectedTeamMember?.authorities.filter(authority => authority.context === 'APP').reduce((authorityAcc, authority) => {
    return {
      ...authorityAcc,
      [authority.id]: authority.permissions.reduce((permissionAcc: IPermissionScope, permission) => {
        return {
          ...permissionAcc,
          [permission.name.toLowerCase()]: permission.scope
        };
      }, {
        credentials: 'NONE',
        info: 'NONE',
        plan: 'NONE',
        campaigns: 'NONE',
        contracts: 'NONE'
      })
    };
  }, {}) as AppPermissions);
  const selectedMemberAppsAccess: {
    [id: string]: boolean;
  } | undefined = selectedTeamMember?.authorities.filter(authority => authority.context === 'APP').reduce((authAcc, authority) => {
    return {
      ...authAcc,
      [authority.id]: true
    };
  }, {});
  const parseAppPermissionsToAuthorityList = (appPermissions: AppPermissions): AuthorityDTO[] => {
    return Object.entries(appPermissions).filter(([_, permissions]) => !!permissions).map(([appId, permissions]) => {
      return {
        id: appId,
        context: 'APP',
        permissions: Object.entries((permissions as IPermissionScope)).filter(([_, scope]) => scope !== 'NONE').map(([permissionName, scope]) => ({
          name: permissionName.toUpperCase(),
          scope
        }))
      };
    });
  };
  const parseOrgPermissionsToPermissionList = (orgPermissions: OrgPermissions): PermissionDTO[] => {
    return Object.entries(orgPermissions).filter(([_, permissions]) => !!permissions).map(([permissionName, permissions]) => {
      return {
        name: permissionName,
        scope: orgPermissions[permissionName]
      };
    });
  };
  const canSelectedMemberEditPermission = (permissionName: string): boolean => {
    const orgAuthority = selectedTeamMember?.authorities.filter(authority => authority.context === 'ORGANIZATION' && authority.id === props.currentOrg.id);
    if (orgAuthority) {
      const orgPermission = orgAuthority[0].permissions.filter(permission => permission.name === permissionName || permission.name === 'OWNER');
      if (orgPermission.length > 0) {
        return !!orgPermission.find(permission => permission.scope === 'WRITE');
      }
    }
    return false;
  };
  const simplifyAuthority = (authority: AuthorityDTO) => {
    return authority.permissions.map(permission => {
      return `${authority.context}:${authority.id}:${permission.name}:${permission.scope}`;
    });
  };
  const simplifyPermissions = (permission: PermissionDTO) => {
    return `${permission.name}:${permission.scope}`;
  };
  const parseAuthString = (strAuthority: string): AuthorityDTO => {
    const [context, id, name, scope] = strAuthority.split(':');
    return (({
      id,
      context,
      permissions: [{
        name,
        scope
      }]
    } as any) as AuthorityDTO);
  };
  const parsePermissionString = (strPermission: string): PermissionDTO => {
    const [name, scope] = strPermission.split(':');
    return {
      name,
      scope: (scope as PermissionScope)
    };
  };
  const authStringsToAuthorityList = (strAuthorities: string[]) => {
    const authorityMap = strAuthorities.reduce((authMap, strAuthority) => {
      const authority = parseAuthString(strAuthority);
      if (!authMap[authority.id]) {
        return {
          ...authMap,
          [authority.id]: authority
        };
      } else {
        return {
          ...authMap,
          [authority.id]: {
            ...authMap[authority.id],
            permissions: [...authMap[authority.id].permissions, ...authority.permissions]
          }
        };
      }
    }, ({} as {
      [id: string]: AuthorityDTO;
    }));
    return Object.values(authorityMap);
  };
  const permStringsToPermissionList = (strPermissions: string[]) => {
    return strPermissions.map(parsePermissionString);
  };
  const inferAuthorityOperations = (oldAuthorities: AuthorityDTO[], newAuthorities: AuthorityDTO[]) => {
    const oldStrAuthorities = oldAuthorities.flatMap(simplifyAuthority);
    const newStrAuthorities = newAuthorities.flatMap(simplifyAuthority);
    const strAuthoritiesToBeRemoved: string[] = oldStrAuthorities.filter(oldAuth => !newStrAuthorities.includes(oldAuth));
    const strAuthoritiesToBeAdded: string[] = newStrAuthorities.filter(newAuth => !oldStrAuthorities.includes(newAuth));
    return {
      authoritiesAdded: authStringsToAuthorityList(strAuthoritiesToBeAdded),
      authoritiesRemoved: authStringsToAuthorityList(strAuthoritiesToBeRemoved)
    };
  };
  const inferOrgPermissionOperations = (oldOrgPermissions: PermissionDTO[], newOrgPermissions: PermissionDTO[]) => {
    const oldStrPermissions = oldOrgPermissions.flatMap(simplifyPermissions);
    const newStrPermissions = newOrgPermissions.flatMap(simplifyPermissions);
    const strPermissionsToBeAdded: string[] = newStrPermissions.filter(newPerm => !oldStrPermissions.includes(newPerm));
    const strPermissionsToBeRemoved: string[] = oldStrPermissions.filter(oldPerm => !newStrPermissions.includes(oldPerm));
    return {
      permissionsAdded: permStringsToPermissionList(strPermissionsToBeAdded),
      permissionsRemoved: permStringsToPermissionList(strPermissionsToBeRemoved)
    };
  };
  const handleSubmit = async () => {
    if (modalType === 'invite') {
      const inviteeDTO: InviteeDTO = {
        type: 'USER',
        locale: 'en',
        // TODO: use proper locales
        email,
        role: 'OTHER',
        client_id: AuthenticationOptions.clientId,
        redirect_uri: AuthenticationOptions.redirectUri,
        authorities: [...parseAppPermissionsToAuthorityList(appPermissionScopes), {
          context: 'ORGANIZATION',
          id: currentOrg.id,
          permissions: [{
            name: 'APPS',
            scope: canCreateApps ? 'WRITE' : 'READ'
          }, {
            name: 'ORG',
            scope: canEditOrg ? 'WRITE' : 'READ'
          }, {
            name: 'USERS',
            scope: canInviteOthers ? 'WRITE' : 'READ'
          }]
        }]
      };
      try {
        setIsLoading(true);
        handleSubmitInvite(inviteeDTO);
      } catch (err) {
        // empty
      }
    } else {
      const oldAppAuthoritiesParsed = parseAppPermissionsToAuthorityList(selectedMemberAppPermissions);
      const newAppAuthoritiesParsed = parseAppPermissionsToAuthorityList(appPermissionScopes);
      const appAuthoritiesToBeUpdated = inferAuthorityOperations(oldAppAuthoritiesParsed, newAppAuthoritiesParsed);
      const oldOrgPermissionsParsed = parseOrgPermissionsToPermissionList(selectedMemberOrgPermissions);
      const newOrgPermissionsParsed = parseOrgPermissionsToPermissionList(orgPermissionScopes);
      const orgPermissionsToBeUpdated = inferOrgPermissionOperations(oldOrgPermissionsParsed, newOrgPermissionsParsed);
      const authoritiesUpdateRequest: AccountAuthoritiesUpdateRequestDTO = {
        updates: [{
          operation: 'add',
          authorities: [...appAuthoritiesToBeUpdated.authoritiesAdded]
        }, {
          operation: 'remove',
          authorities: [...appAuthoritiesToBeUpdated.authoritiesRemoved]
        }]
      };
      if (orgPermissionsToBeUpdated.permissionsAdded.length > 0) {
        authoritiesUpdateRequest.updates.find(update => update.operation === 'add')?.authorities.push({
          context: 'ORGANIZATION',
          id: props.currentOrg.id,
          permissions: orgPermissionsToBeUpdated.permissionsAdded
        });
      }
      if (orgPermissionsToBeUpdated.permissionsRemoved.length > 0) {
        authoritiesUpdateRequest.updates.find(update => update.operation === 'remove')?.authorities.push({
          context: 'ORGANIZATION',
          id: props.currentOrg.id,
          permissions: orgPermissionsToBeUpdated.permissionsRemoved
        });
      }
      try {
        const memberHasUpdatesToBeSubmitted = authoritiesUpdateRequest.updates.find(update => update.authorities.length > 0);
        if (selectedTeamMember && memberHasUpdatesToBeSubmitted) {
          setIsLoading(true);
          handleSubmitEdit(selectedTeamMember.id, authoritiesUpdateRequest);
        } else {
          setEmptyRequestModal(true);
        }
      } catch (err) {
        // empty
      }
    }
  };
  const isValidEmail = (inviteeEmail: string) => {
    return inviteeEmail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };
  useEffect(() => {
    setEmail(selectedTeamMember && modalType === 'edit' ? selectedTeamMember.email : '');
    setRole(selectedTeamMember && modalType === 'edit' ? selectedTeamMember.role : '');
    setCanCreateApps(selectedTeamMember && modalType === 'edit' ? canSelectedMemberEditPermission('APPS') : false);
    setCanEditOrg(selectedTeamMember && modalType === 'edit' ? canSelectedMemberEditPermission('ORG') : false);
    setCanInviteOthers(selectedTeamMember && modalType === 'edit' ? canSelectedMemberEditPermission('USERS') : false);
    setIsLoading(false);
    setCanAccessApp(selectedTeamMember && modalType === 'edit' && selectedMemberAppsAccess ? selectedMemberAppsAccess : {});
    setAppPermissionScopes(selectedTeamMember && modalType === 'edit' ? selectedMemberAppPermissions : {});
    setOrgPermissionScopes(selectedTeamMember && modalType === 'edit' ? selectedMemberOrgPermissions : {});
  }, [props.visible]);
  const handleEvent = (updateCallback: (newState: any) => void) => {
    return (event: ChangeEvent<{
      value: any;
    }> | any) => {
      updateCallback(typeof event === 'object' ? event.target.value : event);
    };
  };
  const handleAppCheckboxClick = (appId: UUID) => {
    return () => {
      const canAccessThisApp = !canAccessApp[appId];
      setCanAccessApp({
        ...canAccessApp,
        [appId]: canAccessThisApp
      });
      if (!canAccessThisApp) {
        setAppPermissionScopes({
          ...appPermissionScopes,
          [appId]: undefined
        });
      } else {
        setAppPermissionScopes({
          ...appPermissionScopes,
          [appId]: {
            credentials: 'READ',
            info: 'READ',
            plan: 'READ',
            campaigns: 'READ',
            contracts: 'READ'
          }
        });
      }
    };
  };
  const handleAppPermissionSelect = (appId: UUID, permissionName: string) => {
    return (value: PermissionScope) => {
      setAppPermissionScopes({
        ...appPermissionScopes,
        [appId]: ({
          ...appPermissionScopes[appId],
          [permissionName]: value
        } as IPermissionScope)
      });
    };
  };
  const renderAppAccessCheckbox = (appId: UUID) => <div className="canAccessCheckBox" data-sentry-component="renderAppAccessCheckbox" data-sentry-source-file="InvitationModal.tsx">
      <Checkbox checked={canAccessApp[appId]} onChange={handleAppCheckboxClick(appId)} data-sentry-element="Checkbox" data-sentry-source-file="InvitationModal.tsx" />
    </div>;
  const renderAppPermissionSelect = (permissionName: AppPermissionNames) => {
    return (appId: UUID) => {
      const canShowNoneOption = permissionName !== 'info' || !canAccessApp[appId];
      if (!canAccessApp[appId]) {
        return <> - </>;
      }
      const inviteePermissionToApp = inviteeUserPermissionToApp(appId, permissionName);
      return <div className="userSelectInTable">
          <Select disabled={!canAccessApp[appId]} defaultValue={selectedTeamMember && modalType === 'edit' ? appPermissionScopes[appId]?.[permissionName] : 'READ'} bordered={false} onChange={handleAppPermissionSelect(appId, permissionName)} value={appPermissionScopes[appId]?.[permissionName]}>
            {iff(canShowNoneOption, <Option value="NONE" key="NONE"> - </Option>)}
            <Option value="READ" key="READ">CAN VIEW</Option>
            <Option disabled={inviteePermissionToApp === 'NONE' || inviteePermissionToApp === 'READ'} value="WRITE" key="WRITE">CAN EDIT</Option>
          </Select>
        </div>;
    };
  };
  const permissionColumns = [{
    title: 'Can Access',
    width: '9.5%',
    dataIndex: 'id',
    align: ('center' as const),
    key: 'canAccessApp',
    render: renderAppAccessCheckbox
  }, {
    title: 'Name',
    width: '10.7%',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name)
  }, {
    title: 'Info',
    width: '13.3%',
    dataIndex: 'id',
    align: ('center' as const),
    key: 'info',
    render: renderAppPermissionSelect('info')
  }, {
    title: 'Plans',
    width: '13.3%',
    dataIndex: 'id',
    align: ('center' as const),
    key: 'Plans',
    render: renderAppPermissionSelect('plan')
  }, {
    title: 'Credentials',
    width: '13.3%',
    dataIndex: 'id',
    align: ('center' as const),
    key: 'credentials',
    render: renderAppPermissionSelect('credentials')
  }, {
    title: 'Campaigns',
    width: '13.3%',
    dataIndex: 'id',
    align: ('center' as const),
    key: 'campaigns',
    render: renderAppPermissionSelect('campaigns')
  }, {
    title: 'Contracts',
    width: '13.3%',
    dataIndex: 'id',
    align: ('center' as const),
    key: 'contracts',
    render: renderAppPermissionSelect('contracts')
  }];
  const invitationForm = useMemo(() => <div className="userInformationLtBlock">
      <div className="userInformationContent">
      <Text strong={true} style={{
        fontSize: '14px'
      }}>User Information</Text>

        <div className="userInfoInputBlock">
          <label>Email</label>
          <Input placeholder="User email" value={email} onChange={handleEvent(setEmail)} />
        </div>

        {/* Role field disabled for now */}
        {/* <div className="userInfoInputBlock">
          <label>Role <span className="ltGray">(optional)</span></label>
          <div className="userInfoSelect">
            <Select
              value={role}
              onChange={handleEvent(setRole)}
              disabled={disabledFields}
            >
              <Option value="">Select a work role</Option>
              <Option value="MANAGER">Product Manager</Option>
              <Option value="DEVELOPER">Developer</Option>
              <Option value="SUPPORT">Support</Option>
              <Option value="OTHER">Other</Option>
            </Select>
          </div>
         </div> */}

        <div className="userCanHeading">
          <Text strong={true} style={{
          fontSize: '14px'
        }}>This user can</Text>
        </div>

        {iff(currentUserCanCreateApps, <div className="userSwitchBlock">
            <span>Create new Apps</span>
            <div className="userSwitch">
              <Switch checked={canCreateApps} onChange={() => {
            const canCreateAppNow = !canCreateApps;
            if (canCreateAppNow) {
              setOrgPermissionScopes({
                ...orgPermissionScopes,
                ['APPS']: 'WRITE'
              });
            } else {
              setOrgPermissionScopes({
                ...orgPermissionScopes,
                ['APPS']: 'READ'
              });
            }
            setCanCreateApps(!canCreateApps);
          }} disabled={false} />
            </div>
          </div>)}
        <div className="clrFloat" />
        {iff(currentUserCanEditOrg, <div className="userSwitchBlock">
            <span>Edit this organization</span>
            <div className="userSwitch">
              <Switch checked={canEditOrg} onChange={() => {
            const canEditNow = !canEditOrg;
            if (canEditNow) {
              setOrgPermissionScopes({
                ...orgPermissionScopes,
                ['ORG']: 'WRITE'
              });
            } else {
              setOrgPermissionScopes({
                ...orgPermissionScopes,
                ['ORG']: 'READ'
              });
            }
            setCanEditOrg(!canEditOrg);
          }} />
            </div>
          </div>)}
        <div className="clrFloat" />
        {iff(currentUserCanInviteOthers, <div className="userSwitchBlock">
            <span>Invite Members</span>
            <div className="userSwitch">
              <Switch checked={canInviteOthers} onChange={(_, e) => {
            const canInviteNow = !canInviteOthers;
            if (canInviteNow) {
              setOrgPermissionScopes({
                ...orgPermissionScopes,
                ['USERS']: 'WRITE'
              });
            } else {
              setOrgPermissionScopes({
                ...orgPermissionScopes,
                ['USERS']: 'READ'
              });
            }
            setCanInviteOthers(!canInviteOthers);
          }} />
            </div>
          </div>)}
        <div className="clrFloat" />
      </div>
    </div>, [email, role, canEditOrg, canCreateApps, canInviteOthers]);
  const permissionTable = useMemo(() => <div className="appPermissionRtBlock">
      <div className="appPermissionContent">
        <Text strong={true} style={{
        fontSize: '14px'
      }}>App access permissions</Text>
        {/* App Access Permissions Table - Block */}
        <div className="appPermissionTableBlock">
          <Table loading={loadingApps} columns={permissionColumns} dataSource={props.paginationApp.appList} pagination={{
          pageSize: props.paginationApp.pageSize,
          current: props.paginationApp.pageNumber,
          total: props.paginationApp.total,
          showSizeChanger: true
        }} onChange={onChange} rowClassName={record => !appsInviteeUserHasAccessTo.includes(record) ? 'appsTableDisabledRow' : ''} />
        </div>
      </div>
    </div>, [canAccessApp, appPermissionScopes, loadingApps]);
  return <>
      <Modal open={emptyRequestModal} width="384px" footer={[<Button key={1} type={ButtonType.danger} size="middle" onClick={() => setEmptyRequestModal(false)}>
              Ok
            </Button>]} data-sentry-element="Modal" data-sentry-source-file="InvitationModal.tsx">
        <div className="errorModalContent">
            <div className="errorModalHeader">
                <Text strong={true} style={{
            color: '#f74141',
            fontSize: '16px'
          }} data-sentry-element="Text" data-sentry-source-file="InvitationModal.tsx">Empty request</Text>
            </div>
            <div className="errorModalBody">
                <p>There are no changes to save.</p>
            </div>
        </div>
      </Modal>
      <div className="inviteEditUserModalContent">
        <div className="inviteEditUserModalHeader">
          {/* "Invite user / Edit user" - You can dynamically render Title Based on Model type flag  */}
          <Text strong={true} style={{
          fontSize: '16px'
        }} data-sentry-element="Text" data-sentry-source-file="InvitationModal.tsx">{currentOrg.name} • {modalType === 'invite' ? 'Invite' : 'Edit'} user</Text>
          {/* <span>Syngenta Digital • Edit user</span> */}

        </div>
        <div className="inviteEditUserModalBody">

          {/* Left Block  */}
          {invitationForm}

          {/* Right Block  */}
          {permissionTable}
        </div>
      </div>
      <div className="clrFloat" />

      <div className="inviteEditUserModalFooter">
        <div className="modelBtnsBlock">

          {/* Cancel - Button */}
          <div className="userCancelBtnBlock">
            <Button key="cancel" type={ButtonType.default} style={{
            width: 90,
            margin: '0 15px 0 0'
          }} onClick={handleClose} data-sentry-element="Button" data-sentry-source-file="InvitationModal.tsx">
              Cancel
            </Button>
          </div>

          {/* show or hide buttons - based on Model type like Invite or Edit User */}
          <div className="userSuccessBtn">
            <Button style={{
            width: 150
          }} type={ButtonType.primary} onClick={handleSubmit} loading={isLoading} disabled={!isValidEmail(email)} data-sentry-element="Button" data-sentry-source-file="InvitationModal.tsx">
              {modalType === 'invite' ? 'Invite User' : 'Save Changes'}
            </Button>
          </div>
        </div>
      </div>
      <div className="clrFloat" />
    </>;
};