import { AxiosResponse } from 'axios';
import { axiosRequest } from 'core/axios';
import { getHeaders } from 'core/common-methods';
import { OrganizationDTO } from 'core/orgs/models';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import { PageDTO, UUID } from 'core/utils/BasicModels';
import qs from 'querystring';
import { Dispatch } from 'redux';

export const ACTION_GET_ORGS_LIST: RequestAction = createRequestAction('GET_ORGS_LIST_REQUEST');
export const ACTION_CREATE_ORG: RequestAction = createRequestAction('CREATE_ORG_REQUEST');
export const ACTION_EDIT_ORG: RequestAction = createRequestAction('EDIT_ORG_REQUEST');
export const ACTION_CREATE_ORG_IMG: RequestAction = createRequestAction('CREATE_ORG_IMG_REQUEST');
export const ACTION_DELETE_ORG_IMG: RequestAction = createRequestAction('DELETE_ORG_IMG_REQUEST');
export const ACTION_GET_ORG: RequestAction = createRequestAction('GET_ORG_REQUEST');
export const ACTION_DELETE_ORG: RequestAction = createRequestAction('DELETE_ORG_REQUEST');
export const ACTION_SET_CURRENT_ORG = 'ACTION_SET_CURRENT_ORG';
export const ACTION_RESET_CURRENT_ORG: RequestAction = createRequestAction('RESET_CURRENT_ORG_REQUEST');
export const ACTION_GET_ORG_TEAM: RequestAction = createRequestAction('GET_ORG_TEAM_REQUEST');

export const fetchAllOrgs = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_GET_ORGS_LIST.REQUEST });

    const allOrgs = await axiosRequest
        .get<any, AxiosResponse<PageDTO<OrganizationDTO>>>(`/v2/orgs?business_line=SOFTWARE`,
      {
        headers: getHeaders().common,
      });

    dispatch({ type: ACTION_GET_ORGS_LIST.SUCCESS, payload: allOrgs.data });
    return allOrgs.data;
  } catch (e) {
    dispatch({ type: ACTION_GET_ORGS_LIST.ERROR, payload: e });
    throw e;
  }
};

export const createOrg = (valuesRequest: OrganizationDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_ORG.REQUEST });

    const orgValues = {
      ...valuesRequest,
      fallback_details_language: 'pt-BR',
    };

    const org = await axiosRequest
            .post<any, AxiosResponse<OrganizationDTO>>(`/v2/orgs`, orgValues,
      {
        headers: {
          ...getHeaders().common,
        },
      });

    const data = org.data;

    dispatch({ type: ACTION_CREATE_ORG.SUCCESS, payload: data });
    return data;

  } catch (e) {
    dispatch({ type: ACTION_CREATE_ORG.ERROR, payload: e });
    throw e;
  }
};

export const createOrgImg = (orgId: UUID, data: FormData) => async (dispatch: Dispatch) => {

  try {
    dispatch({ type: ACTION_CREATE_ORG_IMG.REQUEST });

    const org = await axiosRequest({
      method: 'post',
      url: `/v2/orgs/${orgId}/picture`,
      headers: { ...getHeaders().common, 'Content-Type': 'multipart/form-data' },
      data,
    });

    const dataResult = org.data;

    dispatch({ type: ACTION_CREATE_ORG_IMG.SUCCESS, payload: dataResult });
    return dataResult;

  } catch (e) {
    dispatch({ type: ACTION_CREATE_ORG_IMG.ERROR, payload: e });
    throw e;
  }
};

export const deleteOrgImg = (orgId: UUID) => async (dispatch: Dispatch) => {

  try {
    dispatch({ type: ACTION_DELETE_ORG_IMG.REQUEST });

    const orgImgDeleted = await axiosRequest({
      method: 'delete',
      url: `/v2/orgs/${orgId}/picture`,
      headers: { ...getHeaders().common },
    });

    dispatch({ type: ACTION_DELETE_ORG_IMG.SUCCESS, payload: orgImgDeleted });
    return orgImgDeleted;

  } catch (e) {
    dispatch({ type: ACTION_DELETE_ORG_IMG.ERROR, payload: e });
    throw e;
  }
};

export const editOrg = (orgId: UUID, valuesRequest: OrganizationDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_EDIT_ORG.REQUEST });

    const orgValues = {
      ...valuesRequest,
      fallback_details_language: 'pt-BR',
    };

    const orgEdited = await axiosRequest
            .put<any, AxiosResponse<OrganizationDTO>>(`/v2/orgs/${orgId}`, orgValues,
      {
        headers: {
          ...getHeaders().common,
          // tslint:disable-next-line:no-duplicate-string  TODO(marcela): use user preference
          'Accept-Language': 'pt-BR',
          'Content-Language': 'pt-BR',
        },
      });

    dispatch({ type: ACTION_EDIT_ORG.SUCCESS, payload: orgEdited.data });

    return orgEdited.data;

  } catch (e) {
    dispatch({ type: ACTION_EDIT_ORG.ERROR, payload: e });
  }
};

export const getOrgById = (orgId: UUID) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_GET_ORG.REQUEST });

    const org = await axiosRequest
            .get<any, AxiosResponse<OrganizationDTO>>(`/v2/orgs/${orgId}`,
      {
        headers: {
          ...getHeaders().common,
          'Accept-Language': 'pt-BR',
        },
      });

    const data = org.data;

    dispatch({ type: ACTION_GET_ORG.SUCCESS, payload: data });

    return data;
  } catch (e) {
    dispatch({ type: ACTION_GET_ORG.ERROR, payload: e });
    throw e;
  }
};

export const deleteOrg = (orgId: UUID) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_ORG.REQUEST });

    const deleteOrgResponse = await axiosRequest
        .delete(`/v2/orgs/${orgId}`,
      {
        headers: {
          ...getHeaders().common,
          'Accept-Language': 'pt-BR',
        },
      });

    dispatch({ type: ACTION_DELETE_ORG.SUCCESS, payload: deleteOrgResponse });

    return deleteOrgResponse;
  } catch (e) {
    dispatch({ type: ACTION_DELETE_ORG.ERROR, payload: e });
    throw e;
  }
};

export const setCurrentOrg = (currentOrgId: UUID) => async (dispatch: Dispatch) => {
  dispatch({ type: ACTION_SET_CURRENT_ORG, payload: currentOrgId });
};

export const resetCurrentOrg = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_RESET_CURRENT_ORG.REQUEST });

    const data = '';

    dispatch({ type: ACTION_RESET_CURRENT_ORG.SUCCESS, payload: data });

    return data;
  } catch (e) {
    dispatch({ type: ACTION_RESET_CURRENT_ORG.ERROR, payload: e });
    throw e;
  }
};

export const fetchOrgTeam = (orgId: any, pageNumber: number, pageSize: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_GET_ORG_TEAM.REQUEST });

    const pageInfo = {
      page: pageNumber,
      size: pageSize,
    };

    const accounts = await axiosRequest
        .get<any, AxiosResponse<any>>(`/v2/orgs/${orgId}/accounts?${qs.stringify(pageInfo)}`,
      {
        headers: getHeaders().common,
      },
        );

    dispatch({ type: ACTION_GET_ORG_TEAM.SUCCESS, payload: accounts.data });

    return accounts.data;
  } catch (e) {
    dispatch({ type: ACTION_GET_ORG_TEAM.ERROR, payload: e });
    throw e;
  }
};
