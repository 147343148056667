import React from 'react';
import { FlexContainer } from '../../styled';
import styled from 'styled-components';
import { NoAccessImg } from '../../images/NoAccessImg';
const Container = styled(FlexContainer)`
  border-radius: 12px;
  background-color: #ffffff;
  border: 1px solid #e8eaed;
  margin: 24px;
  padding: 56px;
`;
const Title = styled.h1`
  margin: 20px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #232630;
`;
export const IntegrationsNoAccess = () => {
  return <Container direction="column" data-sentry-element="Container" data-sentry-component="IntegrationsNoAccess" data-sentry-source-file="integrations-no-access.tsx">
      <NoAccessImg data-sentry-element="NoAccessImg" data-sentry-source-file="integrations-no-access.tsx" />
      <Title data-sentry-element="Title" data-sentry-source-file="integrations-no-access.tsx">{`You don't have access to Integrations`}</Title>
      <p style={{
      maxWidth: 400,
      textAlign: 'center'
    }}>
        If necessary, ask for permission to access the Integrations.
      </p>
    </Container>;
};