import { Divider, Layout, Modal, Skeleton, Table } from 'antd';
import { getCurrentOrgId } from 'app-redux/selectors/orgs.selector';
import React, { useEffect, useState } from 'react';
import { Button, ButtonType, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
const {
  Content
} = Layout;
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { deleteApiClient, fetchApiClients, setCurrentApiClient } from 'app-redux/actions/api-client.actions';
import { AppState } from 'app-redux/reducers';
import { OAuth2ClientDTO, PagedOAuth2ClientDTO } from 'core/api-clients/models';
import { INavigationRouteProps } from 'core/navigation/models';
import { PageDTO, UUID } from 'core/utils/BasicModels';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { currentUserHasPermission } from '../../app-redux/selectors/accounts.selector';
import { getApiClientList, getApiClientPaged, getCurrentApiClientLoadingStatus } from '../../app-redux/selectors/api-clients.selector';
import { history, RoutePaths } from '../../core/history';
import { iff } from '../../core/iff';
import './style.less';
const {
  Text
} = Typography;
interface IGetCredentialsProps extends INavigationRouteProps<{}> {
  getApiClient: (appId: UUID, pageNumber: number, pageSize: number) => Promise<PagedOAuth2ClientDTO>;
  dispatchDeleteApiClient: (apiClientId: UUID) => any;
  setCurrentApiClient: (clientId: string) => any;
  apiClientList: OAuth2ClientDTO[];
  apiClientPaged: PagedOAuth2ClientDTO;
  isLoadingClients: boolean;
  currentOrgId: string;
  currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string) => boolean;
}

// tslint:disable-next-line:no-big-function
export const Credentials: React.FC<IGetCredentialsProps> = props => {
  const [visibleSuccessModal, setVisibleSuccessModal] = useState(false);
  const [visibleErrorModal, setVisibleErrorModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentApiClientId, setCurrentApiClientId] = useState('');
  const [paged, setPaged] = useState({
    pageSize: 0,
    pageNumber: 0,
    total: 0
  });
  const handleClose = async (type: string) => {
    if (type === 'success') {
      setVisibleSuccessModal(false);
    } else {
      setVisibleErrorModal(false);
    }

    // tslint:disable-next-line:deprecation
    window.location.reload();
  };
  const handleDeleteApiClient = async () => {
    setDeleteModal(false);
    const clientDeleted = await props.dispatchDeleteApiClient(currentApiClientId);
    if (clientDeleted.status === 200) {
      setVisibleSuccessModal(true);
    }
  };
  const columns = [{
    title: 'Name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    dataIndex: 'name',
    key: 'name',
    render: (text: string) => <a style={{
      color: '#14151C'
    }}>{text}</a>
  }, {
    title: 'Created At',
    filters: [{
      text: 'March',
      value: 'March'
    }, {
      text: 'August',
      value: 'August'
    }],
    dataIndex: 'created_at',
    onFilter: (value: any, record: any) => record.created_at.includes(value),
    key: 'created_at',
    render: (text: string) => <div onClick={e => {
      e.stopPropagation();
    }}>
            {text ? moment(text).format('MM/DD/YYYY h:mm A') : '--'}
        </div>
  }, {
    title: 'Client ID',
    sorter: (a: any, b: any) => a.id.localeCompare(b.id),
    dataIndex: 'client_id',
    key: 'client_id',
    render: (text: string) => <div onClick={e => {
      e.stopPropagation();
    }}>
            {text}
        </div>
  }, {
    title: 'Redirect URIs',
    sorter: (a: any, b: any) => a.redirect_uris - b.redirect_uris,
    dataIndex: 'redirect_uris',
    key: 'redirect_uris',
    render: (redirectUris: any) => <div onClick={e => {
      e.stopPropagation();
    }}>
            {redirectUris.length ? redirectUris.length : '--'}
          </div>
  }, {
    title: 'Last Access',
    sorter: (a: any, b: any) => a.last_access.localeCompare(b.last_access),
    dataIndex: 'last_access',
    key: 'last_access',
    render: (text: string) => <div onClick={e => {
      e.stopPropagation();
    }}>
            {text ? moment(text).format('MM/DD/YYYY h:mm A') : '--'}
        </div>
  }, {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    render: (text: any, record: any, index: any) => {
      const canChangeThisClient = props.currentUserHasPermission(props.match.params.appId, 'APP', 'CREDENTIALS', 'WRITE') || props.currentUserHasPermission(props.currentOrgId, 'ORGANIZATION', 'OWNER', 'WRITE');
      if (!canChangeThisClient) {
        return <> - </>;
      }
      return <div className="cw-credential-actions">
            <a className="cw-credential-revoke" onClick={e => {
          e.stopPropagation();
          const apiClientDeleted = async () => {
            const apiClientId = record.client_id;
            if (apiClientId) {
              setCurrentApiClientId(apiClientId);
              setDeleteModal(true);
            }
          };
          apiClientDeleted().catch();
        }}>
              Revoke
            </a>
            <Divider type="vertical" className="cw-divider-left" />
            <a className="cw-credential-edit" onClick={async e => {
          e.stopPropagation();
          const currentApiClient = record;
          if (currentApiClient.client_id) {
            await props.setCurrentApiClient(currentApiClient.client_id);
          }
          history.push({
            pathname: RoutePaths.API_CLIENT_DETAILS(props.match.params.appId, currentApiClient.client_id),
            state: {
              currentApiClient
            }
          });
        }}>
              Edit
            </a>
          </div>;
    }
  }];
  const onRowClick = async (record: any) => {
    const currentApiClient = record;
    if (currentApiClient.client_id) {
      await props.setCurrentApiClient(currentApiClient.client_id);
    }
    history.push({
      pathname: RoutePaths.API_CLIENT_DETAILS(props.match.params.appId, currentApiClient.client_id),
      state: {
        currentApiClient
      }
    });
  };
  useEffect(() => {
    const loadApiClients = async () => {
      return props.getApiClient(props.match.params.appId, 0, 5);
    };
    loadApiClients().then(response => {
      setPaged({
        pageSize: response.size,
        pageNumber: response.number + 1,
        total: response.total_elements
      });
    }).catch();
  }, []);
  const onChange = async (pagination: any, filters: any, sorter: any, extra: any) => {
    const page = pagination.current - 1;
    const pageSize = pagination.pageSize;
    const credentials = await props.getApiClient(props.match.params.appId, page, pageSize);
    setPaged({
      pageNumber: credentials.number + 1,
      pageSize: credentials.size,
      total: credentials.total_elements
    });
  };
  return <>
      <Content className="cw-content-container" data-sentry-element="Content" data-sentry-source-file="index.tsx">
        <Modal open={visibleSuccessModal} onOk={() => handleClose('success')} onCancel={() => handleClose('success')} width="384px" footer={[<Button key={1} type={ButtonType.primary} size="large" onClick={() => handleClose('success')}>
                Ok
              </Button>]} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
          <p><CheckCircleOutlined style={{
            color: 'green'
          }} data-sentry-element="CheckCircleOutlined" data-sentry-source-file="index.tsx" /> Api client successfully revoked!</p>
        </Modal>

        <Modal open={visibleErrorModal} onOk={() => handleClose('error')} onCancel={() => handleClose('error')} width="384px" footer={[<Button key={1} type={ButtonType.primary} size="large" onClick={() => handleClose('error')}>
                Ok
              </Button>]} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
            <p><CloseCircleOutlined style={{
            color: 'red'
          }} data-sentry-element="CloseCircleOutlined" data-sentry-source-file="index.tsx" /> Sorry, api client couldn't be revoked.</p>
        </Modal>

        <Modal open={deleteModal} onOk={handleDeleteApiClient} onCancel={() => setDeleteModal(false)} width="384px" footer={[<Button key={1} type={ButtonType.default} size="middle" onClick={() => setDeleteModal(false)}>
                Cancel
              </Button>, <Button key={2} type={ButtonType.danger} size="middle" onClick={handleDeleteApiClient}>
                Delete
              </Button>]} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
          <div className="deleteModalContent">
              <div className="deleteModalHeader">
                <Text strong={true} style={{
              color: '#f74141',
              fontSize: '16px'
            }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Revoke api client</Text>
              </div>
              <div className="deleteModalBody">
                <p>Are you sure you want to revoke this api client?</p>
              </div>
          </div>
        </Modal>

              <Text strong={true} style={{
        fontSize: '20px',
        color: '#2F3031'
      }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Credentials</Text>
              <Text strong={true} style={{
        fontSize: '16px',
        marginTop: '30px'
      }} data-sentry-element="Text" data-sentry-source-file="index.tsx">API Clients</Text>
              {iff(props.currentUserHasPermission(props.match.params.appId, 'APP', 'CREDENTIALS', 'WRITE') || props.currentUserHasPermission(props.currentOrgId, 'ORGANIZATION', 'OWNER', 'WRITE'), <div className="cw-credential-create-client-button">
                    <Button style={{
          width: 147,
          marginTop: '20px'
        }} type={ButtonType.primary} onClick={() => {
          history.push(RoutePaths.CREATE_API_CLIENT(props.match.params.appId));
        }}>
                      Create Client
                    </Button>
                  </div>)}

        <div className="cw-credential-table">
            {props.isLoadingClients ? <Skeleton loading={props.isLoadingClients} active={true} avatar={true} /> : <Table onRow={(record, rowIndex) => {
          return {
            onClick: () => onRowClick(record)
          };
        }} rowKey={record => record.client_id ? record.client_id : ''} pagination={{
          pageSize: paged.pageSize,
          current: paged.pageNumber,
          total: paged.total
        }} columns={columns} dataSource={props.apiClientList} onChange={onChange} />}
          </div>

      </Content>
    </>;
};
const mapStateToProps = (state: AppState) => ({
  apiClientList: getApiClientList(state),
  apiClientPaged: getApiClientPaged(state),
  isLoadingClients: getCurrentApiClientLoadingStatus(state),
  currentOrgId: getCurrentOrgId(state),
  currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string) => currentUserHasPermission(state, id, context, permission, scope)
});
const mapDispatchToProps = (dispatch: any, ownProps: IGetCredentialsProps): IGetCredentialsProps => {
  return {
    ...ownProps,
    getApiClient: (appId: UUID, pageNumber: number, pageSize: number) => dispatch(fetchApiClients(appId, pageNumber, pageSize)),
    dispatchDeleteApiClient: (apiClientId: UUID) => dispatch(deleteApiClient(apiClientId)),
    setCurrentApiClient: (clientId: string) => dispatch(setCurrentApiClient(clientId))
  };
};
export const CredentialsComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(Credentials));