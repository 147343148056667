import { IntegrationsContext } from 'pages/integrations';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IntegrationLogo } from './integration-logo';
import { IntegrationDescription } from './integration-description';
import { IntegrationName } from './integration-name';
import { IntegrationUrlAttribute } from './integration-url-attribute';
import { FlexContainer } from 'pages/integrations/styled';
import { IntegrationLanguageDropdown } from './integration-language-dropdown';
import { IntegrationCard } from './integration-card';
import { IntegrationDeckCard } from './integration-deck-card';
import bgs from '../../images/backgrounds';
import { Button, ButtonType, Spin } from 'syngenta-digital-cropwise-react-ui-kit';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ArrowBackIcon } from 'pages/integrations/images/ArrowBackIcon';
import { IntegrationBackgroundImg } from './integration-background-image';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { Integration, IntegrationAvailableLanguages, IntegrationClass, IntegrationProvider, IntegrationSource, IntegrationTag } from '../../utils/types';
import { BGContainer, Container, Header, BackBtn, Content, Panel, PanelTitle, RightPanel, Footer, CancelBtn, Mask, Divider, RequiredAppsSelect } from './styled';
import { CustomSelect } from 'components/custom-select';
import { FormGroup } from './form-group';
import { CountryCode, CountryMap, countries } from 'pages/integrations/utils/contants';
import { useSelector } from 'react-redux';
import { Modal, ModalFuncProps, Tag } from 'antd';
import { CloseIcon } from 'pages/integrations/images/CloseIcon';
import styled from 'styled-components';
import { getIsDeletingIntegrations, getIsSavingIntegrations } from 'app-redux/selectors/integrations.selector';
import { IntegrationEntitlementKey } from './integration-entitlement-key';
const getRandBg = () => {
  return new Promise<string>(resolve => {
    fetch(bgsArray[Math.floor(Math.random() * bgsArray.length)]) // NOSONAR
    .then(res => res.blob()).then(blob => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve((reader.result as string)));
      reader.readAsDataURL(blob);
    });
  });
};
interface ContextValue {
  language: {
    value: IntegrationAvailableLanguages;
    set: (newValue: IntegrationAvailableLanguages) => void;
  };
  errors?: {
    [key: string]: string | undefined;
  };
  setError: <T extends keyof ContextValue['values']>(key: T, value: string | undefined) => void;
  values: Omit<Integration, 'id'>;
  setValues: <T extends keyof ContextValue['values']>(key: T, value: Integration[T]) => void;
}
export const IntegrationsFormContext = (React.createContext<ContextValue | null>(null) as React.Context<ContextValue>);
const bgsArray = Object.values(bgs.default);
const defaultFormState: Omit<Integration, 'id'> = (({
  name: '',
  provider: '',
  description: {},
  countries: [],
  classes: [],
  supported_applications: [],
  required_applications: [],
  icon: '',
  image: '',
  tags: []
} as unknown) as Omit<Integration, 'id'>);
export const IntegrationsForm: React.FC<{
  onClose: () => void;
  onSubmit: (integration: Partial<Integration>) => void;
  onDelete: (id: string) => void;
}> = ({
  onClose,
  onSubmit,
  onDelete
}) => {
  const isDeleting = useSelector(getIsDeletingIntegrations);
  const isSaving = useSelector(getIsSavingIntegrations);
  const [modal, contextHolder] = Modal.useModal();
  const [background, setBackground] = useState<string>();
  const {
    currentIntegration,
    fetchApps
  } = useContext(IntegrationsContext);
  const [language, setLanguage] = useState<IntegrationAvailableLanguages>('en');
  const [isLoaded, setIsLoaded] = useState(false);
  const [formState, setFormState] = useState<Omit<Integration, 'id'>>(currentIntegration ?? defaultFormState);
  const [errorsState, setErrorsState] = useState<{
    [key: string]: string | undefined;
  }>({});
  const canSubmit = useMemo(() => {
    return formState.icon && formState.name && formState.source && Object.values(formState.description).some(trans => !!trans) && formState.classes?.length === 1 && formState.provider && formState.countries?.length > 0 && !Object.values(errorsState).some(value => !!value);
  }, [formState, errorsState]);
  useEffect(() => {
    const cacheImage = async () => {
      return await new Promise<void>(resolve => {
        if (background) {
          const img = new Image();
          img.src = background;
          img.onload = () => {
            resolve();
          };
        }
      });
    };
    cacheImage().finally(() => setIsLoaded(true));
  }, [background]);
  useEffect(() => {
    const setBgOrRand = async () => {
      setBackground(formState.image || (await getRandBg()));
    };
    setBgOrRand().catch(() => {
      console.warn("Couldn't set background image");
    });
  }, [formState.image]);
  useEffect(() => {
    if (!formState.image && formState.classes?.length > 0) {
      const intClass = formState.classes[0];
      if (bgs.byClass[intClass]) {
        setBackground(bgs.byClass[intClass]);
      }
    }
  }, [formState.image, formState.classes]);
  useEffect(() => {
    if (!formState.description[language]) {
      const availableTrans = Object.entries(formState.description).find(([, trans]) => !!trans);
      if (availableTrans) {
        setLanguage((availableTrans[0] as IntegrationAvailableLanguages));
      }
    }
  }, [formState.description, language]);
  const setValues: ContextValue['setValues'] = useCallback((key, value) => {
    setFormState(state => ({
      ...state,
      [key]: value
    }));
  }, []);
  const setError: ContextValue['setError'] = useCallback((key: string, value: string | undefined) => {
    setErrorsState(state => ({
      ...state,
      [key]: value
    }));
  }, []);
  const addEditLabel = useMemo(() => `${currentIntegration ? 'Edit' : 'Add'} Integration`, [currentIntegration]);

  /*
   * Input Renderes
   */
  const renderTags = useCallback(() => <CustomSelect<IntegrationTag> multiple={true} dark={true} options={[{
    value: 'free',
    label: 'Free'
  }, {
    value: 'paid',
    label: 'Paid'
  }, {
    value: 'beta',
    label: 'Beta'
  }]} value={formState.tags} showSearch={false} onApply={values => setValues('tags', values)} placeholder="Select Tags" />, [formState.tags, setValues]);
  const renderClasses = useCallback(() => <CustomSelect<IntegrationClass> multiple={false} dark={true} options={[{
    value: 'erp',
    label: 'ERP'
  }, {
    value: 'weather',
    label: 'Weather'
  }, {
    value: 'machinery',
    label: 'Machinery'
  }, {
    value: 'traps',
    label: 'Trap'
  }, {
    value: 'ag_insights',
    label: 'Ag-Insights'
  }]} value={formState.classes[0]} showSearch={false} onApply={value => setValues('classes', [value])} placeholder="Select Class" dataTestId="integration-class" />, [formState.classes, setValues]);
  const renderProvider = useCallback(() => <CustomSelect<IntegrationProvider> multiple={false} dark={true} options={[{
    value: 'Trap View',
    label: 'Trap View'
  }, {
    value: 'Farmobile',
    label: 'Farmobile'
  }, {
    value: 'Raven Industries',
    label: 'Raven Industries'
  }, {
    value: 'Veris Technologies',
    label: 'Veris Technologies'
  }, {
    value: 'Claas',
    label: 'Claas'
  }, {
    value: 'Perfect Flight',
    label: 'Perfect Flight'
  }, {
    value: 'GroGuru',
    label: 'GroGuru'
  }, {
    value: 'John Deere',
    label: 'John Deere'
  }, {
    value: 'SAP',
    label: 'SAP'
  }, {
    value: 'Nectar',
    label: 'Nectar'
  }, {
    value: 'Siagri',
    label: 'Siagri'
  }, {
    value: 'Ministry of Agriculture, Fisheries and Food',
    label: 'Ministry of Agriculture, Fisheries and Food'
  }, {
    value: 'Cropwise',
    label: 'Cropwise'
  }, {
    value: 'Pessl Instruments',
    label: 'Pessl Instruments'
  }, {
    value: 'CNHi',
    label: 'CNHi'
  }, {
    value: 'AGCO',
    label: 'AGCO'
  }, {
    value: 'Ag Leader',
    label: 'Ag Leader'
  }, {
    value: 'Sencrop',
    label: 'Sencrop'
  }, {
    value: 'Stara',
    label: 'Stara'
  }, {
    value: 'Trimble Agriculture',
    label: 'Trimble Agriculture'
  }, {
    value: 'Arable',
    label: 'Arable'
  }]} value={formState.provider} showSearch={false} onApply={value => setValues('provider', value)} placeholder="Insert Provider" disabled={!!currentIntegration} dataTestId="integration-provider" />, [formState.provider, currentIntegration, setValues]);
  const renderSource = useCallback(() => <CustomSelect<IntegrationSource> multiple={false} dark={true} options={[{
    value: 'product-level',
    label: 'Product Level'
  }, {
    value: 'module',
    label: 'Module'
  }, {
    value: 'native',
    label: 'Native'
  }, {
    value: 'third-party',
    label: 'External'
  }]} value={formState.source} showSearch={false} onApply={value => setValues('source', value)} placeholder="Insert Source" dataTestId="integration-source" />, [formState.source, setValues]);
  const renderCountries = useCallback(() => <CustomSelect<CountryCode> multiple={true} dark={true} options={countries.map(c => ({
    label: `${c.flag} ${c.name}`,
    value: c.code
  }))} value={formState.countries} allSelectedLabel="All Countries" onApply={value => setValues('countries', value)} placeholder="Select Countries" formatter={codes => codes.map(code => CountryMap[code]).join(', ')} dataTestId="integration-countries" />, [formState.countries, setValues]);
  const renderEntitlementKeys = useCallback(() => <IntegrationEntitlementKey />, []);
  const renderRequiredApps = useCallback(() => {
    const tagRender = (props: CustomTagProps) => {
      // eslint-disable-next-line react/prop-types
      const {
        label,
        closable,
        onClose
      } = props;
      const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
      };
      return <StyledTag color={'#F3F4F6'} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} closeIcon={<CloseIcon />} style={{
        borderRadius: 4,
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#696F88',
        fontWeight: 600,
        lineHeight: '20px',
        margin: 4
      }} data-sentry-element="StyledTag" data-sentry-component="tagRender" data-sentry-source-file="integrations-form.tsx">
          {label}
        </StyledTag>;
    };
    return <RequiredAppsSelect mode="tags" size="large" bordered={false} style={{
      width: '100%'
    }} placeholder="Insert the Required Apps" value={formState.required_applications} onChange={values => setValues('required_applications', (values as string[]))} options={[]} tokenSeparators={['\n', ',']} dropdownStyle={{
      display: 'none'
    }} tagRender={tagRender} />;
  }, [formState.required_applications, setValues]);
  const renderCropwiseApps = useCallback(() => <CustomSelect<string> multiple={true} dark={true} options={[]} value={formState.supported_applications} allSelectedLabel="All Cropwise Apps" onApply={value => setValues('supported_applications', value)} placeholder="Select Cropwise Apps" lazy={!currentIntegration} loadOnOpen={() => fetchApps().then(apps => apps.map(app => ({
    value: (app.id as string),
    label: (app.name as string)
  })))} />, [formState.supported_applications, currentIntegration, setValues, fetchApps]);
  const contextValue = useMemo(() => ({
    values: formState,
    setValues,
    language: {
      value: language,
      set: setLanguage
    },
    errors: errorsState,
    setError
  }), [errorsState, formState, language, setError, setValues]);
  return isLoaded ? <IntegrationsFormContext.Provider value={contextValue}>
      <BGContainer style={{
      background: `url(${background})`
    }} data-testid="integrations-form">
        <Container>
          <Header>
            <BackBtn onClick={onClose} data-testid="integrations-form-back-btn">
              <ArrowBackIcon />
            </BackBtn>
            <span>{addEditLabel}</span>
          </Header>
          <Content>
            <Panel>
              <PanelTitle>Basic Information</PanelTitle>
              <FlexContainer style={{
              width: '100%',
              marginTop: 16
            }} align="flex-start" justify="flex-start">
                <IntegrationLogo />
                <FlexContainer style={{
                maxWidth: 'calc(100% - 145px - 12px)'
              }} direction="column" align="flex-start" gap={12}>
                  <IntegrationName />
                  <IntegrationDescription />
                  <FormGroup inputs={[{
                  label: 'Tags',
                  optional: true,
                  render: renderTags
                }, {
                  label: 'Class',
                  render: renderClasses
                }]} />
                  <Divider />
                  <FormGroup inputs={[{
                  label: 'Integration Source',
                  render: renderSource
                }, {
                  label: 'Countries',
                  render: renderCountries
                }, {
                  label: 'Provider',
                  render: renderProvider
                }, {
                  label: 'Entitlement Key',
                  render: renderEntitlementKeys,
                  optional: true
                }, {
                  label: 'Required Applications',
                  optional: true,
                  tooltip: 'Press Enter or Comma keys to add multiple required applications',
                  containerStyle: {
                    alignSelf: 'flex-end'
                  },
                  render: renderRequiredApps
                }, {
                  label: 'Supported Cropwise Apps',
                  optional: true,
                  render: renderCropwiseApps
                }]} />
                  <IntegrationUrlAttribute />
                </FlexContainer>
              </FlexContainer>
            </Panel>
            <RightPanel>
              <FlexContainer align="center" justify="space-between" style={{
              width: '100%',
              marginBottom: 16
            }}>
                <PanelTitle>Preview</PanelTitle>
                <IntegrationLanguageDropdown />
              </FlexContainer>
              <div style={{
              margin: '0 12px'
            }}>
                <IntegrationCard />
                <IntegrationDeckCard />
                <IntegrationBackgroundImg />
              </div>
            </RightPanel>
          </Content>
          <Footer>
            {currentIntegration && <Button size="middle" type={ButtonType.danger} disabled={isDeleting || isSaving} loading={isDeleting} onClick={() => modal.confirm(configDeleteModal(() => onDelete(currentIntegration.id)))}>
                Delete Integration
              </Button>}
            <FlexContainer style={{
            marginLeft: 'auto'
          }}>
              <CancelBtn size="middle" onClick={onClose} disabled={isSaving || isDeleting}>
                Cancel
              </CancelBtn>
              <Button data-testid="integrations-form-add-btn" size="middle" type={ButtonType.primary} disabled={!canSubmit || isSaving || isDeleting} loading={isSaving} onClick={() => {
              if (!formState.image) {
                setFormState(state => ({
                  ...state,
                  image: (background as string)
                }));
              }
              onSubmit({
                ...formState,
                id: currentIntegration?.id,
                icon: formState.icon.split(',')[1],
                image: (background || '').split(',')[1],
                provider: currentIntegration ? undefined : formState.provider,
                setup_url: formState.setup_url || '',
                entitlement_key: formState.entitlement_key || ''
              });
            }}>
                {addEditLabel}
              </Button>
            </FlexContainer>
          </Footer>
        </Container>
        <Mask />
      </BGContainer>
      {contextHolder}
    </IntegrationsFormContext.Provider> : <FlexContainer style={{
    height: '100%'
  }}>
      <Spin indicator={<LoadingOutlined />} />
    </FlexContainer>;
};
const StyledTag = styled(Tag)`
  [class*='-tag-close-icon'] {
    cursor: pointer;
    height: 16px;
    margin-left: 4px;
    transition: 300ms;

    &:hover {
      opacity: 0.7;
    }
  }
`;
const configDeleteModal = (onOk: () => void): ModalFuncProps => ({
  wrapClassName: 'integrations-delete-warning',
  centered: true,
  width: 420,
  icon: <ExclamationCircleOutlined style={{
    color: '#cf3537'
  }} />,
  cancelText: 'Cancel',
  okText: 'Delete',
  okButtonProps: {
    style: {
      backgroundColor: '#cf3537',
      border: 'none',
      borderRadius: 4
    },
    size: 'large',
    danger: true
  },
  cancelButtonProps: {
    style: {
      color: '#14151C',
      backgroundColor: '#fff',
      borderColor: '#c2c7d0',
      borderRadius: 4
    },
    size: 'large'
  },
  title: <span style={{
    color: '#14151c',
    fontSize: 16,
    fontWeight: 600
  }}>
      Are you sure you want to delete this integration?
    </span>,
  content: <span style={{
    color: '#14151c'
  }}>
      This action is irreversible and the data from this integration cannot be recovered.
    </span>,
  bodyStyle: {
    padding: 24
  },
  onOk
});