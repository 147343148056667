import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Input as AntdInput, Layout, message, Modal, Spin, Upload } from 'antd';
import { deleteApp, deleteAppIcon, editApp, getAppById, resetGetApp, setCurrentApp, uploadAppIcon } from 'app-redux/actions/apps.actions';
import { AppState } from 'app-redux/reducers';
import { App } from 'app-redux/reducers/apps.reducer';
import { currentUserHasPermission } from 'app-redux/selectors/accounts.selector';
import { getCurrentApp } from 'app-redux/selectors/apps.selector';
import { getCurrentOrgId } from 'app-redux/selectors/orgs.selector';
import { AppPageHeader } from 'components/headers/app-details-page-header';
import { InfoErrorModal } from 'components/info-modals/InfoErrorModal';
import { InfoSuccessModal } from 'components/info-modals/InfoSuccessModal';
import { AppConstants } from 'core/app.constants';
import { AppType, DeveloperAppDTO } from 'core/apps/models';
import { history, RoutePaths } from 'core/history';
import { INavigationRouteProps } from 'core/navigation/models';
import { UUID } from 'core/utils/BasicModels';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Button, ButtonType, Input, InputType, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import { AvailableLanguagesDropdown } from '../../components/available-languages-dropdown';
import { UploadAppIcon, UploadTypes } from '../../components/upload-app-icon';
import './style.less';
const {
  Content
} = Layout;
const {
  Text
} = Typography;
const Label = styled(Text)`
    display: block;
    color: #2F3031;
    font-size: 16px;
    font-weight: 400;
`;
const getBase64 = (img: File, callback: (result: string | ArrayBuffer | null) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
interface IAppDetails {
  currentApp: {
    id: UUID;
    name: string;
    published?: boolean | null;
    short_description: string;
    detailed_description: string;
    site_uri: string;
    icon_uri: string;
    fallback_details_language: string;
    type: AppType;
    supported_apps: string[] | null;
    apple_store_uri: string | null;
    play_store_uri: string | null;
    details_review_status: string | null;
  };
}
interface IAppDetailsProps extends INavigationRouteProps<IAppDetails> {
  currentOrgId: string;
  dispatchSendAppChanges: (appId: UUID, valuesRequest: DeveloperAppDTO) => any;
  resetCurrentApp?: () => Promise<string>;
  dispatchGetAppById: (appId: any, language: string) => any;
  setCurrentApp: (appId: UUID) => any;
  getCurrentApp: App | null;
  loading?: boolean;
  currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string) => boolean;
  uploadAppIcon: (appId: UUID, icon: string | Blob, acceptedLanguage?: string) => Promise<any>;
  deleteAppIcon: (appId: UUID, acceptedLanguage?: string) => Promise<any>;
  dispatchDeleteApp: (appId: UUID) => any;
}

// tslint:disable-next-line:no-big-function
export const AppDetails: React.FC<IAppDetailsProps> = props => {
  const [currentAppProps, setCurrentAppProps] = useState(props.location.state.currentApp);
  const [resetProcess, setResetProcess] = useState(false);
  const {
    TextArea
  } = AntdInput;
  const fetchCurrentApp = async () => {
    const currentAppResponse = await props.dispatchGetAppById(props.match.params.appId, 'en');
    setCurrentAppProps(currentAppResponse);
  };
  useEffect(() => {
    if (!props.getCurrentApp && !resetProcess) {
      fetchCurrentApp().catch();
    }
  }, [props.getCurrentApp]);
  const handleLanguageClick = async (lan: string, setFieldValue: any) => {
    setFieldValue('appLanguage', lan);
    setCurrentLanguage(lan);
    const app = await props.dispatchGetAppById(currentAppProps.id, lan);
    if (app.headerLanguageContent === lan) {
      setFieldValue('appName', app.name);
      setFieldValue('appDescription', app.short_description);
      setFieldValue('detailedDescription', app.detailed_description);
      setFieldValue('appDomain', app.site_uri);
      // Icon is invalid
      setImgUrl('');
    } else {
      setFieldValue('appName', '');
      setFieldValue('appDescription', '');
      setFieldValue('detailedDescription', '');
      setFieldValue('appDomain', '');
      setImgUrl('');
    }
  };
  const [currentLanguage, setCurrentLanguage] = useState<string | undefined>(undefined);
  const [loadingBanner, setLoadingBanner] = useState(false);
  const [loadingScreenShot1, setLoadingScreenShot1] = useState(false);
  const [loadingScreenShot2, setLoadingScreenShot2] = useState(false);
  const [imageUrl, setImgUrl] = useState('');
  const [bannerUrl, setBannerUrl] = useState('');
  const [screenShotUrl1, setScreenShotUrl1] = useState('');
  const [screenShotUrl2, setScreenShotUrl2] = useState('');
  const [infoSuccessModalVisible, setInfoSuccessModalVisible] = useState(false);
  const [infoErrorModalVisible, setInfoErrorModalVisible] = useState(false);
  const [infoModalMessage, setInfoModalMessage] = useState<string>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [published, setPublished] = useState(!!currentAppProps.published);
  const handleSendChanges = async (values: any) => {
    const valuesReq: DeveloperAppDTO = {
      ...(currentAppProps ?? {}),
      apple_store_uri: currentAppProps.apple_store_uri,
      details_review_status: currentAppProps.details_review_status,
      fallback_details_language: currentAppProps.fallback_details_language,
      play_store_uri: currentAppProps.play_store_uri,
      supported_apps: currentAppProps.supported_apps,
      type: currentAppProps.type,
      id: currentAppProps.id,
      org_id: props.currentOrgId,
      name: values.appName ? values.appName : currentAppProps.name,
      published: published ? 1 : 0,
      short_description: values.appDescription ? values.appDescription : currentAppProps.short_description,
      detailed_description: values.detailedDescription,
      site_uri: values.appDomain ? values.appDomain : currentAppProps.site_uri,
      icon_uri: imageUrl ? imageUrl : undefined,
      language: values.appLanguage,
      image_uri: undefined
    };
    const appChangesSent = await props.dispatchSendAppChanges(currentAppProps.id, valuesReq);
    if (appChangesSent) {
      setInfoModalMessage('App updated successfully!');
      setInfoSuccessModalVisible(true);
    } else {
      setInfoModalMessage('Sorry, could not update this app.');
      setInfoErrorModalVisible(true);
    }
  };
  const handleBannerImgChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoadingBanner(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (newBannerUrl: any) => {
        setBannerUrl(newBannerUrl);
        setLoadingBanner(false);
      });
    }
  };
  const handleSS1ImgChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoadingScreenShot1(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (screenShotUrl: any) => {
        setScreenShotUrl1(screenShotUrl);
        setLoadingScreenShot1(false);
      });
    }
  };
  const handleSS2ImgChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoadingScreenShot2(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (screenShotUrl: any) => {
        setScreenShotUrl2(screenShotUrl);
        setLoadingScreenShot2(false);
      });
    }
  };
  const beforeUpload = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file.');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB.');
    }
    return isJpgOrPng && isLt2M;
  };
  const handleClose = async () => {
    history.push({
      pathname: RoutePaths.APPS()
    });
    if (props.resetCurrentApp) {
      setResetProcess(true);
      await props.resetCurrentApp().catch();
    }
  };
  const handleDeleteApp = async () => {
    setDeleteModal(false);
    const appDeletedStatus = await props.dispatchDeleteApp(currentAppProps.id);
    if (appDeletedStatus.status === 200) {
      setInfoModalMessage('App deleted successfully!');
      setInfoSuccessModalVisible(true);
    } else {
      setInfoModalMessage('Sorry, could not delete this app.');
      setInfoErrorModalVisible(true);
    }
  };
  const checkCurrentUserPermission = (contextId: string, context: string, permission: string, scope: string) => {
    return props.currentUserHasPermission(contextId, context, permission, scope) || props.currentUserHasPermission(props.currentOrgId, 'ORGANIZATION', 'OWNER', 'WRITE');
  };
  const canDeleteThisApp = checkCurrentUserPermission(currentAppProps.id, 'APP', 'INFO', 'DELETE');
  const canChangeThisApp = checkCurrentUserPermission(currentAppProps.id, 'APP', 'INFO', 'WRITE');
  const ScreenShotUploads: React.FC<any> = ({
    visibility = false
  }) => {
    return <div style={{
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'row',
      visibility: visibility ? 'visible' : 'hidden'
    }} data-sentry-component="ScreenShotUploads" data-sentry-source-file="index.tsx">
          <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
            <Label data-testid="label" data-sentry-element="Label" data-sentry-source-file="index.tsx">Add some screenshots</Label>
            <div style={{
          paddingTop: '20px'
        }}>
              <Upload name="screenshot1" listType="picture-card" className="icon-uploader" showUploadList={false} action="https://www.mocky.io/v2/5cc8019d300000980a055e76" beforeUpload={beforeUpload} onChange={handleSS1ImgChange} data-sentry-element="Upload" data-sentry-source-file="index.tsx">
            {screenShotUrl1 ? <img src={screenShotUrl1} alt="screenshot1" style={{
              width: '100%'
            }} /> : <div>
                      {loadingScreenShot1 ? <LoadingOutlined /> : <PlusOutlined />}
                      <div className="ant-upload-text">Upload</div>
                    </div>}
              </Upload>
            </div>
          </div>
          <div style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '45px'
      }}>
            <Upload name="screenshot2" listType="picture-card" showUploadList={false} action="https://www.mocky.io/v2/5cc8019d300000980a055e76" beforeUpload={beforeUpload} onChange={handleSS2ImgChange} data-sentry-element="Upload" data-sentry-source-file="index.tsx">
            {screenShotUrl2 ? <img src={screenShotUrl2} alt="screenshot1" style={{
            width: '100%'
          }} /> : <div>
                      {loadingScreenShot2 ? <LoadingOutlined /> : <PlusOutlined />}
                      <div className="ant-upload-text">Upload</div>
                    </div>}
            </Upload>
          </div>
        </div>;
  };
  const BannerUpload: React.FC<any> = ({
    visibility = false
  }) => {
    return <div style={{
      marginLeft: '40px',
      display: 'flex',
      flexDirection: 'column',
      visibility: visibility ? 'visible' : 'hidden'
    }} data-sentry-component="BannerUpload" data-sentry-source-file="index.tsx">
          <Label data-testid="label" data-sentry-element="Label" data-sentry-source-file="index.tsx">Select a banner image</Label>
          <div style={{
        paddingTop: '20px'
      }}>
            <Upload name="banner" listType="picture-card" showUploadList={false} action="https://www.mocky.io/v2/5cc8019d300000980a055e76" beforeUpload={beforeUpload} onChange={handleBannerImgChange} data-sentry-element="Upload" data-sentry-source-file="index.tsx">
          {bannerUrl ? <img src={bannerUrl} alt="banner" style={{
            width: '100%'
          }} /> : <div>
                    {loadingBanner ? <LoadingOutlined /> : <PlusOutlined />}
                    <div className="ant-upload-text">Upload</div>
                  </div>}
            </Upload>
          </div>
        </div>;
  };
  return <>
      <Content className="cw-content-container" data-sentry-element="Content" data-sentry-source-file="index.tsx">
        <Formik initialValues={{
        appName: currentAppProps.name,
        appDescription: currentAppProps.short_description,
        detailedDescription: currentAppProps.detailed_description,
        appDomain: currentAppProps.site_uri,
        appLanguage: currentAppProps.fallback_details_language
      }} onSubmit={(values, {
        setSubmitting
      }) => {
        alert(JSON.stringify(values, null, 2));
        setSubmitting(false);
      }} data-sentry-element="Formik" data-sentry-source-file="index.tsx">
            {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting
        }) => <form onSubmit={handleSubmit}>
              <AppPageHeader isPublished={published} publishedDisabled={!canChangeThisApp} onPublishClick={() => setPublished(!published)} canDeleteThisApp={canDeleteThisApp} canChangeThisApp={canChangeThisApp} setDeleteModal={setDeleteModal} handleSendChanges={handleSendChanges} formValues={values} isSubmitting={isSubmitting} submitButtonTitle={'Send Changes'} onBack={() => {
            if (props.resetCurrentApp) {
              setResetProcess(true);
              props.resetCurrentApp();
            }
            history.goBack();
          }} />
                <InfoSuccessModal visible={infoSuccessModalVisible} message={infoModalMessage} handleClose={handleClose} />
                <InfoErrorModal visible={infoErrorModalVisible} message={infoModalMessage} handleClose={handleClose} />
                <Modal open={deleteModal} onOk={handleDeleteApp} onCancel={() => setDeleteModal(false)} width="384px" footer={[<Button key={1} type={ButtonType.default} size="middle" onClick={() => setDeleteModal(false)}>
                        Cancel
                      </Button>, <Button key={2} type={ButtonType.danger} size="middle" onClick={handleDeleteApp}>
                        Delete
                      </Button>]}>
                  <div className="deleteModalContent">
                      <div className="deleteModalHeader">
                          <Text strong={true} style={{
                  color: '#f74141',
                  fontSize: '16px'
                }}>Delete app</Text>
                      </div>
                      <div className="deleteModalBody">
                          <p>Are you sure you want to delete this app?</p>
                      </div>
                  </div>
                </Modal>
                <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
                  <div className="cw-app-details-row">
                    <Text strong={true} style={{
                fontSize: '16px'
              }}>App Details</Text>
                    <div style={{
                marginLeft: '100px'
              }}>
                      <span style={{
                  marginRight: '30px',
                  color: 'black'
                }}>Set app on language:</span>
                      <AvailableLanguagesDropdown onClick={languageKey => handleLanguageClick(languageKey, setFieldValue)} preSelectedLanguageKey={values.appLanguage} />
                    </div>
                  </div>
                  {!props.loading ? <>
                      <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}>
                        <div className="cw-app-details-column1">
                          <div className="cw-app-details-input">
                            <Label data-testid="label">Name your App</Label>
                            <Input data-testid="inputId" name="appName" type={InputType.success} value={values.appName} onChange={handleChange} placeholder={'My Awesome App'} autoFocus={true} disabled={!canChangeThisApp} maxLength={AppConstants.NAME_MAX_LENGTH} />
                          </div>
                          <div className="cw-app-details-input">
                            <Label data-testid="label">Brief Description</Label>
                            <Input data-testid="inputId" name="appDescription" type={InputType.success} value={values.appDescription} onChange={handleChange} placeholder={'App Description'} autoFocus={true} disabled={!canChangeThisApp} maxLength={AppConstants.NAME_MAX_LENGTH} />
                          </div>
                          <div className="cw-app-details-input">
                            <Label data-testid="label">Detailed Description</Label>
                            <TextArea style={{
                      marginTop: '5px'
                    }} data-testid="inputId" name="detailedDescription" rows={5} value={values.detailedDescription} onChange={handleChange} placeholder={'Detailed Description'} autoFocus={true} disabled={!canChangeThisApp} maxLength={AppConstants.NAME_MAX_LENGTH} />
                          </div>
                          <div className="cw-app-details-input pad40">
                            <Label data-testid="label">Site</Label>
                            <Input data-testid="inputId" name="appDomain" type={InputType.success} value={values.appDomain} onChange={handleChange} placeholder={'https://protector.cropwise.com'} autoFocus={true} disabled={!canChangeThisApp} maxLength={AppConstants.NAME_MAX_LENGTH} />
                          </div>
                        </div>
                        <div className="cw-app-details-column2">
                          <Text strong={true} style={{
                    fontSize: '16px'
                  }}>Graphical Resources</Text>
                          <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '25px'
                  }}>
                            <div style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                              <div style={{
                        paddingTop: '20px'
                      }}>
                                <UploadAppIcon defaultImgUrl={`${currentAppProps.icon_uri}?t=${new Date().getTime()}`} mode={UploadTypes.UPLOAD_BUTTON} deleteAppIcon={async () => props.deleteAppIcon(currentAppProps.id, currentLanguage)} uploadAppIcon={async icon => props.uploadAppIcon(currentAppProps.id, icon ?? '', currentLanguage)} />
                              </div>
                            </div>
                            <BannerUpload visibility={false} />
                          </div>
                          <ScreenShotUploads visibility={false} />
                        </div>
                      </div>
                    </> : <Spin size="large" style={{
              marginTop: '100px'
            }} />}
                </div>
                </form>}
        </Formik>
      </Content>
    </>;
};
const mapStateToProps = (state: AppState) => ({
  getCurrentApp: getCurrentApp(state),
  loading: state.apps.loading,
  currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string) => currentUserHasPermission(state, id, context, permission, scope),
  currentOrgId: getCurrentOrgId(state)
});
const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatchSendAppChanges: (appId: UUID, valuesRequest: DeveloperAppDTO) => dispatch(editApp(appId, valuesRequest)),
    resetCurrentApp: () => dispatch(resetGetApp()),
    dispatchGetAppById: (appId: any, language: string) => dispatch(getAppById(appId, language)),
    setCurrentApp: (appId: UUID) => dispatch(setCurrentApp(appId)),
    uploadAppIcon: (appId: UUID, icon: string | Blob, acceptedLanguage = 'en') => dispatch(uploadAppIcon(appId, icon, acceptedLanguage)),
    deleteAppIcon: (appId: UUID, acceptedLanguage = 'en') => dispatch(deleteAppIcon(appId, acceptedLanguage)),
    dispatchDeleteApp: (appId: UUID) => dispatch(deleteApp(appId))
  };
};
export const AppDetailsComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(AppDetails));