import { CheckCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import './style.less';
export const InfoSuccessModal = (props: any) => {
  return <Modal open={props.visible} onOk={props.handleClose} onCancel={props.handleClose} width="384px" footer={[<Button key={1} type={ButtonType.primary} size="large" onClick={props.handleClose}>
            Ok
          </Button>]} data-sentry-element="Modal" data-sentry-component="InfoSuccessModal" data-sentry-source-file="InfoSuccessModal.tsx">
      <div className="modal-placeholder">
        <>
          <CheckCircleFilled className="modal-success" data-sentry-element="CheckCircleFilled" data-sentry-source-file="InfoSuccessModal.tsx" />
          <p style={{
          paddingTop: '20px'
        }}>{props.message}</p>
        </>
      </div>
    </Modal>;
};