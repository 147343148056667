import { Layout } from 'antd';
import { fetchApps, setCurrentApp } from 'app-redux/actions/apps.actions';
import { verifyRbacPermissions } from 'app-redux/actions/rbac.actions';
import { AppState } from 'app-redux/reducers';
import { getCurrentOrgId } from 'app-redux/selectors/orgs.selector';
import { getAllowedAndDeniedOperationsDTO, rbacPermissionsCheck } from 'app-redux/selectors/rbac.selector';
import { AppsPageHeader } from 'components/headers/apps-page-header';
import { AppsTable } from 'components/tables/apps-table';
import { IUserAccount } from 'core/accounts/models';
import { isUserUsingRBAC } from 'core/common-methods';
import { INavigationRouteProps } from 'core/navigation/models';
import { AllowedAndDeniedOperationsDTO, OperationListDTO } from 'core/rbac/models';
import { UUID } from 'core/utils/BasicModels';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { App, PagedApp } from '../../app-redux/reducers/apps.reducer';
import { currentUserHasPermission, getCurrentUser } from '../../app-redux/selectors/accounts.selector';
import { getAppList, getCurrentApp, getCurrentAppLoadingStatus } from '../../app-redux/selectors/apps.selector';
import { history, RoutePaths } from '../../core/history';
import './style.less';
import { getRBACEnabledOnFront } from 'app-redux/selectors/rollout.selector';
const {
  Content
} = Layout;
interface IGetAppProps extends INavigationRouteProps<unknown> {
  getAppsList: (orgId: UUID, pageNumber: number, pageSize: number) => Promise<PagedApp>;
  appList: App[];
  pages?: PagedApp;
  pageNumber: number;
  isLoadingApp: boolean;
  currentOrgId: string;
  currentUser?: IUserAccount;
  allowedAndDeniedOperationsDTO?: AllowedAndDeniedOperationsDTO;
  verifyRbacPermissions: (operationsListDTO: OperationListDTO) => Promise<void>;
  dispatchDeleteApp: (appId: UUID) => any;
  setCurrentApp: (appId: UUID) => any;
  rbacPermissionsCheck: (id: UUID, context: string, permission: string, scope: string, isDevConsoleCheck: boolean) => boolean;
  currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string, isDevConsoleCheck: boolean) => boolean;
}

// tslint:disable-next-line:no-big-function
export const AppsComponent: React.FC<IGetAppProps> = ({
  currentUserHasPermission,
  verifyRbacPermissions,
  getAppsList,
  currentOrgId,
  ...props
}) => {
  const [paged, setPaged] = useState({
    pageNumber: props.pageNumber,
    pageSize: (props.pages?.size as number),
    total: (props.pages?.number_of_elements as number),
    appList: props.appList
  });
  const [currentUserCanCreateApps, setCurrentUserCanCreateApps] = useState(false);
  const [currentUserCanViewApps, setCurrentUserCanViewApps] = useState(false);
  const isRbacEnabledOnFront = useSelector(getRBACEnabledOnFront);
  const getOperationsToVerify = () => {
    return {
      operations: [{
        action: 'app:new',
        resource: 'crn:system'
      }]
    };
  };
  const verifyAccessToCreateApp = useCallback(async () => {
    await verifyRbacPermissions(getOperationsToVerify());
  }, [verifyRbacPermissions]);
  const checkUserHasPermissionDefaultValue = useCallback((id: UUID, context: string, permission: string, scope: string, isDevConsoleCheck = false) => {
    return currentUserHasPermission(id, context, permission, scope, isDevConsoleCheck);
  }, [currentUserHasPermission]);
  useEffect(() => {
    verifyAccessToCreateApp().catch();
  }, [verifyAccessToCreateApp]);
  useEffect(() => {
    const currUserCanCreateApps = checkUserHasPermissionDefaultValue(currentOrgId, 'ORGANIZATION', 'APPS', 'WRITE') || checkUserHasPermissionDefaultValue(currentOrgId, 'ORGANIZATION', 'OWNER', 'WRITE');
    const currUserCanViewApps = isRbacEnabledOnFront && isUserUsingRBAC() || checkUserHasPermissionDefaultValue(currentOrgId, 'ORGANIZATION', 'APPS', 'READ', true) || checkUserHasPermissionDefaultValue(currentOrgId, 'ORGANIZATION', 'OWNER', 'READ');
    setCurrentUserCanCreateApps(currUserCanCreateApps);
    setCurrentUserCanViewApps(currUserCanViewApps);
  }, [isRbacEnabledOnFront, props.allowedAndDeniedOperationsDTO, currentOrgId, checkUserHasPermissionDefaultValue]);
  const loadApps = useCallback(async () => {
    return getAppsList(currentOrgId, 0, 8);
  }, [getAppsList, currentOrgId]);
  useEffect(() => {
    if (props.currentUser) {
      loadApps().then(response => {
        setPaged({
          pageNumber: response.number + 1,
          pageSize: response.size,
          total: response.total_elements,
          appList: Array.from(response.content)
        });
      }).catch();
    }
  }, [props.currentUser, loadApps]);
  const onChange = async (pagination: any, filters: any, sorter: any, extra: any) => {
    if (extra.action === 'paginate') {
      const page = pagination.current - 1;
      const pageSize = pagination.pageSize;
      const apps = await getAppsList(currentOrgId, page, pageSize);
      setPaged({
        pageNumber: apps.number + 1,
        pageSize: apps.size,
        total: apps.total_elements,
        appList: Array.from(apps.content)
      });
    }
  };
  const checkCurrentUserPermission = (contextId: string, context: string, permission: string, scope: string) => {
    return checkUserHasPermissionDefaultValue(contextId, context, permission, scope) || checkUserHasPermissionDefaultValue(currentOrgId, 'ORGANIZATION', 'OWNER', 'WRITE');
  };
  const onRowClick = async (record: any) => {
    const currentApp = record;
    if (currentApp.id) {
      await props.setCurrentApp(currentApp.id);
    }
    history.push({
      pathname: RoutePaths.APP_DETAILS(currentApp.id),
      state: {
        currentApp
      }
    });
  };
  return <>
      {currentUserCanViewApps && <Content className="cw-app-content-container">
          <AppsPageHeader currentUserCanCreateApps={currentUserCanCreateApps} />
          <AppsTable paged={paged} isLoadingApp={props.isLoadingApp} onChange={onChange} setCurrentApp={props.setCurrentApp} checkCurrentUserPermission={checkCurrentUserPermission} onRowClick={onRowClick} />
        </Content>}
    </>;
};
const mapStateToProps = (state: AppState) => {
  const pageNumberState = state.apps.pagedApp?.number ? state.apps.pagedApp?.number + 1 : 1;
  return {
    appList: getAppList(state),
    pages: state.apps.pagedApp,
    pageNumber: pageNumberState,
    isLoadingApp: getCurrentAppLoadingStatus(state),
    getCurrentApp: getCurrentApp(state),
    currentOrgId: getCurrentOrgId(state),
    currentUser: getCurrentUser(state),
    allowedAndDeniedOperationsDTO: getAllowedAndDeniedOperationsDTO(state),
    rbacPermissionsCheck: (id: UUID, context: string, permission: string, scope: string, isDevConsoleCheck = false) => rbacPermissionsCheck(state, id, context, permission, scope, isDevConsoleCheck),
    currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string, isDevConsoleCheck = false) => currentUserHasPermission(state, id, context, permission, scope, isDevConsoleCheck)
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getAppsList: (orgId: UUID, pageNumber: number, pageSize: number) => dispatch(fetchApps(orgId, pageNumber, pageSize)),
    setCurrentApp: (appId: UUID) => dispatch(setCurrentApp(appId)),
    verifyRbacPermissions: (operationsListDTO: OperationListDTO) => dispatch(verifyRbacPermissions(operationsListDTO))
  };
};
export const Apps = withRouter(connect(mapStateToProps, mapDispatchToProps)(AppsComponent));