import { Layout } from 'antd';
import { AppState } from 'app-redux/reducers';
import { App } from 'app-redux/reducers/apps.reducer';
import { currentUserHasPermission } from 'app-redux/selectors/accounts.selector';
import { getCurrentApp } from 'app-redux/selectors/apps.selector';
import { getCurrentOrgId } from 'app-redux/selectors/orgs.selector';
import { INavigationRouteProps } from 'core/navigation/models';
import { PlanDTO } from 'core/plans/models';
import { UUID } from 'core/utils/BasicModels';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PlanFormComponent } from '../plans-form';
const {
  Content
} = Layout;
interface ILicensePlan {
  currentPlan: PlanDTO;
}
interface INewLicensePlanProps extends INavigationRouteProps<ILicensePlan> {
  currentOrgId: string;
  resetCurrentPlan?: () => Promise<string>;
  currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string) => boolean;
  getCurrentApp: App | null;
  dispatchGetPlanById: (entitlementId: UUID) => Promise<PlanDTO>;
}
export const NewLicensePlan: React.FC<INewLicensePlanProps> = props => {
  const [currentApp, setCurrentApp] = useState<App | null>(null);
  const [appId, setAppId] = useState<UUID>('');
  const [planId, setPlanId] = useState<UUID>('');
  // const canChangeThisPlan = props.currentUserHasPermission(props.match.params.appId, 'APP', 'PLAN', 'WRITE') ||
  //   props.currentUserHasPermission(props.currentOrgId, 'ORGANIZATION', 'OWNER', 'WRITE');
  const canChangeThisPlan = true;
  useEffect(() => {
    setAppId(props.match.params.appId);
    setPlanId(props.match.params.planId);
  }, []);
  useEffect(() => {
    setCurrentApp(props.getCurrentApp);
  }, [props.getCurrentApp]);
  return <>
      <Content className="cw-content-container" data-sentry-element="Content" data-sentry-source-file="index.tsx">
        <PlanFormComponent canChangeThisPlan={canChangeThisPlan} appId={appId} currentApp={currentApp} planId={planId} orgId={props.currentOrgId} data-sentry-element="PlanFormComponent" data-sentry-source-file="index.tsx" />
      </Content>
    </>;
};
const mapStateToProps = (state: AppState) => {
  return {
    currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string) => currentUserHasPermission(state, id, context, permission, scope),
    getCurrentApp: getCurrentApp(state),
    currentOrgId: getCurrentOrgId(state)
  };
};
export const EditPlan = withRouter(connect(mapStateToProps)(NewLicensePlan));