import { LeftOutlined } from '@ant-design/icons';
import { resetGetApp } from 'app-redux/actions/apps.actions';
import { resetCurrentOrg } from 'app-redux/actions/org.actions';
import { verifyRbacPermissions } from 'app-redux/actions/rbac.actions';
import { AppState } from 'app-redux/reducers';
import { App } from 'app-redux/reducers/apps.reducer';
import { currentUserHasPermission, getCurrentUser } from 'app-redux/selectors/accounts.selector';
import { getCurrentApp } from 'app-redux/selectors/apps.selector';
import { getCurrentOrgId } from 'app-redux/selectors/orgs.selector';
import { getAllowedAndDeniedOperationsDTO } from 'app-redux/selectors/rbac.selector';
import { IUserAccount } from 'core/accounts/models';
import { isUserUsingRBAC } from 'core/common-methods';
import { history, RoutePaths } from 'core/history';
import { INavigationRouteProps } from 'core/navigation/models';
import { AllowedAndDeniedOperationsDTO, OperationListDTO } from 'core/rbac/models';
import React, { ReactText, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Sidebar, UsersGlyph } from 'syngenta-digital-cropwise-react-ui-kit';
import { UUID } from '../../core/utils/BasicModels';
import { AppsIcon } from './images/AppsIcon';
import { CodeIcon } from './images/CodeIcon';
import { KeyIcon } from './images/KeyIcon';
import { PageIcon } from './images/PageIcon';
import { PuzzlesIcon } from './images/PuzzlesIcon';
import './index.less';
import { Book } from './images/Book';
interface IMenuProps extends INavigationRouteProps<{}> {
  currentApp?: App | null;
  currentUser?: IUserAccount;
  currentOrgId?: string;
  currentUserAllowedAndDeniedRBACOperations?: AllowedAndDeniedOperationsDTO;
  resetCurrentApp?: () => Promise<string>;
  resetCurrentOrg?: () => Promise<string>;
  currentUserHasPermission?: (id: UUID, context: string, permission: string, scope: string) => boolean;
  verifyRbacPermissions?: (operationsListDTO: OperationListDTO) => AllowedAndDeniedOperationsDTO;
}
interface IRouteTree {
  name: string;
  path: string;
  icon: JSX.Element;
  hidden?: boolean;
}
export const MenuBar: React.FC<IMenuProps> = props => {
  const currentPath = props.location.pathname;
  const currentOrgId = props.currentOrgId;
  const navigateTo = async (targetPath: ReactText, name: string) => {
    if (name === 'Details') {
      const currentApp = props.currentApp;
      history.push({
        pathname: targetPath.toString(),
        state: {
          currentApp
        }
      });
    } else {
      history.push(targetPath.toString());
    }
  };
  const handleOnClick = async (name: any) => {
    if (name === 'App Management' && props.resetCurrentApp) {
      await props.resetCurrentApp();
    }
    if (name === 'Home' && props.resetCurrentOrg) {
      await props.resetCurrentOrg();
    }
  };
  const getAppInfoOperations = (appId: string) => {
    return {
      operations: [{
        action: 'app_info:read',
        resource: `crn:app:${appId}`
      }, {
        action: 'app_info:write',
        resource: `crn:app:${appId}`
      }, {
        action: 'app_info:delete',
        resource: `crn:app:${appId}`
      }, {
        action: 'app_credentials:read',
        resource: `crn:app:${appId}`
      }, {
        action: 'app_credentials:write',
        resource: `crn:app:${appId}`
      }, {
        action: 'app_plan:read',
        resource: `crn:app:${appId}`
      }, {
        action: 'app_plan:write',
        resource: `crn:app:${appId}`
      }]
    };
  };
  const verifyRBACPermissionsForAppInfo = async () => {
    if (props.currentApp?.id && props.verifyRbacPermissions) {
      props.verifyRbacPermissions(getAppInfoOperations(props.currentApp?.id));
    }
  };
  useEffect(() => {
    if (isUserUsingRBAC() && props.currentApp) {
      verifyRBACPermissionsForAppInfo();
    }
  }, [props.currentUser, props.currentApp]);
  let routesTree: any = [];
  if (!props.currentUserHasPermission) {
    props.currentUserHasPermission = () => false;
  }
  if (!props.currentApp && props.currentUser && currentOrgId) {
    const canAccessTeams = props.currentUserHasPermission(currentOrgId || '', 'ORGANIZATION', 'USERS', 'READ') || props.currentUserHasPermission(currentOrgId || '', 'ORGANIZATION', 'OWNER', 'WRITE');
    routesTree = [{
      name: 'Apps',
      path: RoutePaths.APPS(),
      icon: <AppsIcon />
    }, {
      name: 'Team',
      path: RoutePaths.TEAM_PAGE(),
      icon: <UsersGlyph />,
      hidden: !canAccessTeams
    }, {
      name: 'UI Libraries',
      path: RoutePaths.UI_KIT(),
      icon: <Book />
    }, {
      name: 'Docs and SDKs',
      path: RoutePaths.SDK_OVERVIEW(),
      icon: <CodeIcon />
    }, {
      name: 'Integrations',
      path: RoutePaths.INTEGRATIONS(),
      icon: <PuzzlesIcon />
    }].filter(routeItem => !routeItem.hidden);
  }
  if (props.currentApp) {
    const canAccessAppCredentials = props.currentUserHasPermission(props.currentApp?.id, 'APP', 'CREDENTIALS', 'READ') || props.currentUserHasPermission(currentOrgId || '', 'ORGANIZATION', 'OWNER', 'WRITE');
    const canAccessAppPlans = true; // On Licensing V2, everyone with App access can read plans.

    routesTree = [{
      name: 'App Management',
      path: RoutePaths.APPS(),
      icon: <LeftOutlined />
    }, {
      name: 'Details',
      path: RoutePaths.APP_DETAILS(props.currentApp?.id),
      icon: <AppsIcon />
    }, {
      name: 'Credentials',
      path: RoutePaths.CREDENTIALS(props.currentApp?.id),
      icon: <KeyIcon />,
      hidden: !canAccessAppCredentials
    }, {
      name: 'Plans',
      path: RoutePaths.LICENSING_PAGE(props.currentApp?.id),
      icon: <PageIcon />,
      hidden: !canAccessAppPlans
    }
    // TODO: Add these routes when they are integrated with the API
    // {
    //   name: 'Widgets',
    //   path: RoutePaths.WIDGETS_PAGE(props.currentOrg?.id, props.currentApp?.id),
    //   icon: <QuestionCircleOutlined />,
    // },
    ].filter(routeItem => !routeItem.hidden);
  }
  const pagesWithMenu = ['/overview', '/ui-kit'];
  let showMenu = false;
  if (props.currentUser && (currentOrgId || pagesWithMenu.indexOf(currentPath) > -1)) {
    showMenu = true;
  }
  return showMenu ? <Sidebar theme="dark" selectedKeys={[props.location.pathname]} menuItems={routesTree.map((menuItem: IRouteTree) => ({
    key: menuItem.path,
    name: menuItem.name,
    icon: menuItem.icon,
    onClick: async (e: any) => {
      navigateTo(e.key, menuItem.name);
      await handleOnClick(menuItem.name);
    }
  }))} /> : <></>;
};
const mapStateToProps = (state: AppState, ownProps: IMenuProps): IMenuProps => {
  return {
    ...ownProps,
    currentApp: getCurrentApp(state),
    currentUser: getCurrentUser(state),
    currentOrgId: getCurrentOrgId(state),
    currentUserAllowedAndDeniedRBACOperations: getAllowedAndDeniedOperationsDTO(state),
    currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string) => currentUserHasPermission(state, id, context, permission, scope)
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  resetCurrentApp: () => dispatch(resetGetApp()),
  resetCurrentOrg: () => dispatch(resetCurrentOrg()),
  verifyRbacPermissions: (operationsListDTO: OperationListDTO) => dispatch(verifyRbacPermissions(operationsListDTO))
});
export const MenuBarComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuBar));