import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PreviewSectionTitle } from './preview-section-title';
import styled from 'styled-components';
import { Skeleton } from './skeleton';
import { FlexContainer } from 'pages/integrations/styled';
import { IntegrationsFormContext } from './integrations-form';
import { iff } from 'core/iff';
import leafImage from 'assets/images/cropwise-leaf.svg';
import { IntegrationsContext } from 'pages/integrations';
import { Tooltip } from 'antd';
const Card = styled.div`
  border-radius: 8px;
  width: 360px;
  height: 204px;
  margin: 22px auto 18px;
  background-color: #ffffff;
  padding: 16px 16px 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;
const Name = styled.p`
  margin: 0 0 4px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #14151c;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;
const Description = styled.p`
  margin: 0 0 8px 0;
  font-size: 12px;
  line-height: 20px;
  color: #14151c;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 40px;
  word-break: break-all;
`;
const AppIcon = styled(FlexContainer)`
  background-color: #00004b;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  img {
    max-width: calc(100%);
    height: auto;
  }

  &.overflow {
    background-color: #dfe2e7;
    color: #696f88;
    font-size: 12px;
    line-height: 20px;
  }
`;

// 11 = max number of app icons supported by the card without overflow
// +/- 1 on lines (+{overflowApps + 1} /  MAX_APP_ICONS_BEFORE_OVERFLOW - 1) because we'll hide one app to show the overflow design */}
const MAX_APP_ICONS_BEFORE_OVERFLOW = 11;
export const IntegrationCard = () => {
  const {
    fetchApps
  } = useContext(IntegrationsContext);
  const {
    values: {
      name,
      description,
      supported_applications,
      icon
    },
    language: {
      value: selectedLanguage
    }
  } = useContext(IntegrationsFormContext);
  const [appsIcons, setAppsIcons] = useState<{
    [key: string]: {
      icon_uri: string;
      name: string;
    };
  }>({});
  const [apps, setApps] = useState(supported_applications);
  const overflowApps = useMemo(() => Math.max(0, apps.length - MAX_APP_ICONS_BEFORE_OVERFLOW), [apps]);
  useEffect(() => {
    setApps(supported_applications);
  }, [supported_applications]);
  useEffect(() => {
    fetchApps().then(apps => {
      setAppsIcons(apps.filter(app => !!app.id).reduce((acc, {
        id,
        icon_uri,
        name
      }) => ({
        ...acc,
        [(id as string)]: {
          icon_uri,
          name
        }
      }), {}));
    }).catch(async () => {
      console.warn("We couldn't load the Cropwise apps");
    });
  }, [fetchApps]);
  const getOverflowTooltip = useCallback(() => {
    return apps.slice(-(overflowApps + 1)).map(app => appsIcons[app]?.name ?? '').join(', ');
  }, [apps, appsIcons, overflowApps]);
  return <>
      <PreviewSectionTitle title="Integrations Card" data-sentry-element="PreviewSectionTitle" data-sentry-source-file="integration-card.tsx" />
      <Card data-sentry-element="Card" data-sentry-source-file="integration-card.tsx">
        {iff(Boolean(icon), <FlexContainer>
            <img src={icon} style={{
          height: 60,
          width: 'auto',
          margin: '0 auto'
        }} />
          </FlexContainer>, <Skeleton width={145} height={60} image dashed rounded style={{
        margin: '0 auto'
      }} />)}
        <FlexContainer align="flex-start" gap={2} style={{
        margin: '16px 0 8px'
      }} direction="column" data-sentry-element="FlexContainer" data-sentry-source-file="integration-card.tsx">
          {iff(Boolean(name), <Name>{name}</Name>, <Skeleton background="#f3f4f6" width={125} height={20} rounded style={{
          marginBottom: 2
        }} />)}
          {iff(Boolean(description[selectedLanguage]), <>
              <Description>{description[selectedLanguage]}</Description>
            </>, <>
              <Skeleton background="#f3f4f6" width={328} height={20} rounded />
              <Skeleton background="#f3f4f6" width={328} height={20} rounded />
            </>)}
        </FlexContainer>
        <FlexContainer justify="flex-start" gap={4} data-sentry-element="FlexContainer" data-sentry-source-file="integration-card.tsx">
          {iff(apps.length > 0, <>
              {apps.slice(0, overflowApps > 0 ? MAX_APP_ICONS_BEFORE_OVERFLOW - 1 : MAX_APP_ICONS_BEFORE_OVERFLOW).map(app => <Tooltip key={app} title={appsIcons[app]?.name} color="#fff" overlayInnerStyle={{
            color: '#14151C',
            borderRadius: 4,
            textAlign: 'center'
          }}>
                    <AppIcon>
                      <img src={appsIcons[app]?.icon_uri} onError={({
                currentTarget
              }) => {
                currentTarget.onerror = null;
                currentTarget.src = leafImage;
              }} />
                    </AppIcon>
                  </Tooltip>)}
              {overflowApps > 0 && <Tooltip key={'app-overflow'} title={getOverflowTooltip()} color="#fff" overlayInnerStyle={{
            color: '#14151C',
            borderRadius: 4,
            textAlign: 'center'
          }}>
                  <AppIcon className="overflow">+{overflowApps + 1}</AppIcon>
                </Tooltip>}
            </>, <>
              <Skeleton width={24} height={24} dashed rounded />
              <Skeleton width={24} height={24} dashed rounded />
              <Skeleton width={24} height={24} dashed rounded />
              <Skeleton width={24} height={24} dashed rounded />
              <Skeleton width={24} height={24} dashed rounded />
            </>)}
        </FlexContainer>
      </Card>
    </>;
};