import { CloudUploadOutlined, FolderOutlined } from '@ant-design/icons';
import { Divider, Layout, Modal, Table, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload/interface';
import { TitleBar } from 'components/title-bar';
import React, { useState } from 'react';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import './style.less';
const {
  Content
} = Layout;
const {
  Dragger
} = Upload;

/* Table Columns Data and Keys */
const columns = [{
  title: 'Version Code',
  dataIndex: 'versioncode',
  key: 'versioncode',
  sorter: (a: any, b: any) => a.versioncode.length - b.versioncode.length
}, {
  title: 'Submitted At',
  dataIndex: 'submittedat',
  key: 'submittedat',
  filters: [{
    text: 'March',
    value: 'March'
  }, {
    text: 'August',
    value: 'August'
  }],
  onFilter: (value: any, record: any) => record.submittedat.includes(value)
}, {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
  sorter: (a: any, b: any) => a.status.length - b.status.length
}, {
  title: 'Resources',
  dataIndex: 'resources',
  key: 'resources',
  sorter: (a: any, b: any) => a.resources.length - b.resources.length
}, {
  title: 'Action',
  dataIndex: 'action',
  align: ('center' as const),
  key: 'action',
  render: () => <div className="widgetActions">
                <a className="widgetAppDelete">Delete</a>
                <Divider type="vertical" className="dividerLeft" />
                <a className="widgetAppEdit">Publish</a>
            </div>
}];

/* Get Table Data from API and Bind the data*/
const data = [{
  key: '0',
  versioncode: 'F6AE76940587D73',
  submittedat: 'Aug 28, 2020',
  status: 'Published',
  // tslint:disable-next-line:no-duplicate-string
  resources: 'Menu Items, Map Overlays'
}, {
  key: '1',
  versioncode: '8EC161805E04D76',
  submittedat: 'Mar 06, 2020 ',
  status: 'Approved',
  resources: 'Menu Items'
}, {
  key: '2',
  versioncode: '98765EC16184D76',
  submittedat: 'Apr 09, 2020 ',
  status: 'Unpublished',
  resources: 'Menu Items, Map Overlays'
}, {
  key: '3',
  versioncode: '77770000C16184D76',
  submittedat: 'June 09, 2020 ',
  status: 'Unpublished',
  resources: 'Menu Items, Map Overlays'
}];
function onChange(pagination: any, filters: any, sorter: any, extra: any) {
  // tslint:disable-next-line:no-console
  console.log('params', pagination, filters, sorter, extra);
}
export const Widgets: React.FC<UploadProps> = (props: UploadProps) => {
  const [visible, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return <>
            <Content className="widgetContent" data-sentry-element="Content" data-sentry-source-file="index.tsx">
                {/* Heading - Block */}
                <TitleBar data-sentry-element="TitleBar" data-sentry-source-file="index.tsx">Widgets and Extension Points</TitleBar>

                {/* Sub-Heading Block */}
                <div className="subHeadingBlock">
                    <div className="subHeadingText">
                        Submission history
                    </div>
                    <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          paddingBottom: '35px'
        }}>
                        <Button style={{
            width: 150
          }} type={ButtonType.primary} onClick={handleShow} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                            Submit Manifest
                        </Button>
                    </div>

                </div>

                {/* Widgets - Table Block */}
                <div className="widgetTableBlock">
                    <Table columns={columns} dataSource={data} onChange={onChange} data-sentry-element="Table" data-sentry-source-file="index.tsx" />
                </div>
            </Content>

            {/* Submit Manifest Modal Window - Start  */}
            <Modal title="Upload the file containing the widget manifest" className="submitManifestModal" style={{
      top: 100
    }} open={visible} onCancel={handleClose} footer={null} width={520} closable={false} data-sentry-element="Modal" data-sentry-source-file="index.tsx">

                <div className="submitManifestModalContent">

                    {/* Drag and Drop - Block */}
                    <div className="uploadChoiceBlock">
                        <div className="uploadGrid">
                            <Dragger {...props} data-sentry-element="Dragger" data-sentry-source-file="index.tsx">
                                <span className="uploadTypeIcon">
                                    <CloudUploadOutlined data-sentry-element="CloudUploadOutlined" data-sentry-source-file="index.tsx" />
                                </span>
                                <div className="uploadChoiceHeading">Drag and drop</div>
                                <div className="uploadChoiceText">
                                    Drag and drop your file anywhere on the screen
                                </div>
                            </Dragger>
                        </div>
                    </div>

                    {/* Upload File - Block */}
                    <div className="uploadChoiceBlock">
                        <div className="uploadGrid">
                            <Upload {...props} data-sentry-element="Upload" data-sentry-source-file="index.tsx">
                                <span className="uploadTypeIcon">
                                    <FolderOutlined data-sentry-element="FolderOutlined" data-sentry-source-file="index.tsx" />
                                </span>
                                <div className="uploadChoiceHeading">Find file</div>
                                <div className="uploadChoiceText">
                                    Find the files on your computer
                                </div>
                            </Upload>
                        </div>
                    </div>
                </div>
                <div className="clrFloat" />

                <div className="submitManifestModalFooter">
                    <div className="helpText">
                        If you need help, click <span>here</span> to see the manifest specification.
                    </div>
                    <div className="cancelBtnBlock">
                        <Button key="cancel" type={ButtonType.default} style={{
            width: 90
          }} onClick={handleClose} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className="clrFloat" />
            </Modal>
            {/* Submit Manifest Modal Window - End  */}

        </>;
};
export default Widgets;