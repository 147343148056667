import { Typography, Form, Drawer, Input, Select, InputNumber, Button } from 'antd';
import { AppConstants } from 'core/app.constants';
import React, { useState, useEffect } from 'react';
import { AcceptedEntityTypes, AcceptedUnits, QuotaDTO } from '../models';
import './style.less';
import { ButtonType, InputType } from 'syngenta-digital-cropwise-react-ui-kit';
const {
  Text
} = Typography;
interface IQuotaDrawerProps {
  drawerVisible: boolean;
  onCloseDrawer: () => void;
  canChangeThisQuota: boolean;
  initialQuota?: QuotaDTO;
  onSubmit: (quota: any) => void;
  onDelete: (quotaKey: string | undefined) => void;
}
export const QuotaDrawer: React.FC<IQuotaDrawerProps> = props => {
  const [entitySelectionDisabled, setEntitySelectionDisabled] = useState(false);
  const [isSaveButtonDisable, setIsSaveButtonDisable] = useState(false);
  const defineQuotaType = () => {
    return props.initialQuota?.system == false ? 'App Managed Quotas' : 'Platform Managed Quotas';
  };
  const quotaType = defineQuotaType();
  const isQuotaTypeAppManaged = () => {
    return props.initialQuota && quotaType !== 'Platform Managed Quotas';
  };
  const [form] = Form.useForm();
  const drawerModeTitle = props.initialQuota === undefined ? 'Add Quota' : 'Edit Quota';
  useEffect(() => {
    form.setFieldsValue({
      quotaType: defineQuotaType(),
      key: props.initialQuota?.key,
      name: props.initialQuota?.name,
      unit: props.initialQuota?.unit,
      entity_type: props.initialQuota?.entity_type,
      threshold: props.initialQuota?.threshold,
      max_limit: props.initialQuota?.max_limit
    });
  }, [props.initialQuota]);
  const onChangeQuotaUnitSelected = (value: string) => {
    if (value === 'custom') {
      setEntitySelectionDisabled(true);
      form.setFieldsValue({
        entity_type: ''
      });
    } else {
      setEntitySelectionDisabled(false);
    }
  };
  const handleAddQuotaRow = async () => {
    const newData = await form.validateFields();
    props.onSubmit(newData);
    props.onCloseDrawer();
  };
  const onHandleInputChange = () => {
    const setButtonStatus = (condition: boolean) => {
      if (condition) {
        setIsSaveButtonDisable(false);
      } else {
        setIsSaveButtonDisable(true);
      }
    };
    const isValidName = (name: string) => {
      return name.length > 0 && name.length < AppConstants.NAME_MAX_LENGTH;
    };
    const isValidKey = (key: string) => {
      return key.length > 0 && key.length < AppConstants.NAME_MAX_LENGTH;
    };
    form.validateFields(['name', 'key', 'max_limit', 'threshold']).then(quota => {
      setButtonStatus(isValidName(quota.name) && isValidKey(quota.key));
    }).catch(e => {
      setButtonStatus(e?.errorFields.length === 0);
    });
  };
  function onHandleClose() {
    props.onCloseDrawer();
    form.resetFields();
    setIsSaveButtonDisable(false);
  }
  const DeleteQuotaButton: React.FC = () => {
    return <div data-sentry-component="DeleteQuotaButton" data-sentry-source-file="index.tsx">
        <div className="deleteLink">
          <a onClick={async () => {
          const data = await form.validateFields();
          props.onDelete(data.key);
        }}>
            Delete quota
          </a>
        </div>
      </div>;
  };
  return <div data-sentry-component="QuotaDrawer" data-sentry-source-file="index.tsx">
      <Drawer title={drawerModeTitle} placement="right" width={350} closable={true} onClose={onHandleClose} open={props.drawerVisible} data-sentry-element="Drawer" data-sentry-source-file="index.tsx">
        <Form form={form} layout="vertical" name="form_in_drawer" initialValues={props.initialQuota!} onFinish={handleAddQuotaRow} onChange={onHandleInputChange} data-sentry-element="Form" data-sentry-source-file="index.tsx">
          <Text strong={true} style={{
          fontSize: '14px',
          color: '#2F3031',
          margin: 0
        }} data-sentry-element="Text" data-sentry-source-file="index.tsx">
            Basic settings
          </Text>
          <div>
            <Form.Item name="quotaType" label="Quota type" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
              <Input disabled={true} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
            </Form.Item>

            <Form.Item name="key" label="Key" rules={[{
            required: true,
            message: "Quota's key cannot be empty"
          }]} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
              <Input data-sentry-element="Input" data-sentry-source-file="index.tsx" />
            </Form.Item>

            <Form.Item name="unit" label="Unit" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
              <Select data-testid="selectId" style={{
              width: 300
            }} onChange={onChangeQuotaUnitSelected} placeholder={'Select Unit'} autoFocus={true} disabled={!props.canChangeThisQuota} data-sentry-element="Select" data-sentry-source-file="index.tsx">
                {Object.values(AcceptedUnits).map(v => <Select.Option key={v} value={v}>{v}</Select.Option>)}
              </Select>
            </Form.Item>

            <Form.Item name="entity_type" label="Entity" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
              <Select data-testid="selectId" placeholder={'Select Entity'} autoFocus={true} style={{
              width: 300
            }} disabled={entitySelectionDisabled} data-sentry-element="Select" data-sentry-source-file="index.tsx">
                {Object.values(AcceptedEntityTypes).map(v => <Select.Option key={v} value={v}>{v}</Select.Option>)}
              </Select>
            </Form.Item>

            <Form.Item name="name" label="Name" rules={[{
            required: true,
            message: "Quota's name cannot be empty"
          }, {
            max: AppConstants.NAME_MAX_LENGTH,
            message: `Quota's name cannot be longer than ${AppConstants.NAME_MAX_LENGTH} characters`
          }]} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
              <Input data-sentry-element="Input" data-sentry-source-file="index.tsx" />
            </Form.Item>

            <Form.Item name="threshold" label="Quota's threshold" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
              <InputNumber autoFocus={true} data-testid="inputId" disabled={!props.canChangeThisQuota} defaultValue={(0 as number)} formatter={value => `${value}%`} min={0} parser={value => Number(value?.replace('%', ''))} placeholder={"Insert quota's threshold"} style={{
              width: 300
            }} type={InputType.success} onChange={onHandleInputChange} data-sentry-element="InputNumber" data-sentry-source-file="index.tsx" />
            </Form.Item>

            <Form.Item name="limit" label="Quota's limit" rules={[{
            required: true,
            message: "Quota's limit cannot be empty"
          }]} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
              <InputNumber autoFocus={true} data-testid="inputId" defaultValue={0} disabled={!props.canChangeThisQuota} min={0} placeholder={"Insert quota's limit"} style={{
              width: 300
            }} onChange={onHandleInputChange} data-sentry-element="InputNumber" data-sentry-source-file="index.tsx" />
            </Form.Item>
          </div>
          <div className="drawerButtons" style={{
          display: isQuotaTypeAppManaged() ? 'flex' : 'inherit'
        }}>
            {isQuotaTypeAppManaged() && <DeleteQuotaButton />}
            <div>
              <Button size="large" style={{
              width: '76px',
              textAlign: 'center'
            }} type={ButtonType.default} onClick={onHandleClose} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                Cancel
              </Button>
              <Button size="large" style={{
              width: '76px',
              textAlign: 'center'
            }} type={ButtonType.primary}
            // @ts-ignore
            htmlType="submit" disabled={isSaveButtonDisable} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                Save
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>
    </div>;
};