import { Modal } from 'antd';
import { AppState } from 'app-redux/reducers';
import { getErrorMessage } from 'app-redux/selectors/error-handler.selector';
import { INavigationRouteProps } from 'core/navigation/models';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, ButtonType, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import { ACTION_RESPONSE_ERROR_INTERCEPTOR, ErrorMessageDTO } from '../../core/AxiosResponseInterceptor';
import './style.less';
const {
  Text
} = Typography;
interface IErrorModal extends INavigationRouteProps<{}> {
  errorData?: ErrorMessageDTO;
  clearErrorMessage: () => void;
}
export const ErrorModalComponent: React.FC<IErrorModal> = props => {
  const [isVisible, setVisible] = useState<boolean>(false);
  useEffect(() => {
    setVisible(!!props.errorData);
  }, [props.errorData]);
  return <Modal open={isVisible} onOk={props.clearErrorMessage} onCancel={props.clearErrorMessage} width="384px" footer={[<Button key={1} type={ButtonType.primary} size="middle" onClick={props.clearErrorMessage}>
              Ok
          </Button>]} data-sentry-element="Modal" data-sentry-component="ErrorModalComponent" data-sentry-source-file="ErrorModal.tsx">
      <div className="errorModalContent">
          <div className="errorModalHeader">
              <Text strong={true} style={{
          color: '#f74141',
          fontSize: '16px'
        }} data-sentry-element="Text" data-sentry-source-file="ErrorModal.tsx">Error</Text>
          </div>
          <div className="errorModalBody">
            <p>Could not complete request. <br />
              Reached error code {props.errorData?.code}
            </p>
          </div>
      </div>
    </Modal>;
};
const mapStateToProps = (state: AppState) => ({
  errorData: getErrorMessage(state)
});
const mapDispatchToProps = (dispatch: any) => {
  return {
    clearErrorMessage: () => dispatch({
      type: ACTION_RESPONSE_ERROR_INTERCEPTOR,
      payload: null
    })
  };
};
export const ErrorModal = withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorModalComponent));