import { ACTION_RESPONSE_ERROR_INTERCEPTOR, ErrorMessageDTO } from 'core/AxiosResponseInterceptor';
import { PayloadAction } from 'core/utils/actionUtils';

export interface ErrorHandlerState {
  errorData?: ErrorMessageDTO;
}

const initialState: ErrorHandlerState = {
};

export const errorHandlerReducer = (state: ErrorHandlerState = initialState, action: PayloadAction<any>): ErrorHandlerState => {
  if (action.type === ACTION_RESPONSE_ERROR_INTERCEPTOR) {
    return {
      ...state,
      errorData: action.payload,
    };
  } else {
    return state;
  }
};
