import { AppState } from 'app-redux/reducers';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Action } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { IntegrationsContent } from './styled';
import { CountryCode, countries } from './utils/contants';
import { IntegrationsHeader } from './components/table/integrations-header';
import './styles/index.less';
import { IntegrationsTable } from './components/table/integrations-table';
import { fetchIntegrations, saveIntegration, deleteIntegration } from 'app-redux/actions/integrations.actions';
import { Integration } from './utils/types';
import { pipe, useDebounced } from 'core/common-methods';
import { IntegrationsNoAccess } from './components/table/integrations-no-access';
import { IntegrationsForm } from './components/form/integrations-form';
import { message, notification } from 'antd';
import { DeveloperAppDTO } from 'core/apps/models';
import { getCurrentOrgId } from 'app-redux/selectors/orgs.selector';
import { CloseIcon } from './images/CloseIcon';
import { CheckCircleIcon } from './images/CheckCircleIcon';
import { fetchApps } from './utils/functions';
import { slugify } from './utils/slugify';
message.config({
  prefixCls: 'syngenta-ant-message'
});
export interface IntegrationContextValue {
  integrations: Integration[];
  loading: boolean;
  error: string | null;
  nameFiltered: string;
  countriesFiltered: CountryCode[];
  currentIntegration?: Integration;
  fetchApps: () => Promise<DeveloperAppDTO[]>;
  setCurrentIntegration: (integration: Integration) => void;
  onSearch: (query: string) => void;
  onFilterCountries: (countries: CountryCode[]) => void;
  clearFilters: () => void;
}
export const IntegrationsContext = (React.createContext<IntegrationContextValue | null>(null) as React.Context<IntegrationContextValue>);
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
interface Props extends DispatchProps, StateProps {}
interface Filter {
  key: string;
  fn: (integration: Integration) => boolean;
}
export const Integrations: React.FC<Props> = ({
  integrations,
  loading,
  error,
  fetchIntegrations,
  saveIntegration,
  deleteIntegration
}) => {
  const currentOrgId = useSelector(getCurrentOrgId);
  const cacheFetchAppsRef = useRef<Promise<DeveloperAppDTO[]>>();
  // TODO Change when we need to control access, for now, everyone that access dev console can access the integrations page
  const [hasAccess] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState<Integration>();
  const [maxRowsPerPage, setMaxRowsPerPage] = useState(10);
  const [nameFiltered, setNameFiltered] = useState('');
  const [countriesFiltered, setCountriesFiltered] = useState(countries.map(c => c.code));
  const [filters, setFilters] = useState<Filter[]>([]);
  const filteredIntegrations = useMemo(() => {
    // Pipe all filter functions (filter by name, then country, and vice-versa)
    const filterFuns = filters.map(f => (ints: Integration[]) => ints.filter(f.fn));
    return (pipe(...filterFuns)(integrations) as Integration[]);
  }, [integrations, filters]);
  useEffect(() => {
    notification.config({
      closeIcon: <CloseIcon size={18} />,
      placement: 'topRight',
      top: 68 // 56 (header) + 12 (padding)
    });

    fetchIntegrations();
  }, [fetchIntegrations]);
  useLayoutEffect(() => {
    const handleResize = () => {
      const contentH = document.querySelector('[class*=-layout-content]')?.clientHeight;
      const headerH = document.getElementById('integrations-header')?.clientHeight;
      const paginationH = 64;
      const rowH = 55;
      if (contentH && headerH) {
        const tableH = contentH - 48 - headerH - paginationH - rowH; // content - integration header - pagination - table heading
        const rows = Math.floor(tableH / rowH);
        setMaxRowsPerPage(rows);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    setShowForm(!!currentIntegration);
  }, [currentIntegration]);
  const replaceFilter = useCallback((newFilterKey: string, fn?: Filter['fn']) => {
    setFilters(filters => {
      const newFilters = filters.filter(({
        key
      }) => key !== newFilterKey);
      if (fn) newFilters.push({
        key: newFilterKey,
        fn
      });
      return newFilters;
    });
  }, []);
  const clearFilters = useCallback(() => {
    replaceFilter('name');
    replaceFilter('country');
    setCountriesFiltered(countries.map(c => c.code));
  }, [replaceFilter]);
  const onSearch = useDebounced(useCallback((query: string) => {
    setNameFiltered(query);
    replaceFilter('name', i => i.name.toLowerCase().includes(query.toLowerCase()));
  }, [replaceFilter]));
  const onFilterCountries = useCallback((countries: CountryCode[]) => {
    setCountriesFiltered(countries);
    replaceFilter('country', i => i.countries.some(c => countries.includes(c)));
  }, [replaceFilter]);
  const closeForm = useCallback(() => {
    setShowForm(false);
    setCurrentIntegration(undefined);
  }, []);
  const openForm = useCallback(() => {
    setShowForm(true);
    setCurrentIntegration(undefined);
  }, []);
  const cacheFetchApps = useCallback(() => {
    if (!cacheFetchAppsRef.current) cacheFetchAppsRef.current = fetchApps(currentOrgId);
    return cacheFetchAppsRef.current;
  }, [currentOrgId]);
  const handleSaveIntegration = (values: Partial<Integration>) => {
    if (!values.id && values.provider) {
      values.partner_enum = slugify(values.provider);
    }
    saveIntegration(values).then(async integration => {
      if (integration) {
        onSearch(integration.name);
        closeForm();
        await fetchIntegrations();
      }
    }).catch(async () => {
      await message.error("We couldn't save the integration, please try again!");
    });
  };
  return <IntegrationsContext.Provider value={{
    nameFiltered,
    countriesFiltered,
    integrations: filteredIntegrations,
    loading,
    error,
    currentIntegration,
    setCurrentIntegration,
    onSearch,
    onFilterCountries,
    clearFilters,
    fetchApps: cacheFetchApps
  }} data-sentry-element="unknown" data-sentry-component="Integrations" data-sentry-source-file="index.tsx">
      <IntegrationsContent data-testid="integrations" fullHeight={showForm} data-sentry-element="IntegrationsContent" data-sentry-source-file="index.tsx">
        {showForm ? <IntegrationsForm onClose={closeForm} onSubmit={handleSaveIntegration} onDelete={id => {
        deleteIntegration(id).then(async integration => {
          if (integration) {
            closeForm();
            notification.success({
              icon: <CheckCircleIcon fill="#19A04B" size={24} />,
              message: 'Integration successfully deleted',
              description: <span>
                          <b>{integration.name}</b> integration was successfully deleted.
                        </span>,
              className: 'integrations-delete-notification'
            });
            await fetchIntegrations();
          }
        }).catch(async () => {
          await message.error("We couldn't delete the integration, please try again!");
        });
      }} /> : <>
            <IntegrationsHeader onAdd={openForm} />
            {hasAccess && <IntegrationsTable maxRowsPerPage={maxRowsPerPage} />}
          </>}
      </IntegrationsContent>
      {!hasAccess && <IntegrationsNoAccess />}
    </IntegrationsContext.Provider>;
};
const mapStateToProps = (state: AppState) => {
  return {
    ...state.integrations
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    fetchIntegrations: () => dispatch(fetchIntegrations()),
    saveIntegration: (integration: Partial<Integration>) => dispatch(saveIntegration(integration)),
    deleteIntegration: (id: string) => dispatch(deleteIntegration(id))
  };
};
export const IntegrationsPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(Integrations));