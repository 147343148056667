import { fetchCurrentUser, initOAuth2Flow, logout } from 'app-redux/actions/login.actions';
import { AppState } from 'app-redux/reducers';
import { getCurrentUser, getCurrentUserLoadingStatus } from 'app-redux/selectors/accounts.selector';
import { IUserAccount } from 'core/accounts/models';
import { isAccessTokenAvailable } from 'core/common-methods';
import { ProfileDropdown } from '@cw-elements/profile-dropdown';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoginButton from './LoginButton';
interface ILoginSelectionProps {
  currentUser?: IUserAccount;
  fetchCurrentUser?: () => Promise<any>;
  isLoadingUser?: boolean;
  logoutUser?: () => any;
}
const LoginSectionComponent: React.FC<ILoginSelectionProps> = props => {
  const handleLoginClick = () => {
    initOAuth2Flow();
  };
  useEffect(() => {
    if (isAccessTokenAvailable() && !props.currentUser && !props.isLoadingUser && props.fetchCurrentUser) {
      props.fetchCurrentUser().then();
    }
  }, []);
  const handleLogoutClick = () => {
    if (props.logoutUser) {
      props.logoutUser();
      initOAuth2Flow();
    }
  };
  return <>
      <LoginButton currentUser={props.currentUser} onClick={handleLoginClick} data-sentry-element="LoginButton" data-sentry-source-file="LoginSection.tsx" />
      <ProfileDropdown availableLanguages={[]} availableUnitSystems={[]} onLogout={handleLogoutClick} data-sentry-element="ProfileDropdown" data-sentry-source-file="LoginSection.tsx" />
    </>;
};
const mapStateToProps = (state: AppState, ownProps: ILoginSelectionProps): ILoginSelectionProps => {
  return {
    ...ownProps,
    currentUser: getCurrentUser(state),
    isLoadingUser: getCurrentUserLoadingStatus(state)
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  logoutUser: () => dispatch(logout())
});
export const LoginSection = connect(mapStateToProps, mapDispatchToProps)(LoginSectionComponent);