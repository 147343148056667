import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { ApiClientState } from '../reducers/api-clients.reducer';

export const getApiClientsState = (state: AppState) => state.apiClients || {} as ApiClientState;

export const getCurrentApiClientLoadingStatus = createSelector(getApiClientsState,
    (apiClientsState) => !!apiClientsState.loading);

export const getApiClientList = createSelector(getApiClientsState,
    (apiClientsState) => apiClientsState.apiClients?.content || []);

export const getApiClientPaged = createSelector(getApiClientsState,
    (apiClientsState) => apiClientsState.apiClients);
