import React from 'react';
export const MagnifyingGlass = () => {
  return <svg width="234" height="160" viewBox="0 0 234 160" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="MagnifyingGlass" data-sentry-source-file="MagnifyingGlassImg.tsx">
      <path d="M130.404 95.7494L130.295 95.6315C130.519 95.1455 130.589 94.6025 130.495 94.0755C130.401 93.5486 130.148 93.063 129.77 92.6841V92.6841C129.408 92.3223 128.948 92.0745 128.447 91.9712C127.945 91.868 127.425 91.9138 126.949 92.1031L126.361 91.5936C130.602 85.5392 132.872 78.3251 132.86 70.9346C132.86 51.0062 116.702 34.8525 96.7686 34.8525C76.8351 34.8525 60.6726 51.002 60.6726 70.9304C60.6726 90.8589 76.833 107.013 96.7665 107.013C104.621 107.024 112.263 104.463 118.522 99.7199L118.958 100.107C118.777 100.579 118.737 101.093 118.842 101.587C118.947 102.082 119.193 102.535 119.55 102.893V102.893C119.913 103.252 120.372 103.499 120.872 103.603C121.372 103.707 121.891 103.663 122.367 103.478C122.421 103.524 122.471 103.574 122.517 103.627L150.487 131.579L158.37 123.701L130.404 95.7494Z" fill="#DFE2E7" data-sentry-element="path" data-sentry-source-file="MagnifyingGlassImg.tsx" />
      <path d="M125.502 96.2547C125.011 95.7633 124.735 95.0974 124.735 94.4031C124.735 93.7088 125.011 93.0429 125.502 92.5515L132.014 86.0421C132.257 85.7987 132.546 85.6056 132.864 85.4739C133.181 85.3422 133.522 85.2744 133.866 85.2744C134.211 85.2744 134.551 85.3422 134.869 85.4739C135.187 85.6056 135.476 85.7987 135.719 86.0421C136.21 86.5335 136.486 87.1994 136.486 87.8936C136.486 88.5879 136.21 89.2538 135.719 89.7452L129.207 96.2547C128.964 96.498 128.675 96.6911 128.357 96.8228C128.04 96.9545 127.699 97.0223 127.355 97.0223C127.01 97.0223 126.67 96.9545 126.352 96.8228C126.034 96.6911 125.745 96.498 125.502 96.2547V96.2547Z" fill="white" data-sentry-element="path" data-sentry-source-file="MagnifyingGlassImg.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M133.867 86.1166C133.633 86.1166 133.402 86.1626 133.186 86.2519C132.971 86.3412 132.775 86.4722 132.61 86.6372L126.098 93.1465L126.098 93.1468C125.765 93.4803 125.578 93.9321 125.578 94.4032C125.578 94.8743 125.765 95.3262 126.098 95.6597M132.541 84.6961C132.961 84.522 133.412 84.4324 133.867 84.4324C134.321 84.4324 134.772 84.522 135.192 84.6961C135.612 84.8702 135.994 85.1254 136.316 85.4471L136.316 85.4472C136.964 86.0965 137.328 86.9764 137.328 87.8937C137.328 88.811 136.964 89.6909 136.316 90.3402L129.804 96.8499L129.804 96.8501C129.482 97.1717 129.1 97.4268 128.68 97.6008C128.26 97.7749 127.81 97.8645 127.355 97.8645C126.9 97.8645 126.449 97.7749 126.029 97.6008C125.609 97.4267 125.227 97.1715 124.906 96.8499L125.502 96.2547L124.905 96.8497C124.257 96.2004 123.893 95.3205 123.893 94.4032C123.893 93.4859 124.257 92.606 124.905 91.9567L131.417 85.4471L131.418 85.4468C131.739 85.1252 132.121 84.8702 132.541 84.6961ZM126.098 95.6599C126.263 95.8248 126.459 95.9557 126.675 96.045C126.89 96.1343 127.121 96.1803 127.355 96.1803C127.588 96.1803 127.819 96.1343 128.035 96.045C128.251 95.9557 128.446 95.8247 128.611 95.6597L135.123 89.1504L135.123 89.1501C135.456 88.8166 135.643 88.3648 135.643 87.8937C135.643 87.4226 135.456 86.9707 135.123 86.6372" fill="#00004B" data-sentry-element="path" data-sentry-source-file="MagnifyingGlassImg.tsx" />
      <path d="M102.716 100.375C122.65 100.375 138.81 84.2201 138.81 64.2926C138.81 44.365 122.65 28.2104 102.716 28.2104C82.7819 28.2104 66.6221 44.365 66.6221 64.2926C66.6221 84.2201 82.7819 100.375 102.716 100.375Z" fill="white" data-sentry-element="path" data-sentry-source-file="MagnifyingGlassImg.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M102.716 29.0526C83.2475 29.0526 67.4646 44.8298 67.4646 64.2926C67.4646 83.7554 83.2475 99.5326 102.716 99.5326C122.184 99.5326 137.967 83.7554 137.967 64.2926C137.967 44.8298 122.184 29.0526 102.716 29.0526ZM65.7793 64.2926C65.7793 43.9002 82.3161 27.3684 102.716 27.3684C123.116 27.3684 139.653 43.9002 139.653 64.2926C139.653 84.685 123.116 101.217 102.716 101.217C82.3161 101.217 65.7793 84.685 65.7793 64.2926Z" fill="#00004B" data-sentry-element="path" data-sentry-source-file="MagnifyingGlassImg.tsx" />
      <path d="M102.716 34.5032C108.615 34.499 114.382 36.2433 119.289 39.5154C124.195 42.7875 128.02 47.4403 130.28 52.8853C132.54 58.3302 133.133 64.3227 131.984 70.1045C130.835 75.8864 127.996 81.1978 123.826 85.3669C119.656 89.536 114.342 92.3754 108.557 93.5259C102.772 94.6764 96.7748 94.0863 91.3252 91.8303C85.8756 89.5743 81.2179 85.7538 77.9416 80.852C74.6652 75.9502 72.9173 70.1874 72.9189 64.2927C72.9181 60.3815 73.6882 56.5084 75.1852 52.8947C76.6822 49.2809 78.8768 45.9971 81.6437 43.2309C84.4106 40.4647 87.6956 38.2702 91.3112 36.7727C94.9268 35.2752 98.8022 34.504 102.716 34.5032V34.5032Z" fill="white" data-sentry-element="path" data-sentry-source-file="MagnifyingGlassImg.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M116.312 38.7549C110.608 35.7234 104.053 34.6851 97.6908 35.8053C91.3282 36.9255 85.5233 40.1399 81.1996 44.937C76.8758 49.7341 74.2816 55.8385 73.8296 62.2788C73.3776 68.7191 75.0938 75.1255 78.7053 80.4788L78.0065 80.9495L78.7062 80.4802C81.3498 84.4155 84.9237 87.6387 89.1113 89.8644C93.299 92.0901 97.9712 93.2495 102.714 93.2401H102.716C109.176 93.2399 115.451 91.0807 120.542 87.1059C125.633 83.1311 129.248 77.5691 130.811 71.3048C132.374 65.0404 131.796 58.4336 129.169 52.5355C126.542 46.6373 122.016 41.7865 116.312 38.7549ZM77.3073 81.4195C73.486 75.7548 71.6701 68.9758 72.1483 62.161C72.6266 55.3458 75.3719 48.8862 79.9473 43.8098C84.5227 38.7335 90.6655 35.3321 97.3984 34.1466C104.131 32.9612 111.067 34.0599 117.103 37.268C123.139 40.476 127.928 45.6091 130.709 51.8505C133.489 58.092 134.101 65.0834 132.446 71.7123C130.792 78.3412 126.967 84.2269 121.58 88.4331C116.193 92.6388 109.554 94.9237 102.718 94.9243C97.6974 94.9343 92.7522 93.7071 88.3199 91.3514C83.8878 88.9958 80.1053 85.5845 77.3073 81.4195Z" fill="#00004B" data-sentry-element="path" data-sentry-source-file="MagnifyingGlassImg.tsx" />
      <path d="M120.713 58.5558C122.294 61.436 123.122 64.6681 123.122 67.9531C123.121 71.238 122.291 74.4697 120.709 77.3492C119.127 80.2287 116.844 82.6629 114.071 84.4267C111.297 86.1905 108.124 87.2269 104.844 87.44C105.292 87.4674 105.741 87.4842 106.196 87.4842C109.918 87.482 113.579 86.5433 116.842 84.7546C120.105 82.966 122.865 80.385 124.866 77.2497C126.868 74.1144 128.048 70.5259 128.296 66.815C128.545 63.1042 127.854 59.3906 126.288 56.0168L120.713 58.5558Z" fill="#DFE2E7" data-sentry-element="path" data-sentry-source-file="MagnifyingGlassImg.tsx" />
      <path d="M128.469 96.9899L156.437 124.939L164.322 117.06L136.354 89.1105L128.469 96.9899Z" fill="white" data-sentry-element="path" data-sentry-source-file="MagnifyingGlassImg.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M156.437 126.13L127.277 96.9899L136.354 87.9195L165.514 117.06L156.437 126.13ZM156.437 123.748L163.13 117.06L136.354 90.3014L129.661 96.9899L156.437 123.748Z" fill="#00004B" data-sentry-element="path" data-sentry-source-file="MagnifyingGlassImg.tsx" />
      <path d="M87.5088 54.9858L90.1707 56.4302C90.5095 56.614 90.5945 57.0643 90.4591 57.4252C90.18 58.1687 90.2227 59.2033 91.5986 59.9498C93.0121 60.7167 93.9883 60.179 94.513 59.5517C94.746 59.273 95.1451 59.1292 95.4644 59.3024L98.3751 60.8818C98.675 61.0445 99.05 60.9333 99.2127 60.6334L100.715 57.8639C100.878 57.5639 100.761 57.1899 100.519 56.9492C99.9805 56.4129 99.5289 55.4518 100.233 54.1542C100.936 52.859 102.02 52.8275 102.792 53.0844C103.137 53.199 103.549 53.1017 103.722 52.7824L105.197 50.0645C105.36 49.7646 105.248 49.3896 104.948 49.2268L93.8894 43.2263C93.5895 43.0636 93.2145 43.1748 93.0517 43.4747L87.2604 54.1482C87.0977 54.4481 87.2089 54.8231 87.5088 54.9858Z" stroke="#90D6AA" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="MagnifyingGlassImg.tsx" />
      <path d="M114.172 72.0306L111.877 69.2428C111.676 68.9987 111.748 68.6311 111.979 68.415C112.627 67.8074 113.149 66.6986 111.996 65.2988C110.822 63.8719 109.543 64.1292 108.775 64.6041C108.522 64.7608 108.175 64.7458 107.986 64.5158L105.508 61.5052C105.33 61.2892 105.011 61.2582 104.795 61.436L101.929 63.7948C101.713 63.9726 101.686 64.2921 101.811 64.5423C102.185 65.2881 102.342 66.5149 101.033 67.5921C99.7255 68.6687 98.5666 68.1328 97.9174 67.5085C97.7026 67.3021 97.3597 67.2505 97.1297 67.4398L94.3015 69.768C94.0855 69.9458 94.0545 70.2651 94.2323 70.4811L103.047 81.189C103.225 81.405 103.544 81.4359 103.76 81.2581L114.103 72.7437C114.319 72.5659 114.35 72.2467 114.172 72.0306Z" stroke="#5EBB7F" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="MagnifyingGlassImg.tsx" />
    </svg>;
};