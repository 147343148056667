import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Drawer, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, ButtonType, Input, InputType, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import { deleteEntitlementsForApp } from '../../app-redux/actions/entitlements.actions';
import { AppState } from '../../app-redux/reducers';
import { AppConstants } from '../../core/app.constants';
import { EntitlementBasicDataForDTO, EntitlementDTO } from '../../core/entitlements/models';
import { INavigationRouteProps } from '../../core/navigation/models';
import { UUID } from '../../core/utils/BasicModels';
import CustomModal from '../Modal';
import './style.less';
const {
  Text
} = Typography;
interface IEntitlementDrawerProps extends INavigationRouteProps<any> {
  drawerVisible: boolean;
  onCloseDrawer: () => void;
  initialEntitlement?: EntitlementDTO;
  onSubmit: (entitlementBasicData: EntitlementBasicDataForDTO) => void;
  onDelete: () => void;
  deleteEntitlementForApp: (entitlementId: UUID) => Promise<any>;
}
const ManageEntitlementDrawerComponent: React.FC<IEntitlementDrawerProps> = props => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const [manageEntitlementForm] = Form.useForm();
  useEffect(() => {
    manageEntitlementForm.setFieldsValue({
      key: props.initialEntitlement?.key,
      name: props.initialEntitlement?.name
    });
    if (props.initialEntitlement) {
      setIsButtonDisable(false);
    }
  }, [props.initialEntitlement]);
  const handleSaveEditButton = async () => {
    setIsButtonLoading(true);
    const newData = await manageEntitlementForm.validateFields();
    props.onSubmit((newData as EntitlementBasicDataForDTO));
    onHandleClose();
    setIsButtonLoading(false);
  };
  const onHandleInputChangeOnForm = () => {
    const isValidName = (name: string) => {
      return name.length > 0 && name.length < AppConstants.NAME_MAX_LENGTH;
    };
    const isValidKey = (key: string) => {
      return key.length > 0 && key.length < AppConstants.NAME_MAX_LENGTH;
    };
    manageEntitlementForm.validateFields(['name', 'key']).then(quota => {
      if (isValidName(quota.name) && isValidKey(quota.key)) {
        setIsButtonDisable(false);
      } else {
        setIsButtonDisable(true);
      }
    }).catch(() => {
      setIsButtonDisable(true);
    });
  };
  function onHandleClose() {
    props.onCloseDrawer();
    manageEntitlementForm.resetFields();
    setIsButtonDisable(false);
  }
  const DeleteEntitlementWarningModal: React.FC = () => {
    const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);
    const WarningContent: React.FC = () => {
      return <>
            <p>
              This action is irreversible and can impact all Protector plans. Do you want to continue?
            </p>
          </>;
    };
    return <CustomModal cancelButton={{
      text: 'Cancel',
      onCancel: () => {
        setIsWarningModalVisible(false);
      }
    }} content={<WarningContent />} height="245px" icon={
    // tslint:disable
    <ExclamationCircleTwoTone twoToneColor="#CF3537" style={{
      fontSize: '20px'
    }} />
    // tslint:enable
    } okButton={{
      text: 'Continue',
      loading: isDeleteButtonLoading,
      onContinue: async () => {
        setIsDeleteButtonLoading(true);
        await props.deleteEntitlementForApp(props.initialEntitlement?.id ?? '');
        setIsWarningModalVisible(false);
        setIsDeleteButtonLoading(false);
        props.onDelete();
      }
    }} title="Do you want to delete this entitlement?" visible={isWarningModalVisible} width="423px" data-sentry-element="CustomModal" data-sentry-component="DeleteEntitlementWarningModal" data-sentry-source-file="index.tsx" />;
  };
  return <div data-sentry-component="ManageEntitlementDrawerComponent" data-sentry-source-file="index.tsx">
      <Drawer title={props.initialEntitlement ? 'Edit Entitlement' : 'Create Entitlement'} placement="right" width={370} closable={true} onClose={onHandleClose} open={props.drawerVisible} data-sentry-element="Drawer" data-sentry-source-file="index.tsx">
        <div style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
          <Text strong={true} style={{
          fontSize: '14px',
          color: '#2F3031',
          marginBottom: '20px'
        }} data-sentry-element="Text" data-sentry-source-file="index.tsx">
            Basic settings
          </Text>
          <DeleteEntitlementWarningModal data-sentry-element="DeleteEntitlementWarningModal" data-sentry-source-file="index.tsx" />
          <Form form={manageEntitlementForm} layout="vertical" name="form_in_drawer" initialValues={props.initialEntitlement!} onChange={onHandleInputChangeOnForm} data-sentry-element="Form" data-sentry-source-file="index.tsx">
            <div>
              <Form.Item name="key" label="Key" rules={[{
              required: true,
              message: 'Entitlement\'s key cannot be empty'
            }, {
              max: AppConstants.NAME_MAX_LENGTH,
              message: `Entitlement's key cannot be longer than ${AppConstants.NAME_MAX_LENGTH} characters`
            }]} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Input data-testid="inputId" type={InputType.success} placeholder={'ex.: scouting.app'} autoFocus={true} maxLength={AppConstants.NAME_MAX_LENGTH} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
              </Form.Item>
              <Form.Item name="name" label="Name" rules={[{
              required: true,
              message: 'Entitlement\'s name cannot be empty'
            }, {
              max: AppConstants.NAME_MAX_LENGTH,
              message: `Entitlement's cannot be longer than ${AppConstants.NAME_MAX_LENGTH} characters`
            }]} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Input data-testid="inputId" type={InputType.success} placeholder={'ex.: Scouting App'} autoFocus={true} maxLength={AppConstants.NAME_MAX_LENGTH} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
              </Form.Item>
            </div>
          </Form>
          <div className="entitlements-drawer-manage-buttons">
            <div className="entitlements-drawer-manage-buttons__wrapper" style={{
            justifyContent: props.initialEntitlement ? 'space-between' : 'right'
          }}>
              {props.initialEntitlement && <div onClick={() => setIsWarningModalVisible(true)}><Text className="entitlement-drawer-manage-delete-button">Delete Entitlement</Text></div>}
              <div className="entitlements-drawer-manage-buttons__wrapper__right">
                <Button onClick={onHandleClose} className="entitlement-drawer-manage-cancel-button" data-sentry-element="Button" data-sentry-source-file="index.tsx">Cancel</Button>
                <Button loading={isButtonLoading} disabled={isButtonDisable} style={{
                width: '84px'
              }} type={ButtonType.primary} onClick={handleSaveEditButton} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                  {props.initialEntitlement ? 'Edit' : 'Save'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>;
};
const mapStateToProps = (state: AppState) => ({});
const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteEntitlementForApp: (entitlementId: UUID) => dispatch(deleteEntitlementsForApp(entitlementId))
  };
};
export const ManageEntitlementDrawer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageEntitlementDrawerComponent));