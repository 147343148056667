import { AppState } from 'app-redux/reducers';
import { createSelector } from 'reselect';

export const getRolloutState = (state: AppState) => state.rollout;
export const getRolloutLoadingStatus = createSelector(
  getRolloutState,
  (rolloutState) => !!rolloutState.loading
);

export const getRBACEnabledOnFront = createSelector(
  getRolloutState,
  (rolloutState) => !!rolloutState.flags.isRBACVerificationEnabledOnBaseFront
);
