import { Input, InputProps } from 'antd';
import React, { FormEvent, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { IntegrationsFormContext } from './integrations-form';
import { isValidHttpUrl } from 'pages/integrations/utils/url';
export const ErrorText = styled.p`
  margin-top: 5px;
  color: ${({
  theme
}) => theme.colors.ErrorColor};
  font-size: 12px;
  line-height: 14px;
`;
const Label = styled.label`
  span.label {
    font-size: 14px;
    line-height: 24px;
  }

  width: 100%;
`;
interface StyledInput extends InputProps {
  $isInvalid: boolean;
}
export const StyledTextInput = styled((p: StyledInput) => <Input {...p} />)`
  padding: 10px;
  background-color: #232630;
  color: #fff;
  border-radius: 4px;
  border: 1px solid
    ${({
  $isInvalid,
  theme
}: {
  $isInvalid?: boolean;
  theme: any;
}) => $isInvalid ? theme.colors.ErrorColor : '#232630'};
  transition: 300ms;
  height: 40px;

  &::placeholder {
    color: #868ca2;
  }

  &:hover {
    border-color: #868ca2 !important;
  }

  &:focus {
    border-color: #868ca2 !important;
    box-shadow: 0px 0px 0px 2px rgba(134, 140, 162, 0.25) !important;
  }
`;
export const IntegrationUrlAttribute = () => {
  const {
    values: {
      setup_url
    },
    errors,
    setError,
    setValues
  } = useContext(IntegrationsFormContext);
  const onChange = useCallback((ev: FormEvent<HTMLInputElement>) => {
    const newValue = (ev.target as HTMLInputElement).value ?? '';
    setValues('setup_url', newValue);
  }, [setValues]);
  const onBlur = useCallback((ev: FormEvent<HTMLInputElement>) => {
    const value = (ev.target as HTMLInputElement).value ?? '';
    if (value && !isValidHttpUrl(value)) {
      setError('setup_url', 'Enter a valid URL Attribute format');
    } else {
      setError('setup_url', undefined);
    }
  }, [setError]);
  return <Label htmlFor="integration-url-attribute" data-sentry-element="Label" data-sentry-component="IntegrationUrlAttribute" data-sentry-source-file="integration-url-attribute.tsx">
      <span className="label">URL Attribute</span>
      <span className="optional"> (optional)</span>
      <StyledTextInput id="integration-url-attribute" size="large" defaultValue={setup_url} placeholder="Insert URL Attribute" onInput={onChange} onBlur={onBlur} $isInvalid={!!errors?.setup_url} data-testid="integration-url-attribute" data-sentry-element="StyledTextInput" data-sentry-source-file="integration-url-attribute.tsx" />
      {errors?.setup_url && <ErrorText className="error" data-testid="integration-url-attribute-error">
          {errors?.setup_url}
        </ErrorText>}
    </Label>;
};