/**
 * App Loader : Before Loading App in the dom, we need to load few meta data in our system.
 * App Loader will be used to control the sequence in which things are loaded.
 * 1. We need to load language resources from Google before we start the app.
 * 2. We need to determine if user is logged in or not.
 */
import { isAccessTokenAvailable } from 'core/common-methods';
import { history, PublicRoutes } from 'core/history';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import { initOAuth2Flow } from '../app-redux/actions/login.actions';
import langData from '../getLangData.json';
import { enableDebug } from 'app.constant';

class AppLoader {
  private readonly metaData: {
    isUserLoggedIn?: boolean;
  };

  constructor() {
    this.metaData = {};
  }
  public getMetaData() {
    return this.metaData;
  }
  public initI18n() {
    const lng = localStorage.getItem('i18nextLng') || 'en';
    moment.locale(lng);
    return i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        // we init with resources
        resources: langData, // TODO
        lng,
        // lng: 'hi',
        fallbackLng: {
          'en-GB': ['en'],
          'en-US': ['en'],
          'fr-FR': ['fr'],
          default: ['en']
        },
        debug: enableDebug,
        keySeparator: false, // we use content as keys
        interpolation: {
          escapeValue: false
        }
      });
  }
  public isUserLoggedIn() {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        this.metaData.isUserLoggedIn = true;
        resolve();
      }, 1000);
    });
  }
  public async redirectAsPerLoggedInStatus() {
    if (!isAccessTokenAvailable() && !PublicRoutes.includes(history.location.pathname)) {
      initOAuth2Flow(history.location.pathname);
      return true;
    }
    return false;
  }
  public async load() {
    const redirectStatus = await this.redirectAsPerLoggedInStatus();
    if (redirectStatus) {
      return redirectStatus;
    }
    await this.initI18n();
    return false;
  }
}

export { AppLoader };
