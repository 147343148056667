import {
  ACTION_APP_ICON_RESET,
  ACTION_CREATE_APP,
  ACTION_DELETE_APP, ACTION_DELETE_APP_ICON,
  ACTION_EDIT_APP,
  ACTION_GET_APP,
  ACTION_GET_APPS_LIST,
  ACTION_RESET_GET_APP,
  ACTION_SET_CURRENT_APP,
  ACTION_UPLOAD_APP_ICON,
} from 'app-redux/actions/apps.actions';
import { PayloadAction } from 'core/utils/actionUtils';
import { PageDTO, UUID } from 'core/utils/BasicModels';

export interface App {
  key?: string;
  id: UUID;
  name?: string;
  published?: number|null;
  short_description?: string;
  detailed_description?: string;
  site_uri?: string;
  icon_uri: string;
  status?: string;
  fallback_details_language?: string;
}

export interface PagedApp extends PageDTO<App> {
  apps: App[];
}

export interface AppsState {
  currentAppId?: UUID;
  pagedApp?: PagedApp;
  currentApp?: App;
  loading?: boolean;
  error?: any;
  avatarHasUpdated?: boolean;
}

const initialState: AppsState = {
};

export const appsReducer = (state: AppsState = initialState, action: PayloadAction<any>): AppsState => {
  switch (action.type) {
    case ACTION_GET_APPS_LIST.REQUEST:
    case ACTION_CREATE_APP.REQUEST:
    case ACTION_GET_APP.REQUEST:
    case ACTION_RESET_GET_APP.REQUEST:
    case ACTION_DELETE_APP.REQUEST:
    case ACTION_EDIT_APP.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_GET_APPS_LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        pagedApp: action.payload,
      };
    case ACTION_GET_APPS_LIST.ERROR:
    case ACTION_CREATE_APP.ERROR:
    case ACTION_GET_APP.ERROR:
    case ACTION_RESET_GET_APP.ERROR:
    case ACTION_DELETE_APP.ERROR:
    case ACTION_EDIT_APP.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACTION_CREATE_APP.SUCCESS:
    case ACTION_DELETE_APP.SUCCESS:
    case ACTION_EDIT_APP.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTION_GET_APP.SUCCESS:
      return {
        ...state,
        loading: false,
        currentAppId: action.payload.id,
        currentApp: action.payload,
      };
    case ACTION_RESET_GET_APP.SUCCESS:
      return {
        ...state,
        loading: false,
        currentAppId: action.payload,
        currentApp: action.payload,
      };
    case ACTION_SET_CURRENT_APP:
      return {
        ...state,
        currentAppId: action.payload,
      };
    case ACTION_UPLOAD_APP_ICON.SUCCESS:
    case ACTION_DELETE_APP_ICON.SUCCESS:
      return {
        ...state,
        avatarHasUpdated: true,
      };
    case ACTION_APP_ICON_RESET.SUCCESS:
      return {
        ...state,
        avatarHasUpdated: false,
      };
    default:
      return state;
  }
};
