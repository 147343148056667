import { Modal } from 'antd';
import React from 'react';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
interface IPlanFormErrorModalProps {
  errorIcon: React.ReactNode;
  errorText: string;
  handleClose: any;
  isVisible: boolean;
}
export const PlanFormErrorModal: React.FC<IPlanFormErrorModalProps> = props => {
  return <Modal open={props.isVisible} onOk={() => props.handleClose('error')} onCancel={() => props.handleClose('error')} width="384px" footer={[<Button key={1} type={ButtonType.primary} size="large" onClick={() => props.handleClose('error')}>
                      Ok
                  </Button>]} data-sentry-element="Modal" data-sentry-component="PlanFormErrorModal" data-sentry-source-file="index.tsx">
              <div className="modal-placeholder">
                  {props.errorIcon}
                  <p style={{
        paddingTop: '20px'
      }}>
                      {props.errorText}
                  </p>
              </div>
      </Modal>;
};