import { isUserUsingRBAC } from 'core/common-methods';
import { createSelector } from 'reselect';
import { IUserAccount } from '../../core/accounts/models';
import { UUID } from '../../core/utils/BasicModels';
import { AppState } from '../reducers';
import { AccountsState } from '../reducers/accounts.reducer';
import { rbacPermissionsCheck } from './rbac.selector';

export const getAccountsState = (state: AppState) => state.accounts || {} as AccountsState;

export const getCurrentUser = createSelector(getAccountsState,
    (accountsState) => accountsState.currentUser);
export const getInvitedAccount = createSelector(getAccountsState,
    (accountsState) => accountsState.invitedAccount);
export const getCurrentUserLoadingStatus = createSelector(getAccountsState,
  (accountsState) => !!accountsState.loading);

export const userCanAccessSytOrg = (state: AppState) => {
  const currentUser = getCurrentUser(state) as IUserAccount;
  if (currentUser) {
    return currentUser?.authorities.filter((authority) => authority.id === '43414955-4249-5359-4e47-454e54413432')?.length > 0;
  }
  return false;
};

export const legacyPermissionsCheck = (state: AppState, id: UUID, context: string, permission: string, scope: string): boolean => {
  const currentUser = getCurrentUser(state) as IUserAccount;
  const authorityDTOS = currentUser?.authorities.filter((authority) => {
    if (authority.id === id && context === authority.context) {
      return authority.permissions.reduce((hasPermission, currentPermission) => {
        return hasPermission || currentPermission.name === permission && (currentPermission.scope === scope || currentPermission.scope === 'WRITE');
      }, false as boolean);
    } else {
      return false;
    }
  });
  return authorityDTOS?.length > 0;
};

export const currentUserHasPermission = (state: AppState, id: UUID, context: string, permission: string, scope: string, isDevConsoleCheck = false): boolean => {
  if (isUserUsingRBAC()) {
    return rbacPermissionsCheck(state, id, context, permission, scope, isDevConsoleCheck);
  }
  return legacyPermissionsCheck(state, id, context, permission, scope);
};
