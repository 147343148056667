import { ACTION_CREATE_PLAN, ACTION_DELETE_PLAN, ACTION_EDIT_PLAN, ACTION_GET_PLAN, ACTION_GET_PLANS_LIST, ACTION_RESET_GET_PLAN, ACTION_SET_CURRENT_PLAN } from 'app-redux/actions/plans.actions';
import { PlanDTO } from 'core/plans/models';
import { PayloadAction } from 'core/utils/actionUtils';
import { PageDTO, UUID } from 'core/utils/BasicModels';

export interface Pageable {
  current_key: string;
  next_key: string;
  size: number;
}

export interface PagedPlan extends PageDTO<PlanDTO> {
  pageable: Pageable;
}

export interface PlansState {
  currentPlanId?: UUID;
  pagedPlan?: PagedPlan;
  loading?: boolean;
  error?: any;
}

const initialState: PlansState = {
};

export const plansReducer = (state: PlansState = initialState, action: PayloadAction<any>): PlansState => {
  switch (action.type) {
    case ACTION_GET_PLANS_LIST.REQUEST:
    case ACTION_CREATE_PLAN.REQUEST:
    case ACTION_GET_PLAN.REQUEST:
    case ACTION_RESET_GET_PLAN.REQUEST:
    case ACTION_EDIT_PLAN.REQUEST:
    case ACTION_DELETE_PLAN.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_GET_PLANS_LIST.SUCCESS:
      return {
        ...state,
        pagedPlan: action.payload,
        loading: false,
      };
    case ACTION_GET_PLANS_LIST.ERROR:
    case ACTION_CREATE_PLAN.ERROR:
    case ACTION_GET_PLAN.ERROR:
    case ACTION_RESET_GET_PLAN.ERROR:
    case ACTION_EDIT_PLAN.ERROR:
    case ACTION_DELETE_PLAN.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACTION_CREATE_PLAN.SUCCESS:
    case ACTION_EDIT_PLAN.SUCCESS:
    case ACTION_DELETE_PLAN.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTION_GET_PLAN.SUCCESS:
      return {
        ...state,
        loading: false,
        currentPlanId: action.payload.id,
      };
    case ACTION_RESET_GET_PLAN.SUCCESS:
      return {
        ...state,
        loading: false,
        currentPlanId: action.payload,
      };
    case ACTION_SET_CURRENT_PLAN:
      return {
        ...state,
        currentPlanId: action.payload,
      };
    default:
      return state;
  }
};
