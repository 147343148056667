import { LoadingOutlined } from '@ant-design/icons';
import { performLogin } from 'app-redux/actions/login.actions';
import { history, RoutePaths } from 'core/history';
import { INavigationRouteProps } from 'core/navigation/models';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Spin } from 'syngenta-digital-cropwise-react-ui-kit';
import './index.less';
interface ILoginProps extends INavigationRouteProps<{}> {
  performLogin: (code: string) => Promise<{}>;
}
const AuthenticationRedirect: React.FC<ILoginProps> = props => {
  const code = new URLSearchParams(props.location.search).get('code') || '';
  const state = new URLSearchParams(props.location.search).get('state');
  useEffect(() => {
    if (code === '') {
      return;
    }
    const loginAndRedirect = () => {
      return props.performLogin(code);
    };
    loginAndRedirect().then(() => {
      if (state) {
        try {
          const newPath = atob(state);
          if (newPath.startsWith('/')) {
            history.replace(newPath);
          }
          history.replace(RoutePaths.HOME());
        } catch (ex) {
          history.replace(RoutePaths.HOME());
        }
      } else {
        history.replace(RoutePaths.HOME());
      }
    }).catch();
  }, []);
  return <div style={{
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }} data-sentry-component="AuthenticationRedirect" data-sentry-source-file="index.tsx">
      <Spin tip={'Loading...'} indicator={<LoadingOutlined />} data-sentry-element="Spin" data-sentry-source-file="index.tsx" />
    </div>;
};
const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: any) => {
  return {
    performLogin: (code: string) => dispatch(performLogin(code))
  };
};
export const AuthenticationRedirectComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticationRedirect));