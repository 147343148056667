import { AxiosResponse } from 'axios';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import { Dispatch } from 'redux';
import { axiosRequest } from '../../core/axios';
import { getHeaders } from '../../core/common-methods';
import { CreateEntitlementDTO, EntitlementDTO, UpdateEntitlementDTO } from '../../core/entitlements/models';
import { UUID } from '../../core/utils/BasicModels';

export const ACTION_CREATE_ENTITLEMENT_FOR_APP: RequestAction = createRequestAction('CREATE_ENTITLEMENT_FOR_APP_REQUEST');
export const ACTION_UPDATE_ENTITLEMENT_FOR_APP: RequestAction = createRequestAction('UPDATE_ENTITLEMENT_FOR_APP_REQUEST');
export const ACTION_DELETE_ENTITLEMENT_FOR_APP: RequestAction = createRequestAction('DELETE_ENTITLEMENT_FOR_APP_REQUEST');

export const createEntitlementsForApp = (entitlementCreateDTO: CreateEntitlementDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_ENTITLEMENT_FOR_APP.REQUEST });
    const entitlements: AxiosResponse<EntitlementDTO> = await axiosRequest
            .post<any, AxiosResponse<EntitlementDTO>>(`/v2/entitlements/`, entitlementCreateDTO,
      {
        headers: getHeaders().common,
      });

    dispatch({ type: ACTION_CREATE_ENTITLEMENT_FOR_APP.SUCCESS, payload: entitlements.data });
    return entitlements.data;
  } catch (e) {
    dispatch({ type: ACTION_CREATE_ENTITLEMENT_FOR_APP.ERROR, payload: e });
    throw e;
  }
};

export const editEntitlementsForApp = (entitlementId: UUID, updateEntitlementDTO: UpdateEntitlementDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_UPDATE_ENTITLEMENT_FOR_APP.REQUEST });
    const entitlements: AxiosResponse<EntitlementDTO> = await axiosRequest
            .put<any, AxiosResponse<EntitlementDTO>>(`/v2/entitlements/${entitlementId}`, updateEntitlementDTO,
      {
        headers: getHeaders().common,
      });

    dispatch({ type: ACTION_UPDATE_ENTITLEMENT_FOR_APP.SUCCESS, payload: entitlements.data });
    return entitlements.data;
  } catch (e) {
    dispatch({ type: ACTION_UPDATE_ENTITLEMENT_FOR_APP.ERROR, payload: e });
    throw e;
  }
};

export const deleteEntitlementsForApp = (entitlementId: UUID) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_ENTITLEMENT_FOR_APP.REQUEST });
    const entitlements: AxiosResponse<EntitlementDTO> = await axiosRequest
        .delete<any, AxiosResponse<EntitlementDTO>>(`/v2/entitlements/${entitlementId}`,
      {
        headers: getHeaders().common,
      });

    dispatch({ type: ACTION_DELETE_ENTITLEMENT_FOR_APP.SUCCESS, payload: entitlements.data });
    return entitlements.data;
  } catch (e) {
    dispatch({ type: ACTION_DELETE_ENTITLEMENT_FOR_APP.ERROR, payload: e });
    throw e;
  }
};
