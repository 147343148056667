import { requestDevAuth } from 'app-redux/actions/api-client.actions';
import bulbIcon from 'assets/images/bulb.png';
import requestAccessImage from 'assets/images/RequestAccess_1.gif';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.less';
import { Button, Modal } from 'antd';
export const RequestAccessButton = (props: any) => {
  return <Button disabled={props.isRequestIsInProgres} key="newRequestAccess" className={'access-button-container ' + (props.isRequestIsInProgres ? 'disableButton' : 'enableButton')} onClick={() => {
    props.setIsRequestIsInProgres(true);
    requestDevAuth();
  }} data-sentry-element="Button" data-sentry-component="RequestAccessButton" data-sentry-source-file="index.tsx">
      {props.t('Request Access')}
    </Button>;
};
export const AccessRequestSentMessage = (props: any) => {
  return <div className="request-sent-message-text" data-sentry-component="AccessRequestSentMessage" data-sentry-source-file="index.tsx">
          <img src={bulbIcon} alt="Request" />
          <span>
          {props.t('Your request has been sent. Await authorization and reload the page.')}
          </span>
    </div>;
};
export const RequestAccessModal = () => {
  const {
    t
  } = useTranslation();
  const [isRequestIsInProgres, setIsRequestIsInProgres] = React.useState(false);
  return <Modal className="newRequestAccessModal" style={{
    top: '3rem'
  }} open footer={null} width={920} destroyOnClose={true} closable={false} data-sentry-element="Modal" data-sentry-component="RequestAccessModal" data-sentry-source-file="index.tsx">
      <img data-testid="newRequestAccessModal" className="not-request-access-gif" src={requestAccessImage} alt="Request" />
      <div className="text-content-container">
        <p className="title">{t('You don’t have access to Cropwise Dev Console yet!')}</p>
        <br />
        <p className="sub-title-text">{t(' Dev Console is the Cropwise asset responsible for')}</p>
        <p className="sub-title-text">{t(' managing users, along with their APPs, plans, contracts,')}</p>
        <p className="sub-title-text">{t(' and permissions within the ecosystem.')}</p>
        <br />
        <p className="sub-title-text">{t(' If you need access to Dev console, you can request it!')}</p>
      </div>
      <div className="action-container">
        <div className="access-request-sent-message-container">
            {isRequestIsInProgres ? <AccessRequestSentMessage t={t} /> : null}
        </div>
        <div data-testid="request-access-addon">
          <RequestAccessButton isRequestIsInProgres={isRequestIsInProgres} setIsRequestIsInProgres={setIsRequestIsInProgres} t={t} data-sentry-element="RequestAccessButton" data-sentry-source-file="index.tsx" />
        </div>
      </div>
    </Modal>;
};
const RequestAccessAddon: React.FC<{}> = (props: any) => {
  return <RequestAccessModal {...props} data-sentry-element="RequestAccessModal" data-sentry-component="RequestAccessAddon" data-sentry-source-file="index.tsx" />;
};
export default RequestAccessAddon;