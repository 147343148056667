import bg1 from './bg1.jpg';
import bg2 from './bg2.jpg';
import bg3 from './bg3.jpg';
import bg4 from './bg4.jpg';
import bg5 from './bg5.jpg';
import bg6 from './bg6.jpg';
import erp from './classes/ERp.jpg';
import machinery from './classes/Machinery.jpg';
import traps from './classes/Trap.jpg';
import weather from './classes/Weather.jpg';

export default {
  default: { bg1, bg2, bg3, bg4, bg5, bg6 },
  byClass: { erp, machinery, traps, weather, ag_insights: traps }
};
