import { DeveloperAppDTO } from 'core/apps/models';
import { axiosRequest } from 'core/axios';
import { getHeaders } from 'core/common-methods';
import { PageDTO } from 'core/utils/BasicModels';

export const fetchApps = (currentOrgId: string) =>
  axiosRequest
    .get<PageDTO<DeveloperAppDTO>>(`/v2/orgs/${currentOrgId}/apps`, {
      headers: { ...getHeaders().common }
    })
    .then((res) => res.data)
    .then(({ content }) =>
      content.filter((app) => app.published).filter((app) => app.id && app.name)
    );
