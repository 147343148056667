import { CheckCircleFilled, CloseCircleFilled, DownOutlined, ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Dropdown, Layout, Menu, Modal, Table } from 'antd';
import { createApiClient, editApiClient, resetGetApiClient } from 'app-redux/actions/api-client.actions';
import { getCurrentOrgId } from 'app-redux/selectors/orgs.selector';
import { TechnologyCard } from 'components/technology-card';
import { OAuth2ClientDTO } from 'core/api-clients/models';
import { AppConstants } from 'core/app.constants';
import { history, RoutePaths } from 'core/history';
import { INavigationRouteProps } from 'core/navigation/models';
import { UUID } from 'core/utils/BasicModels';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Button, ButtonType, Input, InputType, Switch, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import { AppState } from '../../app-redux/reducers';
import { currentUserHasPermission } from '../../app-redux/selectors/accounts.selector';
import './style.less';
const {
  Content
} = Layout;
const {
  Text
} = Typography;
const Label = styled(Text)`
    display: block;
    color: #2F3031;
    font-size: 16px;
    font-weight: 400;
`;
const SmallTitle = styled(Text)`
    margin-top: 20px;
    display: block;
    color: #2F3031;
    font-size: 20px;
    font-weight: 400;
`;
interface IApiClientDetails {
  currentApiClient: {
    id: UUID;
    app_id: UUID;
    created_at: Date;
    last_access: Date;
    revoked: boolean;
    name: string;
    redirect_uris: string[];
    client_id: string;
    client_secret: string;
  };
}
interface IAppDetailsProps extends INavigationRouteProps<IApiClientDetails> {
  currentOrgId: string;
  dispatchSendApiClientChanges: (clientId: string, valuesRequest: OAuth2ClientDTO) => any;
  resetCurrentApiClient?: () => Promise<string>;
  dispatchCreateApiClients: (valuesRequest: OAuth2ClientDTO) => any;
  currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string) => boolean;
}

// tslint:disable-next-line:no-big-function
export const ApiClientDetails: React.FC<IAppDetailsProps> = props => {
  const currentApiClientProps = props.location.state ? props.location.state.currentApiClient : undefined;
  const canWriteCredentials = props.currentUserHasPermission(props.match.params.appId, 'APP', 'CREDENTIALS', 'WRITE') || props.currentUserHasPermission(props.currentOrgId, 'ORGANIZATION', 'OWNER', 'WRITE');
  const [visibleSuccessModal, setVisibleSuccessModal] = useState(false);
  const [visibleErrorModal, setVisibleErrorModal] = useState(false);
  const [selectedTechnology, setSelectedTechnology] = useState<string>();
  const [apiSecret, setApiSecret] = useState(false);
  const [apiURIList, setApiURIList] = useState<string[]>([]);
  const [dataSource, setDataSource] = useState(currentApiClientProps ? currentApiClientProps?.redirect_uris.map((uri: any, index: any) => ({
    key: index,
    redirectURI: uri
  })) : []);
  const appTechnologies = [{
    name: 'React'
  }, {
    name: 'Angular'
  }, {
    name: 'Vue'
  }, {
    name: 'Android'
  }, {
    name: 'iOS'
  }, {
    name: 'React Native'
  }];
  const columns = [{
    title: 'Redirect URI',
    dataIndex: 'redirectURI',
    editable: true
  }, {
    title: 'Operation',
    dataIndex: 'operation',
    render: (text: any, record: any) => dataSource && dataSource.length >= 1 ? <a style={{
      color: '#73DC78'
    }} onClick={() => handleDelete(record.key)}>Delete</a> : null
  }].filter(item => !canWriteCredentials ? item.dataIndex !== 'operation' : true);
  const handleCreateApiClient = async (values: any) => {
    dataSource?.forEach(item => {
      apiURIList.push(item.redirectURI);
    });
    const valuesReq: OAuth2ClientDTO = {
      app_id: props.match.params.appId,
      name: values.apiName,
      redirect_uris: apiURIList,
      create_secret: apiSecret
    };
    if (valuesReq.name) {
      const apiClientCreated = await props.dispatchCreateApiClients(valuesReq);
      history.push({
        pathname: RoutePaths.API_CLIENT_CREATED(props.match.params.appId),
        state: {
          apiClientCreated
        }
      });
    } else {
      setVisibleErrorModal(true);
    }
  };
  const handleSendChanges = async (values: any) => {
    dataSource?.forEach(item => {
      apiURIList.push(item.redirectURI);
    });
    const valuesReq: OAuth2ClientDTO = {
      app_id: currentApiClientProps?.app_id,
      name: values.apiName,
      redirect_uris: apiURIList
    };
    if (currentApiClientProps?.client_id) {
      const apiClientChangesSent = await props.dispatchSendApiClientChanges(currentApiClientProps.client_id, valuesReq);
      if (apiClientChangesSent) {
        setVisibleSuccessModal(true);
      }
    }
  };
  const handleDelete = (key: number) => {
    setDataSource(dataSource?.filter(item => item.key !== key));
  };
  const handleAddRow = (apiText: any) => {
    if (apiText.length !== 0 && dataSource) {
      const newData = {
        key: dataSource.length + 1,
        redirectURI: apiText
      };
      setDataSource(dataSource?.concat(newData));
    }
  };
  const handleClose = async (type: string) => {
    if (type === 'success') {
      setVisibleSuccessModal(false);
      history.push({
        pathname: RoutePaths.CREDENTIALS(props.match.params.appId)
      });
      if (props.resetCurrentApiClient) {
        await props.resetCurrentApiClient().catch();
      }
    } else {
      setVisibleErrorModal(false);
    }
  };
  const onSwitchSecretChange = (checked: boolean) => {
    setApiSecret(checked);
  };
  const renderSaveButton = (isSubmitting: boolean, values: any) => {
    if (!canWriteCredentials) {
      return <></>;
    }
    return <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }} data-sentry-component="renderSaveButton" data-sentry-source-file="index.tsx">
        {currentApiClientProps ? <Button style={{
        width: 147,
        marginTop: '30px',
        marginLeft: '350px'
      }} type={ButtonType.primary} disabled={isSubmitting} onClick={() => handleSendChanges(values)}>
              Send Changes
            </Button> : <Button style={{
        width: 147,
        marginTop: '30px',
        marginLeft: '350px'
      }} type={ButtonType.primary} disabled={isSubmitting} onClick={() => handleCreateApiClient(values)}>
              Create Client
            </Button>}
      </div>;
  };
  return <>
        <Content className="cw-content-container" data-sentry-element="Content" data-sentry-source-file="index.tsx">
          <Modal open={visibleSuccessModal} onOk={() => handleClose('success')} onCancel={() => handleClose('success')} width="384px" footer={[<Button key={1} type={ButtonType.primary} size="large" onClick={() => handleClose('success')}>
                    Ok
                </Button>]} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
            <div className="modal-placeholder">
              <CheckCircleFilled className="modal-success" data-sentry-element="CheckCircleFilled" data-sentry-source-file="index.tsx" />
              <p style={{
            paddingTop: '20px'
          }}>Api client successfully updated!</p>
            </div>
          </Modal>

          <Modal open={visibleErrorModal} onOk={() => handleClose('error')} onCancel={() => handleClose('error')} width="384px" footer={[<Button key={2} type={ButtonType.primary} size="large" onClick={() => handleClose('error')}>
                    Ok
                </Button>]} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
            {currentApiClientProps ? <div className="modal-placeholder">
                  <CloseCircleFilled className="modal-error" />
                  <p style={{
            paddingTop: '20px'
          }}>Sorry, api client couldn't be updated.</p>
                </div> : <div className="modal-placeholder">
                  <ExclamationCircleFilled className="name-error" />
                  <p style={{
            paddingTop: '20px'
          }}>To create an api client, you must add a name.</p>
                </div>}
          </Modal>

            <Formik initialValues={{
        apiName: currentApiClientProps?.name,
        apiAuthURI: ''
      }} onSubmit={(values, {
        setSubmitting
      }) => {
        alert(JSON.stringify(values, null, 2));
        setSubmitting(false);
      }} data-sentry-element="Formik" data-sentry-source-file="index.tsx">
                    {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => <form onSubmit={handleSubmit}>

                    <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
                        <div style={{
              display: 'flex',
              flexDirection: 'row'
            }}>
                            {currentApiClientProps ? <Text strong={true} style={{
                fontSize: '20px'
              }}>Api Client Details</Text> : <Text strong={true} style={{
                fontSize: '20px'
              }}>Create Api Client</Text>}
                        </div>
                        <div style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}>
                            <div className="cw-client-details-column1">
                                <div style={{
                  marginTop: '10px'
                }}>
                                    <Label data-testid="label">Name</Label>
                                    <Input data-testid="inputId" name="apiName" type={InputType.success} value={values.apiName} onChange={handleChange} placeholder={'Protector Backend Client'} autoFocus={true} maxLength={AppConstants.NAME_MAX_LENGTH} />
                                </div>

                                {!currentApiClientProps ? <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  minWidth: '400px',
                  paddingTop: '20px'
                }}>
                                            <Label data-testid="label">Create private client secret</Label>
                                            <div style={{
                    marginLeft: '280px'
                  }}><Switch onChange={onSwitchSecretChange} /> </div>
                                        </div> : <></>}

                                <SmallTitle>Choose a technology</SmallTitle>
                                <div className="pad40">
                                  <div style={{
                    display: 'flex'
                  }}>
                                      {appTechnologies.slice(0, 3).map(technology => <TechnologyCard selected={selectedTechnology === technology.name} key={technology.name} name={technology.name} onClick={() => {
                      setSelectedTechnology(technology.name);
                    }} />)}
                                  </div>
                                  <div style={{
                    display: 'flex'
                  }}>
                                      {appTechnologies.slice(3, 6).map(technology => <TechnologyCard selected={selectedTechnology === technology.name} key={technology.name} name={technology.name} onClick={() => {
                      setSelectedTechnology(technology.name);
                    }} />)}
                                  </div>
                                </div>
                            </div>
                            <div className="cw-client-details-column2">
                                <div>
                                    <Label data-testid="label">OAuth2 Redirect URI</Label>
                                    <Input data-testid="inputId" name="apiAuthURI" type={InputType.success} value={values.apiAuthURI} onChange={handleChange} placeholder={'https://protector.cropwise.com/auth'} autoFocus={true} maxLength={AppConstants.EMAIL_MAX_LENGTH} allowClear={true} />
                                </div>
                                <div className={'cw-api-uri-button'}>
                                    <Button style={{
                    width: '100%',
                    borderColor: '#E8EAED',
                    color: '#363948'
                  }} type={ButtonType.primary} ghost={true} icon={<PlusOutlined style={{
                    color: '#73DC78'
                  }} />} disabled={isSubmitting} onClick={() => handleAddRow(values.apiAuthURI)}>
                                        Add new redirect URI
                                    </Button>
                                </div>
                                <div style={{
                  marginTop: '40px'
                }}>
                                <Table bordered={true} dataSource={dataSource} columns={columns} />
                                </div>
                            </div>
                        </div>
                      {renderSaveButton(isSubmitting, values)}
                    </div>
                    </form>}
            </Formik>
        </Content>

    </>;
};
const mapStateToProps = (state: AppState) => ({
  currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string) => currentUserHasPermission(state, id, context, permission, scope),
  currentOrgId: getCurrentOrgId(state)
});
const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatchSendApiClientChanges: (clientId: string, valuesRequest: OAuth2ClientDTO) => dispatch(editApiClient(clientId, valuesRequest)),
    resetCurrentApiClient: () => dispatch(resetGetApiClient()),
    dispatchCreateApiClients: (valuesRequest: OAuth2ClientDTO) => dispatch(createApiClient(valuesRequest))
  };
};
export const ApiClientDetailsComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiClientDetails));