import { RightOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import contributeIcon from 'assets/images/contributeIcon.png';
import guideIcon from 'assets/images/guideIcon.png';
import JSIcon from 'assets/images/JSIcon.png';
import uiLeafImage from 'assets/images/leaf.png';
import React from 'react';
import { Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import './style.less';
const {
  Content
} = Layout;
const {
  Text
} = Typography;
interface SDKCardProps {
  title: string;
  installationLink: string;
  contributeLink: string;
  enterLink: string;
}
const SDKCard: React.FC<SDKCardProps> = props => {
  const {
    title,
    installationLink,
    contributeLink,
    enterLink
  } = props;
  return <div className="uiKitContentData" data-sentry-component="SDKCard" data-sentry-source-file="index.tsx">

          {/* Thumbnail -1 */}
          <div className="uiKitThumbnail">
              {/* Inner Thumbnail - Block  */}
              <div className="uiKitInnerThumbnail">
                  <div className="uiKitInnerData">
                      <div className="InnerLeftBlock">
                          <div className="innerLeafBlock">
                              <img src={uiLeafImage} className="innerLeafImage" alt="Leaf Image" />
                          </div>
                      </div>

                      <div className="innerRightBlock">
                          <div className="innerThumbnailHeading">
                              {title}
                              <RightOutlined className="coreUiIcon" onClick={() => window.open(enterLink, '_blank')} data-sentry-element="RightOutlined" data-sentry-source-file="index.tsx" />
                          </div>
                          <div className="technologiesBlock">
                              <div className="greenCircle" />
                              <span>Technologies</span>
                          </div>
                          <div className="technologiesIconBlock">
                              <div className="technologiesIcon">
                                  <img src={JSIcon} alt="Java Script Icon" />
                              </div>
                          </div>
                          <div className="clrFloat" />
                      </div>

                  </div>
                  <div className="clrFloat" />
              </div>

              {/* Outer Thumbnail - Block */}
              <div className="outerThumbData">
                  <div className="installtionGuide" onClick={() => window.open(installationLink, '_blank')}>
                      <img src={guideIcon} alt="guide Icon" />
                      <span>Installation Guide</span>
                  </div>
                  <div className="contribute" onClick={() => window.open(contributeLink, '_blank')}>
                      <img src={contributeIcon} alt="Contribute Icon" />
                      <span>Contribute</span>
                  </div>
              </div>
              <div className="clrFloat" />
          </div>

      </div>;
};
export const UiKit: React.FC<{}> = props => {
  const imageryWidget = 'https://github.com/syngenta-digital/web-react-cropwise-imagery-widget';
  return <>
            <Content className="uiKitContent" data-sentry-element="Content" data-sentry-source-file="index.tsx">
                {/* Heading - Block */}
                <Text strong={true} style={{
        fontSize: '20px',
        color: '#2F3031'
      }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Take a tour into our UI kit</Text>

                {/* Main Conent - Block  */}
                <SDKCard title={'React UI Kit'} installationLink={'https://github.com/syngenta-digital/cropwise-react-ui-kit#installing-on-your-app'} contributeLink={'https://github.com/syngenta-digital/cropwise-react-ui-kit/blob/main/CONTRIBUTING.md'} enterLink={'https://github.com/syngenta-digital/cropwise-react-ui-kit'} data-sentry-element="SDKCard" data-sentry-source-file="index.tsx" />
                <SDKCard title={'UI Kit Showcase'} installationLink={'https://github.com/syngenta-digital/cropwise-react-ui-kit#installing-on-your-app'} contributeLink={'https://github.com/syngenta-digital/cropwise-react-ui-kit/blob/main/CONTRIBUTING.md'} enterLink={'http://ui-kit.cropwise.com.s3-website-us-east-1.amazonaws.com/'} data-sentry-element="SDKCard" data-sentry-source-file="index.tsx" />
                <SDKCard title={'Cropwise Imagery Widget'} installationLink={imageryWidget} contributeLink={imageryWidget} enterLink={imageryWidget} data-sentry-element="SDKCard" data-sentry-source-file="index.tsx" />
            </Content>
        </>;
};
export default UiKit;