import * as Sentry from '@sentry/react';
import { store } from 'app-redux/store';
import { history } from 'core/history';
import { Route } from 'react-router-dom';

export const SENTRY_KEY = process.env.REACT_APP_SENTRY_DSN ?? '';
const APP_ENVIRONMENT = process.env.REACT_APP_ENV_NAME ?? '';
const SAMPLE_RATE = process.env.REACT_APP_SENTRY_SAMPLE_RATE ?? 1.0;
const NORMALIZE_DEPTH = 5;

export const SentryRoute = Sentry.withSentryRouting(Route);

export const SENTRY_CONFIG: Sentry.BrowserOptions = {
  dsn: SENTRY_KEY,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history })
  ],
  tracesSampleRate: Number(SAMPLE_RATE),
  // release: `v${APP_VERSION}`,
  environment: APP_ENVIRONMENT,
  normalizeDepth: NORMALIZE_DEPTH,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb?.category?.startsWith('ui')) {
      const target = hint?.event.target;
      const dataTestId = target.dataset.testid;
      const customMessage = `[data-testid="${dataTestId}"]${breadcrumb.message}`;
      breadcrumb.message = dataTestId ? customMessage : breadcrumb.message;
    }
    return breadcrumb;
  },
  beforeSend(event) {
    const hasAppId = window.location.pathname.includes('apps')
    const appId = hasAppId ? window.location.pathname.split('/')[2]: undefined
    const state = store.getState()
    const currentApp = state.apps.currentApp
    const currentAppName = currentApp?.id === appId ? currentApp?.name : undefined

    event.tags = {
      app_id: appId,
      app_name: currentAppName
    };
    return event;
  }
};
