
export const legacyPermissionsToRBACMapper = {
  APPS_READ: 'app:read',
  APPS_WRITE: 'app:new',
  PLAN_READ: 'app_plan:read',
  PLAN_WRITE: 'app_plan:write',
  WIDGETS_READ: 'app_widgets:read',
  WIDGETS_WRITE: 'app_widgets:write',
  CREDENTIALS_READ: 'app_credentials:read',
  CREDENTIALS_WRITE: 'app_credentials:write',
  INFO_READ: 'app_info:read',
  CAMPAIGNS_READ: 'app_campaigns:read',
  CAMPAIGNS_WRITE: {
    WRITE: 'app_campaigns:write',
    DELETE: 'app_campaigns:delete',
  },
  CONTRACTS_READ: 'app_contracts:read',
  CONTRACTS_WRITE: {
    WRITE: 'app_contracts:write',
    DELETE: 'app_contracts:delete',
  },
  INFO_WRITE: 'app_info:write',
  INFO_DELETE: 'app_info:delete',
  EMAIL_TEMPLATE_READ: 'app_email_template:read',
  EMAIL_TEMPLATE_WRITE: 'app_email_template:write',
  ASSIGNEES_WRITE: {
    WRITE: 'assignees:write',
    DELETE: 'assignees:delete',
    NEW: 'assignees:new',
  },
  ASSIGNEES_READ: 'assignees:read',
  EQUIPMENTS_WRITE: {
    WRITE: 'equipments:write',
    DELETE: 'equipments:delete',
    NEW: 'equipments:new',
  },
  EQUIPMENTS_READ: 'equipments:read',
  ORG_WRITE: {
    WRITE: 'organization:write',
    DELETE: 'organization:delete',
    NEW: 'organization:new',
  },
  ORG_ACCESS: 'organization:read',
  ORG_READ: 'organization:read',
  PRODUCTS_WRITE: {
    WRITE: 'products:write',
    DELETE: 'products:delete',
    NEW: 'products:new',
  },
  PRODUCTS_READ: 'products:read',
  PROPERTIES_WRITE: 'property:new',
  PROPERTIES_READ: 'property:read',
  USERS_READ: 'members:read',
  USERS_WRITE: {
    NEW: 'members:new',
    WRITE: 'members:write',
    DELETE: 'members:delete',
  },
  FIELDS_WRITE: {
    WRITE: 'fields:write',
    DELETE: 'fields:delete',
    NEW: 'fields:new',
  },
  FIELDS_READ: 'fields:read',
  INFORMATION_WRITE: {
    DELETE: 'property:delete',
    WRITE: 'property:write',
  },
  DEV_READ: 'dev_console:read',
  PROPERTY_ACCESS: 'property:read',
} as any;
