import { initRollout } from 'app-redux/actions/rollout.actions';
import { getCurrentUser } from 'app-redux/selectors/accounts.selector';
import { IUserAccount } from 'core/accounts/models';
import { isAccessTokenAvailable } from 'core/common-methods';
import { history } from 'core/history';
import { ThemeProvider } from 'core/theme';
import get from 'lodash/get';
import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Router, Switch } from 'react-router';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { SentryRoute } from 'sentry.config';
import './main.less';
export const StyledRoot = styled.div`
  font-family: ${props => get(props, 'theme.font.family')};
  height: 100%;
  min-height: 100%;
  * {
    box-sizing: border-box;
  }
`;
interface IAppComponentProps {
  currentUser?: IUserAccount;
  initRollout?: (configs: any) => Promise<any>;
}
const AsyncMainContainer = React.lazy(() => import('page-layout/main-container'));
export const App: React.FC<IAppComponentProps> = props => {
  useEffect(() => {
    if (isAccessTokenAvailable() && props.currentUser && props.initRollout) {
      const {
        id
      } = props.currentUser;
      props.initRollout({
        userContext: {
          id
        }
      });
    }
  }, [props.currentUser]);
  return <ThemeProvider data-sentry-element="ThemeProvider" data-sentry-component="App" data-sentry-source-file="App.tsx">
      <StyledRoot data-sentry-element="StyledRoot" data-sentry-source-file="App.tsx">
        <Router history={history} data-sentry-element="Router" data-sentry-source-file="App.tsx">
          <Suspense fallback={<></>} data-sentry-element="Suspense" data-sentry-source-file="App.tsx">
            <Switch data-sentry-element="Switch" data-sentry-source-file="App.tsx">
              <SentryRoute path="/" component={AsyncMainContainer} data-sentry-element="SentryRoute" data-sentry-source-file="App.tsx" />
            </Switch>
          </Suspense>
        </Router>
      </StyledRoot>
    </ThemeProvider>;
};
const mapStateToProps = (state: any) => {
  return {
    currentUser: getCurrentUser(state)
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  initRollout: (configs: any) => dispatch(initRollout(configs))
});
export default connect(mapStateToProps, mapDispatchToProps)(Sentry.withProfiler(App));