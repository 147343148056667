import React, { ErrorInfo } from 'react';
class ErrorBoundary extends React.Component<{}, {
  hasError: boolean;
}> {
  public static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  public componentDidCatch(error: Error, info: ErrorInfo) {
    // tslint:disable-next-line:no-console
    console.error(error, info);
  }
  public render() {
    if (this.state.hasError) {
      return <h1 data-sentry-component="ErrorBoundary" data-sentry-source-file="index.tsx">Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
export { ErrorBoundary };