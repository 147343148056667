import { Form } from 'antd';
import { AppConstants } from 'core/app.constants';
import React, { useState } from 'react';
import { Select, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import '../plans-form/style.less';
const {
  Text
} = Typography;
const {
  Option
} = Select;
export const LocationSelectComponent = (props: any) => {
  const [isAllLocationsSelected, setIsAllLocationsSelected] = useState<boolean>(false);
  const availableLocations = AppConstants.SUPPORTED_COUNTRIES.concat({
    value: 'all',
    name: '-- All locations --'
  }).sort((a, b) => a.name > b.name ? 1 : -1);
  const availableLocationKeys = AppConstants.SUPPORTED_COUNTRIES.map((language: any) => language.value);
  const onChangeLocation = (value: string[]) => {
    if (value.find((v: string) => v.includes('all'))) {
      if (!isAllLocationsSelected) {
        setIsAllLocationsSelected(true);
        return availableLocationKeys;
      } else {
        setIsAllLocationsSelected(false);
        return [];
      }
    } else {
      return value;
    }
  };
  return <div data-testid="plan-location-select-container" data-sentry-component="LocationSelectComponent" data-sentry-source-file="index.tsx">
      <Form.Item getValueFromEvent={onChangeLocation} name="location" label={<Text strong={true} style={{
      fontSize: '16px',
      color: '#2F3031'
    }}>Location</Text>} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <Select data-testid="location-select" mode="multiple" style={{
        width: '470px'
      }} placeholder="Select locations" onChange={onChangeLocation} disabled={!props.canChangeLocation} data-sentry-element="Select" data-sentry-source-file="index.tsx">
          {availableLocations.map((country: any) => <Option key={country.value} value={country.value}>{country.name}</Option>)}
        </Select>
      </Form.Item>
    </div>;
};