import { AppState } from 'app-redux/reducers';
import { RbacState } from 'app-redux/reducers/rbac.reducer';
import { IUserAccount } from 'core/accounts/models';
import { legacyPermissionsToRBACMapper } from 'core/legacyPermissionsToRBAC';
import { OperationDTO } from 'core/rbac/models';
import { UUID } from 'core/utils/BasicModels';
import { createSelector } from 'reselect';
import { getCurrentUser } from './accounts.selector';

export const getRbacState = (state: AppState) => state.rbac || {} as RbacState;

export const getAllowedAndDeniedOperationsDTO = createSelector(getRbacState,
  (rbacState) => rbacState.allowedAndDeniedOperationsDTO);

export const getDevConsoleAccess = createSelector(getRbacState,
  (rbacState) => rbacState.devConsoleAccess);

export const rbacPermissionsCheck = (state: AppState, id: UUID, context: string, permission: string, scope: string, isDevConsoleCheck = false) => {
  const currentUser = getCurrentUser(state) as IUserAccount;
  const legacyToRBACMapper = `${permission}_${scope}`;
  const operationAction = legacyPermissionsToRBACMapper[legacyToRBACMapper];
  const operationResource = (context === 'ORGANIZATION') ? 'crn:system' : `crn:${context.toLowerCase()}:${id}`;
  const allowedAndDeniedOperationsDTO = isDevConsoleCheck ? getDevConsoleAccess(state) : getAllowedAndDeniedOperationsDTO(state);
  if (allowedAndDeniedOperationsDTO) {
    return (allowedAndDeniedOperationsDTO?.account_id === currentUser.id &&
      !!allowedAndDeniedOperationsDTO.allowed_operations.find((operation: OperationDTO) => operation.action === operationAction && operation.resource === operationResource));
  }
  return false;
};
