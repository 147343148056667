import { matchPath } from 'react-router';
import { createSelector } from 'reselect';
import { history } from '../../core/history';
import { AppState } from '../reducers';
import { App, AppsState } from '../reducers/apps.reducer';

export const getAppsState = (state: AppState) => state.apps || {} as AppsState;

export const getAppList = createSelector(getAppsState,
  (appsState) => Array.from(appsState.pagedApp?.content || []));

export const getCurrentAppFromState = (appsState: AppsState) => appsState.currentApp;

const appsMapToList = (appsState: AppsState) => appsState.pagedApp;

export const hasLoadedApps = createSelector(getAppsState,
    (appsState: AppsState) => {
      return !!appsMapToList(appsState)?.apps?.length;
    },
);

export const getCurrentAppLoadingStatus = createSelector(getAppsState,
    (appsState) => !!appsState.loading);

const getCurrentAppIdFromState = (appsState: AppsState) => appsState.currentAppId;

const getCurrentAppIdFromPath = (path: string) => {
  const pathParams = matchPath(history.location.pathname, {
        path: [
            '/orgs/:orgId/apps/:appId/*',
        ],
        exact: true,
        strict: true,
    })?.params as { appId?: string };

  return (pathParams && pathParams.appId) ? pathParams.appId : undefined;
};

export const getCurrentApp = createSelector(getAppsState,
    (appsState: AppsState) => {
      const appsList = appsState.pagedApp?.content || [];
      const stateAppId = getCurrentAppIdFromState(appsState);
      const pathAppId = getCurrentAppIdFromPath(history.location.pathname);
      const currentAppId = pathAppId || stateAppId;
      const currentApp = appsList.find((app) => app.id === currentAppId) || getCurrentAppFromState(appsState);
      return currentApp || null;
    });

export const getAvatarHasUpdated = createSelector(getAppsState, (appsState: AppsState) => appsState.avatarHasUpdated);
