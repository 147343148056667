import { Skeleton, Table } from 'antd';
import { App } from 'app-redux/reducers/apps.reducer';
import leafImage from 'assets/images/cropwise-leaf.svg';
import { isUserUsingRBAC } from 'core/common-methods';
import { history, RoutePaths } from 'core/history';
import React from 'react';
import './index.less';
interface IappsTable {
  isLoadingApp: boolean;
  paged: {
    pageNumber: number;
    pageSize: number;
    total: number;
    appList: App[];
  };
  checkCurrentUserPermission: (contextId: string, context: string, permission: string, scope: string) => boolean;
  setCurrentApp: (id: string) => any;
  onChange: (pagination: any, filters: any, sorter: any, extra: any) => Promise<void>;
  onRowClick: (record: any) => Promise<void>;
}
export const AppsTable: React.FC<IappsTable> = (props: IappsTable) => {
  const columns = [{
    title: '',
    width: 100,
    dataIndex: 'image',
    key: 'image',
    // tslint:disable-next-line:variable-name
    render: (text: any, record: any) => <div className="cw-app-icon-image-placeholder">
        <img className="cw-app-icon-image" alt={`app icon ${record.id}`} src={`${record.icon_uri}?t=${new Date().getTime()}`} onError={({
        currentTarget
      }) => {
        currentTarget.onerror = null;
        currentTarget.src = leafImage;
      }} />
        </div>
  }, {
    title: 'Name',
    width: 300,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    dataIndex: 'name',
    key: 'name',
    render: (text: string) => <a style={{
      color: '#14151C'
    }}>{text}</a>
  }, {
    title: 'Description',
    width: 300,
    dataIndex: 'short_description',
    key: 'short_description',
    render: (text: string) => <div onClick={e => {
      e.stopPropagation();
    }}>
                  {text ? text : '--'}
              </div>
  }, {
    title: 'Site',
    width: 300,
    sorter: (a: any, b: any) => a.site_uri.localeCompare(b.site_uri),
    dataIndex: 'site_uri',
    key: 'site_uri',
    render: (text: string) => <div onClick={e => {
      e.stopPropagation();
    }}>
                  {text}
              </div>
  }, {
    title: 'Status',
    width: 300,
    dataIndex: 'published',
    key: 'published',
    render: (text: string) => <div onClick={e => {
      e.stopPropagation();
    }}>
                  {text ? 'Published' : 'Not published'}
              </div>
  }, {
    title: 'Action',
    width: 300,
    dataIndex: 'action',
    key: 'action',
    // tslint:disable-next-line:variable-name
    render: (_text: any, record: any, _index: any) => {
      const canViewThisApp = isUserUsingRBAC() || props.checkCurrentUserPermission(record.id, 'APP', 'INFO', 'READ');
      return <div className="cw-app-actions">
            {canViewThisApp && <a className="cw-app-edit" onClick={async e => {
          e.stopPropagation();
          const currentApp = record;
          if (currentApp.id) {
            await props.setCurrentApp(currentApp.id);
          }
          history.push({
            pathname: RoutePaths.APP_DETAILS(currentApp.id),
            state: {
              currentApp
            }
          });
        }}>
                  Details
                </a>}
          </div>;
    }
  }];
  return <div className="cw-app-table" data-testid="apps-table" data-sentry-component="AppsTable" data-sentry-source-file="index.tsx">
      {props.isLoadingApp ? <Skeleton loading={props.isLoadingApp} active={true} avatar={true} /> : <Table onRow={record => {
      return {
        onClick: () => props.onRowClick(record)
      };
    }} rowKey={record => record.id} pagination={{
      pageSize: props.paged.pageSize,
      current: props.paged.pageNumber,
      total: props.paged.total
    }} columns={columns} dataSource={props.paged.appList} onChange={props.onChange} />}
    </div>;
};