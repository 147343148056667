import React, { useCallback, useContext, useState } from 'react';
import { PreviewSectionTitle } from './preview-section-title';
import { Button, Upload, UploadFile, UploadProps, message } from 'antd';
import { UploadImageIcon } from 'pages/integrations/images/UploadImageIcon';
import styled from 'styled-components';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { IntegrationsFormContext } from './integrations-form';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { LoadingOutlined } from '@ant-design/icons';
import { AttachFileIcon } from 'pages/integrations/images/AttachFileIcon';
import { CloseIcon } from 'pages/integrations/images/CloseIcon';
import { FlexContainer } from 'pages/integrations/styled';
const UploadButton = styled(Button)`
  display: flex;
  align-items: center;
  transition: 300ms;
  color: #fff;
  border-color: #fff;

  svg {
    path {
      transition: 300ms;
    }
    margin-right: 4px;
  }

  &:hover,
  &:focus {
    color: #31b4f2;
    border-color: #31b4f2;

    svg path {
      fill: #31b4f2;
    }
  }
`;
const beforeUpload = async (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    await message.error('You can only upload JPG/PNG file!');
  }
  const isLt3M = file.size / 1024 / 1024 <= 3;
  if (!isLt3M) {
    await message.error('Image must smaller than 3MB!');
  }
  return isJpgOrPng && isLt3M;
};
const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback((reader.result as string)));
  reader.readAsDataURL(img);
};
export const IntegrationBackgroundImg = () => {
  const {
    values: {
      image
    },
    setValues
  } = useContext(IntegrationsFormContext);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState<string>(image);
  const [imageUrl, setImageUrl] = useState<string>(image);
  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    setFileName(info.file.name);
    setLoading(info.file.status === 'uploading' ? true : false);
  };
  const handleUpload = useCallback(({
    onSuccess,
    file
  }: UploadRequestOption) => {
    getBase64((file as RcFile), url => {
      setImageUrl(url);
      setValues('image', url);
    });
    onSuccess?.('Ok');
  }, [setValues]);
  const renderLoadingOrDefault = useCallback(() => {
    if (loading) return <LoadingOutlined spin={true} style={{
      color: '#fff'
    }} />;else return <UploadButton size="large" icon={<UploadImageIcon />} type="ghost">
          Upload background image
        </UploadButton>;
  }, [loading]);
  return <>
      <PreviewSectionTitle title="Background Image" showInfo={true} data-sentry-element="PreviewSectionTitle" data-sentry-source-file="integration-background-image.tsx" />
      <p style={{
      margin: '12px 0 20px',
      fontSize: 12,
      color: '#C2C7D0'
    }}>
        Upload an image to be used as the background image for this integration, or the default
        image that you are seeing as the background here will be used.
      </p>
      <Upload customRequest={handleUpload} beforeUpload={beforeUpload} showUploadList={false} onChange={handleChange} style={{
      width: '100%'
    }} data-sentry-element="Upload" data-sentry-source-file="integration-background-image.tsx">
        {imageUrl && !loading ? <FilenameWrap>
            <AttachFileIcon />
            <span>{fileName}</span>
            <div style={{
          height: 16
        }} onClick={ev => {
          ev.stopPropagation();
          setImageUrl('');
          setFileName('');
          setValues('image', '');
        }}>
              <CloseIcon />
            </div>
          </FilenameWrap> : renderLoadingOrDefault()}
      </Upload>
    </>;
};
const FilenameWrap = styled(FlexContainer)`
  color: #005693;
  background-color: #eaf6ff;
  padding: 1px 4px 1px 4px;
  border-radius: 4px;
  gap: 4px;
  width: 306px;
  cursor: pointer;

  span {
    flex: 1;
    max-width: 256px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span + div svg {
    transition: 200ms;

    &:hover {
      opacity: 0.8;
    }
  }
`;