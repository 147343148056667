import { AxiosResponse } from 'axios';
import { AllowedAndDeniedOperationsDTO, OperationDTO, OperationListDTO } from 'core/rbac/models';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import { Dispatch } from 'redux';
import { axiosRequest } from '../../core/axios';
import { getHeaders } from '../../core/common-methods';

export const ACTION_VERIFY_RBAC_PERMISSIONS: RequestAction = createRequestAction('VERIFY_RBAC_PERMISSIONS_REQUEST');
export const ACTION_DISPATCH_DEV_CONSOLE_ACCESS = 'ACTION_DISPATCH_DEV_CONSOLE_ACCESS';

export const verifyRbacPermissions = (operationListDTO: OperationListDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_VERIFY_RBAC_PERMISSIONS.REQUEST });

    const allowedAndDeniedOperationsDTO = await axiosRequest
            .post<any, AxiosResponse<AllowedAndDeniedOperationsDTO>>(`/v2/auth/verify`, operationListDTO,
      {
        headers: {
          ...getHeaders().common,
        },
      });

    const responseData = allowedAndDeniedOperationsDTO.data;

    const hasCheckedForDevConsoleAccess = operationListDTO.operations.find((operation: OperationDTO) => operation.action === 'dev_console:read');
    if (hasCheckedForDevConsoleAccess) {
      dispatch({ type: ACTION_DISPATCH_DEV_CONSOLE_ACCESS, payload: responseData });
    } else {
      dispatch({ type: ACTION_VERIFY_RBAC_PERMISSIONS.SUCCESS, payload: responseData });
    }
    return responseData;

  } catch (e) {
    dispatch({ type: ACTION_VERIFY_RBAC_PERMISSIONS.ERROR, payload: e });
    throw e;
  }
};
