import leafImage from 'assets/images/cropwise-leaf.svg';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import './style.less';
const {
  Text
} = Typography;
const Label = styled(Text)`
  display: block;
  color: #2F3031;
  font-size: 16px;
  font-weight: 400;
`;
interface ITechnologyCardProps {
  name: string;
  selected: boolean;
  onClick: () => void;
}
export const TechnologyCard: React.FC<ITechnologyCardProps> = props => <div onClick={props.onClick} className={classNames('cw-technology-card', {
  'cw-technology-card-selected': props.selected
})} data-sentry-component="TechnologyCard" data-sentry-source-file="index.tsx">
        <img src={leafImage} className={'cw-technology-image-placeholder'} />
        <Label data-sentry-element="Label" data-sentry-source-file="index.tsx">
            {props.name}
        </Label>
    </div>;