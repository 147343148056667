import { Select, Tooltip } from 'antd';
import { InfoIcon } from 'pages/integrations/images/InfoIcon';
import { FlexContainer } from 'pages/integrations/styled';
import React, { useContext } from 'react';
import { IntegrationsFormContext } from './integrations-form';
import styled from 'styled-components';
import { ArrowDownIcon } from 'pages/integrations/images/ArrowDownIcon';
import { IntegrationAvailableLanguages, languages } from 'pages/integrations/utils/types';
const StyledSelect = styled(Select)`
  width: 132px;
  border-radius: 4px;
  color: #fff;
  margin-left: 4px;

  background-color: #232630;
`;
export const IntegrationLanguageDropdown = () => {
  const {
    values: {
      description
    },
    language: {
      value: selectedLanguage,
      set: setLanguage
    }
  } = useContext(IntegrationsFormContext);
  const isDisabled = description && Object.values(description).length === 0 || !description;
  return <FlexContainer style={{
    margin: '4px 4px 0'
  }} data-sentry-element="FlexContainer" data-sentry-component="IntegrationLanguageDropdown" data-sentry-source-file="integration-language-dropdown.tsx">
      <Tooltip title={`${isDisabled ? 'Add Short Description and s' : 'S'}elect the language to see the preview`} color="#fff" overlayInnerStyle={{
      color: '#14151C',
      borderRadius: 4,
      width: isDisabled ? 250 : 150,
      textAlign: 'center'
    }} data-sentry-element="Tooltip" data-sentry-source-file="integration-language-dropdown.tsx">
        <FlexContainer data-sentry-element="FlexContainer" data-sentry-source-file="integration-language-dropdown.tsx">
          <InfoIcon data-sentry-element="InfoIcon" data-sentry-source-file="integration-language-dropdown.tsx" />
        </FlexContainer>
      </Tooltip>
      <StyledSelect placeholder="Language" disabled={isDisabled} popupClassName="integrations-select-dropdown" value={description[selectedLanguage] ? selectedLanguage : undefined} size="small" bordered={false} onChange={newValue => setLanguage((newValue as IntegrationAvailableLanguages))} options={languages.map(opt => ({
      ...opt,
      disabled: !description[opt.value]
    }))} suffixIcon={<ArrowDownIcon />} data-sentry-element="StyledSelect" data-sentry-source-file="integration-language-dropdown.tsx" />
    </FlexContainer>;
};