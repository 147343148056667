import { CheckCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
interface IPlanFormSuccessModalProps {
  successText: string;
  handleClose: any;
  isVisible: boolean;
}
export const PlanFormSuccessModal: React.FC<IPlanFormSuccessModalProps> = props => {
  return <Modal open={props.isVisible} onOk={() => props.handleClose('success')} onCancel={() => props.handleClose('success')} width="384px" footer={[<Button key={1} type={ButtonType.primary} size="large" onClick={() => props.handleClose('success')}>
                            Ok
                        </Button>]} data-sentry-element="Modal" data-sentry-component="PlanFormSuccessModal" data-sentry-source-file="index.tsx">
                <div className="modal-placeholder">
                    <CheckCircleFilled className="modal-success" data-sentry-element="CheckCircleFilled" data-sentry-source-file="index.tsx" />
                    <p style={{
        paddingTop: '20px'
      }}>{props.successText}</p>
                </div>
            </Modal>;
};