import { AxiosResponse } from 'axios';
import { TermsDTO } from 'core/terms/models';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import qs from 'querystring';
import { Dispatch } from 'redux';
import { axiosRequest } from '../../core/axios';
import { getHeaders } from '../../core/common-methods';

export const ACTION_GET_ALL_TERMS_LIST: RequestAction = createRequestAction('GET_ALL_TERMS_LIST_REQUEST');

export const fetchAllTerms = (pageSize: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_GET_ALL_TERMS_LIST.REQUEST });

    const pageInfo = {
      size: pageSize,
    };

    const allTerms = await axiosRequest
        .get<any, AxiosResponse<TermsDTO[]>>(`/v2/terms/all?${qs.stringify(pageInfo)}`,
      {
        headers: getHeaders().common,
      });

    dispatch({ type: ACTION_GET_ALL_TERMS_LIST.SUCCESS, payload: allTerms.data });
    return allTerms.data;
  } catch (e) {
    dispatch({ type: ACTION_GET_ALL_TERMS_LIST.ERROR, payload: e });
    throw e;
  }
};