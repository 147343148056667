import { history, RoutePaths } from 'core/history';
import { ApiClientCreated } from 'pages/api-client-created';
import { ApiClientDetailsComponent } from 'pages/api-client-details';
import { AppCreated } from 'pages/app-created';
import { AppDetailsComponent } from 'pages/app-details';
import { Apps } from 'pages/apps';
import { AuthenticationRedirectComponent } from 'pages/authentication-redirect-component';
import { CreateAppComponent } from 'pages/create-app';
import { CredentialsComponent } from 'pages/credentials';
import { IntegrationsPage } from 'pages/integrations';

// Licensing V2
import { EditPlan } from 'pages/licensing-v2/edit-plan';
import { ListPlans } from 'pages/licensing-v2/list-plans';
import { PageNotFound } from 'pages/page-not-found';
import { SDKsOverview } from 'pages/sdk-overview';
import { TeamManagement } from 'pages/team-management';
import { UiKit } from 'pages/ui-kit';
import { Widgets } from 'pages/widgets';
import React from 'react';
import { Router, Switch } from 'react-router-dom';
import { SentryRoute } from 'sentry.config';
export const MainRoutes = (props: any) => {
  return <Router history={history} data-sentry-element="Router" data-sentry-component="MainRoutes" data-sentry-source-file="index.tsx">
        <Switch data-sentry-element="Switch" data-sentry-source-file="index.tsx">
          <SentryRoute path={RoutePaths.HOME()} exact={true} component={Apps} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.APPS()} exact={true} component={Apps} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.AUTH()} exact={true} component={AuthenticationRedirectComponent} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.CREATE_APP()} exact={true} component={CreateAppComponent} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.APP_CREATED()} exact={true} component={AppCreated} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.CREATE_API_CLIENT()} exact={true} component={ApiClientDetailsComponent} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.APP_DETAILS()} exact={true} component={AppDetailsComponent} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.API_CLIENT_DETAILS()} exact={true} component={ApiClientDetailsComponent} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.API_CLIENT_CREATED()} exact={true} component={ApiClientCreated} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.CREDENTIALS()} exact={true} component={CredentialsComponent} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.WIDGETS_PAGE()} exact={true} component={Widgets} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.TEAM_PAGE()} exact={true} component={TeamManagement} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.SDK_OVERVIEW()} exact={true} component={SDKsOverview} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.UI_KIT()} exact={true} component={UiKit} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.LICENSING_PAGE()} exact={true} component={ListPlans} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.NEW_LICENSE_PLAN()} exact={true} component={EditPlan} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.LICENSE_PLAN_DETAILS()} exact={true} component={EditPlan} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute path={RoutePaths.INTEGRATIONS()} exact={true} component={IntegrationsPage} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
          <SentryRoute component={PageNotFound} data-sentry-element="SentryRoute" data-sentry-source-file="index.tsx" />
        </Switch>
      </Router>;
};
export default MainRoutes;