import React, { useContext } from 'react';
import { CountryCode, CountryMap, countries } from '../../utils/contants';
import { IntegrationsContext } from '../..';
import { CustomSelect } from 'components/custom-select';
const options = countries.map(c => ({
  label: `${c.flag} ${c.name}`,
  value: c.code
}));
export const IntegrationsCountryFilter = () => {
  const {
    countriesFiltered,
    onFilterCountries
  } = useContext(IntegrationsContext);
  return <CustomSelect<CountryCode> width={256} multiple={true} dark={false} options={options} value={countriesFiltered} allSelectedLabel="All Countries" onApply={values => onFilterCountries(values)} placeholder="Search Countries" formatter={codes => codes.map(code => CountryMap[code]).join(', ')} data-sentry-element="CustomSelect" data-sentry-component="IntegrationsCountryFilter" data-sentry-source-file="integrations-country-filter.tsx" />;
};