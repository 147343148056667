import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { Tooltip, Upload, UploadFile, UploadProps, message } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { DeleteIcon } from 'pages/integrations/images/DeleteIcon';
import { EditIcon } from 'pages/integrations/images/EditIcon';
import { InfoIcon } from 'pages/integrations/images/InfoIcon';
import { FlexContainer } from 'pages/integrations/styled';
import React, { MouseEvent, useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { IntegrationsFormContext } from './integrations-form';
message.config({
  prefixCls: 'syngenta-ant-message'
});
const Container = styled.div`
  width: 145px;
  margin-right: 12px;
  flex-shrink: 0;
`;
const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback((reader.result as string)));
  reader.readAsDataURL(img);
};
const beforeUpload = async (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    await message.error('You can only upload JPG/PNG file!');
  }
  const isLt1M = file.size / 1024 / 1024 <= 1;
  if (!isLt1M) {
    await message.error('Image must smaller than 1MB!');
  }
  return isJpgOrPng && isLt1M;
};
export const IntegrationLogo = () => {
  const {
    values: {
      icon
    },
    setValues
  } = useContext(IntegrationsFormContext);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>(icon);
  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    setLoading(info.file.status === 'uploading' ? true : false);
  };
  const handleUpload = useCallback(({
    onSuccess,
    file
  }: UploadRequestOption) => {
    getBase64((file as RcFile), url => {
      setImageUrl(url);
      setValues('icon', url);
    });
    onSuccess?.('Ok');
  }, [setValues]);
  return <Container data-sentry-element="Container" data-sentry-component="IntegrationLogo" data-sentry-source-file="integration-logo.tsx">
      <FlexContainer justify="space-between" data-sentry-element="FlexContainer" data-sentry-source-file="integration-logo.tsx">
        <p style={{
        margin: 0,
        lineHeight: '24px'
      }}>Integration Logo</p>
        <Tooltip title={'The file must be JPG/PNG and smaller than 2MB.'} color="#fff" overlayInnerStyle={{
        color: '#14151C',
        borderRadius: 4,
        width: 190,
        textAlign: 'center'
      }} data-sentry-element="Tooltip" data-sentry-source-file="integration-logo.tsx">
          <FlexContainer data-sentry-element="FlexContainer" data-sentry-source-file="integration-logo.tsx">
            <InfoIcon data-sentry-element="InfoIcon" data-sentry-source-file="integration-logo.tsx" />
          </FlexContainer>
        </Tooltip>
      </FlexContainer>
      <Upload customRequest={handleUpload} beforeUpload={beforeUpload} showUploadList={false} onChange={handleChange} style={{
      width: '100%'
    }} data-testid="integration-upload-logo" data-sentry-element="Upload" data-sentry-source-file="integration-logo.tsx">
        {imageUrl && !loading ? <ImageWrap>
            <div className="mask">
              <ActionBtn className="edit">
                <EditIcon />
              </ActionBtn>
              <Tooltip title={'Delete logo'} color="#fff" overlayInnerStyle={{
            color: '#14151C',
            borderRadius: 4,
            textAlign: 'center'
          }}>
                <ActionBtn className="delete" onClick={(ev: MouseEvent<HTMLDivElement>) => {
              ev.stopPropagation();
              setImageUrl('');
              setValues('icon', '');
            }}>
                  <DeleteIcon />
                </ActionBtn>
              </Tooltip>
            </div>
            <img src={imageUrl} />
          </ImageWrap> : <UploadWrap direction="column">
            {loading ? <LoadingOutlined spin={true} style={{
          color: '#fff'
        }} /> : <>
                <PlusOutlined />
                <span style={{
            lineHeight: '22px'
          }}>Upload Logo</span>
              </>}
          </UploadWrap>}
      </Upload>
    </Container>;
};
const ActionBtn = styled(FlexContainer)`
  position: absolute;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  transition: 300ms;

  &:hover {
    background-color: #f3f4f6;
  }
`;
const ImageWrap = styled(FlexContainer)`
  width: 145px;
  height: 60px;
  background-color: #fff;
  border-radius: 4px;
  position: relative;

  img {
    margin: 0 auto;
    max-height: 100%;
    width: auto;
    max-width: 100%;
  }

  .mask {
    transition: opacity 200ms ease;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .delete {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 4px;
    bottom: 4px;
  }

  .edit {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    .mask {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;
const UploadWrap = styled(FlexContainer)`
  width: 145px;
  height: 60px;
  border: 1px dashed #696f88;
  background-color: #232630;
  padding: 8px;
  border-radius: 4px;
  color: #868ca2;
  transition: 300ms;
  cursor: pointer;

  &:hover {
    border-color: #fff;
    color: #fff;
  }
`;