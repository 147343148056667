import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { getAccessToken, getCurrentEnv } from 'core/common-methods';
import { CWEnv } from 'core/utils/BasicModels';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import MainApp from './App';
import { store } from './app-redux/store';
import { AppLoader } from './core/AppLoader';
import { axiosRequest } from './core/axios';
import { initCWElements } from '@cw-elements/config';
import * as Sentry from "@sentry/react";
import { SENTRY_CONFIG, SENTRY_KEY } from 'sentry.config';
import { isLocal } from 'app.constant';
if (!isLocal && SENTRY_KEY) {
  Sentry.init(SENTRY_CONFIG);
}
const initClients = () => {
  let tokens;
  try {
    tokens = JSON.parse(getAccessToken());
  } catch (ignored) {
    tokens = null;
  }
  initCWElements({
    environment: (getCurrentEnv() as CWEnv),
    axiosInstance: axiosRequest,
    token: tokens?.access_token
  });
};
initClients();
(async function loadAppLoader() {
  const myapp = new AppLoader();
  const isRedirecting = await myapp.load();
  if (!isRedirecting) {
    ReactDOM.render(<Sentry.ErrorBoundary showDialog>
        <Provider store={store}>
          <MainApp />
        </Provider>
      </Sentry.ErrorBoundary>, document.getElementById('root'));
  }
})();
ReactDOM.render(<div />, document.getElementById('root'));