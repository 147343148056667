import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { FlexContainer } from '../../styled';
import { IntegrationsContext } from '../../';
import { Input } from 'antd';
import { IntegrationsCountryFilter } from './integrations-country-filter';
const MainContainer = styled.div`
  padding: 24px;
`;
const Title = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #14151c;
  margin: 0;
`;
const StyledInput = styled(Input)`
  border-radius: 4px;
  margin-right: 8px;

  [class*='-input-prefix'] {
    margin: 0 8px 0 -2px;
  }

  &:hover {
    border: 1px solid #0092e4 !important;
  }
`;
const ClearBtn = styled.button`
  color: #a3a9b9;
  margin-left: 18px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: color 300ms ease-in-out;

  :hover {
    color: #848997;
  }
`;
export const IntegrationsHeader = ({
  onAdd
}: {
  onAdd: () => void;
}) => {
  const {
    nameFiltered,
    onSearch,
    clearFilters
  } = useContext(IntegrationsContext);
  const [search, setSearch] = useState<string>(nameFiltered);
  useEffect(() => {
    setSearch(nameFiltered);
  }, [nameFiltered]);
  return <MainContainer id="integrations-header" data-testid="integrations-header" data-sentry-element="MainContainer" data-sentry-component="IntegrationsHeader" data-sentry-source-file="integrations-header.tsx">
      <FlexContainer justify="space-between" style={{
      marginBottom: 24
    }} data-sentry-element="FlexContainer" data-sentry-source-file="integrations-header.tsx">
        <Title data-sentry-element="Title" data-sentry-source-file="integrations-header.tsx">Integrations</Title>
        <Button type={ButtonType.primary} size="large" icon={<PlusOutlined />} onClick={onAdd} data-testid="integrations-add-btn" data-sentry-element="Button" data-sentry-source-file="integrations-header.tsx">
          Add integration
        </Button>
      </FlexContainer>
      <FlexContainer justify="flex-start" data-sentry-element="FlexContainer" data-sentry-source-file="integrations-header.tsx">
        <StyledInput prefix={<SearchOutlined />} onChange={ev => {
        setSearch(ev.target.value);
        onSearch(ev.target.value);
      }} placeholder={'Search name'} style={{
        width: 276
      }} size="large" value={search} data-sentry-element="StyledInput" data-sentry-source-file="integrations-header.tsx" />
        <IntegrationsCountryFilter data-sentry-element="IntegrationsCountryFilter" data-sentry-source-file="integrations-header.tsx" />
        <ClearBtn onClick={() => {
        setSearch('');
        clearFilters();
      }} data-sentry-element="ClearBtn" data-sentry-source-file="integrations-header.tsx">
          Clear Filters
        </ClearBtn>
      </FlexContainer>
    </MainContainer>;
};