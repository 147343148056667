import { Layout } from 'antd';
import { INavigationRouteProps } from 'core/navigation/models';
import { UUID } from 'core/utils/BasicModels';
import React from 'react';
import { Button, ButtonType, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import { history, RoutePaths } from '../../core/history';
import './style.less';
const {
  Content
} = Layout;
const {
  Text,
  Paragraph
} = Typography;
interface ApiClientState {
  apiClientCreated: {
    id: UUID;
    app_id: UUID;
    created_at: Date;
    last_access: Date;
    revoked: boolean;
    name: string;
    redirect_uris: string[];
    client_id: string;
    client_secret: string;
  };
}
type ICreateApiProps = INavigationRouteProps<ApiClientState>;
export const ApiClientCreated: React.FC<ICreateApiProps> = props => {
  return <>
            <Content className="cw-content-container" data-sentry-element="Content" data-sentry-source-file="index.tsx">
                <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '50px'
      }}>
                    <Text style={{
          fontSize: '28px'
        }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Your API Client has been created</Text>
                </div>

                    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '60px'
      }}>
                        <Text style={{
          fontSize: '16px'
        }} data-sentry-element="Text" data-sentry-source-file="index.tsx">An API Client was generated for your app</Text>
                        <Text style={{
          fontSize: '16px'
        }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Copy and save the data below, you won't be able to see the secret again without resetting your client.</Text>
                    </div>
                    <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '80px 0 0 30px'
      }}>
                        <div className={'cw-app-created-client-container'}>
                            <Text style={{
            fontSize: '14px'
          }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Client ID</Text>
                            <div className={'cw-api-created-copy-container'}>
                                <Paragraph copyable={true} data-sentry-element="Paragraph" data-sentry-source-file="index.tsx">{props.location.state.apiClientCreated.client_id}</Paragraph>
                            </div>
                        </div>
                        {props.location.state.apiClientCreated.client_secret ? <div className={'cw-app-created-client-container'}>
                                    <Text style={{
            fontSize: '14px'
          }}>Client Secret</Text>
                                    <div className={'cw-api-created-copy-container'}>
                                        <Paragraph copyable={true}>{props.location.state.apiClientCreated.client_secret}</Paragraph>
                                    </div>
                                </div> : <></>}

                    </div>
                    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '100px'
      }}>
                    <Button type={ButtonType.default} size="large" onClick={() => {
          history.push(RoutePaths.CREDENTIALS(props.match.params.appId));
        }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                        <Text style={{
            fontSize: '14px'
          }} data-sentry-element="Text" data-sentry-source-file="index.tsx">See my clients</Text>
                    </Button>
                    </div>
            </Content>
        </>;
};