import { AppState } from 'app-redux/reducers';
import { IntegrationsState } from 'app-redux/reducers/integrations.reducer';
import { createSelector } from 'reselect';

export const getIntegrationsState = (state: AppState) =>
  state.integrations || ({} as IntegrationsState);
export const getIsSavingIntegrations = createSelector(
  getIntegrationsState,
  (state) => state.saving
);
export const getIsDeletingIntegrations = createSelector(
  getIntegrationsState,
  (state) => state.deleting
);
