import { IUserAccount } from 'core/accounts/models';
import React from 'react';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
interface ILoginButtonProps {
  currentUser?: IUserAccount;
  onClick?: () => void;
}
const LoginButton: React.FC<ILoginButtonProps> = props => {
  if (!props.currentUser) {
    return <Button type={ButtonType.default} onClick={props.onClick} style={{
      marginRight: '20px'
    }}>
        Log in
      </Button>;
  }
  return <></>;
};
export default LoginButton;