import { Input, InputProps } from 'antd';
import React, { FormEvent, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { IntegrationsFormContext } from './integrations-form';
export const ErrorText = styled.p`
  margin-top: 5px;
  color: ${({
  theme
}) => theme.colors.ErrorColor};
  font-size: 12px;
  line-height: 14px;
`;
interface StyledInput extends InputProps {
  $isInvalid: boolean;
}
export const StyledTextInput = styled((p: StyledInput) => <Input {...p} />)`
  padding: 10px;
  background-color: #232630;
  color: #fff;
  border-radius: 4px;
  border: 1px solid
    ${({
  $isInvalid,
  theme
}: {
  $isInvalid?: boolean;
  theme: any;
}) => $isInvalid ? theme.colors.ErrorColor : '#232630'};
  transition: 300ms;
  height: 40px;

  &::placeholder {
    color: #868ca2;
  }

  &:hover {
    border-color: #868ca2 !important;
  }

  &:focus {
    border-color: #868ca2 !important;
    box-shadow: 0px 0px 0px 2px rgba(134, 140, 162, 0.25) !important;
  }
`;
export const IntegrationEntitlementKey = () => {
  const {
    values: {
      entitlement_key
    },
    errors,
    setError,
    setValues
  } = useContext(IntegrationsFormContext);
  const onChange = useCallback((ev: FormEvent<HTMLInputElement>) => {
    const newValue = (ev.target as HTMLInputElement).value ?? '';
    setValues('entitlement_key', newValue);
  }, [setValues]);
  const onBlur = useCallback((ev: FormEvent<HTMLInputElement>) => {
    const value = (ev.target as HTMLInputElement).value ?? '';
    const regex = new RegExp('^[a-zA-Z0-9]+$');
    if (value && (!value.includes('integration.') || !regex.test(value.replace('integration.', '')))) {
      setError('entitlement_key', 'Enter a valid entitlement key format');
    } else {
      setError('entitlement_key', undefined);
    }
  }, [setError]);
  return <>
      <StyledTextInput size="large" defaultValue={entitlement_key} placeholder="Insert Entitlement Key" onInput={onChange} onBlur={onBlur} $isInvalid={!!errors?.entitlement_key} data-testid="integration-entitlement-key" data-sentry-element="StyledTextInput" data-sentry-source-file="integration-entitlement-key.tsx" />
      {errors?.entitlement_key && <ErrorText className="error" data-testid="integration-entitlement-key-error">
          {errors?.entitlement_key}
        </ErrorText>}
    </>;
};