import { CheckCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Divider, Input, Layout, Modal, Skeleton, Table } from 'antd';
import { deletePlan, fetchAllPlansByApp, setCurrentPlan } from 'app-redux/actions/plans.actions';
import { AppState } from 'app-redux/reducers';
import { currentUserHasPermission, getCurrentUser, legacyPermissionsCheck } from 'app-redux/selectors/accounts.selector';
import { getCurrentOrgId } from 'app-redux/selectors/orgs.selector';
import { getPagedPlans, getPlanList, getPlanLoadingStatus } from 'app-redux/selectors/plans.selector';
import { CardPageHeader } from 'components/headers/card-page-header';
import { IUserAccount } from 'core/accounts/models';
import { AppConstants } from 'core/app.constants';
import { history, RoutePaths } from 'core/history';
import { iff } from 'core/iff';
import { INavigationRouteProps } from 'core/navigation/models';
import { UUID } from 'core/utils/BasicModels';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, ButtonType, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import PlansEmptyState from 'pages/licensing/PlansEmptyState';
import './style.less';
import { PlanDTO } from '../models';
import { AllowedAndDeniedOperationsDTO, OperationListDTO } from "../../../core/rbac/models";
import { verifyRbacPermissions } from "../../../app-redux/actions/rbac.actions";
import { isUserUsingRBAC } from "../../../core/common-methods";
const {
  Content
} = Layout;
const {
  Text
} = Typography;
const PLANS_LIST_PAGE_SIZE = 7;
interface ILicensingProps extends INavigationRouteProps<{}> {
  planList: PlanDTO[];
  // todo improve typing
  pagedPlans?: any;
  currentUser?: IUserAccount;
  isLoadingPlans: boolean;
  currentOrgId: string;
  getPlansList: (appId: UUID, page: number, size: number, lastKey: string, planName?: string) => Promise<any>;
  setCurrentPlan: (planId: string) => any;
  dispatchDeletePlan: (planId: string) => any;
  currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string) => boolean;
  verifyRbacPermissions: (operationsListDTO: OperationListDTO) => Promise<AllowedAndDeniedOperationsDTO>;
  isUsingRbac: boolean;
  legacyPermissionsCheck: (id: UUID, context: string, permission: string, scope: string) => boolean;
}
export const Licensing: React.FC<ILicensingProps> = props => {
  const [currentPlanId, setCurrentPlanId] = useState<string>('');
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [visibleSuccessModal, setVisibleSuccessModal] = useState<boolean>(false);
  const [searchPlan, setSearchPlan] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paged, setPaged] = useState({
    pageSize: PLANS_LIST_PAGE_SIZE,
    planList: props.planList,
    isFirst: false,
    isLast: false,
    lastKey: ''
  });
  const [allowedOperations, setAllowedOperations] = useState<Set<string>>(new Set());
  const [canReadPlan, setCanReadPlan] = useState<boolean>(false);
  const [canChangeThisPlan, setCanChangeThisPlan] = useState<boolean>(false);
  const itemRender = (current: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return <a>Previous</a>;
    } else if (type === 'next') {
      return <a>Next</a>;
    } else {
      return null;
    }
  };
  const getTotalRecordsForPagination = (data: PlanDTO[], isFirst: boolean, isLast: boolean, paginationLimit: number, current: number) => {
    if (isFirst && !isLast) {
      return paginationLimit + 1;
    } else if (!isFirst && !isLast) {
      return paginationLimit * current + 1;
    } else if (isLast && !isFirst) {
      return paginationLimit * current - 1;
    }
    return data.length;
  };
  const fetchPlansList = async (page: number, size: number, lastKey: string, searchValue: any) => {
    const plans = await props.getPlansList(props.match.params.appId, page, size, lastKey, searchValue);
    if (plans) {
      setPaged({
        pageSize: size,
        planList: Array.from(plans.content),
        isFirst: plans.first,
        isLast: plans.last,
        lastKey: plans.pageable.next_key
      });
    }
  };
  const onChange = async (pagination: any) => {
    const pageSize = pagination.pageSize;
    await fetchPlansList(pagination.current, pageSize, paged.lastKey, searchPlan);
    setCurrentPage(pagination.current);
  };
  const credentialsActions = ['app_credentials:read', 'app_credentials:read'];
  const readActions = ['app_info:read', "app_plan:read"];
  const writeActions = ['app_info:write', "app_plan:write"];
  const ops = {
    operations: [...readActions, ...writeActions, ...credentialsActions].map(action => ({
      resource: `crn:app:${props.match.params.appId}`,
      action
    }))
  };
  useEffect(() => {
    async function fetchData() {
      if (props.isUsingRbac) {
        const verify = await props.verifyRbacPermissions(ops);
        setAllowedOperations(new Set(verify.allowed_operations?.map(operation => operation.action)));
      } else {
        const hasOrgOwner = props.legacyPermissionsCheck(props.currentOrgId, 'ORGANIZATION', 'OWNER', 'WRITE');
        const hasPlanWrite = props.legacyPermissionsCheck(props.match.params.appId, 'APP', 'PLAN', 'WRITE');
        const hasPlanRead = props.legacyPermissionsCheck(props.match.params.appId, 'APP', 'PLAN', 'READ');
        setCanReadPlan(hasOrgOwner || hasPlanRead);
        setCanChangeThisPlan(hasOrgOwner || hasPlanWrite);
      }
    }
    fetchData().then();
  }, [props.isUsingRbac]);
  useEffect(() => {
    if (props.isUsingRbac) {
      setCanChangeThisPlan([...readActions, ...writeActions].every(action => allowedOperations.has(action)));
      setCanReadPlan(readActions.every(action => allowedOperations.has(action)));
    }
  }, [allowedOperations, props.isUsingRbac]);
  const handleClose = async (type: string) => {
    if (type === 'success') {
      setVisibleSuccessModal(false);
    }
    // tslint:disable-next-line:deprecation
    window.location.reload();
  };
  const handleDeletePlan = async () => {
    setVisibleDeleteModal(false);
    const planDeleted = await props.dispatchDeletePlan(currentPlanId);
    if (planDeleted.status === 200) {
      setVisibleSuccessModal(true);
    }
  };
  const onRowClick = async (record: any) => {
    const currentPlan = record;
    if (currentPlan.id) {
      await props.setCurrentPlan(currentPlan.id);
    }
    history.push({
      pathname: RoutePaths.LICENSE_PLAN_DETAILS(props.match.params.appId, currentPlan.id),
      state: {
        currentPlan
      }
    });
  };
  const onChangeSearchPlans = async (search: any) => {
    const searchValue = search.target.value;
    setSearchPlan(searchValue);
    fetchPlansList(1, paged.pageSize, '', searchValue);
    setCurrentPage(1);
  };

  /* Table Columns Data and Keys */
  const columns = [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    render: (text: string) => <a style={{
      color: '#14151C'
    }}>{text}</a>
  }, {
    title: 'Action',
    dataIndex: 'action',
    width: '30%',
    align: ('left' as const),
    key: 'action',
    render: (text: any, record: any, index: any) => {
      return <div>
              <a className="licensingAppEdit" onClick={async e => {
          e.stopPropagation();
          const currentPlan = record;
          if (currentPlan.id) {
            await props.setCurrentPlan(currentPlan.id);
          }
          history.push({
            pathname: RoutePaths.LICENSE_PLAN_DETAILS(props.match.params.appId, currentPlan.id),
            state: {
              currentPlan
            }
          });
        }}>
                {canChangeThisPlan ? 'Edit' : canReadPlan ? 'View' : ''}
              </a>
              {iff(canChangeThisPlan, <>
                  <Divider type="vertical" className="dividerLeft" />
                  <a className="licensingAppDelete" onClick={async e => {
            e.stopPropagation();
            const currentPlan = record;
            if (currentPlan.id) {
              setCurrentPlanId(currentPlan.id);
              setVisibleDeleteModal(true);
            }
          }}>
                    Delete
                  </a>
                </>)}
          </div>;
    }
  }];
  useEffect(() => {
    fetchPlansList(currentPage, paged.pageSize, paged.lastKey, '');
  }, []);
  const PlansTable = () => {
    if (paged.planList.length > 0) {
      return <Table onRow={record => {
        return {
          onClick: () => onRowClick(record)
        };
      }} rowKey={record => record.id ?? ''} className="licensingTable" pagination={{
        pageSize: paged.pageSize,
        total: getTotalRecordsForPagination(paged.planList, paged.isFirst, paged.isLast, paged.pageSize, currentPage),
        current: currentPage,
        itemRender
      }} columns={columns} dataSource={paged.planList} onChange={onChange} />;
    }
    return <PlansEmptyState data-sentry-element="PlansEmptyState" data-sentry-component="PlansTable" data-sentry-source-file="index.tsx" />;
  };
  return <>
      <Content className="cw-content-container" data-sentry-element="Content" data-sentry-source-file="index.tsx">
        <Modal open={visibleSuccessModal} onOk={() => handleClose('success')} onCancel={() => handleClose('success')} width="384px" footer={[<Button key={1} type={ButtonType.primary} size="large" onClick={() => handleClose('success')}>
                Ok
              </Button>]} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
          <p><CheckCircleOutlined style={{
            color: 'green'
          }} data-sentry-element="CheckCircleOutlined" data-sentry-source-file="index.tsx" /> Plan successfully deleted!</p>
        </Modal>
        <Modal open={visibleDeleteModal} onOk={handleDeletePlan} onCancel={() => setVisibleDeleteModal(false)} width="384px" footer={[<Button key={1} type={ButtonType.default} size="middle" onClick={() => setVisibleDeleteModal(false)}>
                Cancel
              </Button>, <Button key={2} type={ButtonType.danger} size="middle" onClick={handleDeletePlan}>
                Delete
              </Button>]} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
          <div className="deleteModalContent">
            <div className="deleteModalHeader" style={{
            borderBottom: 'none',
            marginBottom: '0px'
          }}>
              <Text strong={true} style={{
              fontSize: '16px'
            }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Attention!</Text>
            </div>
            <div className="deleteModalBody" style={{
            width: '23rem'
          }}>
              <p>
                When you are deleting a plan, all its campaigns and contracts are gonna be affected. <br />
                CAMPAIGN: If the campaign has this single deleted plan, we'll end this campaign.
                If this campaign has more than the deleted plan, we'll take this plan out. <br />
                CONTRACTS: We'll end all contracts with this plan.
              </p>
            </div>
          </div>
        </Modal>

        {/* Heading - Block */}
        <CardPageHeader title="Plans" onBack={history.goBack} data-sentry-element="CardPageHeader" data-sentry-source-file="index.tsx">
          <Input prefix={<SearchOutlined />} data-testid="inputId" name="planName" onChange={onChangeSearchPlans} placeholder={'Search plans'} autoFocus={true} style={{
          width: '270px',
          marginLeft: '20px'
        }} maxLength={AppConstants.NAME_MAX_LENGTH} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
          {iff(canChangeThisPlan, <div className="licensingCreatePlanButton">
                <Button style={{
            width: 147
          }} type={ButtonType.primary} onClick={() => {
            history.push(RoutePaths.NEW_LICENSE_PLAN(props.match.params.appId));
          }}>
                Create Plan
                </Button>
              </div>)}
        </CardPageHeader>
        <div className="clrFloat" />

        {/* Licensing - Table Block */}
        <div className="licensingTableBlock">
          {props.isLoadingPlans ? <Skeleton loading={props.isLoadingPlans} active={true} avatar={true} /> : <PlansTable />}
        </div>
      </Content>
    </>;
};
const mapStateToProps = (state: AppState) => {
  return {
    isUsingRbac: isUserUsingRBAC(),
    planList: ((getPlanList(state) as unknown) as PlanDTO[]),
    pagedPlans: getPagedPlans(state),
    currentUser: getCurrentUser(state),
    isLoadingPlans: getPlanLoadingStatus(state),
    currentOrgId: getCurrentOrgId(state),
    currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string) => currentUserHasPermission(state, id, context, permission, scope),
    legacyPermissionsCheck: (id: UUID, context: string, permission: string, scope: string) => legacyPermissionsCheck(state, id, context, permission, scope)
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getPlansList: (appId: UUID, page: number, size: number, lastKey: string, planName?: string) => dispatch(fetchAllPlansByApp(appId, page, size, lastKey, planName)),
    setCurrentPlan: (planId: string) => dispatch(setCurrentPlan(planId)),
    dispatchDeletePlan: (planId: string) => dispatch(deletePlan(planId)),
    verifyRbacPermissions: (operationsListDTO: OperationListDTO) => dispatch(verifyRbacPermissions(operationsListDTO))
  };
};
export const ListPlans = withRouter(connect(mapStateToProps, mapDispatchToProps)(Licensing));