import { ConfigProvider, Table, message } from 'antd';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Integration } from '../../utils/types';
import Column from 'antd/lib/table/Column';
import { CountryCode, countries } from '../../utils/contants';
import { pipe } from 'core/common-methods';
import { IntegrationsContext } from '../../';
import leafImage from 'assets/images/cropwise-leaf.svg';
import { FlexContainer } from '../../styled';
import { EditIcon } from '../../images/EditIcon';
import { LoadingOutlined } from '@ant-design/icons';
import { MagnifyingGlass } from '../../images/MagnifyingGlassImg';
message.config({
  prefixCls: 'syngenta-ant-message'
});
const StyledTable = styled(Table<Integration>)`
  [class*='-table-pagination'] {
    margin-right: 24px;
  }
`;
const Action = styled(FlexContainer)`
  cursor: pointer;

  &,
  path {
    transition: color 300ms ease-in-out, fill 300ms ease-in-out;
  }

  span {
    margin: 0 4px;
  }

  :hover {
    color: #14803c;
    path {
      fill: #14803c;
    }
  }
`;
interface IApps {
  icon_uri: string;
  id: string;
  image_uri: string;
  name: string;
}
const TableHeader = styled.span`
  font-weight: 600;
  color: #14151c;
`;
const mapCountries = (values: CountryCode[]) => {
  return values.map(code => countries.find(country => country.code === code)?.name).filter(Boolean);
};
const renderArray = (values: string[]) => values.join(', ');
const sortString = (key: string) => (a: {
  [key: string]: string;
}, b: {
  [key: string]: string;
}) => {
  if (a[key] < b[key]) return -1;else if (a[key] > b[key]) return 1;
  return 0;
};
export const IntegrationsTable = ({
  maxRowsPerPage
}: {
  maxRowsPerPage: number;
}) => {
  const {
    integrations,
    loading,
    setCurrentIntegration
  } = useContext(IntegrationsContext);
  const mapApps = (values: IApps[]) => {
    return values.map(app => app.name);
  };
  return <ConfigProvider renderEmpty={() => <CustomEmpty loading={loading} />} data-sentry-element="ConfigProvider" data-sentry-component="IntegrationsTable" data-sentry-source-file="integrations-table.tsx">
      <StyledTable data-testid="integrations-table" dataSource={integrations} rowKey={'id'} showSorterTooltip={false} loading={{
      spinning: loading,
      indicator: <LoadingOutlined spin={true} style={{
        color: '#14803c'
      }} />
    }} pagination={{
      showSizeChanger: false,
      pageSize: maxRowsPerPage
    }} data-sentry-element="StyledTable" data-sentry-source-file="integrations-table.tsx">
        <Column<Integration> width={64} render={(_, integration) => <FlexContainer justify="center">
              <img style={{
          height: 20,
          maxWidth: 32
        }} src={integration.icon ?? ''} onError={({
          currentTarget
        }) => {
          currentTarget.onerror = null;
          currentTarget.src = leafImage;
        }} />
            </FlexContainer>} data-sentry-element="Column" data-sentry-source-file="integrations-table.tsx" />
        <Column title={<TableHeader>Integration name</TableHeader>} dataIndex="name" key="name" sorter={sortString('name')} data-sentry-element="Column" data-sentry-source-file="integrations-table.tsx" />
        <Column title={<TableHeader>Countries available</TableHeader>} dataIndex="countries" key="countries" render={pipe(mapCountries, renderArray)} sorter={sortString('countries')} data-sentry-element="Column" data-sentry-source-file="integrations-table.tsx" />
        <Column title={<TableHeader>Applications available</TableHeader>} dataIndex="supported_applications" key="supported_applications" render={pipe(mapApps, renderArray)} sorter={sortString('supported_applications')} data-sentry-element="Column" data-sentry-source-file="integrations-table.tsx" />
        <Column<Integration> title={<TableHeader>Action</TableHeader>} width={80} render={(_, integration) => <Action justify="center" onClick={() => setCurrentIntegration(integration)} data-testid="integration-edit-button">
              <EditIcon />
              <span style={{
          marginLeft: 4
        }}>Edit</span>
            </Action>} data-sentry-element="Column" data-sentry-source-file="integrations-table.tsx" />
      </StyledTable>
    </ConfigProvider>;
};
const EmptyText = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #00004b;
  margin: 0 0 0 -20px;
`;
const CustomEmpty = ({
  loading
}: {
  loading: boolean;
}) => {
  if (loading) return null;
  return <FlexContainer direction="column" style={{
    margin: '16px 0'
  }} data-sentry-element="FlexContainer" data-sentry-component="CustomEmpty" data-sentry-source-file="integrations-table.tsx">
      <EmptyText data-sentry-element="EmptyText" data-sentry-source-file="integrations-table.tsx">No results found</EmptyText>
      <MagnifyingGlass data-sentry-element="MagnifyingGlass" data-sentry-source-file="integrations-table.tsx" />
    </FlexContainer>;
};