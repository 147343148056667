import { SearchOutlined } from '@ant-design/icons';
import { Divider, Layout, Modal, Select, Table } from 'antd';
import { fetchApps } from 'app-redux/actions/apps.actions';
import { deleteUserFromOrg, inviteAccount, updateAuthorities } from 'app-redux/actions/login.actions';
import { AppState } from 'app-redux/reducers';
import { App, AppsState, PagedApp } from 'app-redux/reducers/apps.reducer';
import { getCurrentUser, getInvitedAccount } from 'app-redux/selectors/accounts.selector';
import { getAppsState, getCurrentApp, getCurrentAppLoadingStatus, hasLoadedApps } from 'app-redux/selectors/apps.selector';
import { getCurrentOrg, getOrgsMap, hasLoadedOrgs, selectIsLoadingOrgs } from 'app-redux/selectors/orgs.selector';
import { TitleBar } from 'components/title-bar';
import { AccountAuthoritiesUpdateRequestDTO, InviteeDTO, IUserAccount, PagedAccount, UserAuthorityRemovalDTO } from 'core/accounts/models';
import { INavigationRouteProps } from 'core/navigation/models';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, ButtonType, Input } from 'syngenta-digital-cropwise-react-ui-kit';
import { fetchOrgTeam } from '../../app-redux/actions/org.actions';
import { OrgMap } from '../../app-redux/reducers/orgs.reducer';
import { OrganizationDTO } from '../../core/orgs/models';
import { UUID } from '../../core/utils/BasicModels';
import { InvitationModal } from './InvitationModal';
import './style.less';
const {
  Content
} = Layout;
const {
  Option
} = Select;
export interface IPaginationApp {
  pageNumber: number;
  pageSize: number;
  total: number;
  appList: App[];
}
interface IGetAppProps extends INavigationRouteProps<{}> {
  currentApp?: App | null;
  currentAppList?: AppsState;
  currentOrg: OrganizationDTO;
  currentOrgTeam?: IUserAccount[];
  currentUser?: IUserAccount;
  invitedAccount?: any;
  orgMap: OrgMap;
  isLoadingOrgs?: boolean;
  hasLoadedOrgs?: boolean;
  hasLoadedApps?: boolean;
  isLoadingApp?: boolean;
  pages?: PagedApp;
  pageNumber: number;
  fetchApps?: (orgId: UUID, pageNumber: number, pageSize: number) => Promise<PagedApp>;
  fetchOrgTeam?: (orgId: UUID, pageNumber: number, pageSize: number) => Promise<any>;
  inviteAccount: (valuesRequest: InviteeDTO) => Promise<any>;
  deleteUserFromOrg: (accountId: UUID, authorityRemovalDTO: UserAuthorityRemovalDTO) => Promise<any>;
  updateAuthorities: (accountId: UUID, accountAuthoritiesUpdateRequestDTO: AccountAuthoritiesUpdateRequestDTO) => Promise<any>;
}
export const TeamManagementComponent: React.FC<IGetAppProps> = props => {
  const [apps, setApps] = useState<App[]>((props.currentAppList?.pagedApp?.content as App[]) ?? []);
  const [team, setTeam] = useState<IUserAccount[]>(props.currentOrgTeam ?? []);
  const [loadingTeam, setLoadingTeam] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [modalType, setModalType] = useState<'invite' | 'edit'>('invite');
  const [pagination, setPagination] = useState<{
    pageNumber: number;
    pageSize: number;
    total: number;
  }>({
    pageNumber: 1,
    pageSize: 3,
    total: 0
  });
  const [currentAccount, setCurrentAccount] = useState<IUserAccount>();
  const [paginationApp, setPaginationApp] = useState<IPaginationApp>({
    pageNumber: props.pageNumber,
    pageSize: (props.pages?.size as number),
    total: (props.pages?.number_of_elements as number),
    appList: apps
  });
  const currentOrg = props.orgMap[props.currentOrg?.id] || ({
    id: props.currentOrg?.id,
    name: ''
  } as OrganizationDTO);
  const currentOrgAuthorities = props.currentUser?.authorities.filter(authority => authority.context === 'ORGANIZATION' && authority.id === currentOrg.id)[0];
  const isCurrentUserOwner = currentOrgAuthorities ? currentOrgAuthorities.permissions.filter(permission => permission.name === 'OWNER').length > 0 : false;
  const currentUserCanInviteOrEdit = isCurrentUserOwner || (currentOrgAuthorities ? currentOrgAuthorities.permissions.filter(permission => permission.name === 'USERS' && permission.scope === 'WRITE').length > 0 : false);
  const members = team.map((member, i) => {
    return {
      ...member,
      key: i,
      loginemail: member.email
    };
  });
  const appData = apps.map((app, i) => {
    return {
      ...app,
      key: i
    };
  });
  const columns = [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: any, b: any) => a.name.length - b.name.length
  }, {
    title: 'Login/email',
    dataIndex: 'loginemail',
    key: 'loginemail',
    sorter: (a: any, b: any) => a.loginemail.length - b.loginemail.length
  }, {
    title: 'Action',
    align: ('center' as const),
    key: 'action',
    render: (text: string, userAccount: IUserAccount) => <div className="teamAppActions">
          {isCurrentUserOwner && <>
              <Button type={ButtonType.link} color="blue" className="teamAppDelete" onClick={() => handleDeleteUser(userAccount)}>
                Delete
              </Button>
              <Divider type="vertical" className="dividerLeft" />
            </>}
          {currentUserCanInviteOrEdit && <a className="teamAppEdit" onClick={async (e: any) => {
        e.stopPropagation();
        setCurrentAccount(userAccount);
        handleShow('edit');
      }}>
              Edit
            </a>}
        </div>
  }];
  const setTeamList = (pagedAccount: PagedAccount) => {
    const teamContent = pagedAccount.content;
    const currentUserId = props.currentUser ? props.currentUser.id : '';
    setTeam(Array.from(teamContent).filter(member => member.id !== currentUserId));
    setLoadingTeam(false);
    setPagination({
      pageNumber: pagedAccount.number + 1,
      pageSize: pagedAccount.size,
      total: pagedAccount.total_elements
    });
  };
  const reloadTeam = () => {
    if (props.fetchOrgTeam) {
      setLoadingTeam(true);
      props.fetchOrgTeam(props.currentOrg.id, 0, 5).then((pagedAccount: PagedAccount) => {
        setTeamList(pagedAccount);
      }).catch();
    }
  };
  useEffect(() => {
    reloadTeam();
    if (props.fetchApps) {
      props.fetchApps(props.currentOrg.id, 0, 8).then(appsResponse => {
        setApps(Array.from(appsResponse.content));
        setPaginationApp({
          pageNumber: appsResponse.number + 1,
          pageSize: appsResponse.size,
          total: appsResponse.total_elements,
          appList: Array.from(appsResponse.content)
        });
      });
    }
  }, []);
  const handleChangeAppPagination = (appPagination: IPaginationApp) => {
    setPaginationApp(appPagination);
    setApps(appPagination.appList);
  };
  const handleShow = (type: 'invite' | 'edit') => {
    setVisible(true);
    setModalType(type);
  };
  const handleClose = () => {
    setVisible(false);
  };
  const handleSubmitInvite = async (inviteeDTO: InviteeDTO) => {
    await props.inviteAccount(inviteeDTO).then(data => {
      handleClose();
      reloadTeam();
    }).catch();
  };
  const handleSubmitEdit = async (accountId: UUID, accountAuthoritiesUpdateRequestDTO: AccountAuthoritiesUpdateRequestDTO) => {
    await props.updateAuthorities(accountId, accountAuthoritiesUpdateRequestDTO).then(data => {
      handleClose();
      reloadTeam();
    }).catch();
  };
  const handleDeleteUser = async (user: IUserAccount) => {
    setLoadingTeam(true);
    const appIds = apps.map(app => app.id);
    const authorityRemovalDTO: UserAuthorityRemovalDTO = {
      updates: [{
        operation: 'remove',
        authorities: user.authorities.filter(authority => {
          return authority.context === 'ORGANIZATION' && authority.id === props.currentOrg.id || authority.context === 'APP' && appIds.includes(authority.id);
        })
      }]
    };
    await props.deleteUserFromOrg(user.id, authorityRemovalDTO);
    reloadTeam();
  };
  const handleChange = async (teamPagination: any, filters: any, sorter: any, extra: any) => {
    const page = teamPagination.current - 1;
    const pageSize = teamPagination.pageSize;
    if (props.fetchOrgTeam) {
      setLoadingTeam(true);
      props.fetchOrgTeam(props.currentOrg.id, page, pageSize).then((pagedAccount: PagedAccount) => {
        setTeamList(pagedAccount);
      }).catch();
    }
  };
  const invitationModalMemo = useMemo(() => <InvitationModal modalType={modalType} handleClose={handleClose} handleSubmitInvite={handleSubmitInvite} handleSubmitEdit={handleSubmitEdit} appData={(appData as any)} visible={visible} currentOrg={currentOrg} selectedTeamMember={currentAccount} currentUserAuthorities={props.currentUser?.authorities || []}
  // currentUserAuthorities={[...mockAuthorities]}
  selectedUserAuthorities={[]} fetchApps={props.fetchApps} paginationApp={paginationApp} handleChangeAppPagination={handleChangeAppPagination} />, [visible, paginationApp, apps]);
  return <>
      <Content className="cw-app-content-container TeamContent" data-sentry-element="Content" data-sentry-source-file="index.tsx">

        {/* Heading - Block */}
        <TitleBar data-sentry-element="TitleBar" data-sentry-source-file="index.tsx">Team Access</TitleBar>

        {/* Sub-Heading Block */}
        <div className="teamSubHeadingBlock">
          <div className="teamSubHeadingInputSearch">
            <Input placeholder="Search by name" prefix={<SearchOutlined />} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
          </div>
          {currentUserCanInviteOrEdit && <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end'
        }}>
              <Button style={{
            width: 150
          }} type={ButtonType.primary} onClick={() => handleShow('invite')}>
                Invite User
              </Button>
            </div>}
        </div>

        {/* Team Access - Table Block */}
        <div className="teamTableBlock">
          <Table loading={loadingTeam} columns={columns} dataSource={members} onChange={handleChange} pagination={{
          pageSize: pagination.pageSize,
          current: pagination.pageNumber,
          total: pagination.total,
          showSizeChanger: true
        }} data-sentry-element="Table" data-sentry-source-file="index.tsx" />
        </div>
      </Content>

      {/* Invite/Edit User Modal Window - Start  */}
      <Modal className="inviteEditUserModal" style={{
      top: 60
    }} open={visible} onOk={handleClose} onCancel={handleClose} footer={null} width={1200} closable={false} destroyOnClose={true} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
        {invitationModalMemo}
      </Modal>
      {/* Invite/Edit User Modal Window - End  */}
    </>;
};
const mapStateToProps = (state: AppState, ownProps: IGetAppProps): IGetAppProps => {
  const pageNumberState = state.apps.pagedApp?.number ? state.apps.pagedApp?.number + 1 : 1;
  return {
    ...ownProps,
    pages: state.apps.pagedApp,
    pageNumber: pageNumberState,
    currentApp: getCurrentApp(state),
    currentAppList: getAppsState(state),
    currentOrg: getCurrentOrg(state),
    currentUser: getCurrentUser(state),
    invitedAccount: getInvitedAccount(state),
    orgMap: getOrgsMap(state),
    hasLoadedOrgs: hasLoadedOrgs(state),
    hasLoadedApps: hasLoadedApps(state),
    isLoadingOrgs: selectIsLoadingOrgs(state),
    isLoadingApp: getCurrentAppLoadingStatus(state)
  };
};
const mapDispatchToProps = (dispatch: any, ownProps: IGetAppProps): IGetAppProps => {
  return {
    ...ownProps,
    fetchApps: (orgId: UUID, pageNumber: number, pageSize: number) => dispatch(fetchApps(orgId, pageNumber, pageSize)),
    fetchOrgTeam: (orgId: UUID, pageNumber: number, pageSize: number) => dispatch(fetchOrgTeam(orgId, pageNumber, pageSize)),
    inviteAccount: (valuesRequest: InviteeDTO) => dispatch(inviteAccount(valuesRequest)),
    updateAuthorities: (accountId: UUID, accountAuthoritiesUpdateRequestDTO: AccountAuthoritiesUpdateRequestDTO) => dispatch(updateAuthorities(accountId, accountAuthoritiesUpdateRequestDTO)),
    deleteUserFromOrg: (accountId: UUID, authorityRemovalDTO: UserAuthorityRemovalDTO) => dispatch(deleteUserFromOrg(accountId, authorityRemovalDTO))
  };
};
export const TeamManagement = withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamManagementComponent));