import { Layout } from 'antd';
import { ErrorBoundary } from 'components/error-boundary';
import { iff } from 'core/iff';
import React from 'react';
import styled from 'styled-components';
const {
  Content
} = Layout;
const StyledLayout = styled(Layout)`
  height: 100%;
  min-height: 100%;
`;
const StyledContent = styled(Content)`
  background-color: #e5e5e5;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
`;
interface IPageLayoutProps {
  header: React.ReactNode;
  content: React.ReactNode;
}
export const PageLayout: React.FC<IPageLayoutProps> = props => {
  return <StyledLayout data-sentry-element="StyledLayout" data-sentry-component="PageLayout" data-sentry-source-file="index.tsx">
            <StyledContent data-sentry-element="StyledContent" data-sentry-source-file="index.tsx">
                <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="index.tsx">{iff(!!props.content, props.content)}</ErrorBoundary>
            </StyledContent>
        </StyledLayout>;
};