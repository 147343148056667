import { AxiosResponse } from 'axios';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import qs from 'querystring';
import { Dispatch } from 'redux';
import { DeveloperAppDTO } from '../../core/apps/models';
import { axiosRequest } from '../../core/axios';
import { getHeaders } from '../../core/common-methods';
import { EntitlementDTO } from '../../core/entitlements/models';
import { PageDTO, UUID } from '../../core/utils/BasicModels';
import { getCurrentApp } from '../selectors/apps.selector';
import { fetchCurrentUser } from './login.actions';

export const ACTION_GET_APPS_LIST: RequestAction = createRequestAction('GET_APPS_LIST_REQUEST');
export const ACTION_CREATE_APP: RequestAction = createRequestAction('CREATE_APP_REQUEST');
export const ACTION_GET_APP: RequestAction = createRequestAction('GET_APP_REQUEST');
export const ACTION_RESET_GET_APP: RequestAction = createRequestAction('RESET_GET_APP_REQUEST');
export const ACTION_SET_CURRENT_APP = 'SET_CURRENT_APP_REQUEST';
export const ACTION_DELETE_APP: RequestAction = createRequestAction('DELETE_APP_REQUEST');
export const ACTION_EDIT_APP: RequestAction = createRequestAction('EDIT_APP_REQUEST');
export const ACTION_GET_APPS_ENTITLEMENTS_LIST: RequestAction = createRequestAction('GET_APPS_ENTITLEMENTS_LIST_REQUEST');
export const ACTION_UPLOAD_APP_ICON: RequestAction = createRequestAction('UPLOAD_APP_ICON_REQUEST');
export const ACTION_DELETE_APP_ICON: RequestAction = createRequestAction('DELETE_APP_ICON_REQUEST');
export const ACTION_APP_ICON_RESET: RequestAction = createRequestAction('APP_ICON_RESET_REQUEST');

export const fetchApps = (orgId: UUID, pageNumber: number, pageSize: number) => async (dispatch: Dispatch) => {
  try {

    dispatch({ type: ACTION_GET_APPS_LIST.REQUEST });

    const pageInfo = {
      page: pageNumber,
      size: pageSize,
    };

    const allApps = await axiosRequest
            .get<any, AxiosResponse<PageDTO<DeveloperAppDTO>>>(`/v2/orgs/${orgId}/apps?${qs.stringify(pageInfo)}`,
      {
        headers: {
          ...getHeaders().common,
          // tslint:disable-next-line:no-duplicate-string TODO(marcela): use user preference
          'Accept-Language': 'pt-BR',
        },
      });

    const data = allApps.data;

    dispatch({ type: ACTION_GET_APPS_LIST.SUCCESS, payload: data });

    return data;
  } catch (e) {
    dispatch({ type: ACTION_GET_APPS_LIST.ERROR, payload: e });
    throw e;
  }
};

export const createApps = (valuesRequest: DeveloperAppDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_APP.REQUEST });

    const appValues = {
      ...valuesRequest,
      fallback_details_language: valuesRequest.language,
    };

    const app = await axiosRequest
            .post<any, AxiosResponse<DeveloperAppDTO>>(`/v2/apps`, appValues,
      {
        headers: {
          ...getHeaders().common,
          'Accept-Language': valuesRequest.language,
        },
      });

    const data = app.data;

    dispatch({ type: ACTION_CREATE_APP.SUCCESS, payload: data });

    fetchCurrentUser()(dispatch);

    return data;

  } catch (e) {
    dispatch({ type: ACTION_CREATE_APP.ERROR, payload: e });
    throw e;
  }
};

export const editApp = (appId: UUID, valuesRequest: DeveloperAppDTO) => async (dispatch: Dispatch, getState: any) => {
  try {
    dispatch({ type: ACTION_EDIT_APP.REQUEST });

    const currentApp = getCurrentApp(getState());

    const appValues = {
      ...valuesRequest,
      fallback_details_language: currentApp!.fallback_details_language,
    };

    const appEdited = await axiosRequest
            .put<any, AxiosResponse<DeveloperAppDTO>>(`/v2/apps/${appId}`, appValues,
      {
        headers: {
          ...getHeaders().common,
          'Accept-Language': valuesRequest.language,
          'Content-Language': valuesRequest.language,
        },
      });

    dispatch({ type: ACTION_EDIT_APP.SUCCESS, payload: appEdited.data });

    return appEdited.data;

  } catch (e) {
    dispatch({ type: ACTION_EDIT_APP.ERROR, payload: e });
  }
};

export const getAppById = (appId: UUID, language: string) => async (dispatch: Dispatch): Promise<DeveloperAppDTO & {headerLanguageContent: string}> => {
  try {
    dispatch({ type: ACTION_GET_APP.REQUEST });

    const allApps = await axiosRequest
            .get<any, AxiosResponse<DeveloperAppDTO>>(`/v2/apps/${appId}`,
      {
        headers: {
          ...getHeaders().common,
          'Accept-Language': language,
        },
      });

    const data = allApps.data;

    dispatch({ type: ACTION_GET_APP.SUCCESS, payload: data });

    return { ...data, headerLanguageContent: allApps.headers['content-language'] };
  } catch (e) {
    dispatch({ type: ACTION_GET_APP.ERROR, payload: e });
    throw e;
  }
};

export const resetGetApp = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_RESET_GET_APP.REQUEST });

    const data = '';

    dispatch({ type: ACTION_RESET_GET_APP.SUCCESS, payload: data });

    return data;
  } catch (e) {
    dispatch({ type: ACTION_RESET_GET_APP.ERROR, payload: e });
    throw e;
  }
};

export const deleteApp = (appId: UUID) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_APP.REQUEST });

    const deleteAppResponse = await axiosRequest
        .delete(`/v2/apps/${appId}`,
      {
        headers: {
          ...getHeaders().common,
          'Accept-Language': 'pt-BR',
        },
      });

    dispatch({ type: ACTION_DELETE_APP.SUCCESS, payload: deleteAppResponse });

    return deleteAppResponse;
  } catch (e) {
    dispatch({ type: ACTION_DELETE_APP.ERROR, payload: e });
    throw e;
  }
};

export const setCurrentApp = (currentAppId: UUID) => async (dispatch: Dispatch) => {
  dispatch({ type: ACTION_SET_CURRENT_APP, payload: currentAppId });
};

export const getAppEntitlements = (appId: UUID, lastKey?: UUID, size?: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_GET_APPS_ENTITLEMENTS_LIST.REQUEST });

    const params = {
      size,
      last_key: lastKey,
    };

    const entitlements: AxiosResponse<PageDTO<EntitlementDTO>> = await axiosRequest
        .get<any, AxiosResponse<PageDTO<EntitlementDTO>>>(`/v2/apps/${appId}/entitlements?size=1000000`,
      {
        headers: getHeaders().common,
        params,
      });

    dispatch({ type: ACTION_GET_APPS_ENTITLEMENTS_LIST.SUCCESS, payload: entitlements.data });
    return entitlements.data;
  } catch (e) {
    dispatch({ type: ACTION_GET_APPS_ENTITLEMENTS_LIST.ERROR, payload: e });
    throw e;
  }
};
export const uploadAppIcon = (appId: UUID, icon: string | Blob, acceptedLanguage = 'en') => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_UPLOAD_APP_ICON.REQUEST });
    const formData = new FormData();
    formData.append('icon', icon);
    const appIcon: AxiosResponse = await axiosRequest
          .post<any, AxiosResponse<PageDTO<EntitlementDTO>>>(`/v2/apps/${appId}/icon`, formData,
      {
        headers: { ...getHeaders().common, 'Content-Type': 'multipart/form-data', 'Accept-Language': acceptedLanguage },
      });

    dispatch({ type: ACTION_UPLOAD_APP_ICON.SUCCESS, payload: appIcon.data });
    return appIcon.data;
  } catch (e) {
    dispatch({ type: ACTION_UPLOAD_APP_ICON.ERROR, payload: e });
    throw e;
  }
};

export const deleteAppIcon = (appId: UUID, acceptedLanguage = 'en') => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_APP_ICON.REQUEST });
    const appIcon: AxiosResponse = await axiosRequest
        .delete<any, AxiosResponse<PageDTO<EntitlementDTO>>>(`/v2/apps/${appId}/icon`,
      {
        headers: { ...getHeaders().common, 'Accept-Language': acceptedLanguage },
      });

    dispatch({ type: ACTION_DELETE_APP_ICON.SUCCESS, payload: appIcon.data });
    return appIcon.data;
  } catch (e) {
    dispatch({ type: ACTION_DELETE_APP_ICON.ERROR, payload: e });
    throw e;
  }
};

export const resetAvatarHasUpdated = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_APP_ICON_RESET.SUCCESS, payload: {} });
    return;
  } catch (e) {
    dispatch({ type: ACTION_APP_ICON_RESET.ERROR, payload: e });
    throw e;
  }
};
