import { store } from 'app-redux/store';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import { useRef } from 'react';

export const isAccessTokenAvailable = () => !!localStorage.getItem('tokens');
export const setAccessToken = (token: any) => localStorage.setItem('tokens', token);
export const getAccessToken = () => localStorage.getItem('tokens') || '';
export const clearAccessToken = () => localStorage.removeItem('tokens');

export const getProgressByValues = (
  isPasswordValid: boolean,
  isMobileNumberValid: boolean,
  termsCheckbox: boolean
) => {
  let count = 1;
  if (isPasswordValid) {
    count++;
  }
  if (isMobileNumberValid) {
    count++;
  }
  if (termsCheckbox) {
    count++;
  }
  return count;
};

export const isEmptyStr = (str?: string): boolean => {
  return !(str && str.length > 0);

};

export const getHeaders = () => ({
  common: {
    Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`
  }
});

export const getLangHeaders = () => {
  let lang = localStorage.getItem('i18nextLng') || 'en';
  if (lang === 'pt') {
    lang = 'pt-BR';
  }
  return {
    common: {
      Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`,
      'Accept-Language': lang
    }
  };
};

export const getInitialName = (name: string) => {
  if (isEmpty(name)) {
    return '';
  }
  const splitedNames = name.split(' ');
  const firstName = first(splitedNames) || '';
  const lastName = last(splitedNames) || '';
  if (splitedNames.length >= 2) {
    return `${firstName.charAt(0).toUpperCase()} ${lastName.charAt(0).toUpperCase()}`;
  }
  return firstName.charAt(0).toUpperCase();
};

export const setTimeoutPromise = async (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const isSearchSubStringFound = (searchString = '', fields: string[] = []) =>
  !isEmpty(
    fields.filter((field) => (field || '').toLowerCase().indexOf(searchString.toLowerCase()) >= 0)
  );

export const CommaSeparatedList = (a: [], b: []) => {
  try {
    if (!b) {
      return a.join(', ');
    }
    return get(a, b).join(', ');
  } catch (e) {
    // do nothing
    return '';
  }
};

export const getSyngentaDigitalOrgId = () => '43414955-4249-5359-4e47-454e54413432';

export const isUserUsingRBAC = (): boolean => {
  const state = store.getState();
  try {
    const token = JSON.parse(getAccessToken());
    return !!(state.rollout.flags.isRBACVerificationEnabledOnBaseFront && token.is_using_rbac);
  } catch (err) {
    return false;
  }
};

export const getCurrentEnv = () => {
  const currentEnv = process.env.REACT_APP_ENV_NAME;
  if (currentEnv === 'local') {
    return 'qa';
  }
  return currentEnv;
};

/* eslint-disable */
export const pipe =
  (...fns: CallableFunction[]) =>
    (x: any) =>
      fns.reduce((v, f) => f(v), x);

export const compose =
  (...fns: CallableFunction[]) =>
    (x: any) =>
      fns.reduceRight((v, f) => f(v), x);

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => unknown ? A : never;
export const useDebounced = <T extends Function>(func: T, delay: number = 300) => {
  const timer = useRef<number>();
  return (...args: ArgumentTypes<T>) => {
    const ctx = this;
    if (timer) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      func.apply(ctx, args);
    }, delay);
  };
};
/* eslint-enable */
