import { Layout } from 'antd';
import logoImg from 'assets/images/cropwise-logo.svg';
import React from 'react';
import { Divider } from 'syngenta-digital-cropwise-react-ui-kit';
import { LoginSection } from './LoginSection';
const {
  Header,
  Content
} = Layout;
export const TopNavBar: React.FC = () => {
  return <Header style={{
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    height: 'auto'
  }} data-sentry-element="Header" data-sentry-component="TopNavBar" data-sentry-source-file="topNavBar.tsx">
      <div style={{
      display: 'flex',
      alignItems: 'center'
    }}>
        <img src={logoImg} alt="" style={{
        width: '8rem'
      }} />
        <Divider type="vertical" style={{
        height: '50%',
        borderColor: '#dfe2e7'
      }} data-sentry-element="Divider" data-sentry-source-file="topNavBar.tsx" />
        <h1 style={{
        fontSize: '1.2rem',
        color: '#363948',
        margin: '0 0 0 1rem'
      }}>Dev Console</h1>
      </div>
      <div style={{
      display: 'flex',
      alignItems: 'center',
      lineHeight: '42px'
    }}>
        <LoginSection data-sentry-element="LoginSection" data-sentry-source-file="topNavBar.tsx" />
      </div>
    </Header>;
};
export default TopNavBar;