import { Input } from 'antd';
import { FlexContainer } from 'pages/integrations/styled';
import React, { FormEvent, useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { IntegrationsFormContext } from './integrations-form';
const Label = styled.label`
  span.label {
    font-size: 14px;
    line-height: 24px;
  }

  width: 100%;
`;
export const StyledTextInput = styled(Input)`
  padding: 10px;
  background-color: #232630;
  color: #fff;
  border-radius: 4px;
  border: 1px solid #232630;
  transition: 300ms;
  height: 40px;

  &::placeholder {
    color: #868ca2;
  }

  &:hover {
    border-color: #868ca2 !important;
  }

  &:focus {
    border-color: #868ca2 !important;
    box-shadow: 0px 0px 0px 2px rgba(134, 140, 162, 0.25) !important;
  }
`;
const SmallText = styled.span`
  color: #c2c7d0;
  font-size: 12px;
  line-height: 20px;

  ${({
  warning
}: {
  warning?: boolean;
}) => warning && `
    font-weight: 600;
    color: #E99921;
  `}
`;
export const IntegrationName = () => {
  const {
    values: {
      name
    },
    setValues
  } = useContext(IntegrationsFormContext);
  const [charCount, setCharCount] = useState(name?.length ?? 0);
  const onChange = useCallback((ev: FormEvent<HTMLInputElement>) => {
    const newValue = (ev.target as HTMLInputElement).value ?? '';
    setCharCount(newValue.length);
    setValues('name', newValue);
  }, [setValues]);
  return <Label htmlFor="integration-name" data-sentry-element="Label" data-sentry-component="IntegrationName" data-sentry-source-file="integration-name.tsx">
      <span className="label">Integration name</span>
      <StyledTextInput data-testid="integration-name" id="integration-name" size="large" defaultValue={name} placeholder="Insert Integration Name" onInput={onChange} data-sentry-element="StyledTextInput" data-sentry-source-file="integration-name.tsx" />
      <FlexContainer justify="space-between" style={{
      width: '100%'
    }} data-sentry-element="FlexContainer" data-sentry-source-file="integration-name.tsx">
        <SmallText data-sentry-element="SmallText" data-sentry-source-file="integration-name.tsx">Suggested 20-character limit</SmallText>
        <SmallText warning={charCount > 20} data-sentry-element="SmallText" data-sentry-source-file="integration-name.tsx">{charCount}</SmallText>
      </FlexContainer>
    </Label>;
};