import { PageLayout } from 'components/page-layout';
import React from 'react';
import styled from 'styled-components';
const Label = styled.h1`
  display: inline-block;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 46px;
  color: rgb(46, 51, 125);
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 50vh;
`;
export const PageNotFound = () => {
  return PageLayout({
    header: null,
    content: <Container>
                <Label>404</Label>
                <h3>Page not found</h3>
                <p>
                    We are sorry, the page you requested could not be found!
                </p>
            </Container>
  });
};