import { history, RoutePaths } from 'core/history';
import React from 'react';
import { Button, ButtonType, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import './style.less';
const {
  Text
} = Typography;
interface IAppsPageHeader {
  currentUserCanCreateApps: boolean;
}
export const AppsPageHeader: React.FC<IAppsPageHeader> = (props: IAppsPageHeader) => {
  return <>
      <Text strong={true} style={{
      fontSize: '20px',
      color: '#2F3031',
      marginBottom: '5px'
    }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Apps</Text>
      <div className="cw-app-create-app-button">
        <Button type={ButtonType.success} data-testid="create-app-button" onClick={() => {
        history.push(RoutePaths.CREATE_APP());
      }} style={{
        borderColor: '#c1c5c8'
      }} disabled={!props.currentUserCanCreateApps} data-sentry-element="Button" data-sentry-source-file="index.tsx">
          Create App
        </Button>
      </div>
    </>;
};