import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonType, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
const {
  Text
} = Typography;
const Label = styled(Text)`
  display: block;
  color: #2F3031;
  font-size: 16px;
  font-weight: 400;
`;
export const UploadTypes = {
  UPLOAD_BUTTON: 'upload_button',
  BASE64: 'base64'
};
interface IUploadAppIconProps {
  mode: string;
  defaultImgUrl: string;
  uploadAppIcon: (icon?: string | Blob) => void;
  deleteAppIcon?: () => void;
}
const UploadAppIconComponent: React.FC<IUploadAppIconProps> = props => {
  const [imageUrl, setImgUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [newImage, setNewImage] = useState<string | Blob | undefined>(undefined);
  const [isUploadButtonDisabled, setUploadButtonDisabled] = useState<boolean>(true);
  const [isUploadButtonLoading, setUploadButtonLoading] = useState<boolean>(false);
  const [isDeleteButtonDisabled, setDeleteButtonDisabled] = useState<boolean>(true);
  const [isDeleteButtonLoading, setDeleteButtonLoading] = useState<boolean>(false);
  useEffect(() => {
    setImgUrl(props.defaultImgUrl);
    setDeleteButtonDisabled(!props.defaultImgUrl);
    setLoading(!!props.defaultImgUrl);
    if (props.defaultImgUrl) {
      setLoading(false);
    }
  }, [props.defaultImgUrl]);
  const getBase64 = async (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };
  const getUrl = (icon: string) => {
    if (!icon.includes('data:image')) {
      return `${icon}?${new Date().getTime()}`;
    } else {
      return icon;
    }
  };
  const handleAppIconChange = async (info: any) => {
    if (info.file.originFileObj) {
      if (!info.file.url) {
        info.file.preview = await getBase64(info.file.originFileObj);
      }
      setImgUrl(getUrl(info.file.preview));
      setNewImage(info.file.originFileObj);
      setUploadButtonDisabled(false);
      if (props.mode === UploadTypes.BASE64) {
        props.uploadAppIcon(info.file.preview);
      }
    }
  };
  const beforeUpload = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file.');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB.');
    }
    return isJpgOrPng && isLt2M;
  };
  const handleImageErrored = () => {
    setImgUrl('');
    setLoading(false);
    setDeleteButtonDisabled(true);
  };
  const handleIconUpload = async () => {
    if (props.mode === UploadTypes.UPLOAD_BUTTON) {
      setUploadButtonLoading(true);
      setLoading(true);
      props.uploadAppIcon(newImage);
      setLoading(false);
      setUploadButtonLoading(false);
      setUploadButtonDisabled(true);
      setDeleteButtonDisabled(false);
    }
  };
  const handleDeleteIcon = async () => {
    if (props.deleteAppIcon && props.mode === UploadTypes.UPLOAD_BUTTON) {
      setDeleteButtonLoading(true);
      props.deleteAppIcon();
      setDeleteButtonLoading(false);
      setDeleteButtonDisabled(true);
      setImgUrl('');
    }
  };
  const ImageViewer: React.FC<any> = ({
    isLoading,
    imgSrc
  }) => {
    const [showImage, setShowImage] = useState<boolean>(false);
    useEffect(() => setShowImage(imgSrc && !isLoading), [isLoading, imgSrc]);
    if (showImage) {
      return <img src={imgSrc} onError={handleImageErrored} alt="icon" style={{
        width: '100%'
      }} />;
    }
    return <div data-sentry-component="ImageViewer" data-sentry-source-file="index.tsx">
              {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">Upload</div>
          </div>;
  };
  const UploadButtons: React.FC<any> = ({
    uploadMode
  }) => {
    if (uploadMode === UploadTypes.UPLOAD_BUTTON) {
      return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
      }}>
          <Button type={ButtonType.success} onClick={handleIconUpload} disabled={isUploadButtonDisabled} loading={isUploadButtonLoading}>
            Upload App Icon
          </Button>
          <Button onClick={handleDeleteIcon} type={ButtonType.danger} disabled={isDeleteButtonDisabled} loading={isDeleteButtonLoading}>
            Delete App Icon
          </Button>
        </div>;
    }
    return <></>;
  };
  return <>
          <Label data-testid="label" data-sentry-element="Label" data-sentry-source-file="index.tsx">Select an icon</Label>
          <div style={{
      paddingTop: '20px',
      display: 'flex'
    }}>
            <Upload name="icon" listType="picture-card" className="icon-uploader" showUploadList={false} beforeUpload={beforeUpload} onChange={handleAppIconChange} data-sentry-element="Upload" data-sentry-source-file="index.tsx">
              <ImageViewer isLoading={loading} imgSrc={imageUrl} data-sentry-element="ImageViewer" data-sentry-source-file="index.tsx" />
            </Upload>
            <UploadButtons uploadMode={props.mode} data-sentry-element="UploadButtons" data-sentry-source-file="index.tsx" />
          </div>
        </>;
};
export const UploadAppIcon = React.memo(UploadAppIconComponent);