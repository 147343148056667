import { CloseCircleFilled, ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, ButtonType, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import './style.less';
import { getAppEntitlements } from 'app-redux/actions/apps.actions';
import { createEntitlementsForApp, editEntitlementsForApp } from 'app-redux/actions/entitlements.actions';
import { getPlanById, createPlan, editPlan, resetGetPlan } from 'app-redux/actions/plans.actions';
import { AppState } from 'app-redux/reducers';
import { getErrorMessage } from 'app-redux/selectors/error-handler.selector';
import { getEntitlementsAndItsIdsFromCurrentPlan, EntitlementDrawer } from 'components/entitlement-drawer';
import { EntitlementsTable } from 'components/entitlements-table';
import { LocationSelectComponent } from 'components/location-select-component';
import { KeyIcon } from 'components/menu-component/images/KeyIcon';
import { PlanFormErrorModal } from 'components/plans-form-error-modal';
import { PlanFormKeyModals } from 'components/plans-form-key-modals';
import { PlanFormSuccessModal } from 'components/plans-form-success-modal';
import { ErrorMessageDTO } from 'core/AxiosResponseInterceptor';
import { AppConstants } from 'core/app.constants';
import { CreateEntitlementDTO, UpdateEntitlementDTO } from 'core/entitlements/models';
import { history, RoutePaths } from 'core/history';
import { iff } from 'core/iff';
import { INavigationRouteProps } from 'core/navigation/models';
import { UUID, PageDTO } from 'core/utils/BasicModels';
import { EditIcon } from 'pages/integrations/images/EditIcon';
import { CreatePlanDTO, EntitlementDTO, PlanDTO, QuotaDTO, Update, UpdateOperation, UpdatePlanDTO } from '../models';
import { App } from 'app-redux/reducers/apps.reducer';
import { QuotaDrawer } from '../quota-drawer';
function buildEntitlementsUpdateOperations(entIds: string[], plan?: PlanDTO): Update {
  const updates: UpdateOperation[] = [];
  let currentEntIds: string[] = [];
  if (plan) {
    currentEntIds = plan.entitlements.map(ent => ent.id);
    const entsToRemove = currentEntIds.filter(ent => !entIds.includes(ent));
    updates.push(...entsToRemove.map(ent => ({
      operation: "REMOVE",
      entitlement_id: ent
    } as const)));
  }
  const entsToAdd = entIds.filter(ent => !currentEntIds.includes(ent));
  updates.push(...entsToAdd.map(ent => ({
    operation: "ADD",
    entitlement_id: ent
  } as const)));
  return {
    updates
  };
}
const {
  Text
} = Typography;
interface IPlanFormProps extends INavigationRouteProps<any> {
  errorData?: ErrorMessageDTO;
  canChangeThisPlan: boolean;
  currentApp: App | null;
  appId: UUID;
  orgId: UUID;
  planId: UUID;
  resetCurrentPlan: any;
  getEntitlementsFromApp: (appId: UUID) => Promise<PageDTO<EntitlementDTO>>;
  createEntitlementForApp: (entitlementCreateDTO: CreateEntitlementDTO) => Promise<EntitlementDTO>;
  editEntitlementForApp: (entitlementId: UUID, updateEntitlementDTO: UpdateEntitlementDTO) => Promise<EntitlementDTO>;
  dispatchGetPlanById: (entitlementId: UUID) => Promise<PlanDTO>;
  dispatchCreatePlan: (valuesRequest: CreatePlanDTO) => any;
  dispatchEditPlan: (planId: UUID, valuesRequest: UpdatePlanDTO) => any;
}
const PlanForm: React.FC<IPlanFormProps> = props => {
  const [currentLicensePlan, setCurrentLicensePlan] = useState<PlanDTO | undefined>(undefined);
  const [preSelectedKeys, setPreSelectedKeys] = useState<UUID[]>([]);
  const [isButtonLoading, setButtonLoading] = useState<boolean>(false);
  const [isLoadingPlanData, setIsLoadingPlanData] = useState<boolean>(false);
  const [initialQuota, setInitialQuota] = useState<QuotaDTO | undefined>(undefined);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);
  const [selectedEntitlementsIds, setSelectedEntitlementsIds] = useState<UUID[]>([]);
  const [newQuota, setNewQuota] = useState<QuotaDTO>();
  const [editedQuota, setEditedQuota] = useState<QuotaDTO>();
  const [EditingQuotaDrawer, setEditingQuotaDrawer] = useState(false);
  const [AdditionQuotaDrawer, setAdditionQuotaDrawer] = useState(false);
  const [quotaData, setQuotaData] = useState<QuotaDTO[]>([]);
  const [entitlementDrawer, setEntitlementDrawer] = useState(false);
  const [entitlementData, setEntitlementData] = useState<EntitlementDTO[]>([]);
  const [planEntitlementData, setPlanEntitlementData] = useState<EntitlementDTO[]>([]);
  const [visibleSuccessModal, setVisibleSuccessModal] = useState<boolean>(false);
  const [visibleErrorModal, setVisibleErrorModal] = useState<boolean>(false);
  const [visibleKeyErrorModal, setVisibleKeyErrorModal] = useState<boolean>(false);
  useEffect(() => {
    if (planEntitlementData) {
      setSelectedEntitlementsIds(planEntitlementData.map(entitlement => entitlement.id));
    }
  }, [planEntitlementData]);
  useEffect(() => {
    if (props.planId && props.dispatchGetPlanById) {
      setIsLoadingPlanData(true);
      props.dispatchGetPlanById(props.planId).then(plan => {
        setCurrentLicensePlan(plan);
        setIsLoadingPlanData(false);
      });
    }
  }, [props.planId, props.dispatchGetPlanById]);
  useEffect(() => {
    if (props.appId && props.canChangeThisPlan) {
      props.getEntitlementsFromApp(props.appId).then(response => setEntitlementData([...response.content]));
    }
  }, [props.canChangeThisPlan, props.appId]);
  useEffect(() => {
    if (props.errorData) {
      setButtonLoading(false);
    }
  }, [props.errorData]);
  useEffect(() => {
    const quotaIndex = quotaData?.findIndex(item => item.key === editedQuota?.key);
    if (quotaIndex !== -1 && editedQuota) {
      const newQuotaData = [...quotaData];
      newQuotaData[quotaIndex] = editedQuota;
      setQuotaData(newQuotaData);
    }
  }, [editedQuota]);
  useEffect(() => {
    if (newQuota) {
      if (quotaData?.findIndex(item => item.key === newQuota.key) === -1) {
        setQuotaData(quotaData?.concat(newQuota));
      } else {
        setVisibleKeyErrorModal(true);
      }
    }
  }, [newQuota]);
  useEffect(() => {
    const [plansEntitlements, planEntitlementsIds] = getEntitlementsAndItsIdsFromCurrentPlan(currentLicensePlan);
    setPlanEntitlementData(([...plansEntitlements] as EntitlementDTO[]));
    setPreSelectedKeys(([...planEntitlementsIds] as UUID[]));
    form.setFieldsValue({
      planName: currentLicensePlan?.name,
      entKey: '',
      entName: '',
      quotaType: '',
      quotaKey: '',
      quotaUnit: '',
      quotaEntity: '',
      quotaName: '',
      quotaValue: 0,
      quotaThreshold: 0,
      quotaPlanLimit: 0,
      valid_countries: currentLicensePlan?.valid_countries
    });
    // setQuotaData(currentLicensePlan?.quotas ?
    //     currentLicensePlan.quotas.map((quotas: Quota) => (
    //     {
    //       key: quotas.key,
    //       name: quotas.name,
    //       value: quotas.value,
    //       status: !!quotas.status,
    //       unit: quotas.unit,
    //       entity: quotas.entity,
    //       threshold: quotas.threshold,
    //       limit: quotas.limit,
    //     }
    //     ))
    //     : []);
    setIsSaveButtonDisabled(!currentLicensePlan);
  }, [currentLicensePlan]);

  // const onChangeQuotaStatus = (updatedQuota: QuotaDTO) => {
  //   const quotaIndex = quotaData?.findIndex((item) => item.key === updatedQuota?.key);
  //   if (updatedQuota) {
  //     const newQuotaData = [
  //       ...quotaData,
  //     ];
  //     // newQuotaData[quotaIndex].status = !(updatedQuota.status);
  //     setQuotaData(newQuotaData);
  //   }
  // };

  async function handlePlanCreation(values: any) {
    const valuesReq: CreatePlanDTO = {
      commercial_offers_ids: [],
      app: props.appId,
      name: values.planName,
      valid_countries: values.valid_countries,
      // quotas: quotaData,
      entitlements: buildEntitlementsUpdateOperations(selectedEntitlementsIds, currentLicensePlan)
    };
    if (valuesReq.name) {
      const planCreated = await props.dispatchCreatePlan(valuesReq);
      if (planCreated) {
        setVisibleSuccessModal(true);
      }
    } else {
      setVisibleErrorModal(true);
    }
  }
  async function handlePlanEdition(values: any) {
    const valuesReq: UpdatePlanDTO = {
      name: values.planName,
      app: props.appId,
      commercial_offers_ids: [],
      valid_countries: values.valid_countries ?? [],
      // quotas: quotaData,
      entitlements: buildEntitlementsUpdateOperations(selectedEntitlementsIds, currentLicensePlan)
    };
    const planEdited = await props.dispatchEditPlan(currentLicensePlan?.id || '', valuesReq);
    if (planEdited) {
      setVisibleSuccessModal(true);
    }
  }
  const handleSendValues = async (values: any, action: string) => {
    if (action === 'create') {
      await handlePlanCreation(values);
    } else if (action === 'edit' && currentLicensePlan?.id) {
      await handlePlanEdition(values);
    }
  };
  const onDeleteQuota = (quotaKey?: string) => {
    if (quotaKey) {
      setQuotaData(quotaData?.filter(item => item.key !== quotaKey));
    }
    setEditingQuotaDrawer(false);
  };
  const onCloseEditionDrawer = () => {
    setEditingQuotaDrawer(false);
  };
  const onCloseAdditionDrawer = () => {
    setAdditionQuotaDrawer(false);
  };
  const onCloseEntitlementDrawer = () => {
    setEntitlementDrawer(false);
  };
  const onCancelEntitlementDrawer = () => {
    onCloseEntitlementDrawer();
    const entitlementIds: UUID[] = planEntitlementData.map(entitlement => entitlement.id);
    setPreSelectedKeys(entitlementIds);
    setSelectedEntitlementsIds(entitlementIds);
  };
  async function handleCreateAndEdit(values: any) {
    setButtonLoading(true);
    await handleSendValues(values, currentLicensePlan ? 'edit' : 'create');
  }
  const onHandleInputChange = () => {
    const isValidName = (name: string) => {
      return name.length > 0 && name.length < AppConstants.NAME_MAX_LENGTH;
    };
    form.validateFields(['planName']).then(plan => {
      if (isValidName(plan.planName)) {
        setIsSaveButtonDisabled(false);
      } else {
        setIsSaveButtonDisabled(true);
      }
    }).catch(() => {
      setIsSaveButtonDisabled(true);
    });
  };
  const handleEntitlementEdition = async (entitlementId: UUID, updateEntitlementDTO: UpdateEntitlementDTO, currentIndex: number) => {
    props.editEntitlementForApp(entitlementId, updateEntitlementDTO).then(newEntitlementDTO => {
      entitlementData[currentIndex] = newEntitlementDTO;
      setEntitlementData([...entitlementData]);
    });
  };
  const handleEntitlementCreation = (createEntitlementDTO: CreateEntitlementDTO) => {
    props.createEntitlementForApp(createEntitlementDTO).then(newEntitlementDTO => setEntitlementData([...entitlementData, newEntitlementDTO]));
  };
  const handleDeleteEntitlement = (entitlementId: UUID, currentIndex: number) => {
    setEntitlementData([...entitlementData.filter(entitlement => entitlement.id !== entitlementId)]);
    setPlanEntitlementData([...planEntitlementData.filter(entitlement => entitlement.id !== entitlementId)]);
    setPreSelectedKeys([...preSelectedKeys].filter(preselectedKey => entitlementId !== preselectedKey));
  };
  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    // tslint:disable-next-line:no-console
    console.log('params', pagination, filters, sorter, extra);
  }
  const handleModalClose = async (type: string) => {
    if (type === 'success') {
      setVisibleSuccessModal(false);
      setButtonLoading(false);
      history.push({
        pathname: RoutePaths.LICENSING_PAGE(props.appId)
      });
      if (props.resetCurrentPlan) {
        await props.resetCurrentPlan().catch();
      }
    } else {
      setVisibleErrorModal(false);
    }
  };
  const quotaColumns = [{
    title: 'Key',
    dataIndex: 'key',
    key: 'key'
  }, {
    title: 'Entity',
    dataIndex: 'entity_type',
    key: 'entity_type',
    render: (text: any, record: any) => <>
                    {record.entity_type ? <Text>{record.entity_type}</Text> : <Text>-</Text>}
                </>
  }, {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text: any, record: any) => <>
                    {record.system === true ? <Text> {record.name}
                            <Tooltip title="Platform Managed Quota">
                                <div className="spacedIcons">
                                    <KeyIcon />
                                </div>
                            </Tooltip>
                        </Text> : <Text> {record.name} </Text>}
                </>
  }, {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    render: (text: any, record: any) => <>
                    {record.unit ? <Text>{record.unit}</Text> : <Text>-</Text>}
                </>
  }, {
    title: 'Threshold',
    dataIndex: 'threshold',
    key: 'threshold',
    render: (text: any, record: any) => <>
                    {record.threshold !== undefined ? <Text>{record.threshold}</Text> : <Text>-</Text>}
                </>
  }, {
    title: 'Limit',
    dataIndex: 'max_limit',
    key: 'max_limit',
    render: (text: any, record: any) => <>
                    {record.max_limit !== undefined ? <Text>{record.max_limit}</Text> : <Text>-</Text>}
                </>
  }, {
    title: '',
    dataIndex: 'edit',
    key: 'edit',
    align: ('right' as const),
    render: (text: any, record: any) => <>
                    <a className="tableEditIcon"
      // @TODO Disabled as backend team didn't implement quotas api yet
      // onClick={() => {
      //   setEditingQuotaDrawer(true);
      //   setInitialQuota(record);
      // }}
      >
                        <EditIcon />
                    </a>
                </>
  }];
  const [form] = Form.useForm();
  return <Form form={form} layout="vertical" name="form_in_drawer" initialValues={{
    planName: currentLicensePlan?.name,
    entKey: '',
    entName: '',
    valid_countries: currentLicensePlan?.valid_countries,
    quotaType: '',
    quotaKey: '',
    quotaUnit: '',
    quotaEntity: '',
    quotaName: '',
    quotaValue: 0,
    quotaThreshold: 0,
    quotaPlanLimit: 0
  }} onFinish={handleCreateAndEdit} onChange={onHandleInputChange} data-sentry-element="Form" data-sentry-component="PlanForm" data-sentry-source-file="index.tsx">
            <PlanFormSuccessModal successText={currentLicensePlan ? 'Plan successfully updated!' : 'Plan successfully created!'} handleClose={handleModalClose} isVisible={visibleSuccessModal} data-sentry-element="PlanFormSuccessModal" data-sentry-source-file="index.tsx" />
            <PlanFormErrorModal errorIcon={currentLicensePlan ? <CloseCircleFilled className="modal-error" /> : <ExclamationCircleFilled className="name-error" />} errorText={currentLicensePlan ? 'Sorry, plan couldn\'t be updated.' : 'To create a plan, you must add a name.'} handleClose={handleModalClose} isVisible={visibleErrorModal} data-sentry-element="PlanFormErrorModal" data-sentry-source-file="index.tsx" />
            <PlanFormKeyModals isVisible={visibleKeyErrorModal} handleClose={() => setVisibleKeyErrorModal(false)} data-sentry-element="PlanFormKeyModals" data-sentry-source-file="index.tsx" />
            <div className="newLicensePlanContentData">
                <div className="newLicensePlanHeadings">
                    <div className="newLicensePlanHeadingsColumn1">
                        <Text strong={true} style={{
            fontSize: '20px',
            color: '#2F3031'
          }} data-sentry-element="Text" data-sentry-source-file="index.tsx">
                            {currentLicensePlan ? 'Edit Plan' : 'Create Plan'}
                        </Text>
                    </div>
                    <div className="newLicensePlanHeadingsColumn2">
                        <div className="newLicensePlanHeadingsButtons">
                            <Button size="large" style={{
              width: '76px',
              textAlign: 'center'
            }} type={ButtonType.default} onClick={() => history.push({
              pathname: RoutePaths.LICENSING_PAGE(props.appId)
            })} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                Cancel
                            </Button>
                            {iff(props.canChangeThisPlan, <div>
                                        <Button size="large" style={{
                width: '150px',
                textAlign: 'center'
              }} type={ButtonType.primary}
              // @ts-ignore
              htmlType="submit" loading={isButtonLoading} disabled={isSaveButtonDisabled}>
                                            {currentLicensePlan ? 'Send Changes' : 'Save Plan'}
                                        </Button>
                                    </div>)}
                        </div>
                    </div>
                </div>
                <div className="newLicensePlanTop">
                    <div className="newLicensePlanTopColumn1">
                        <div className="newLicensePlanName">
                            <Form.Item name="planName" label={<Text strong={true} style={{
              fontSize: '16px',
              color: '#2F3031'
            }}>
                                        Plan name
                                    </Text>} rules={[{
              required: true,
              message: 'Plan name cannot be empty'
            }]} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                                <Input className="cw-plan-name" data-testid="inputId" size="middle" placeholder={'Plan Name'} autoFocus={true} maxLength={AppConstants.NAME_MAX_LENGTH} disabled={!props.canChangeThisPlan} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="newLicensePlanTopColumn1">
                      <LocationSelectComponent canChangeLocation={props.canChangeThisPlan} data-sentry-element="LocationSelectComponent" data-sentry-source-file="index.tsx" />
                    </div>
                </div>
                <div className="newLicensePlanBody">
                    <div className="newLicensePlanBodyColumn1">
                        <div className="entiltlementsHeadings">
                            <Text strong={true} style={{
              fontSize: '16px',
              color: '#2F3031',
              margin: 0
            }} data-sentry-element="Text" data-sentry-source-file="index.tsx">
                                Entitlements
                            </Text>

                            <Button size="large" style={{
              width: 147,
              borderColor: '#c1c5c8'
            }} type={ButtonType.success} onClick={() => setEntitlementDrawer(true)} disabled={!props.canChangeThisPlan} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                              Add Entitlement
                            </Button>
                            <EntitlementDrawer preSelectedKeys={preSelectedKeys} currentApp={props.currentApp} appEntitlements={entitlementData} drawerVisible={entitlementDrawer} onCancelEntitlementDrawer={onCancelEntitlementDrawer} onSaveEntitlementDrawer={onCloseEntitlementDrawer} onSubmit={() => {
              return;
            }} onDelete={handleDeleteEntitlement} onCreateEntitlement={handleEntitlementCreation} onEditEntitlement={handleEntitlementEdition} onSetEntitlementsOnPlan={(entitlementIds: UUID[], entitlements: EntitlementDTO[]) => {
              setPlanEntitlementData(entitlements);
              setSelectedEntitlementsIds(entitlementIds);
              setPreSelectedKeys(entitlementIds);
            }} data-sentry-element="EntitlementDrawer" data-sentry-source-file="index.tsx" />
                        </div>
                        <EntitlementsTable entitlementsData={planEntitlementData} enableRowSelection={false} isLoadingPlanData={isLoadingPlanData} data-sentry-element="EntitlementsTable" data-sentry-source-file="index.tsx" />
                    </div>
                    <div className="newLicensePlanBodyColumn2">
                        <div className="quotasHeadings">
                            <div className="quotaHeadingsText">
                                <Text strong={true} style={{
                fontSize: '16px',
                color: '#2F3031'
              }} data-sentry-element="Text" data-sentry-source-file="index.tsx">
                                    Quotas
                                    <Tooltip title="The platform give support to two types of quotas: the App Managed Quotas and the Platform Managed Quotas." data-sentry-element="Tooltip" data-sentry-source-file="index.tsx">
                                        <InfoCircleOutlined style={{
                    marginLeft: '5px'
                  }} data-sentry-element="InfoCircleOutlined" data-sentry-source-file="index.tsx" />
                                    </Tooltip>
                                </Text>
                            </div>
                            <Button size="large" style={{
              width: 147,
              borderColor: '#c1c5c8'
            }} type={ButtonType.success} onClick={() => {
              setInitialQuota(undefined);
              setAdditionQuotaDrawer(true);
            }}
            // disabled={!props.canChangeThisPlan}
            // @TODO Disabled as backend team didn't implement quotas api yet
            disabled={true} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                              Add Quota
                            </Button>
                        </div>
                        <QuotaDrawer drawerVisible={initialQuota ? EditingQuotaDrawer : AdditionQuotaDrawer} onCloseDrawer={initialQuota ? onCloseEditionDrawer : onCloseAdditionDrawer} onSubmit={initialQuota ? setEditedQuota : setNewQuota} canChangeThisQuota={props.canChangeThisPlan} initialQuota={initialQuota} onDelete={onDeleteQuota} data-sentry-element="QuotaDrawer" data-sentry-source-file="index.tsx" />
                        <Table dataSource={quotaData} columns={quotaColumns} pagination={false} onChange={onChange} size="middle" data-sentry-element="Table" data-sentry-source-file="index.tsx" />
                    </div>
                </div>
            </div>
        </Form>;
};
const mapStateToProps = (state: AppState) => ({
  errorData: getErrorMessage(state)
});
const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatchGetPlanById: (planId: UUID) => dispatch(getPlanById(planId)),
    dispatchCreatePlan: (valuesRequest: any) => dispatch(createPlan(valuesRequest)),
    dispatchEditPlan: (planId: UUID, valuesRequest: any) => dispatch(editPlan(planId, valuesRequest)),
    getEntitlementsFromApp: (appId: UUID) => dispatch(getAppEntitlements(appId)),
    createEntitlementForApp: (entitlementCreateDTO: CreateEntitlementDTO) => dispatch(createEntitlementsForApp(entitlementCreateDTO)),
    editEntitlementForApp: (entitlementId: UUID, updateEntitlementDTO: UpdateEntitlementDTO) => dispatch(editEntitlementsForApp(entitlementId, updateEntitlementDTO)),
    resetCurrentPlan: () => dispatch(resetGetPlan())
  };
};
export const PlanFormComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(PlanForm));