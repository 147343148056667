import React from 'react';
export const NoAccessImg = () => {
  return <svg width="434" height="216" viewBox="0 0 434 216" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="NoAccessImg" data-sentry-source-file="NoAccessImg.tsx">
      <path d="M48.1197 184.984C29.3339 169.298 0.5 182.805 0.5 214L433.39 214C435.193 190.648 414.66 159.099 388.011 169.298C368.861 176.626 338.789 186.727 309.316 159.099C292.771 143.59 277.663 157.032 262.838 165.937C248.012 174.842 235.066 152.781 212.386 165.937C181.804 183.677 173.067 161.019 152.97 165.937C138.245 169.541 133.259 193.546 102.729 178.884C85.6912 170.701 68.3574 201.882 48.1197 184.984Z" fill="white" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <g opacity="0.6" data-sentry-element="g" data-sentry-source-file="NoAccessImg.tsx">
        <rect x="65.0503" y="98" width="7.86225" height="107.734" rx="1" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect x="329.222" y="98" width="7.86225" height="107.734" rx="1" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect x="196.5" y="104" width="7" height="108" rx="1" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect x="90.5" y="104" width="6" height="105" rx="0.875783" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect x="196.5" y="113" width="139" height="9.94468" rx="1" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect x="196.5" y="134" width="139" height="9.94468" rx="1" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect x="198.072" y="154.437" width="139" height="9.94468" rx="1" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect x="198.072" y="175.983" width="139" height="9.94468" rx="1" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect x="198.072" y="195.873" width="139" height="9.94468" rx="1" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect x="68.5" y="111.083" width="23" height="9.94468" rx="1" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect x="68.5" y="132.083" width="23" height="9.94468" rx="1" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect width="157.192" height="8.12457" rx="1" transform="matrix(0.854251 0.519861 -0.480398 0.877051 201.039 114.403)" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect x="70.0723" y="152.52" width="23" height="9.94468" rx="1" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect x="70.0723" y="174.066" width="23" height="9.94468" rx="1" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <rect x="70.0723" y="193.956" width="23" height="9.94468" rx="1" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
        <path d="M28.7043 205.311C28.8931 205.113 29.1552 205 29.4293 205H72.5C73.0523 205 73.5 205.448 73.5 206V214C73.5 214.552 73.0523 215 72.5 215H21.8293C20.9507 215 20.4992 213.948 21.1043 213.311L28.7043 205.311Z" fill="#C2C7D0" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
        <path d="M328.5 206C328.5 205.448 328.948 205 329.5 205H378.055C378.338 205 378.608 205.12 378.798 205.331L385.998 213.331C386.577 213.975 386.12 215 385.255 215H329.5C328.948 215 328.5 214.552 328.5 214V206Z" fill="#C2C7D0" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
        <rect width="83.3626" height="4.45963" rx="1" transform="matrix(0.222373 0.974962 -0.963817 0.266566 73.5135 116.337)" fill="#C2C7D0" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
      </g>
      <path d="M181.072 76.4579C181.188 75.3576 182.695 71.6808 183.797 71.8568C184.898 72.0327 183.506 74.6085 183.448 75.1587C183.39 75.709 186.259 75.0482 186.926 74.0355C187.593 73.0227 187.26 70.4475 188.419 70.0731C189.578 69.6986 189.23 73.0003 188.809 74.3322C188.389 75.6641 188.534 77.3703 187.867 78.383C187.201 79.3958 185.679 80.5517 185.012 81.5645C184.479 82.3746 183.659 83.773 183.012 84.6023L182.345 85.615L179.882 82.4232L179.848 82.3782C179.765 82.3845 179.576 82.5035 179.636 82.1046C181.52 80.1671 180.956 77.5586 181.072 76.4579Z" fill="#DFE2E7" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M179.882 82.4232C179.907 82.2615 179.557 82.6267 179.636 82.1046M179.636 82.1046C181.52 80.1671 180.956 77.5586 181.072 76.4579C181.188 75.3576 182.695 71.6808 183.797 71.8568C184.898 72.0327 183.506 74.6084 183.448 75.1587C183.39 75.709 186.259 75.0482 186.926 74.0355C187.593 73.0227 187.26 70.4475 188.419 70.0731C189.578 69.6986 189.23 73.0003 188.809 74.3322C188.389 75.6641 188.534 77.3703 187.867 78.383C187.201 79.3958 185.679 80.5517 185.012 81.5645C184.479 82.3746 183.659 83.773 183.012 84.6023L182.345 85.615L179.636 82.1046Z" stroke="#00004B" strokeWidth="0.935495" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M102.984 77.6426C102.753 76.5027 100.81 72.805 99.6744 73.0985C98.5393 73.3921 100.252 75.9491 100.367 76.5192C100.483 77.0892 97.4139 76.6828 96.6155 75.6893C95.817 74.6958 95.9094 71.968 94.6588 71.6913C93.4082 71.4147 94.1011 74.8351 94.6735 76.187C95.2459 77.5389 95.2639 79.3387 96.0623 80.3322C96.8608 81.3257 98.5683 82.3841 99.3667 83.3776C100.005 84.1724 101.002 85.5542 101.762 86.3577L102.561 87.3512L104.821 83.7666L104.853 83.716C104.94 83.7144 105.151 83.8201 105.047 83.4087C102.883 81.5685 103.215 78.7828 102.984 77.6426Z" fill="#DFE2E7" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M104.821 83.7666C104.78 83.5997 105.183 83.9471 105.047 83.4087M105.047 83.4087C102.883 81.5685 103.215 78.7828 102.984 77.6426C102.753 76.5027 100.81 72.805 99.6744 73.0985C98.5393 73.3921 100.252 75.9491 100.367 76.5192C100.483 77.0892 97.4139 76.6828 96.6155 75.6893C95.817 74.6958 95.9094 71.968 94.6588 71.6913C93.4082 71.4147 94.1011 74.8351 94.6735 76.187C95.2459 77.5389 95.2639 79.3387 96.0623 80.3322C96.8608 81.3257 98.5683 82.3841 99.3667 83.3776C100.005 84.1724 101.002 85.5542 101.762 86.3577L102.561 87.3512L105.047 83.4087Z" stroke="#00004B" strokeWidth="0.935495" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M178.577 82.9514C174.704 88.7801 171.218 98.4947 169.282 98.4947C167.345 95.386 165.865 92.2493 165.022 90.6339C164.178 89.0185 163.281 84.4501 160.374 80.6199C157.196 76.4323 153.889 77.8486 150.937 77.4447C150.777 77.4227 150.505 77.3881 150.151 77.3443C143.485 80.9264 141.51 76.3298 140.407 76.3298C139.142 76.3298 137.446 76.2332 136.181 76.2332C134.917 76.2332 130.97 77.3986 128.229 79.8427C125.489 82.2869 122.756 98.1528 122.033 97.7175C120.096 96.5518 105.766 86.4491 105.766 82.9514C105.204 82.6821 103.442 83.7285 101.893 84.1171C100.257 84.5275 99.6645 84.5057 98.7953 84.5057C97.896 84.5057 107.028 95.9277 108.478 97.3289C115.062 103.695 120.484 109.886 124.744 108.945C129.171 107.967 129.778 92.6659 129.778 92.6659V94.9978C129.778 96.2093 129.436 102.483 129.436 103.695C129.436 104.906 129.857 115.81 129.436 116.618C129.099 117.264 129.295 120.926 129.436 122.676H157.683C157.824 118.368 158.526 110.56 158.526 108.945C158.526 105.714 158.05 96.3457 158.05 95.7746C158.05 95.3707 159.267 98.8173 159.688 99.625C160.11 100.433 161.855 104.681 162.698 105.489C163.541 106.297 165.409 111.318 171.993 107.432C173.748 106.396 173.379 107.525 178.964 97.7175C182.063 92.2773 182.45 89.9459 185.548 82.9514H178.577Z" fill="white" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M150.937 77.4447C153.889 77.8486 157.196 76.4323 160.374 80.6199C163.281 84.4501 164.178 89.0185 165.022 90.6339C165.865 92.2493 167.345 95.386 169.282 98.4947C171.218 98.4947 174.704 88.7801 178.577 82.9514H185.548C182.45 89.9459 182.063 92.2773 178.964 97.7175C173.379 107.525 173.748 106.396 171.993 107.432C165.409 111.318 163.541 106.297 162.698 105.489C161.855 104.681 160.11 100.433 159.688 99.625C159.267 98.8173 158.05 95.3707 158.05 95.7746C158.05 96.3457 158.526 105.714 158.526 108.945C158.526 110.56 157.824 118.368 157.683 122.676H129.436C129.295 120.926 129.099 117.264 129.436 116.618C129.857 115.81 129.436 104.906 129.436 103.695C129.436 102.483 129.778 96.2093 129.778 94.9978C129.778 92.9785 129.778 92.6659 129.778 92.6659C129.778 92.6659 129.171 107.967 124.744 108.945C120.484 109.886 115.062 103.695 108.478 97.3289C107.028 95.9277 97.896 84.5057 98.7953 84.5057C99.6645 84.5057 100.257 84.5275 101.893 84.1171C103.442 83.7285 105.204 82.6821 105.766 82.9514C105.766 86.4491 120.096 96.5518 122.033 97.7175C122.756 98.1528 125.489 82.2869 128.229 79.8427C130.97 77.3986 134.917 76.2332 136.181 76.2332C137.446 76.2332 139.142 76.3298 140.407 76.3298C141.51 76.3298 143.485 80.9264 150.151 77.3443M150.937 77.4447C150.777 77.4227 150.505 77.3881 150.151 77.3443M150.937 77.4447L150.151 77.3443" stroke="#00004B" strokeWidth="0.935495" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M149.833 72.6567C149.833 74.256 150.207 76.082 150.771 77.9666C146.804 81.8106 139.59 78.3145 140.195 77.1588C140.619 76.3511 141.464 73.5241 141.888 71.505L140.619 65.851C139.773 66.2549 140.143 66.1062 139.128 65.1369C138.705 64.7331 137.833 63.0239 138.256 61.8125C138.642 60.7074 139.856 61.1259 140.619 61.8127L140.628 59.9768C140.195 48.1021 157.442 45.7348 154.757 59.4453C154.757 59.4453 155.053 60.5874 154.757 63.2567C154.309 67.2852 152.561 69.0757 150.771 70.4186C149.643 71.8994 149.833 71.9273 149.833 72.6567Z" fill="#DFE2E7" stroke="#00004B" strokeWidth="0.935495" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M139.856 58.0617C140.242 56.9125 140.806 55.7355 141.652 54.9973C142.013 55.3943 141.809 58.0518 141.652 58.8083C141.496 59.5647 140.816 60.532 140.259 61.0805C140.161 61.4177 139.47 59.2108 139.856 58.0617Z" fill="#00004B" stroke="#00004B" strokeWidth="0.935495" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M139.774 65.543C137.502 64.9541 132.895 62.1471 131.044 60.3606C129.036 58.4223 128.583 54.155 133.435 53.6653C138.288 53.1755 140.749 55.8258 144.328 56.908C144.472 56.9516 144.618 56.9957 144.765 57.0403C146.83 57.1386 152.706 59.0699 154.224 59.4224C155.146 59.5496 155.967 59.5986 156.632 59.5371C161.487 59.0875 163.707 55.9492 163.673 56.6686C163.639 57.3879 163.766 60.0693 162.046 61.8379C161.227 62.6799 157.991 63.3546 154.414 63.1842C155.097 63.2044 155.119 59.63 154.224 59.4224C151.458 59.0404 147.791 57.9539 144.765 57.0403C142.7 56.9419 141.573 56.7767 140.819 58.1027C140.065 59.4286 140.221 60.9981 140.657 61.4996C139.991 60.9872 138.322 60.2939 137.901 61.3682C137.115 63.3736 139.567 65.0525 139.774 65.543Z" fill="white" stroke="#00004B" strokeWidth="0.935495" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M140.489 50.5959C139.92 51.1209 139.045 53.4623 139.217 54.6208C140.314 55.3633 142.963 57.0671 144.515 57.5598C147.05 58.3647 150.726 59.2377 152.665 59.3301C154.293 59.4077 157.292 60.2485 158.734 58.9554C158.045 58.9226 156.561 58.686 156.045 58.1092C155.399 57.3883 155.531 54.6337 155.596 53.2565C155.662 51.8792 155.039 48.0342 153.773 47.1434C153.032 46.622 150.796 46.6516 148.755 46.0284C146.075 45.2105 144.224 47.1029 143.618 47.8543C141.899 49.9822 141.2 49.9396 140.489 50.5959Z" fill="white" stroke="#00004B" strokeWidth="0.935495" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M142.951 56.8407C144.361 56.227 147.838 55.7118 151.25 56.5552C155.623 57.6362 156.632 59.5354 158.042 58.9217" stroke="#00004B" strokeWidth="0.935495" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M160.924 189.429C160.264 193.208 159.232 199.929 158.809 203.564L149.503 203.16V201.544C149.926 191.852 150.856 171.821 151.195 169.236C151.618 166.006 149.08 159.14 148.234 156.717C147.388 154.294 144.85 138.544 143.581 140.159C142.939 140.977 141.466 155.102 140.62 161.967C140.338 167.89 139.689 180.625 139.351 184.179C139.012 187.733 138.364 198.314 138.082 203.16H129.622V201.544C129.34 190.775 128.775 168.833 128.775 167.217V138.14V113.505C128.775 112.859 129.199 102.601 129.622 96.9476C129.682 96.1419 130.891 94.5245 133.006 92.5053C134.239 91.3278 134.134 89.27 134.275 87.2552C134.698 81.1975 134.698 80.3898 135.121 76.7552C135.121 76.7552 135.544 76.3513 136.813 76.3513C138.15 76.3513 138.505 76.7552 138.505 76.7552C138.082 82.0052 137.236 91.6976 138.082 92.1014C140.011 93.0223 146.542 92.5053 149.926 92.5053C153.31 92.5053 152.887 90.8899 152.887 89.6783C152.887 88.7091 152.464 81.0629 152.464 77.5629C152.887 77.5629 154.156 77.5629 155.425 77.9667C155.989 83.2167 156.824 91.6333 157.117 93.3129C157.54 95.736 158.809 95.736 158.809 98.563V107.044C158.809 111.486 157.963 118.352 158.386 122.39C158.809 126.429 160.501 137.332 161.347 140.159C162.193 142.986 165.154 167.621 165.154 170.044C165.154 172.467 161.77 184.583 160.924 189.429Z" fill="#00004B" stroke="#00004B" strokeWidth="0.935495" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M161.032 202.432C161.298 199.69 161.57 196.731 161.84 194.291C161.928 193.496 161.309 192.797 160.51 192.797H149.742C148.967 192.797 148.356 193.456 148.421 194.228C148.838 199.204 149.741 208.948 149.741 209.553V214.999H152.686L153.644 213.083L155.632 214.999H171.342C171.505 214.719 171.735 213.266 171.342 212.26C170.851 211.004 162.014 209.553 161.032 208.715C160.05 207.877 160.798 204.85 161.032 202.432Z" fill="white" stroke="#00004B" strokeWidth="0.935495" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <rect width="16.6107" height="1.58584" transform="matrix(-1 0 0 1 171.559 213.414)" fill="#00004B" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M139.028 212.864C139.028 212.235 139.028 200.811 139.785 194.341C139.88 193.527 139.253 192.797 138.434 192.797H129.812C129.035 192.797 128.423 193.459 128.49 194.233C128.831 198.16 129.338 203.337 129.338 205.179C129.338 207.313 129.338 213.291 129.823 214.999H139.028V212.864Z" fill="white" stroke="#00004B" strokeWidth="0.935495" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M139 213.414H130.032V215H139V213.414Z" fill="#00004B" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <rect width="3.32214" height="1.58584" transform="matrix(-1 0 0 1 153.29 213.414)" fill="#00004B" data-sentry-element="rect" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M144.897 64.7976C146.328 64.5823 147.14 64.958 148.126 65.787C147.712 66.3718 146.104 67.3003 145.195 67.3477C144.285 67.3951 143.053 66.8491 142.33 66.3236C141.92 66.3088 143.932 64.9428 144.897 64.7976Z" fill="#00004B" stroke="#00004B" strokeWidth="0.935495" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M150.956 67.5937C149.363 67.0389 148.999 66.2518 148.464 65.7796C148.897 65.4648 150.175 65.251 151.228 65.6176C152.821 66.1721 153.103 66.5463 153.996 67.3401C153.295 67.5793 152.549 68.1484 150.956 67.5937Z" fill="#00004B" stroke="#00004B" strokeWidth="0.935495" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path opacity="0.7" d="M130 213.5C130.333 211.833 130 209 134.5 209C139.028 209 138.5 211.5 138.5 213.5" stroke="#868CA2" strokeWidth="0.8" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M57.0173 196.074C58.8588 202.411 59.0886 210.131 59.0034 211.85C57.8767 212.481 57.251 212.303 57.251 212.303C57.251 212.303 57.5064 207.146 56.7617 201.23C56.0136 195.287 53.8932 188.426 52.229 184.269C51.8243 183.349 51.6213 182.882 51.7756 183.176C51.9217 183.515 52.0734 183.88 52.229 184.269C53.3858 186.899 56.1911 193.231 57.0173 196.074Z" fill="#73DC78" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M61.3264 195.789C61.6689 202.256 59.9414 209.426 59.4116 210.974C58.0503 211.157 57.4293 210.773 57.4293 210.773C57.4293 210.773 59.0187 206.128 59.7369 200.433C60.4584 194.713 59.9526 187.666 59.242 183.262C59.046 182.275 58.9491 181.774 59.0382 182.099C59.1074 182.461 59.1756 182.85 59.242 183.262C59.8024 186.084 61.1727 192.887 61.3264 195.789Z" fill="#00004B" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M55.5312 196.229C58.0082 202.213 58.7672 209.549 58.7832 211.185C57.5603 211.81 56.847 211.655 56.847 211.655C56.847 211.655 56.7987 206.746 55.5794 201.138C54.3544 195.503 51.5305 189.027 49.394 185.111C48.8803 184.246 48.6223 183.806 48.8145 184.083C49.0004 184.401 49.1942 184.745 49.394 185.111C50.8622 187.586 54.4199 193.544 55.5312 196.229Z" fill="#00004B" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M59.0024 196.809C59.8328 203.232 58.6521 210.512 58.2408 212.096C56.8972 212.381 56.249 212.045 56.249 212.045C56.249 212.045 57.4828 207.293 57.7685 201.561C58.0555 195.802 57.0186 188.813 55.9773 184.476C55.7071 183.506 55.5727 183.015 55.6861 183.332C55.7825 183.688 55.8799 184.07 55.9773 184.476C56.7493 187.248 58.6299 193.927 59.0024 196.809Z" fill="#009933" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M60.8627 195.536C58.7494 202.047 58.2174 209.724 58.2373 211.416C59.3559 211.898 59.9974 211.642 59.9974 211.642C59.9974 211.642 59.9379 206.566 60.9223 200.611C61.9112 194.629 64.3278 187.563 66.1768 183.234C66.623 182.271 66.8469 181.783 66.6791 182.094C66.5177 182.448 66.3497 182.829 66.1768 183.234C64.9016 185.985 61.8108 192.614 60.8627 195.536Z" fill="#73DC78" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M45.6319 214.966L54.8341 214.954C56.8573 214.952 58.3535 212.972 57.747 211.042C56.468 206.971 54.5746 202.569 50.7964 203.034C47.8544 203.396 44.9495 207.498 43.103 211.167C42.1863 212.988 43.593 214.968 45.6319 214.966Z" fill="#A3A9B9" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M73.4449 214.8L59.5237 214.789C57.2672 214.788 55.6826 212.315 56.8384 210.377C58.5865 207.446 61.1506 204.702 65.7178 205.037C69.3512 205.303 72.9435 208.038 75.477 210.767C77.0266 212.436 75.7225 214.801 73.4449 214.8Z" fill="#696F88" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M50.6102 214.996L60.4821 214.989C62.751 214.987 64.3786 212.456 63.0814 210.594C61.6379 208.523 59.5688 206.78 56.1779 207.023C53.4489 207.218 50.7476 209.044 48.692 211.03C47.0452 212.622 48.3201 214.998 50.6102 214.996Z" fill="#4D5165" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M336.018 196.074C337.859 202.411 338.089 210.131 338.004 211.85C336.877 212.481 336.251 212.303 336.251 212.303C336.251 212.303 336.507 207.146 335.762 201.23C335.014 195.287 332.894 188.426 331.229 184.269C330.825 183.349 330.622 182.882 330.776 183.176C330.922 183.515 331.074 183.88 331.229 184.269C332.386 186.899 335.191 193.231 336.018 196.074Z" fill="#73DC78" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M337.591 195.789C337.934 202.256 336.206 209.426 335.677 210.974C334.315 211.157 333.694 210.773 333.694 210.773C333.694 210.773 335.284 206.128 336.002 200.433C336.723 194.713 336.218 187.666 335.507 183.262C335.311 182.275 335.214 181.774 335.303 182.099C335.372 182.461 335.441 182.85 335.507 183.262C336.067 186.084 337.438 192.887 337.591 195.789Z" fill="#00004B" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M333.62 196.405C336.097 202.39 336.856 209.725 336.872 211.362C335.649 211.987 334.936 211.832 334.936 211.832C334.936 211.832 334.887 206.923 333.668 201.314C332.443 195.68 329.619 189.204 327.483 185.288C326.969 184.422 326.711 183.983 326.903 184.259C327.089 184.578 327.283 184.922 327.483 185.288C328.951 187.762 332.509 193.72 333.62 196.405Z" fill="#00004B" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M339.863 195.536C337.75 202.047 337.218 209.724 337.238 211.416C338.356 211.898 338.998 211.642 338.998 211.642C338.998 211.642 338.938 206.566 339.923 200.611C340.912 194.629 343.328 187.563 345.177 183.234C345.623 182.271 345.847 181.783 345.679 182.094C345.518 182.448 345.35 182.829 345.177 183.234C343.902 185.985 340.811 192.614 339.863 195.536Z" fill="#73DC78" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M333.78 214.642L324.578 214.631C322.555 214.628 321.058 212.649 321.665 210.718C322.944 206.648 324.837 202.245 328.616 202.711C331.558 203.073 334.462 207.175 336.309 210.844C337.226 212.665 335.819 214.645 333.78 214.642Z" fill="#A3A9B9" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M352.445 214.8L338.524 214.789C336.267 214.788 334.683 212.315 335.839 210.377C337.587 207.446 340.151 204.702 344.718 205.037C348.351 205.303 351.944 208.038 354.477 210.767C356.027 212.436 354.723 214.801 352.445 214.8Z" fill="#696F88" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path opacity="0.1" d="M386.418 59H322.165C320.429 57.2606 322.165 52.91 326.507 52.9092C330.848 52.9084 328.72 48.4178 334.321 44.2086C341.267 38.9877 349.95 44.2087 352.555 47.6892C355.16 51.1697 359.11 45.2151 364.71 46.8192C369.92 48.3114 369.618 54.0742 372.525 52.9092C381.207 49.4295 387.286 59.0008 386.418 59Z" fill="#868CA2" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path d="M294.57 70H348.937C350.406 68.5675 348.937 64.9847 345.263 64.984C341.59 64.9834 343.39 61.2853 338.652 57.8189C332.774 53.5193 325.427 57.8189 323.223 60.6852C321.019 63.5515 317.676 58.6477 312.938 59.9687C308.53 61.1976 308.784 65.9434 306.325 64.984C298.979 62.1184 293.835 70.0007 294.57 70Z" fill="white" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path opacity="0.1" d="M149.57 15H203.937C205.406 13.4652 203.937 9.62645 200.263 9.62575C196.59 9.62504 198.39 5.66278 193.652 1.9488C187.774 -2.65789 180.427 1.94887 178.223 5.01988C176.019 8.09088 172.676 2.83684 167.938 4.25219C163.53 5.56887 163.784 10.6537 161.325 9.62575C153.979 6.55545 148.835 15.0007 149.57 15Z" fill="#868CA2" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
      <path opacity="0.1" d="M29.5965 81H104.722C106.752 78.9536 104.723 73.8353 99.6459 73.8343C94.5698 73.8334 97.0576 68.5504 90.5098 63.5984C82.3881 57.4561 72.2352 63.5985 69.1896 67.6932C66.144 71.7878 61.5253 64.7825 54.9775 66.6696C48.8862 68.4252 49.2385 75.2049 45.8398 73.8343C35.6885 69.7406 28.5812 81.0009 29.5965 81Z" fill="#868CA2" data-sentry-element="path" data-sentry-source-file="NoAccessImg.tsx" />
    </svg>;
};