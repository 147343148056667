import { enableDebug } from 'app.constant';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import { Dispatch } from 'redux';

import Rox from 'rox-browser';

export const SETUP_ROLLOUT: RequestAction = createRequestAction('SETUP_ROLLOUT_REQUEST');

// Create a Roxflag in the flags container class
const featureFlags: any = {
  isRBACVerificationEnabledOnBaseFront: new Rox.Flag(false)
};

export const getFeatureFlags = (context: any) => {
  const targetObj: any = {};
  Object.keys(featureFlags).forEach((flagKey: string) => {
    if (featureFlags[flagKey]._type === 'boolean') {
      targetObj[flagKey] = featureFlags[flagKey].isEnabled(context);
    } else {
      targetObj[flagKey] = featureFlags[flagKey].getValue(context);
    }
    return flagKey;
  });
  return targetObj;
};

export const initRollout = (configs: any) => async (dispatch: Dispatch) => {
  try {
    const { forceDefault, userContext } = configs || {};
    dispatch({ type: SETUP_ROLLOUT.REQUEST });
    const ROLLOUT_KEY: any = process.env.REACT_APP_ROLLOUT_KEY;
    Rox.register('', featureFlags);
    if (forceDefault) {
      dispatch({ type: SETUP_ROLLOUT.SUCCESS, payload: getFeatureFlags(featureFlags) });
    } else {
      // Set global context
      Rox.setContext(userContext);
      Rox.setCustomStringProperty('user.id', userContext.id);
      return Rox.setup(ROLLOUT_KEY, {})
        .then(() => {
          setTimeout(() => {
            // tslint:disable-next-line:no-console
            if(enableDebug) {
              console.log('featureFlags initialized - ', getFeatureFlags(featureFlags));
            }
            const data = getFeatureFlags(featureFlags);
            dispatch({ type: SETUP_ROLLOUT.SUCCESS, payload: data });
          }, 100);
        })
        .catch((error: any) => {
          return dispatch({ type: SETUP_ROLLOUT.ERROR, error });
        });
    }
  } catch (e) {
    dispatch({ type: SETUP_ROLLOUT.ERROR, e });
    throw e;
  }
};
