import { Layout } from 'antd';
import { verifyRbacPermissions } from 'app-redux/actions/rbac.actions';
import { AppState } from 'app-redux/reducers';
import { getCurrentUser } from 'app-redux/selectors/accounts.selector';
import { getAllowedAndDeniedOperationsDTO, getDevConsoleAccess, rbacPermissionsCheck } from 'app-redux/selectors/rbac.selector';
import { MenuBarComponent } from 'components/menu-component';
import { IUserAccount } from 'core/accounts/models';
import { INavigationRouteProps } from 'core/navigation/models';
import { AllowedAndDeniedOperationsDTO, OperationListDTO } from 'core/rbac/models';
import { UUID } from 'core/utils/BasicModels';
import { AuthenticationRedirectComponent } from 'pages/authentication-redirect-component';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { MainRoutes } from 'routes';
import { ErrorModal } from './ErrorModal';
import RequestAccessAddon from './requestAccessAddon';
import './style.less';
import TopNavBar from './topNavBar';
const getDevSystemOperations = () => {
  const systemResource = 'crn:system';
  return {
    operations: [{
      action: 'dev_console:read',
      resource: systemResource
    }]
  };
};
interface ImainContainerProps extends INavigationRouteProps<{}> {
  currentUser?: IUserAccount;
  allowedAndDeniedOperationsDTO?: AllowedAndDeniedOperationsDTO;
  devConsoleAccess?: AllowedAndDeniedOperationsDTO;
  currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string, isDevConsoleCheck: boolean) => boolean;
  verifyRbacPermissions: (operationsListDTO: OperationListDTO) => any;
}
export const MainContainer: React.FC<ImainContainerProps> = props => {
  const sidebarMemo = useMemo(() => {
    return <MenuBarComponent />;
  }, [props.location.pathname, props.currentUser]);
  const RequestAccessMemo = useMemo(() => {
    if (!props.currentUser) {
      return <AuthenticationRedirectComponent />;
    }
    if (props.devConsoleAccess) {
      const userHasDevConsolePermission = props.currentUserHasPermission('', 'ORGANIZATION', 'DEV', 'READ', true);
      return userHasDevConsolePermission ? <MainRoutes /> : <RequestAccessAddon />;
    }
  }, [props.currentUser, props.devConsoleAccess]);
  useEffect(() => {
    if (props.currentUser) {
      props.verifyRbacPermissions(getDevSystemOperations());
    }
  }, [props.currentUser]);
  return <Layout style={{
    height: '100vh'
  }} data-sentry-element="Layout" data-sentry-component="MainContainer" data-sentry-source-file="index.tsx">
      <TopNavBar data-sentry-element="TopNavBar" data-sentry-source-file="index.tsx" />
      <Layout data-sentry-element="Layout" data-sentry-source-file="index.tsx">
        <ErrorModal data-sentry-element="ErrorModal" data-sentry-source-file="index.tsx" />
        {sidebarMemo}
        <Layout.Content style={{
        overflow: 'auto',
        backgroundColor: '#F5F8FC'
      }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
          {RequestAccessMemo}
        </Layout.Content>
      </Layout>
    </Layout>;
};
const mapStateToProps = (state: AppState) => {
  return {
    currentUser: getCurrentUser(state),
    currentUserHasPermission: (id: UUID, context: string, permission: string, scope: string, isDevConsoleCheck: boolean) => rbacPermissionsCheck(state, id, context, permission, scope, isDevConsoleCheck),
    allowedAndDeniedOperationsDTO: getAllowedAndDeniedOperationsDTO(state),
    devConsoleAccess: getDevConsoleAccess(state)
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    verifyRbacPermissions: (operationsListDTO: OperationListDTO) => dispatch(verifyRbacPermissions(operationsListDTO))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);