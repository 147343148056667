import { AxiosResponse } from 'axios';
import { CreatePlanDTO, PlanDTO, UpdatePlanDTO } from 'core/plans/models';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import qs from 'querystring';
import { Dispatch } from 'redux';
import { axiosRequest } from '../../core/axios';
import { getHeaders } from '../../core/common-methods';
import { IQueryParam, PageDTO, UUID } from '../../core/utils/BasicModels';
import { Update } from 'pages/licensing-v2/models';

export const ACTION_GET_PLANS_LIST: RequestAction = createRequestAction('GET_PLANS_LIST_REQUEST');
export const ACTION_GET_PLAN: RequestAction = createRequestAction('GET_PLAN_REQUEST');
export const ACTION_CREATE_PLAN: RequestAction = createRequestAction('CREATE_PLAN_REQUEST');
export const ACTION_RESET_GET_PLAN: RequestAction = createRequestAction('RESET_GET_PLAN_REQUEST');
export const ACTION_EDIT_PLAN: RequestAction = createRequestAction('EDIT_PLAN_REQUEST');
export const ACTION_DELETE_PLAN: RequestAction = createRequestAction('DELETE_PLAN_REQUEST');
export const ACTION_SET_CURRENT_PLAN = 'SET_CURRENT_PLAN_REQUEST';

export const fetchAllPlansByApp = (appId: UUID, page: number, size: number, lastKey: string, planName?: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PLANS_LIST.REQUEST });

    const pageInfo: IQueryParam = {
      size,
    };

    if (planName) {
      pageInfo.name = planName;
    }

    if (lastKey !== '') {
      pageInfo.last_key = lastKey;
    }

    const allPlans = await axiosRequest
        .get<any, AxiosResponse<PageDTO<PlanDTO>>>(`/v2/apps/${appId}/plans?${qs.stringify(pageInfo)}`,
      {
        headers: getHeaders().common,
      });

    dispatch({ type: ACTION_GET_PLANS_LIST.SUCCESS, payload: allPlans.data });
    return allPlans.data;
  } catch (e) {
    dispatch({ type: ACTION_GET_PLANS_LIST.ERROR, payload: e });
    throw e;
  }
};

export const getPlanById = (planId: UUID) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PLAN.REQUEST });

    const plan = await axiosRequest
            .get<any, AxiosResponse<PlanDTO>>(`/v2/plans/${planId}`, {
              headers: {
                ...getHeaders().common,
              },
            });

    const data = plan.data;

    dispatch({ type: ACTION_GET_PLAN.SUCCESS, payload: data });

    return data;
  } catch (e) {
    dispatch({ type: ACTION_GET_PLAN.ERROR, payload: e });
    throw e;
  }
};

async function updateEntitlements(planId: string, updateOperations: Update) {
  const entitlements = await axiosRequest
        .post(`/v2/plans/${planId}/entitlements`, updateOperations,
  {
    headers: {
      ...getHeaders().common,
    },
  }) 
  return entitlements.data.all_plan_entitlements

  // @TODO After the backend has the quotas endpoints
  // Call plans/quotas
}

export const createPlan = (valuesRequest: CreatePlanDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_PLAN.REQUEST });

    const entitlements = valuesRequest.entitlements as unknown as Update;
    
    valuesRequest.entitlements = []

    const plan = await axiosRequest
            .post<any, AxiosResponse<PlanDTO>>(`/v2/plans`, valuesRequest,
      {
        headers: {
          ...getHeaders().common,
        },
      });

    if (entitlements?.updates?.length) {
      plan.data.entitlements = await updateEntitlements(plan.data.id as string, entitlements )
    }

    const data = plan.data;

    dispatch({ type: ACTION_CREATE_PLAN.SUCCESS, payload: data });
    return data;

  } catch (e) {
    dispatch({ type: ACTION_CREATE_PLAN.ERROR, payload: e });
    throw e;
  }
};

export const editPlan = (planId: UUID, valuesRequest: UpdatePlanDTO) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_EDIT_PLAN.REQUEST });
    const entitlements = valuesRequest.entitlements as unknown as Update;
    
    valuesRequest.entitlements = []

    const planEdited = await axiosRequest
            .put<any, AxiosResponse<PlanDTO>>(`/v2/plans/${planId}`, valuesRequest,
      {
        headers: {
          ...getHeaders().common,
        },
      });

    if (entitlements?.updates?.length) {
      planEdited.data.entitlements = await updateEntitlements(planId, entitlements )
    }

    dispatch({ type: ACTION_EDIT_PLAN.SUCCESS, payload: planEdited.data });

    return planEdited.data;

  } catch (e) {
    dispatch({ type: ACTION_EDIT_PLAN.ERROR, payload: e });
  }
};

export const deletePlan = (planId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_PLAN.REQUEST });

    const deletePlanResponse = await axiosRequest
        .delete(`/v2/plans/${planId}`,
      {
        headers: {
          ...getHeaders().common,
        },
      });

    dispatch({ type: ACTION_DELETE_PLAN.SUCCESS, payload: deletePlanResponse });

    return deletePlanResponse;
  } catch (e) {
    dispatch({ type: ACTION_DELETE_PLAN.ERROR, payload: e });
    throw e;
  }
};

export const resetGetPlan = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_RESET_GET_PLAN.REQUEST });

    const data = '';

    dispatch({ type: ACTION_RESET_GET_PLAN.SUCCESS, payload: data });

    return data;
  } catch (e) {
    dispatch({ type: ACTION_RESET_GET_PLAN.ERROR, payload: e });
    throw e;
  }
};

export const setCurrentPlan = (currentPlanId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ACTION_SET_CURRENT_PLAN, payload: currentPlanId });
};
