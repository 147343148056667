import { Button, Select } from 'antd';
import { FlexContainer } from '../../../styled';
import styled from 'styled-components';
export const BGContainer = styled.div`
  position: relative;
  height: 100%;

  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  border-radius: 12px;
`;
export const Container = styled(FlexContainer)`
  height: 100%;
  flex-direction: column;
  padding: 16px 24px;
  position: relative;
  z-index: 1;
`;
export const Header = styled(FlexContainer)`
  justify-content: flex-start;
  width: 100%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;

  span {
    color: #fff;
  }
`;
export const Panel = styled(FlexContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  width: 50%;
  min-height: 100%;
`;
export const RightPanel = styled(Panel)`
  margin-left: 12px;
  padding-left: 12px;
  padding-bottom: 12px;
  border-left: 1px solid rgb(223 226 231 / 20%);
  width: calc(50% - 12px);
  max-width: calc(50% - 12px);
  min-width: calc(50% - 12px);
`;
export const Footer = styled(FlexContainer)`
  width: 100%;
  justify-content: space-between;
`;
export const Content = styled(FlexContainer)`
  flex: 1;
  width: 100%;
  margin: 24px auto;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1400px;
  color: #fff;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 5px;
    background-color: #c2c7d0;
  }
`;
export const Mask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.8) 100%);
  background-blend-mode: multiply;
  z-index: 0;
  border-radius: 12px;
`;
export const PanelTitle = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;
export const BackBtn = styled(FlexContainer)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 12px;
  background-color: #fff;
  border: 1px solid #c2c7d0;
  cursor: pointer;
  transition: 300ms;

  &:hover {
    opacity: 0.8;
  }
`;
export const CancelBtn = styled(Button)`
  color: #fff;
  background-color: transparent;
  border: none;
  margin-right: 8px;

  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
    background-color: transparent !important;
    border: none !important;
    opacity: 0.8;
  }
`;
export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #dfe2e7;
  opacity: 0.2;
  margin: 8px 0;
`;
export const RequiredAppsSelect = styled(Select)`
  background: #232630;
  color: #fff;
  border-radius: 4px;

  [class*='-select-selection-item'] {
    color: #232630 !important;
  }

  [class*='-select-selection-placeholder'] {
    left: 13px !important;
    color: #868ca2 !important;
  }

  [class*='-select-selection-item'],
  [class*='-select-selection-search'],
  [class*='-select-selector']::after {
    height: 24px !important;
    line-height: 24px !important;
    font-size: 12px !important;
  }

  [class*='-select-selector'] {
    padding: 5px 6px !important;
  }
`;