import {
  ACTION_CREATE_API_CLIENT,
  ACTION_DELETE_API_CLIENT,
  ACTION_EDIT_API_CLIENT,
  ACTION_GET_API_CLIENT,
  ACTION_GET_API_CLIENTS_LIST,
  ACTION_RESET_GET_API_CLIENT,
  ACTION_SET_CURRENT_API_CLIENT,
} from 'app-redux/actions/api-client.actions';
import { PagedOAuth2ClientDTO } from 'core/api-clients/models';
import { PayloadAction } from 'core/utils/actionUtils';

export interface ApiClientState {
  currentApiClient?: string;
  apiClients?: PagedOAuth2ClientDTO;
  loading?: boolean;
  error?: any;
}

const initialState: ApiClientState = {
};

export const apiClientReducer = (state: ApiClientState = initialState, action: PayloadAction<any>): ApiClientState => {
  switch (action.type) {
    case ACTION_GET_API_CLIENTS_LIST.REQUEST:
    case ACTION_GET_API_CLIENT.REQUEST:
    case ACTION_CREATE_API_CLIENT.REQUEST:
    case ACTION_DELETE_API_CLIENT.REQUEST:
    case ACTION_RESET_GET_API_CLIENT.REQUEST:
    case ACTION_EDIT_API_CLIENT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_GET_API_CLIENTS_LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        apiClients: action.payload,
      };
    case ACTION_GET_API_CLIENTS_LIST.ERROR:
    case ACTION_GET_API_CLIENT.ERROR:
    case ACTION_CREATE_API_CLIENT.ERROR:
    case ACTION_DELETE_API_CLIENT.ERROR:
    case ACTION_RESET_GET_API_CLIENT.ERROR:
    case ACTION_EDIT_API_CLIENT.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACTION_GET_API_CLIENT.SUCCESS:
      return {
        ...state,
        loading: false,
        currentApiClient: action.payload.client_id,
      };
    case ACTION_CREATE_API_CLIENT.SUCCESS:
    case ACTION_DELETE_API_CLIENT.SUCCESS:
    case ACTION_EDIT_API_CLIENT.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTION_SET_CURRENT_API_CLIENT:
      return {
        ...state,
        currentApiClient: action.payload,
      };
    case ACTION_RESET_GET_API_CLIENT.SUCCESS:
      return {
        ...state,
        loading: false,
        currentApiClient: action.payload,
      };
    default:
      return state;
  }
};
