import { RightOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import { DataNode } from 'antd/lib/tree';
import contributeIcon from 'assets/images/contributeIcon.png';
import guideIcon from 'assets/images/guideIcon.png';
import JavaIcon from 'assets/images/JavaIcon.png';
import sdkLeafImage from 'assets/images/leaf.png';
import React from 'react';
import { Divider, Tree, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import treeContent from './content-tree.json';
import './style.less';
const {
  Content
} = Layout;
const {
  Text
} = Typography;
const JavaSDKLink = 'https://github.com/syngenta-digital/cropwise-java-sdk';
const NodeSDKLink = 'https://github.com/syngenta-digital/cropwise-node-sdk';
const BoilerplateLink = 'https://github.com/syngenta-digital/web-react-cropwise-boilerplate-app#cropwise-boilerplate-app';
const {
  TreeNode
} = Tree;
const docContent = treeContent.contentData;
interface DataContent {
  title: React.ReactNode;
  link?: string;
  children?: DataContent[];
}
interface ContentTreeProps {
  contentData: DataContent[];
}
const ContentTree: React.FC<ContentTreeProps> = props => {
  const renderTreeNodes = (data: DataContent[], level = 0): DataNode[] => data.map((item, index) => {
    const title = item.link ? <label className="syngenta-ant-tree-content-title" onClick={() => window.open(item.link, '_blank')}>{item.title}</label> : item.title;
    return {
      ...item,
      title,
      children: item.children ? renderTreeNodes(item.children, level + 1) : undefined,
      key: level + '-' + index + 'KEY'
    };
  });
  return <Tree defaultExpandAll={true} treeData={renderTreeNodes(props.contentData)} data-sentry-element="Tree" data-sentry-component="ContentTree" data-sentry-source-file="index.tsx" />;
};
interface SDKCardProps {
  title: string;
  installationLink: string;
  contributeLink: string;
  enterLink: string;
}
const SDKCard: React.FC<SDKCardProps> = props => {
  const {
    title,
    installationLink,
    contributeLink,
    enterLink
  } = props;
  return <div className="sdkOverviewContentData" data-sentry-component="SDKCard" data-sentry-source-file="index.tsx">

          {/* Thumbnail -1 */}
          <div className="overviewThumbnail">
              {/* Inner Thumbnail - Block  */}
              <div className="overviewInnerThumbnail">
                  <div className="overviewInnerData">
                      <div className="InnerLeftBlock">
                          <div className="innerLeafBlock">
                              <img src={sdkLeafImage} className="innerLeafImage" alt="Leaf Image" />
                          </div>
                      </div>

                      <div className="innerRightBlock">
                          <div className="innerThumbnailHeading">
                              {title}
                              <RightOutlined className="coreSdkIcon" onClick={() => window.open(enterLink, '_blank')} data-sentry-element="RightOutlined" data-sentry-source-file="index.tsx" />
                          </div>
                          <div className="technologiesBlock">
                              <div className="greenCircle" />
                              <span>Technologies</span>
                          </div>
                          <div className="technologiesIconBlock">
                              <div className="technologiesIcon">
                                  <img src={JavaIcon} alt="Java Icon" />
                              </div>
                          </div>
                          <div className="clrFloat" />
                      </div>

                  </div>
                  <div className="clrFloat" />
              </div>

              {/* Outer Thumbnail - Block */}
              <div className="outerThumbData">
                  <div className="installtionGuide" onClick={() => window.open(installationLink, '_blank')}>
                      <img src={guideIcon} alt="guide Icon" />
                      <span>Installation Guide</span>
                  </div>
                  <div className="contribute" onClick={() => window.open(contributeLink, '_blank')}>
                      <img src={contributeIcon} alt="Contribute Icon" />
                      <span>Contribute</span>
                  </div>
              </div>
              <div className="clrFloat" />
          </div>

      </div>;
};
export const SDKsOverview: React.FC<{}> = props => {
  return <>
            <Content className="sdkOverviewContent" data-sentry-element="Content" data-sentry-source-file="index.tsx">
                {/* Heading - documentation */}
                <Text strong={true} style={{
        fontSize: '20px',
        color: '#2F3031'
      }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Documentation</Text>

                {/* Table of documentation */}
                <ContentTree contentData={docContent} data-sentry-element="ContentTree" data-sentry-source-file="index.tsx" />
                <Divider style={{
        marginTop: '15px'
      }} data-sentry-element="Divider" data-sentry-source-file="index.tsx" />

                {/* Heading - SDK */}
                <Text strong={true} style={{
        fontSize: '20px',
        color: '#2F3031'
      }} data-sentry-element="Text" data-sentry-source-file="index.tsx">Take a tour into our available SDKs</Text>

                {/* SDK Content - Block  */}
                <SDKCard title={'Core API Java SDK'} enterLink={JavaSDKLink} contributeLink={JavaSDKLink} installationLink={JavaSDKLink} data-sentry-element="SDKCard" data-sentry-source-file="index.tsx" />

                <SDKCard title={'Core API Node SDK'} enterLink={NodeSDKLink} contributeLink={NodeSDKLink} installationLink={NodeSDKLink} data-sentry-element="SDKCard" data-sentry-source-file="index.tsx" />

                <SDKCard title={'Cropwise App Boilerplate'} enterLink={BoilerplateLink} contributeLink={BoilerplateLink} installationLink={BoilerplateLink} data-sentry-element="SDKCard" data-sentry-source-file="index.tsx" />
            </Content>

        </>;
};
export default SDKsOverview;