import { ArrowLeftOutlined } from '@ant-design/icons';
import React from 'react';
import { Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import './style.less';
interface ICardPageHeader {
  title: string;
  onBack?: () => void;
  children?: any;
}
export const CardPageHeader: React.FC<ICardPageHeader> = (props: ICardPageHeader) => {
  return <header className="card-page-header" data-sentry-component="CardPageHeader" data-sentry-source-file="index.tsx">
    {props.onBack && <ArrowLeftOutlined data-testid="card-page[arrow-back]" className="card-page-header-back-button" onClick={props.onBack} />}
      <Typography.Text strong={true} className="card-page-header-title" data-sentry-element="unknown" data-sentry-source-file="index.tsx">{props.title}</Typography.Text>
    {props.children}
  </header>;
};