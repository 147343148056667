import { InfoIcon } from 'pages/integrations/images/InfoIcon';
import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { FlexContainer } from 'pages/integrations/styled';
interface Props {
  title: string;
  showInfo?: boolean;
}
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(198, 230, 255, 0.2);
  width: 100%;

  p {
    margin: 0 4px 0 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #dfe2e7;
  }
`;
export const PreviewSectionTitle: React.FC<Props> = ({
  title,
  showInfo
}) => {
  return <StyledDiv data-sentry-element="StyledDiv" data-sentry-component="PreviewSectionTitle" data-sentry-source-file="preview-section-title.tsx">
      <p>{title}</p>
      {showInfo && <Tooltip title="The file must be JPG/PNG and smaller than 2MB." color="#fff" overlayInnerStyle={{
      color: '#14151C',
      borderRadius: 4
    }}>
          <FlexContainer>
            <InfoIcon />
          </FlexContainer>
        </Tooltip>}
    </StyledDiv>;
};