import { ACTION_GET_ALL_TERMS_LIST } from 'app-redux/actions/terms.actions';
import { TermsDTO } from 'core/terms/models';
import { PayloadAction } from 'core/utils/actionUtils';

export interface TermsState {
  allTerms?: TermsDTO[];
  loading?: boolean;
  error?: any;
}

const initialState: TermsState = {
};

export const TermsReducer = (state: TermsState = initialState, action: PayloadAction<any>): TermsState => {
  switch (action.type) {
    case ACTION_GET_ALL_TERMS_LIST.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_GET_ALL_TERMS_LIST.SUCCESS:
      return {
        ...state,
        allTerms: action.payload.content,
        loading: false,
      };
    case ACTION_GET_ALL_TERMS_LIST.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
