import { Checkbox, Divider, Input, Popover, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { FormEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { useDebounced } from 'core/common-methods';
import { FixedSizeList as List } from 'react-window';
import { FlexContainer } from 'pages/integrations/styled';
import { ArrowDownIcon } from 'pages/integrations/images/ArrowDownIcon';
import './style.less';
message.config({
  prefixCls: 'syngenta-ant-message'
});
const SelectContainer = styled(FlexContainer)`
  width: 100%;
  border: 1px solid #e8eaed;
  border-radius: 4px;
  padding: 0 6px 0 10px;
  color: #14151c;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  cursor: pointer;
  transition: border-color 300ms ease-in-out, box-shadow 300ms ease-in-out;

  &:hover:not(.disabled) {
    border-color: #0092e4 !important;
  }

  &.focused:not(.disabled) {
    border-color: #0092e4 !important;
    box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25);
  }

  span:not(.placeholder) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 18px);
  }

  span.placeholder {
    color: #868ca2;
  }

  ${({
  dark
}: {
  dark?: boolean;
}) => dark === true && `
      background-color: #232630;
      border-color: #232630;

      span:not(.placeholder) {
        color: #fff;
      }

      &:hover:not(.disabled) {
        border-color: #868ca2 !important;
      }

      &.focused:not(.disabled) {
        border-color: #868ca2 !important;
        box-shadow: 0px 0px 0px 2px rgba(134, 140, 162, 0.25) !important;
      }

      &.disabled {
        background-color: #363948 !important;
        border-color: #363948 !important;
        cursor: not-allowed;
        span {
          color: #696f88 !important;
        }
      }
  `}
`;
interface BaseProps<T> {
  options: {
    value: T;
    label: string;
  }[];
  showSearch?: boolean;
  multiple?: boolean;
  allSelectedLabel?: string;
  placeholder?: string;
  width?: React.CSSProperties['width'];
  dark?: boolean;
  formatter?: (checkedOptions: T[]) => string;
  lazy?: boolean;
  loadOnOpen?: () => Promise<BaseProps<T>['options']>;
  disabled?: boolean;
  dataTestId?: string;
}
interface AsMultiple<T> extends BaseProps<T> {
  multiple: true;
  value: T[];
  onApply: (checkedList: T[]) => void;
}
interface AsDefault<T> extends BaseProps<T> {
  multiple: false;
  value: T;
  onApply: (checkedList: T) => void;
}
export type CustomSelectProps<T> = AsMultiple<T> | AsDefault<T>;
export const CustomSelect = <T,>({
  width,
  dark,
  placeholder,
  lazy = true,
  loadOnOpen,
  formatter,
  options: defaultOptions,
  disabled = false,
  dataTestId = 'custom-select',
  ...props
}: CustomSelectProps<T>) => {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contentWidth, setWidth] = useState(0);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const isEmpty = !props.value || (props as AsMultiple<T>).value?.length === 0;
  const isAllSelected = props.multiple && props.value.length === options.length;
  const ref = useRef<HTMLDivElement | null>(null);
  const load = useCallback(() => {
    setLoading(true);
    return loadOnOpen?.().then(options => {
      setOptions(options);
    }).catch(async () => {
      await message.error(`We couldn't load the options for the ${placeholder} select, please try again!`);
    }).finally(() => {
      setLoading(false);
      setLoaded(true);
    });
  }, [loadOnOpen, placeholder]);
  useEffect(() => {
    if (!lazy && !loading && !loaded) {
      load()?.finally(() => {
        setLoading(false);
      });
    }
  }, [lazy, loaded, loading, placeholder, load]);
  const handleOpenChange = (newOpen: boolean) => {
    if (!disabled) {
      if (!loading) {
        if (newOpen === true && loadOnOpen && !loaded) {
          load()?.finally(() => {
            setOpen(newOpen);
          });
        } else {
          setOpen(newOpen);
        }
      }
    }
  };
  const renderPlaceholder = useCallback(() => <span className="placeholder">{placeholder}</span>, [placeholder]);
  useLayoutEffect(() => {
    setWidth(ref.current?.clientWidth ?? 0);
  }, []);
  const renderPreview = useCallback(() => {
    let preview: string | JSX.Element | undefined;
    if (!isEmpty) {
      if (props.multiple) {
        if (isAllSelected && props.allSelectedLabel) {
          preview = props.allSelectedLabel;
        } else {
          const formatted = formatter?.(props.value);
          preview = formatted ? formatted : options.filter(opt => props.value.includes(opt.value)).map(opt => opt.label).join(', ');
        }
      } else {
        preview = options.find(opt => opt.value === props.value)?.label;
      }
    }

    // If is empty or all the other approaches didn't return a valid label
    if (!preview) {
      preview = renderPlaceholder();
    }
    return preview;
  }, [props.multiple, props.allSelectedLabel, props.value, isEmpty, isAllSelected, options, formatter, renderPlaceholder]);
  return <Popover destroyTooltipOnHide content={<Content onClose={() => setOpen(false)} open={open} width={contentWidth} options={options} {...props} />} trigger="click" open={open} onOpenChange={handleOpenChange} placement="bottomLeft" showArrow={false} overlayClassName="custom-select-popover" data-sentry-element="Popover" data-sentry-component="CustomSelect" data-sentry-source-file="index.tsx">
      <SelectContainer data-testid={dataTestId} ref={ref} justify="space-between" className={`${open ? 'focused' : ''} ${disabled ? 'disabled' : ''}`} style={{
      width
    }} dark={dark} data-sentry-element="SelectContainer" data-sentry-source-file="index.tsx">
        <span>{renderPreview()}</span>
        {loading ? <LoadingOutlined data-testid="custom-select-loading" spin={true} style={{
        color: '#868ca2'
      }} className="placeholder" /> : <ArrowDownIcon />}
      </SelectContainer>
    </Popover>;
};
const Footer = styled(FlexContainer)`
  padding: 6px 12px;
`;
const OptionsContainer = styled(List)`
  overflow-y: auto !important;
  overflow-y: overlay !important;
  min-height: 50px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 5px;
    background-color: #c2c7d0;
  }
`;
const StyledInput = styled(Input)`
  border-radius: 4px;

  [class*='-input-suffix'] {
    margin: 0 -2px 0 8px;

    svg {
      width: 14px;
    }
  }

  &:hover {
    border: 1px solid #0092e4 !important;
  }
`;
type ContentProps<T> = CustomSelectProps<T> & {
  onClose: () => void;
  open: boolean;
  width: number;
};
const Content = <T,>({
  onClose,
  open,
  options,
  value,
  onApply,
  allSelectedLabel,
  showSearch = true,
  width,
  multiple
}: ContentProps<T>) => {
  const allAvailableValues = useMemo(() => options.map(({
    value
  }) => value), [options]);
  const resetChecked = useCallback(() => {
    if (Array.isArray(value)) {
      return value.filter(v => allAvailableValues.includes(v));
    } else if (allAvailableValues.includes(value)) {
      return value;
    }
  }, [allAvailableValues, value]);
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState('');

  // State used when multiple
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [checkedList, setCheckedList] = useState<T[]>((resetChecked() as T[]) ?? []);
  const indeterminate = useMemo(() => {
    return !!checkedList.length && checkedList.length < options.length;
  }, [checkedList, options.length]);
  const checkAll = useMemo(() => {
    return checkedList.length === options.length;
  }, [checkedList, options.length]);

  // State used when default
  const [checked, setChecked] = useState<T>((resetChecked() as T));
  useEffect(() => {
    if (open === true) {
      setSearch('');
      setCheckedList((resetChecked() as T[]) ?? []);
      setFilteredOptions(options);
      setChecked((resetChecked() as T));
    }
  }, [open, options, resetChecked]);
  const onChange = (e: CheckboxChangeEvent) => {
    if (multiple) {
      let newChecked = [...checkedList];
      if (e.target.checked) {
        newChecked.push(e.target.value);
      } else {
        newChecked = newChecked.filter(code => code !== e.target.value);
      }
      setCheckedList(newChecked);
      if (!showSearch) {
        onApply(newChecked);
      }
    } else {
      setChecked(e.target.value);
      if (!showSearch) {
        onApply(e.target.value);
        onClose();
      }
    }
  };
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? options.map(opt => opt.value) : []);
  };
  const handleSearch = useDebounced((value: string) => {
    if (!value) setFilteredOptions(options);else setFilteredOptions(options.filter(opt => opt.label.toLowerCase().includes(value.toLowerCase())));
    setSearching(false);
  });
  return <div data-testid="custom-select-content" style={{
    position: 'relative',
    padding: showSearch ? 0 : '4px 0',
    width,
    minWidth: 'max-content'
  }} data-sentry-component="Content" data-sentry-source-file="index.tsx">
      {showSearch && <>
          <div style={{
        padding: '12px 16px'
      }}>
            <Checkbox data-testid="custom-select-check-all" indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} style={{
          fontWeight: 600
        }}>
              {allSelectedLabel}
            </Checkbox>
          </div>
          <Divider style={{
        margin: 0
      }} />
          <div style={{
        padding: '12px 16px 8px'
      }}>
            <StyledInput data-testid="custom-select-search" size="small" placeholder="Search..." suffix={searching ? <LoadingOutlined /> : <SearchOutlined />} onInput={(e: FormEvent<HTMLInputElement>) => {
          setSearching(true);
          const value = (e.target as HTMLInputElement).value;
          setSearch(value);
          handleSearch(value);
        }} value={search} />
          </div>
          {filteredOptions.length === 0 && <p style={{
        color: '#4D5165',
        fontSize: 12,
        margin: '4px 18px',
        position: 'absolute'
      }}>
              No results found
            </p>}
        </>}
      <OptionsContainer height={Math.min(208, options.length * 32)} itemCount={filteredOptions.length} itemSize={32} width={'100%'} data-sentry-element="OptionsContainer" data-sentry-source-file="index.tsx">
        {({
        index,
        style
      }) => <div style={style} className="virtualized-list">
            <Checkbox className={multiple === false ? 'hide-checkbox' : ''} value={filteredOptions[index].value} checked={checkedList.includes(filteredOptions[index].value)} onChange={onChange}>
              <p className="label">{filteredOptions[index].label}</p>
            </Checkbox>
          </div>}
      </OptionsContainer>
      {showSearch && <Footer justify="space-between">
          <Button size="small" type={ButtonType.outline} onClick={onClose}>
            Cancel
          </Button>
          <Button size="small" type={ButtonType.primary} onClick={() => {
        if (multiple) {
          onApply(checkedList);
        } else {
          onApply(checked);
        }
        onClose();
      }}>
            Apply
          </Button>
        </Footer>}
    </div>;
};