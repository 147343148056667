import styled from 'styled-components';

export interface FlexContainerProps {
  direction?: 'row' | 'column';
  align?: 'center' | 'flex-start';
  justify?: 'center' | 'space-between' | 'flex-start' | 'flex-end';
  gap?: number;
}
export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  align-items: ${({ align = 'center' }) => align};
  justify-content: ${({ justify = 'center' }) => justify};

  ${({ gap }) =>
    gap &&
    `
    gap: ${gap}px;
  `}
`;

export const IntegrationsContent = styled.div<{ fullHeight: boolean }>`
  margin: 24px;
  border: 1px solid #e8eaed;
  border-radius: 12px;
  width: calc(100% - 48px);
  height: ${(props) => (props.fullHeight ? 'calc(100% - 48px);' : 'auto')};
  background-color: #ffffff;
`;
