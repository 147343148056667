import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';
import './styles.less';
const StyledModal = styled(Modal)<{
  height: string;
  width: string;
  rightWidth: string;
}>`
    .ant-modal-content .ant-modal-body {
      padding: 0 !important;
      padding-bottom: 24px !important;
    }

    .syt-antd-modal-content {
      height: ${p => p.height};
      position: absolute;
      width: ${p => p.width};
    }

    .right {
      width: ${p => p.rightWidth};
    }
`;
interface CustomModalProps {
  cancelButton?: any;
  content: React.ReactNode;
  height: string;
  icon?: React.ReactNode;
  okButton?: any;
  title: string;
  visible: boolean;
  width: string;
}
const CustomModal: React.FC<CustomModalProps> = ({
  cancelButton,
  content,
  height,
  icon,
  okButton,
  title,
  visible,
  width
}) => {
  const ModalIcon: React.FC = () => {
    return <div className="left" data-sentry-component="ModalIcon" data-sentry-source-file="index.tsx">
        {icon}
      </div>;
  };
  return <StyledModal cancelButtonProps={{
    className: cancelButton ? 'delete-modal-button__cancel' : 'delete-modal-button__disabled'
  }} cancelText={cancelButton ? cancelButton.text : ''} closable={false} destroyOnClose={true} height={height} keyboard={false} okButtonProps={{
    className: okButton ? 'delete-modal-button__ok' : 'delete-modal-button__disabled',
    disabled: okButton && okButton.disabled ? okButton.disabled : false,
    loading: okButton && okButton.loading ? okButton.loading : false
  }} okText={okButton ? okButton.text : ''} onCancel={cancelButton ? cancelButton.onCancel : () => {
    return;
  }} onOk={okButton ? okButton.onContinue : () => {
    return;
  }} rightWidth={icon ? '90%' : '100%'} open={visible} width={width} data-sentry-element="StyledModal" data-sentry-component="CustomModal" data-sentry-source-file="index.tsx">
      <div className="delete-modal-confirm-content">
        {icon ? <ModalIcon /> : null}
        <div className="right">
          <div className="delete-confirm-title">{title}</div>
          <div>
            {content}
          </div>
        </div>
      </div>
    </StyledModal>;
};
export default React.memo(CustomModal);